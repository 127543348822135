import React, { Component, useEffect, useState } from "react";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
  getComConfig,
} from "../../Utils/Common";
import DatePicker from "react-datepicker";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import Configs from "../../config";
import NumberFormat from "react-number-format";
import axios from "axios";
import Select from "react-select";
import moment from "moment";
import Tooling_report from "../../../src/component/Report/Tooling_po_report";

function Tooling_po() {
  const [MDBT, setMDBT] = useState(true);
  const [test, setTest] = useState({
    test: "",
  });
  const [toolingPOFilter, setToolingPOFilter] = useState({
    t_po_no: "",
    t_supplier_id: "",
    t_recieve_status: "",
    t_start_date: "",
    t_end_date: "",
  });
  const [inquiryList, setinquiryList] = useState([]);
  const [tooling_po, setTooling_po] = useState([]);
  const [supplier_option, setsupplier_option] = useState([]);
  const [receiveStatus_option, setReceiveStatus_option] = useState([]);
  const [dataGenarateReport, setDataGenarateReport] = useState({
    data: [],
  });
  var config_sub = {
    method: "get",
    url: Configs.API_URL + "/tooling/getToolingSupplier?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_receive = {
    method: "get",
    url: Configs.API_URL + "/tooling/getToolingReceiveStatus",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  useEffect(async () => {
    axios(config_sub)
      .then(function (response) {
        const options = [{ value: "", label: "Select All" }];
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
        });
        setsupplier_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios(config_receive)
      .then(function (response) {
        const options = [{ value: "", label: "Select All" }];
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
        });
        setReceiveStatus_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
    FilterToolingPO();
  }, []);

  async function SetDate_start(date) {
    console.log("data", date);
    setToolingPOFilter({ ...toolingPOFilter, t_start_date: date });
  }
  async function SetDate_end(date) {
    setToolingPOFilter({ ...toolingPOFilter, t_end_date: date });
  }

  const Clearfilter = async () => {
    setToolingPOFilter({
      t_po_no: "",
      t_supplier_id: "",
      t_recieve_status: "",
      t_start_date: "",
      t_end_date: "",
    });
    toolingPOFilter.t_po_no = "";
    toolingPOFilter.t_supplier_id = "";
    toolingPOFilter.t_recieve_status = "";
    toolingPOFilter.t_start_date = "";
    toolingPOFilter.t_end_date = "";
    setTest({ ...test, test: "" });
    test.test = "";
    setTest({ ...test, test: "" });
    FilterToolingPO();
  };

  const FilterToolingPO = async () => {
    let start = null; //new Date(Date.UTC(new Date().getFullYear(), 0, 1));
    let end = null; //new Date(Date.UTC(new Date().getFullYear(), 11, 31));
    if (
      toolingPOFilter.t_start_date !== "" &&
      toolingPOFilter.t_end_date !== ""
    ) {
      start = new Date(moment(toolingPOFilter.t_start_date).add(1, "days")); //.add(1, 'days')
      start.setUTCHours(0, 0, 0, 0);
      end = new Date(moment(toolingPOFilter.t_end_date).add(1, "days"));
      end.setUTCHours(23, 59, 59, 999);
    } else if (toolingPOFilter.t_start_date !== "") {
      start = new Date(moment(toolingPOFilter.t_start_date).add(1, "days")); //.add(1, 'days')
      start.setUTCHours(0, 0, 0, 0);
      end = new Date(Date.UTC(new Date().getFullYear(), 11, 31));
      end.setUTCHours(23, 59, 59, 999);
    } else if (toolingPOFilter.t_end_date !== "") {
      end = new Date(moment(toolingPOFilter.t_end_date).add(1, "days"));
      end.setUTCHours(23, 59, 59, 999);
      start = new Date(Date.UTC(new Date().getFullYear(), 0, 1));
      start.setUTCHours(0, 0, 0, 0);
    }
    /* if (toolingPOFilter.t_end_date !== '') {
        } */
    console.log("ter");
    let pushData = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      t_po_no: toolingPOFilter.t_po_no.trim() || null,
      t_supplier: toolingPOFilter.t_supplier_id || null,
      t_recieve_status: toolingPOFilter.t_recieve_status || null,
      t_start_date: toolingPOFilter.t_start_date || null, // start,
      t_end_date: toolingPOFilter.t_end_date || null, // end,
    };
    console.log("pushData", pushData);
    axios({
      method: "post",
      url: Configs.API_URL + "/tooling/getToolingPO",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: pushData,
    })
      .then(async function (response) {
        console.log("response", response);
        const arrToolingPO = [];
        const arrSupplierTooling = [];
        for (let item of response.data) {
          arrToolingPO.push(item.toolingPO);
          arrSupplierTooling.push(item.supplier);
        }
        setTooling_po(arrToolingPO);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
    // });
  };

  function numberWithCommas(x) {
    return (
      <NumberFormat
        thousandSeparator={true}
        decimalScale={2}
        value={x || 0}
        displayType="text"
      />
    );
    /*  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
  }

  const Custom_Input_date = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  const [company, setcompany] = useState();
  var config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };

  useEffect(() => {
    axios(config_com)
      .then(function (response) {
        /*  console.log(response.data); */
        setcompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    // Getmaterial_order();
  }, []);
  const [toolingReport, setToolingReport] = useState([]);

  function generateReport(e) {
    Swal.fire({
      title: "คุณต้องการออก Report ใน PO นี้ใช่หรือไม่?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          toolingSupplier_id: e.tooling_supplier_id,
          tooling_poId: e.po_id,
        };
        axios({
          method: "post",
          url: Configs.API_URL + "/tooling/getToolingBySupplierId",
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: data,
        })
          .then(function (response) {
            console.log("response.data", response.data);
            setMDBT(true);
            setToolingReport(response.data);
            // for (let item of response.data.tooling_receive)
            //     console.log('item.buy_price', item.buy_price);
            setTest({ ...test, test: "" });

            Tooling_report(company, response.data, getComConfig());
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
          });
      }
    });
    //
  }

  function delDataToolingPO(e) {
    console.log("id", e);
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/tooling/delToolingPOById?id=" + e.id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            Swal.fire({
              icon: "success",
              text: "เสร็จสิ้น",
              showConfirmButton: false,
              timer: 1000,
            }).then(() => {
              let arrList = [];
              arrList = tooling_po.filter((po) => po.id !== e.id);
              setTooling_po(arrList);
              setTest({ ...test, test: "" });
              // window.location.href = "/Human_Resource/HRD/External_dev_train";
            });
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
          });
      }
    });
  }
  async function generateReport_email(el) {
    const data = {
      toolingSupplier_id: el.tooling_supplier_id,
      tooling_poId: el.po_id,
    };
    console.log(data);
    axios({
      method: "post",
      url: Configs.API_URL + "/tooling/getToolingBySupplierId",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: data,
    })
      .then(async function (response) {
        console.log("response.data", response.data);
        const temp = dataGenarateReport.data;
        if (temp) {
        //   setDataGenarateReport({ data: [] });
        //   temp.push(response.data);
        //   setDataGenarateReport({ data: temp });
        //   dataGenarateReport.data = temp;
          // console.log('dataGenarateReport.data', dataGenarateReport.data);
          // SendEmailAndFilePDF();
          Swal.fire({
            title: "คุณต้องการส่งเมลใช่หรือไม่",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `ใช่`,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: `ไม่`,
          }).then((result) => {
            if (result.isConfirmed) {
              let pdfContent = null;
              const promiseA = new Promise(async (resolve, reject) => {
                try {
                  // console.log('dataGenarateReport.data', dataGenarateReport.data);
                  //for (let item of dataGenarateReport.data) {
                    //const checkInquiry = -1; //inquiryList.data_forsave.findIndex(el => item.tooling_receive[0].tooling_supplier_id === el.supplier_id);
                    // console.log('checkInquiry', checkInquiry);
                    if (response.data.tooling_receive.length >= 0) {
                      pdfContent = await Tooling_report(
                        company,
                        response.data,
                        getComConfig(),
                        true
                      );
                      // console.log('pdfContent', pdfContent);
                      // inquiryList.data_forsave[checkInquiry].pdfContent = pdfContent;
                    }
                 // }
                  resolve();
                } catch (error) {
                  console.log("error", error);
                  reject(error);
                }
              });
      
              promiseA
                .then(() => {
                  // console.log('pdfContent', JSON.stringify(pdfContent));
                  let total = JSON.stringify(pdfContent);
                  const arrList = [];
      
                  while (total.length > 0) {
                    if (total.length >= 800000) {
                      const cutStr = total.substring(0, 800000);
                      arrList.push(cutStr);
                      total = total.substring(800000); // Update the remaining portion of the string
                    } else {
                      arrList.push(total);
                      total = ""; // The entire string has been added to arrList, so clear it
                    }
                  }
                  let mailOptions = {
                    from: "dechbodin34@gmail.com",
                    to: "dechbodin.lump@northbkk.ac.th",
                    subject: "Attachment experiment",
                    text: "My <3",
                    company_id: getUser().com,
                    oem_id: getOem(),
                    allDataInquiry:  response.data.tooling_receive[0].tooling_supplier_id ,
                    dataPDFList: arrList,
                    /* data1haf1: data1haf1,
                      data1haf2: data1haf2,
                      data2haf1: data2haf1,
                      data2haf2: data2haf2, */
                    attachments: [
                      {
                        // filename: 'attachment.pdf',
                        content: "pdfContent",
                        // path: pdfContent,
                        // contentType: 'application/pdf',
                        // encoding: 'base64'    //this line!!!!
                      },
                    ],
                  };
                  // console.log('mailOptions', mailOptions);
                  // console.log("po_id",response.data.po_id);
                  const formData = new FormData();
                  // formData.append('dataPDFList', mailOptions.dataPDFList);
                  formData.append("allDataInquiry", mailOptions.allDataInquiry);
                  formData.append("oem_id", mailOptions.oem_id);
                  formData.append("po_id", response.data.po_id);
                  formData.append("dataPDFLength", arrList.length);
                  formData.append("company_id", mailOptions.company_id);
                  formData.append("company_name", getComConfig().com_name);
                 
                  for (let item = 0; item < arrList.length; item++) {
                    formData.append(
                      "pdfContent" + item,
                      JSON.stringify(arrList[item])
                    );
                  }
              
                 
                  // Send the request with FormData
                  axios({
                    method: "post",
                    url: Configs.API_URL + "/tooling/sendEmailAndFilePDF",
                    headers: {
                      Authorization: getToken(),
                      "X-TTT": Configs.API_TTT,
                      "Content-Type": "multipart/form-data",
                    },
                    data: formData,
                  })
                    .then(function (response) {
                      if (response) {
                        Swal.fire({
                          icon: "success",
                          text: "ส่งอีเมลสำเร็จ",
                          showConfirmButton: false,
                          timer: 1000,
                        }).then(() => {
                          FilterToolingPO();
                          //window.location.reload(); //.href = "/Human_Resource/intensive_TTT/manage_product_system";
                        });
                      }
                    })
                    .catch(function (error) {
                      console.log(error);
                      Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
                      FilterToolingPO();
                    });
                })
                .catch(function (error) {
                  console.log(error);
                  Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
                });
            }
          });
         
        } else {
          Swal.fire(
            "Error",
            "เกิดข้อผิดพลาด: ไม่พบข้อมูลสำหรับ Report",
            "error"
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
    
  }

//   const SendEmailAndFilePDF = async () => {
//     Swal.fire({
//       title: "คุณต้องการส่งเมลใช่หรือไม่",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonText: `ใช่`,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       cancelButtonText: `ไม่`,
//     }).then((result) => {
//       if (result.isConfirmed) {
//         let pdfContent = null;
//         const promiseA = new Promise(async (resolve, reject) => {
//           try {
//             // console.log('dataGenarateReport.data', dataGenarateReport.data);
//             for (let item of dataGenarateReport.data) {
//               const checkInquiry = -1; //inquiryList.data_forsave.findIndex(el => item.tooling_receive[0].tooling_supplier_id === el.supplier_id);
//               // console.log('checkInquiry', checkInquiry);
//               if (checkInquiry >= 0) {
//                 pdfContent = await Tooling_report(
//                   company,
//                   item,
//                   getComConfig(),
//                   true
//                 );
//                 // console.log('pdfContent', pdfContent);
//                 // inquiryList.data_forsave[checkInquiry].pdfContent = pdfContent;
//               }
//             //}
//             resolve();
//           } catch (error) {
//             console.log("error", error);
//             reject(error);
//           }
//         });

//         promiseA
//           .then(() => {
//             // console.log('pdfContent', JSON.stringify(pdfContent));
//             let total = JSON.stringify(pdfContent);
//             const arrList = [];

//             while (total.length > 0) {
//               if (total.length >= 800000) {
//                 const cutStr = total.substring(0, 800000);
//                 arrList.push(cutStr);
//                 total = total.substring(800000); // Update the remaining portion of the string
//               } else {
//                 arrList.push(total);
//                 total = ""; // The entire string has been added to arrList, so clear it
//               }
//             }
//             let mailOptions = {
//               from: "dechbodin34@gmail.com",
//               to: "dechbodin.lump@northbkk.ac.th",
//               subject: "Attachment experiment",
//               text: "My <3",
//               company_id: getUser().com,
//               oem_id: getOem(),
//               allDataInquiry: /* inquiryList.data_forsave[0].supplier_id */ "",
//               dataPDFList: arrList,
//               /* data1haf1: data1haf1,
//                 data1haf2: data1haf2,
//                 data2haf1: data2haf1,
//                 data2haf2: data2haf2, */
//               attachments: [
//                 {
//                   // filename: 'attachment.pdf',
//                   content: "pdfContent",
//                   // path: pdfContent,
//                   // contentType: 'application/pdf',
//                   // encoding: 'base64'    //this line!!!!
//                 },
//               ],
//             };
//             // console.log('mailOptions', mailOptions);

//             const formData = new FormData();
//             // formData.append('dataPDFList', mailOptions.dataPDFList);
//             formData.append("allDataInquiry", mailOptions.allDataInquiry);
//             formData.append("oem_id", mailOptions.oem_id);
//             formData.append("company_id", mailOptions.company_id);
//             formData.append("company_name", getComConfig().com_name);
//             for (let item = 0; item < arrList.length; item++) {
//               formData.append(
//                 "pdfContent" + item,
//                 JSON.stringify(arrList[item])
//               );
//             }
//             formData.append("dataPDFLength", arrList.length);

//             // Send the request with FormData
//             axios({
//               method: "post",
//               url: Configs.API_URL + "/tooling/sendEmailAndFilePDF",
//               headers: {
//                 Authorization: getToken(),
//                 "X-TTT": Configs.API_TTT,
//                 "Content-Type": "multipart/form-data",
//               },
//               data: formData,
//             })
//               .then(function (response) {
//                 if (response) {
//                   Swal.fire({
//                     icon: "success",
//                     text: "ส่งอีเมลสำเร็จ",
//                     showConfirmButton: false,
//                     timer: 1000,
//                   }).then(() => {
//                     //window.location.reload(); //.href = "/Human_Resource/intensive_TTT/manage_product_system";
//                   });
//                 }
//               })
//               .catch(function (error) {
//                 console.log(error);
//                 Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
//               });
//           })
//           .catch(function (error) {
//             console.log(error);
//             Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
//           });
//       }
//     });
//   };

  const rowsData = [];
  for (let index = 0; index < tooling_po.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["t_po_no"] = tooling_po[index].po_no;

    rowItem["t_supplier_name"] = supplier_option.map((item) => {
      if (item.value === tooling_po[index].tooling_supplier_id) {
        return item.label;
      }
    });

    rowItem["t_price"] = (
      <>
        <div style={{ textAlign: "end" }}>
          {numberWithCommas(
            parseFloat(tooling_po[index].sum_total)
          )}
        </div>
      </>
    );

    rowItem["t_receive_status"] = (
      <>
        {tooling_po[index].receive_status ===
        "0e6bbbfb-12fd-4136-83ad-4131d2a5096d" ? (
          <div
            style={{
              border: "1px solid #00CD00",
              borderRadius: "5px",
              color: "#00CD00",
              padding: "4px 0",
              textAlign: "center",
              fontWeight: "800",
            }}
          >
            {receiveStatus_option.map((item) => {
              if (item.value === tooling_po[index].receive_status) {
                return item.label;
              }
            })}
          </div>
        ) : (
          <div
            style={{
              border: "1px solid #1E90FF",
              borderRadius: "5px",
              color: "#1E90FF",
              padding: "4px 0",
              textAlign: "center",
              fontWeight: "800",
            }}
          >
            {receiveStatus_option.map((item) => {
              if (item.value === tooling_po[index].receive_status) {
                return item.label;
              }
            })}
          </div>
        )}
      </>
    );

    rowItem["t_receive_date"] =
      tooling_po[index].receive_status ===
      "fc3bd39f-841a-4de6-ba7c-37582e299a26"
        ? ""
        : moment(tooling_po[index].receive_date).format("DD/MM/YYYY");

    rowItem["t_report"] = (
      <>
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              id={index}
              /* disabled={disable}*/
              onClick={generateReport.bind(this, tooling_po[index])}
              class={"btn btn-block btn-primary "}
            >
              Report
            </button>
          </div>
        </div>
      </>
    );

    /*  rowItem["toolingAmount"] = (
            <div style={{ textAlign: 'end' }} >
                {numberWithCommas(tooling_po[index].amount || 0)}
            </div>
        ); */

    rowItem["sendEmail"] = (
      <>
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              id={index}
              /* disabled={disable}*/
           onClick={generateReport_email.bind(this, tooling_po[index])}
              class={"btn btn-block btn-primary "}
            >
              Send Email
            </button>
          </div>
        </div>
      </>
    );
    rowItem["last_sendEmail"] =  (tooling_po[index].sent_email_date === null ? "-":moment(tooling_po[index].sent_email_date).format("DD/MM/YYYY hh:mm น."))
    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap" }}>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={tooling_po[index].id}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/Tooling_PO/generatePO/read/" +
                tooling_po[index].po_id;
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
        {tooling_po[index].receive_status ===
        "fc3bd39f-841a-4de6-ba7c-37582e299a26" ? (
          <>
            <div className="col-xl-3 col-md-3 col-xs-3 ">
              <a
                key={tooling_po[index].id}
                id="btn_delete"
                className=" btn btn-xs "
                onClick={() => {
                  window.location.href =
                    "/Warehouse_Management/Tooling_PO/generatePO/edit/" +
                    tooling_po[index].po_id;
                }}
              >
                <i class="fas fa-pencil-alt"></i>
              </a>
            </div>
            <div className="col-xl-3 col-md-3 col-xs-3 ">
              <a
                key={tooling_po[index].id}
                id={tooling_po[index].id}
                className=" btn btn-xs "
                onClick={delDataToolingPO.bind(this, tooling_po[index])}
              >
                <i class="fas fa-trash-alt"></i>
              </a>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    );

    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "PO No.",
        field: "t_po_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Supplier Name",
        field: "t_supplier_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Price",
        field: "t_price",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Receive Status",
        field: "t_receive_status",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Receive Date",
        field: "t_receive_date",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Report",
        field: "t_report",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Send Email",
        field: "sendEmail",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Last Send Email",
        field: "last_sendEmail",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  const generatePO = () => {
    window.location.href = "/Warehouse_Management/Tooling_PO/generatePO/add";
  };

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Purchase Order{"  "}
                  <a target="_blank" href={getAcademy()} rel="noreferrer">
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">Tooling PO</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-12 col-md-3 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={toolingPOFilter.t_po_no}
                    onChange={(e) => {
                      setToolingPOFilter({
                        ...toolingPOFilter,
                        t_po_no: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">PO No.</label>{" "}
                </div>
              </div>

              <div
                className="col-6 col-md-3 col-xl-2"
                style={{ zIndex: "989" }}
              >
                <div className="form-group">
                  <Select
                    options={supplier_option}
                    // ref={select_supplier}
                    value={supplier_option.find(
                      (item) => item.value === toolingPOFilter.t_supplier_id
                    )}
                    onChange={(e) => {
                      //console.log(e);
                      if (e !== null) {
                        setToolingPOFilter({
                          ...toolingPOFilter,
                          t_supplier_id: e.value || "",
                        });
                      }
                    }}
                  />
                  <label htmlFor="">Supplier</label>
                </div>
              </div>
              <div
                className="col-6 col-md-3 col-xl-2"
                style={{ zIndex: "989" }}
              >
                <div className="form-group">
                  <Select
                    options={receiveStatus_option}
                    // ref={select_supplier}
                    value={receiveStatus_option.find(
                      (item) => item.value === toolingPOFilter.t_recieve_status
                    )}
                    onChange={(e) => {
                      //console.log(e);
                      if (e !== null) {
                        setToolingPOFilter({
                          ...toolingPOFilter,
                          t_recieve_status: e.value || "",
                        });
                      }
                    }}
                  />
                  <label htmlFor="">Receive Status</label>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div
                className="col-12 col-md-4 col-xl-3"
                style={{
                  "z-index": "987",
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "5px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <label
                  style={{ whiteSpace: "nowrap", fontSize: "x-large" }}
                  htmlFor=""
                >
                  Start Date
                </label>
                <div className="form-group ">
                  <DatePicker
                    selected={toolingPOFilter.t_start_date}
                    dateFormat={"dd-MM-yyyy"}
                    popperPlacement="bottom"
                    popperModifiers={{
                      flip: {
                        behavior: ["bottom"], // don't allow it to flip to be above
                      },
                      preventOverflow: {
                        enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                      },
                      hide: {
                        enabled: false, // turn off since needs preventOverflow to be enabled
                      },
                    }}
                    onChange={async (date) => {
                      setToolingPOFilter({
                        ...toolingPOFilter,
                        t_start_date: date,
                      });
                    }}
                    // selectsStart
                    startDate={toolingPOFilter.t_start_date}
                    maxDate={toolingPOFilter.t_end_date}
                    customInput={<Custom_Input_date />}
                  />
                </div>
              </div>

              <div
                className="col-12 col-md-4 col-xl-3"
                style={{
                  "z-index": "99",
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "5px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <label
                  style={{ whiteSpace: "nowrap", fontSize: "x-large" }}
                  htmlFor=""
                >
                  Date End
                </label>
                <div className="form-group ">
                  <DatePicker
                    selected={toolingPOFilter.t_end_date}
                    dateFormat={"dd-MM-yyyy"}
                    popperPlacement="bottom"
                    popperModifiers={{
                      flip: {
                        behavior: ["bottom"], // don't allow it to flip to be above
                      },
                      preventOverflow: {
                        enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                      },
                      hide: {
                        enabled: false, // turn off since needs preventOverflow to be enabled
                      },
                    }}
                    onChange={async (date) => {
                      setToolingPOFilter({
                        ...toolingPOFilter,
                        t_end_date: date,
                      });
                    }}
                    // selectsEnd
                    startDate={toolingPOFilter.t_start_date}
                    minDate={toolingPOFilter.t_start_date}
                    customInput={<Custom_Input_date />}
                  ></DatePicker>
                </div>
              </div>

              {/* <div className="col-4 col-md-3 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                       onClick={() => QcTagGenerate()} 
                    >
                       Report Tag
                    </button>
                  </div>
                </div> */}
            </div>
            <div className="row">
              <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      FilterToolingPO();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => Clearfilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => generatePO()}
                  >
                    Generate PO
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Tooling PO</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
              <MDBDataTable
                sortable={false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
                disableRetreatAfterSorting={MDBT}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Tooling_po;
