import React, { useEffect, useState } from "react";
import axios from "axios";
import Configs from "../../../config";
import Swal from "sweetalert2";
import Table_All_Project from "../../../component/component_ticket/table_all_project_of_project";
import {
  DatePicker,
  Avatar,
  Divider,
  Tooltip,
  Space,
  Checkbox,
  Switch,
  Radio,
  ConfigProvider,
  Drawer,
  TimePicker,
  Dropdown,
  Row,
  Input,
  Col,
  Menu,
} from "antd";
import {
  AntDesignOutlined,
  UserOutlined,
  DownOutlined,
} from "@ant-design/icons";
import img_header_ticket from "../../../assets/img/IMG_Ticket_Management/img_header_ticket.png";
import img_Three_dot from "../../../assets/img/IMG_Ticket_Management/three_dot.png";
import Select from "react-select";
import { Button } from "reactstrap";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import moment from "moment";
import { formatTimeStr } from "antd/es/statistic/utils";
import {
  getOem,
  getToken,
  getUser,
  getFeature,
  getAcademy,
} from "../../../Utils/Common";
import { useHistory } from "react-router-dom";

function Customer_Management() {
  // console.log('first')
  const [Filter, setFilter] = useState({
    search_text: "",
  });
  const [configTable, setConfigTable] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  let [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  let [pageSize, setpageSize] = useState(10);
  let [total, Settotal] = useState(0);
  const [getAllCustomer, setGetAllCustomer] = useState([]);
  const [mode, setMode] = useState({
    cus_id: "",
    mode: "Add",
  });
  const [value, setValue] = useState();
  const [customerList, setCustomerList] = useState({
    cus_id: "",
    cus_company_code: "",
    cus_company_email: "",
    cus_company_lineid: "",
    cus_company_name: "",
    cus_company_phone: "",
    cus_created_by: "",
    cus_created_date: "",
    cus_customer_code: "",
    cus_customer_email: "",
    cus_customer_lineid: "",
    cus_customer_name: "",
    cus_customer_phone: "",
    cus_updated_by: "",
    cus_updated_date: "",
  });

  const onShowSizeChange = (current, size) => {
    /* setCurrentPage(1);
    currentPage = 1;
    setpageSize(size);
    pageSize = size; */

    setConfigTable({
      ...configTable,
      currentPage: 1,
      pageSize: size,
    });
    configTable.currentPage = 1;
    configTable.pageSize = size;
    // filterCustomer();
  };

  const onChangePage = (page, pageSize) => {
    // console.log("Page:", page);
    // console.log('pageSize :>> ', pageSize);
    /* setCurrentPage(page);
    setpageSize(pageSize); */
    const _page = configTable.pageSize !== pageSize ? 1 : page;
    setConfigTable({
      ...configTable,
      currentPage: _page,
      pageSize: pageSize,
    });
    configTable.currentPage = _page;
    configTable.pageSize = pageSize;
    // filterCustomer();
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      //   filterSLA_Level();
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      fontSize: 16,
      // marginTop: "-18px",
      // marginLeft: "-9px"
    }),
  };

  const handlePageChange = (pageNumber) => {
    // console.log('Page: ', pageNumber);
  };

  const checkMode = (mode) => {
    console.log("mode----", mode);
    if (mode.mode === "Add") {
      addCustomer();
    } else if (mode.mode === "Edit") {
      editCustomer();
    }
  };

  async function filterCustomer() {
    Swal.fire({
      title: "ระบบ",
      text: `ระบบกำลังอัพเดตข้อมูล`,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    setGetAllCustomer([]);
    // console.log(Filter.search_position);
    let temp = {
      search_text: Filter.search_text.trim() || "",
      current: configTable.currentPage || 1,
      pageSize: configTable.pageSize || 10,
      oem_id: getOem(),
      com_id: getUser().com
    };
    try {
      const response = await axios.post(
        `${Configs.API_Ticket}/api/customer/getAllCustomer`,
        temp,
        {
          headers: {
            Authorization: getToken(),
            "X-TTT": `${Configs.API_TTT}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data && response.data._data) {
        console.log("response.data.AllDataTotal", response.data.AllDataTotal);
        let totalAll = response.data.AllDataTotal;
        Settotal(totalAll);
        total = totalAll;
        console.log("total", total);
        setGetAllCustomer(
          response.data._data.map((item) => ({
            cus_id: item.cus_id,
            cus_company_code: item.cus_company_code,
            cus_company_email: item.cus_company_email,
            cus_company_lineid: item.cus_company_lineid,
            cus_company_name: item.cus_company_name,
            cus_company_phone: item.cus_company_phone,
            cus_created_by: item.cus_created_by,
            cus_created_date: item.cus_created_date,
            cus_customer_code: item.cus_customer_code,
            cus_customer_email: item.cus_customer_email,
            cus_customer_lineid: item.cus_customer_lineid,
            cus_customer_name: item.cus_customer_name,
            cus_customer_phone: item.cus_customer_phone,
            cus_updated_by: item.cus_updated_by,
            cus_updated_date: item.cus_updated_date,
          }))
        );
      }
      Swal.close();
    } catch (error) {
      console.log(error);
      Swal.fire(
        "แจ้งเตือน",
        error.response?.data?.errorMessage || "เกิดข้อผิดพลาด",
        "error"
      );
    }
  }

  async function addCustomer() {
    const error_list = [];
    if (customerList.cus_company_name.trim() === "") {
      let temp_err = {
        message: "กรุณากรอก Company Name",
      };
      error_list.push(temp_err);
    }
    if (customerList.cus_customer_code.trim() === "") {
      let temp_err = {
        message: "กรุณากรอก Customer Code",
      };
      error_list.push(temp_err);
    }
    if (customerList.cus_company_email.trim() === "") {
      let temp_err = {
        message: "กรุณากรอก Company Email",
      };
      error_list.push(temp_err);
      // return error_list
    } else if (
      !/^[^\s@]+@[^\s@]+$/.test(customerList.cus_company_email.trim())
    ) {
      let temp_err = {
        message: "Format Email Company ไม่ถูกต้อง",
      };
      error_list.push(temp_err);
    }
    if (customerList.cus_customer_email.trim() !== "") {
      if (!/^[^\s@]+@[^\s@]+$/.test(customerList.cus_customer_email.trim())) {
        let temp_err = {
          message: "Format Email Customer ไม่ถูกต้อง",
        };
        error_list.push(temp_err);
      }
    }
    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      Swal.fire({
        title: "ระบบ",
        text: "ระบบกำลังอัพเดตข้อมูล",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      setGetAllCustomer([]);
      let temp = {
        cus_company_code: customerList.cus_company_code,
        cus_company_email: customerList.cus_company_email,
        cus_company_lineid: customerList.cus_company_lineid,
        cus_company_name: customerList.cus_company_name,
        cus_company_phone: customerList.cus_company_phone,
        cus_customer_code: customerList.cus_customer_code,
        cus_customer_email: customerList.cus_customer_email,
        cus_customer_lineid: customerList.cus_customer_lineid,
        cus_customer_name: customerList.cus_customer_name,
        cus_customer_phone: customerList.cus_customer_phone,
        cus_updated_by: getUser().emp_id,
        cus_created_by: getUser().emp_id,
        cus_oem_id: getOem(),
        cus_company_id: getUser().com
      };
      try {
        const response = await axios.post(
          `${Configs.API_Ticket}/api/customer/addCustomer`,
          temp,
          {
            headers: {
              Authorization: getToken(),
              "X-TTT": `${Configs.API_TTT}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("response.status", response.data?.Status);
        if (response.data === true) {
          Swal.close();
          Swal.fire({
            title: "ระบบ",
            text: "บันทึกข้อมูลสำเร็จ",
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            icon: "success",
            timer: 1000,
          });
          onClose();
          filterCustomer();
        } else if (response.data?.Status === 403) {
          Swal.fire(
            "แจ้งเตือน",
            response.data?.err_message || "เกิดข้อผิดพลาด",
            "error"
          );
        }
      } catch (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.message, "error");
      }
    }
  }

  async function editCustomer() {
    const error_list = [];
    if (customerList.cus_company_name.trim() === "") {
      let temp_err = {
        message: "กรุณากรอก Company Name",
      };
      error_list.push(temp_err);
    }
    if (customerList.cus_customer_code.trim() === "") {
      let temp_err = {
        message: "กรุณากรอก Customer Code",
      };
      error_list.push(temp_err);
    }
    if (customerList.cus_company_email.trim() === "") {
      let temp_err = {
        message: "กรุณากรอก Company Email",
      };
      error_list.push(temp_err);
      // return error_list
    } else if (
      !/^[^\s@]+@[^\s@]+$/.test(customerList.cus_company_email.trim())
    ) {
      let temp_err = {
        message: "Format Email Company ไม่ถูกต้อง",
      };
      error_list.push(temp_err);
    }
    if (customerList.cus_customer_email.trim() !== "") {
      if (!/^[^\s@]+@[^\s@]+$/.test(customerList.cus_customer_email.trim())) {
        let temp_err = {
          message: "Format Email Customer ไม่ถูกต้อง",
        };
        error_list.push(temp_err);
      }
    }

    if (error_list.length > 0) {
      let err_message = "";
      for (let e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      Swal.fire({
        title: "ระบบ",
        text: "ระบบกำลังอัพเดตข้อมูล",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      setGetAllCustomer([]);

      let temp = {
        cus_company_code: customerList.cus_company_code,
        cus_company_email: customerList.cus_company_email,
        cus_company_lineid: customerList.cus_company_lineid,
        cus_company_name: customerList.cus_company_name,
        cus_company_phone: customerList.cus_company_phone,
        cus_customer_code: customerList.cus_customer_code,
        cus_customer_email: customerList.cus_customer_email,
        cus_customer_lineid: customerList.cus_customer_lineid,
        cus_customer_name: customerList.cus_customer_name,
        cus_customer_phone: customerList.cus_customer_phone,
        cus_updated_by: getUser().emp_id,
        cus_oem_id: getOem(),
        cus_company_id: getUser().com
      };

      try {
        const response = await axios.put(
          `${Configs.API_Ticket}/api/customer/editCustomer/${customerList.cus_id}`,
          temp,
          {
            headers: {
              Authorization: getToken(),
              "X-TTT": `${Configs.API_TTT}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data === true) {
          Swal.close();
          Swal.fire({
            title: "ระบบ",
            text: "บันทึกข้อมูลสำเร็จ",
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            icon: "success",
            timer: 1000,
          });
          onClose();
          filterCustomer();
        } else if (response.data?.Status === 403) {
          Swal.fire(
            "แจ้งเตือน",
            response.data?.err_message || "เกิดข้อผิดพลาด",
            "error"
          );
        }
      } catch (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.message, "error");
      }
    }
  }

  async function deleteCustomer() {
    console.log("customerList.cus_id", customerList.cus_id);
    const result = await Swal.fire({
      title: "ระบบ",
      text: "คุณต้องการลบ Company Name นี้ใช่ไหม",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      cancelButtonColor: "red",
      icon: "warning",
    });

    if (result.isConfirmed) {
      setGetAllCustomer([]);

      let temp = {
        cus_company_code: customerList.cus_company_code,
        cus_company_email: customerList.cus_company_email,
        cus_company_lineid: customerList.cus_company_lineid,
        cus_company_name: customerList.cus_company_name,
        cus_company_phone: customerList.cus_company_phone,
        cus_customer_code: customerList.cus_customer_code,
        cus_customer_email: customerList.cus_customer_email,
        cus_customer_lineid: customerList.cus_customer_lineid,
        cus_customer_name: customerList.cus_customer_name,
        cus_customer_phone: customerList.cus_customer_phone,
        cus_updated_by: getUser().emp_id,
      };

      try {
        const response = await axios.put(
          `${Configs.API_Ticket}/api/customer/deleteCustomer/${customerList.cus_id}`,
          temp,
          {
            headers: {
              Authorization: getToken(),
              "X-TTT": `${Configs.API_TTT}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data === true) {
          Swal.close();
          Swal.fire({
            title: "ระบบ",
            text: "บันทึกข้อมูลสำเร็จ",
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            icon: "success",
            timer: 1000,
          });
          onClose();
          filterCustomer();
        } else if (response.data?.Status === 403) {
          Swal.fire(
            "แจ้งเตือน",
            response.data?.err_message || "เกิดข้อผิดพลาด",
            "error"
          );
        }
      } catch (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.message, "error");
      }
    } else {
      // User cancelled the action
      console.log("User cancelled the deletion");
    }
  }
  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  const maxLength = 10;

  const styleLevel = {
    display: "flex",
    flexDirection: "column",
    fontSize: "12px",
    width: "60%",
    alignItems: "center",
    borderRadius: "10px",
    fontStyle: "italic",
  };

  const cardData = [];
  getAllCustomer.forEach((item) => {
    cardData.push({
      id: item.cus_id,
      content: (
        // <div style={{ width: "50%" }}>
        <>
          <Row
            align="bottom"
            style={{
              alignItems: "center",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Col className="gutter-row" span={15}>
              <Row style={{ alignItems: "center", display: "flex" }}>
                <Col span={11}>
                  <Tooltip
                    title={`${
                      item.cus_company_name + " / " + item.cus_customer_code
                    }`}
                  >
                    <span
                      style={{
                        fontSize: "30px",
                        whiteSpace: "pre-wrap",
                        fontWeight: 700,
                        color: "#007BFF",
                        display: "block",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {`${truncateString(
                        item.cus_company_name + " / " + item.cus_customer_code,
                        30
                      )}`}
                    </span>
                  </Tooltip>
                  <span
                    style={{
                      display: "block",
                      fontWeight: 500,
                      fontSize: "20px",
                    }}
                  >
                    {`${truncateString(item.cus_customer_name || "-", 30)}`}
                  </span>
                </Col>
              </Row>
            </Col>

            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginLeft: "20px",
                fontStyle: "italic",
                fontSize: "16px",
              }}
            >
              <span style={{ color: "gray" }}>Phone</span>
              <Tooltip
                title={`${
                  item.cus_customer_phone ? item.cus_customer_phone : "-"
                }`}
              >
                <span>{`${truncateString(
                  item.cus_customer_phone ? item.cus_customer_phone : "-",
                  maxLength
                )}`}</span>
              </Tooltip>
            </Col>

            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginLeft: "20px",
                fontStyle: "italic",
                fontSize: "16px",
              }}
            >
              <span style={{ color: "gray" }}>Line ID</span>
              <Tooltip
                title={`${
                  item.cus_customer_lineid ? item.cus_customer_lineid : "-"
                }`}
              >
                <span>{`${truncateString(
                  item.cus_customer_lineid ? item.cus_customer_lineid : "-",
                  maxLength
                )}`}</span>
              </Tooltip>
            </Col>

            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginLeft: "20px",
                fontStyle: "italic",
                fontSize: "16px",
              }}
            >
              <span style={{ color: "gray" }}>Email</span>
              <Tooltip
                title={`${
                  item.cus_customer_email ? item.cus_customer_email : "-"
                }`}
              >
                <span>{`${truncateString(
                  item.cus_customer_email ? item.cus_customer_email : "-",
                  maxLength
                )}`}</span>
              </Tooltip>
            </Col>

            <Col className="gutter-row" span={2}>
              <Row>
                <Col
                  span={1}
                  style={{
                    display: "flex",
                    // flexDirection: "column",
                    // alignItems: "flex-end",
                  }}
                >
                  {/* <span
                    style={{
                      color: "gray",
                      fontSize: "16px",
                      fontStyle: "italic",
                    }}
                  >
                    Active
                  </span> */}

                  <div style={{ color: "#007BFF" }}>{/*  */}</div>
                </Col>

                <Col
                  span={8}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      borderRight: "3px solid #ccc",
                      height: "300%",
                      borderRadius: "10px",
                    }}
                  ></div>
                </Col>

                <Col
                  span={5}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "flex-start",
                  }}
                >
                  <Dropdown
                    overlay={
                      <div>
                        <Menu>
                          <div
                            style={{
                              color: "gray",
                              cursor: "default",
                              padding: "8px 12px",
                            }}
                          >
                            Management
                          </div>
                          {[
                            item.projects === 0 || true
                              ? {
                                  label: (
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: "orange" }}
                                      onClick={() => {
                                        setMode({
                                          mode: "Edit",
                                        });
                                        mode.mode = "Edit";
                                        // console.log(
                                        //   "customerList",
                                        //   customerList
                                        // );
                                        setCustomerList({
                                          cus_id: item.cus_id,
                                          cus_company_code:
                                            item.cus_company_code,
                                          cus_company_email:
                                            item.cus_company_email,
                                          cus_company_lineid:
                                            item.cus_company_lineid,
                                          cus_company_name:
                                            item.cus_company_name,
                                          cus_company_phone:
                                            item.cus_company_phone,
                                          cus_created_by: item.cus_created_by,
                                          cus_created_date:
                                            item.cus_created_date,
                                          cus_customer_code:
                                            item.cus_customer_code,
                                          cus_customer_email:
                                            item.cus_customer_email,
                                          cus_customer_lineid:
                                            item.cus_customer_lineid,
                                          cus_customer_name:
                                            item.cus_customer_name,
                                          cus_customer_phone:
                                            item.cus_customer_phone,
                                          cus_updated_by: item.cus_updated_by,
                                          cus_updated_date:
                                            item.cus_updated_date,
                                        });
                                        showDrawer();
                                      }}
                                    >
                                      <i
                                        className="far fa-trash-alt"
                                        style={{
                                          marginTop: "5px",
                                          color: "orange",
                                          marginRight: "8px",
                                        }}
                                      ></i>
                                      Edit Customer
                                    </a>
                                  ),
                                  key: "0",
                                }
                              : null,
                            item.projects === 0 || true
                              ? {
                                  label: (
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: "#DD2025" }}
                                      onClick={() => {
                                        setMode({
                                          mode: "Delete",
                                        });
                                        mode.mode = "Delete";
                                        console.log(
                                          "customerList",
                                          customerList
                                        );
                                        setCustomerList({
                                          cus_id: item.cus_id,
                                          cus_company_code:
                                            item.cus_company_code,
                                          cus_company_email:
                                            item.cus_company_email,
                                          cus_company_lineid:
                                            item.cus_company_lineid,
                                          cus_company_name:
                                            item.cus_company_name,
                                          cus_company_phone:
                                            item.cus_company_phone,
                                          cus_created_by: item.cus_created_by,
                                          cus_created_date:
                                            item.cus_created_date,
                                          cus_customer_code:
                                            item.cus_customer_code,
                                          cus_customer_email:
                                            item.cus_customer_email,
                                          cus_customer_lineid:
                                            item.cus_customer_lineid,
                                          cus_customer_name:
                                            item.cus_customer_name,
                                          cus_customer_phone:
                                            item.cus_customer_phone,
                                          cus_updated_by: item.cus_updated_by,
                                          cus_updated_date:
                                            item.cus_updated_date,
                                        });
                                        customerList.cus_id = item.cus_id;
                                        customerList.cus_company_code =
                                          item.cus_company_code;
                                        customerList.cus_company_email =
                                          item.cus_company_email;
                                        customerList.cus_company_lineid =
                                          item.cus_company_lineid;
                                        customerList.cus_company_name =
                                          item.cus_company_name;
                                        customerList.cus_company_phone =
                                          item.cus_company_phone;
                                        customerList.cus_created_by =
                                          item.cus_created_by;
                                        customerList.cus_created_date =
                                          item.cus_created_date;
                                        customerList.cus_customer_code =
                                          item.cus_customer_code;
                                        customerList.cus_customer_email =
                                          item.cus_customer_email;
                                        customerList.cus_customer_lineid =
                                          item.cus_customer_lineid;
                                        customerList.cus_customer_name =
                                          item.cus_customer_name;
                                        customerList.cus_customer_phone =
                                          item.cus_customer_phone;
                                        customerList.cus_updated_by =
                                          item.cus_updated_by;
                                        customerList.cus_updated_date =
                                          item.cus_updated_date;
                                        deleteCustomer();
                                      }}
                                    >
                                      <i
                                        className="far fa-trash-alt"
                                        style={{
                                          marginTop: "5px",
                                          color: "#DD2025",
                                          marginRight: "8px",
                                        }}
                                      ></i>
                                      Delete Customer
                                    </a>
                                  ),
                                  key: "1",
                                }
                              : null,
                          ]
                            .filter((item) => item !== null)
                            .map((item) => (
                              <Menu.Item key={item.key}>{item.label}</Menu.Item>
                            ))}
                        </Menu>
                      </div>
                    }
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>
                        <img
                          src={img_Three_dot}
                          alt="Three dots"
                          width={25}
                          height={25}
                        />
                      </Space>
                    </a>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ),
    });
  });

  useEffect(() => {
    // console.log('Current:', configTable.currentPage, 'Size:', configTable.pageSize);
    filterCustomer();
    // CheckRolePage().then(function (responseData) {
    //   // console.log(responseData);
    //   if (responseData) {
    //     // generateHoursOptions();
    //     filterSLA_Level();
    //   } else {
    //     // window.location = `/NonPermission`;
    //     history.push("/NonPermission", {
    //       module: "Setting Permission/SLA",
    //       menu: "SLA Level",
    //     });
    //   }
    // });
  }, [configTable.currentPage, configTable.pageSize]);

  return (
    <div className="content-wrapper ">
      <section className="content-header">
        <div
          className="container-fluid"
          style={{ marginTop: "10px", marginLeft: "12px" }}
        >
          <div className="row">
            <div
              className="col-12 col-sm-12 col-xl-6"
              style={{ display: "flex" }}
            >
              <img
                alt="..."
                className="img_header_ticket"
                src={img_header_ticket}
                width={30}
                height={30}
              />
              <h1
                style={{
                  fontWeight: 700,
                  fontSize: "55px",
                  marginTop: "-16px",
                  marginLeft: "18px",
                }}
              >
                Customer Management
              </h1>
            </div>

            <div className="col-12 col-sm-12 col-xl-6 ">
              <ol className="breadcrumb float-sm-right ">
                <li className="breadcrumb-item active">
                  Home
                  {/* <a href="/Welcome">Home</a> */}
                </li>
                <li className="breadcrumb-item active">Project Management</li>
                <li className="breadcrumb-item">Customer Management</li>
                {/* <li className="breadcrumb-item active">{mode}</li> */}
              </ol>
            </div>
          </div>

          <div className="row mt-4">
            <div
              className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 ml-2"
              //   style={{ paddingLeft: "14px" }}
            >
              <div className="row mt-6 ">
                <p style={{ fontSize: "18px", marginTop: "4px" }}>Search</p>
              </div>
              <div
                className="row mr-2"
                style={{
                  marginTop: "-18px",
                  minWidth: "120px",
                  paddingRight: "14px",
                }}
              >
                <input
                  // style={{ marginLeft: "-9px" }}
                  type="search"
                  id="search"
                  className="form-control"
                  placeholder="search"
                  value={Filter.search_text}
                  onChange={(e) => {
                    // setCurrentPage(1)
                    setFilter({
                      ...Filter,
                      search_text: e.target.value,
                    });
                    Filter.search_text = e.target.value;
                  }}
                  onKeyPress={handleEnterKeyPress}
                />
              </div>
            </div>

            <div className="row col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mt-4">
              <div
                className="col-12 col-sm-24 col-md-4 col-lg-4 col-xl-4 mt-1"
                style={{
                  marginBottom: "10px",
                  justifyContent: "end",
                  display: "flex",
                }}
              >
                <Button
                  size="large"
                  type="primary"
                  style={{
                    border: "none",
                    backgroundColor: "#007BFF",
                    minWidth: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "38px",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    setCurrentPage(1);
                    filterCustomer();
                  }}
                >
                  Search
                </Button>
              </div>
              <div
                className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-1 "
                style={{ marginBottom: "10px" }}
              >
                <Button
                  size="large"
                  type="primary"
                  value={Filter.search_text}
                  style={{
                    border: "none",
                    backgroundColor: "#007BFF",
                    minWidth: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "38px",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    setCurrentPage(1);
                    setFilter({
                      ...Filter,
                      search_text: "",
                    });
                    Filter.search_text = "";
                    filterCustomer();
                  }}
                >
                  {/* <span style={{ fontSize: "20px" }}></span> */}
                  Clear
                </Button>
              </div>
            </div>

            <div className="col-12  col-sm-0 col-md-2 col-lg-2 col-xl-2 "></div>

            <div className="col-12  col-sm-12 col-md-2 col-lg-2 col-xl-2 mt-4 pl-3 pr-4">
              <div
                className="row justify-content-end mt-1"
                style={{ paddingRight: "9px" }}
              >
                <Button
                  style={{
                    width: "100%",
                    height: "38px",
                    backgroundColor: "#007BFF",
                    border: "none",
                    minWidth: "150px",
                  }}
                  onClick={() => {
                    setCustomerList({
                      ...customerList,
                      cus_id: "",
                      cus_company_code: "",
                      cus_company_email: "",
                      cus_company_lineid: "",
                      cus_company_name: "",
                      cus_company_phone: "",
                      cus_created_by: "",
                      cus_created_date: "",
                      cus_customer_code: "",
                      cus_customer_email: "",
                      cus_customer_lineid: "",
                      cus_customer_name: "",
                      cus_customer_phone: "",
                      cus_updated_by: "",
                      cus_updated_date: "",
                    });
                    setMode({
                      ...mode,
                      mode: "Add",
                    });
                    showDrawer();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                    style={{ marginTop: "-4px" }}
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                  </svg>
                  {"  "}Add Customer
                </Button>
              </div>
            </div>
          </div>

          <Table_All_Project
            currentPage={configTable.currentPage}
            pageSize={configTable.pageSize}
            onChange={onChangePage}
            cardData={cardData}
            onPageChange={handlePageChange}
            onShowSizeChange={onShowSizeChange}
            total={total}
            minWidth={"1550px"}
          />
        </div>
      </section>
      <ConfigProvider>
        <Drawer
          title={`${mode.mode} Customer`}
          placement="right"
          onClose={onClose}
          open={open}
          // height={}
          width={390}
          style={{ position: "absolute", paddingTop: "55px" }}
          extra={
            <Space>
              <Button
                style={{
                  color: "black",
                  backgroundColor: "white",
                  borderColor: "gray",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: "#007BFF",
                  border: "none",
                  width: "70px",
                }}
                onClick={() => {
                  // console.log("mode", mode);
                  checkMode(mode);
                }}
              >
                OK
              </Button>
            </Space>
          }
        >
          <div
            className="row"
            style={{
              marginLeft: "2px",
              borderBottom: "1px solid #000",
              width: "100%",
              marginBottom: "15px",
              fontWeight: 700,
            }}
          >
            <span style={{ marginBottom: "5px" }}>Company</span>
          </div>

          <div className="row" style={{ marginLeft: "2px" }}>
            Company Name<font color="red">*</font>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <Input
                value={customerList.cus_company_name}
                placeholder="Enter Company Name"
                onChange={(e) => {
                  setValue(e.target.value);
                  // console.log(customerList);
                  setCustomerList({
                    ...customerList,
                    cus_company_name: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="row mt-3" style={{ marginLeft: "2px" }}>
            Company Name ( ชื่อย่อ )
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <div className="row">
                <Input
                  style={{ height: "40px", width: "100%", marginLeft: "8px" }}
                  placeholder="Enter Company Name ( ชื่อย่อ )"
                  value={customerList.cus_company_code}
                  onChange={(e) => {
                    setCustomerList({
                      ...customerList,
                      cus_company_code: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row mt-3" style={{ marginLeft: "2px" }}>
            Customer Code<font color="red">*</font>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <div className="row">
                <Input
                  style={{ height: "40px", width: "100%", marginLeft: "8px" }}
                  placeholder="Enter Customer Code"
                  value={customerList.cus_customer_code}
                  onChange={(e) => {
                    setCustomerList({
                      ...customerList,
                      cus_customer_code: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row mt-3" style={{ marginLeft: "2px" }}>
            Company Email<font color="red">*</font>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <div className="row">
                <Input
                  style={{ height: "40px", width: "100%", marginLeft: "8px" }}
                  placeholder="Enter Email"
                  value={customerList.cus_company_email}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setCustomerList({
                      ...customerList,
                      cus_company_email: inputValue,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row mt-3" style={{ marginLeft: "2px" }}>
            Company Tel.
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <div className="row">
                <Input
                  style={{ height: "40px", width: "100%", marginLeft: "8px" }}
                  placeholder="Enter Company Tel."
                  value={customerList.cus_company_phone}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d{0,10}$/.test(inputValue)) {
                      setCustomerList({
                        ...customerList,
                        cus_company_phone: e.target.value,
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className="row"
            style={{
              marginLeft: "2px",
              borderBottom: "1px solid #000",
              width: "100%",
              marginBottom: "15px",
              fontWeight: 700,
              marginTop: "15px",
            }}
          >
            <span style={{ marginBottom: "5px" }}>Customer Detail</span>
          </div>

          <div className="row mt-3" style={{ marginLeft: "2px" }}>
            Customer Name
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <div className="row">
                <Input
                  style={{ height: "40px", width: "100%", marginLeft: "8px" }}
                  placeholder="Enter Customer Name"
                  value={customerList.cus_customer_name}
                  onChange={(e) => {
                    setCustomerList({
                      ...customerList,
                      cus_customer_name: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row mt-3" style={{ marginLeft: "2px" }}>
            Email
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <div className="row">
                <Input
                  style={{ height: "40px", width: "100%", marginLeft: "8px" }}
                  placeholder="Enter Email"
                  value={customerList.cus_customer_email}
                  onChange={(e) => {
                    setCustomerList({
                      ...customerList,
                      cus_customer_email: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row mt-3" style={{ marginLeft: "2px" }}>
            Phone
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <div className="row">
                <Input
                  style={{ height: "40px", width: "100%", marginLeft: "8px" }}
                  placeholder="Enter Phone"
                  value={customerList.cus_customer_phone}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d{0,10}$/.test(inputValue)) {
                      setCustomerList({
                        ...customerList,
                        cus_customer_phone: inputValue,
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row mt-3" style={{ marginLeft: "2px" }}>
            Line ID
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <div className="row">
                <Input
                  style={{ height: "40px", width: "100%", marginLeft: "8px" }}
                  placeholder="Enter Line ID"
                  value={customerList.cus_customer_lineid}
                  onChange={(e) => {
                    setCustomerList({
                      ...customerList,
                      cus_customer_lineid: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </Drawer>
      </ConfigProvider>
    </div>
  );
}

export default Customer_Management;
