import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../Utils/Common";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Card, Label } from "reactstrap";
import { useParams } from "react-router";

function Leave_information_admin_mgt(params) {
  const { emp_id } = useParams();
  const [data_entLeave, setdata_entLeave] = useState({
    pak_ron: "",
    la_kit: "",
    sick: "",
    out_pak_ron: "",
    out_la_kit: "",
    out_sick: "",
  });
  const [data_leave, setdata_leave] = useState({
    name: "",
    leave_status: "",
    detail: "",
    day_status: "",
    leave_half_day: "",
    start_date: "",
    finish_date: "",
    start_time: "",
    finish_time: "",
    file_name: "",
    file_path: "",
  });

  const [dateZindex, setdateZindex] = useState({
    date1: "98",
    date2: "98",
    time1: "98",
    time2: "98",
  });

  async function getEntLeaveByEmpId(name) {
    var get_filler_employee = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_name_th: name,
      year: moment(new Date()).format("YYYY"),
    };

    //console.log(filler_employee);
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getEmployeeLeaveByAdmin",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_employee,
    })
      .then(async function (response2) {
        let temp = {
          emp_id: emp_id,
          balance_leave: response2.data.data[0],
          year: moment().format("YYYY"),
        };
        axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement +
            "/api/hrManagement/getEntLeaveByEmpId",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(function (response) {
            setdata_entLeave({
              ...data_entLeave,
              all_pak_ron: response.data.data.all_vacation_leave,
              all_la_kit: response.data.data.all_personal_leave,
              all_sick: response.data.data.all_sick_leave,
              pak_ron: response.data.data.ent_vacation_leave,
              la_kit: response.data.data.ent_personal_leave,
              sick: response.data.data.ent_sick_leave,
              out_pak_ron: response.data.data.out_vacation_leave,
              out_la_kit: response.data.data.out_personal_leave,
              out_sick: response.data.data.out_sick_leave,
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error2) {
        console.log(error2);
      });
  }
  async function getNameEmpById() {
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getEmployeeById?id=" +
        emp_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setdata_leave({
          ...data_leave,
          name: response.data.data[0].emp_name_th,
        });
        getEntLeaveByEmpId(response.data.data[0].emp_name_th);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    /* var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "ข้อมูลพนักงาน";
    }); */
    getNameEmpById();
    /*     getEntLeaveByEmpId(); */
  }, []);

  const upload_file = async (e) => {
    console.log("filname", e.target.files);
    if (e.target.files[0]) {
      const file = e.target.files[0];
      console.log("ss", file);
      const regex = /([\u0E00-\u0E7F]+)/gmu;
      const str = file.name;
      let m = regex.exec(str);
      var data = new FormData();
      data.append("Profile", file);

      var config_uploadProfile = {
        method: "post",
        url: Configs.API_URL + "/upload/profile",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config_uploadProfile)
        .then(function (response) {
          if (response.data.status) {
            /*  console.log("filre",response.data); */

            setdata_leave({
              ...data_leave,
              file_name: response.data.data.orgin_name,
              file_path: response.data.data.path,
              /* image: response.data.data, */
            });
          }
          Swal.fire({
            icon: "success",
            title: "อัปโหลดไฟล์สำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", "File name !!!", "error");
        });
      //  }
    }
  };

  function cancle() {
    Swal.fire({
      title: "ระบบ?",
      text: "คุณต้องการยกเลิกใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
          "/Human_Resource/ข้อมูลการปฏิบัติงาน/leave_information_admin";
      }
    });
  }

  async function SaveLeaveEmpData() {
    Swal.fire({
      title: "ระบบ?",
      text: "คุณต้องการบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
    }).then((result) => {
      if (result.isConfirmed) {
        const error_list = [];
        if (data_leave.leave_status == "") {
          let temp_err = {
            message: "โปรดเลือกประเภทการลา.",
          };
          error_list.push(temp_err);
        }
        if (data_leave.detail.trim() == "") {
          let temp_err = {
            message: "กรอกข้อมูลรายละเอียด.",
          };
          error_list.push(temp_err);
        }

        if (data_leave.day_status == "" && data_leave.leave_half_day == "") {
          let temp_err = {
            message:
              "โปรดเลือกว่าจะลาเต็มวัน ลาเป็นช่วงเวลา ลาครึ่งวันเช้าหรือลาครึ่งวันบ่าย",
          };
          error_list.push(temp_err);
        }

        if (data_leave.start_date == "") {
          let temp_err = {
            message: "โปรดเลือกวันที่เริ่มลา.",
          };
          error_list.push(temp_err);
        }
        if (data_leave.start_date == "") {
          let temp_err = {
            message: "โปรดเลือกวันที่สิ้นสุดการลา.",
          };
          error_list.push(temp_err);
        }

        if (error_list.length > 0) {
          var err_message = "";
          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }
          Swal.fire("Warning", err_message, "warning");
        } else {
          var temp = {
            emp_id: emp_id,
            data_leave: data_leave,
            company_id: getUser().com,
            oem_id: getOem(),
          };
          axios({
            method: "post",
            url:
              Configs.API_URL_hrMagenatement +
              "/api/hrManagement/saveEmpLeaveByAdmin",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response2) {
              if (response2.data.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Human_Resource/ข้อมูลการปฏิบัติงาน/leave_information_admin";
                });
              }
            })
            .catch(function (error) {
              console.log(error.response);
              Swal.fire("Error", error.response.data.error.message, "error");
            });
        }
      }
    });
  }

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  เพิ่มข้อมูลวันลาพนักงาน{"  "}[{data_leave.name}]
                  {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">ข้อมูลวันลาพนักงาน</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-6 col-md-3 col-xl-1">
                <button
                  type="button"
                  id="btn_save"
                  onClick={SaveLeaveEmpData}
                  class="btn btn-block btn-success "
                >
                  บันทึก
                </button>
              </div>
              <div className="col-6 col-md-3 col-xl-1">
                <button
                  type="button"
                  id="btn_cancle"
                  onClick={cancle}
                  class="btn btn-block btn-danger "
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>สิทธิการลา</h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    <font color="green">
                      ลาพักร้อน {data_entLeave.all_pak_ron} วัน
                    </font>
                  </h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    <font color="blue">
                      ลากิจ {data_entLeave.all_la_kit} วัน
                    </font>
                  </h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    <font color="red">ลาป่วย {data_entLeave.all_sick} วัน</font>
                  </h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    ประจำปี {"  "}
                    {parseInt(moment(new Date()).format("YYYY")) + 543}
                  </h4>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>วันลาคงเหลือ</h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    <font color="green">
                      ลาพักร้อน {data_entLeave.pak_ron} วัน
                    </font>
                  </h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    <font color="blue">ลากิจ {data_entLeave.la_kit} วัน</font>
                  </h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    <font color="red">ลาป่วย {data_entLeave.sick} วัน</font>
                  </h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    ประจำปี {"  "}
                    {parseInt(moment(new Date()).format("YYYY")) + 543}
                  </h4>
                </div>
              </div>

              {/* <hr />

              <div className="row">
                <div className="col-6 col-md-3 col-xl-2"><h4>วันลาที่เกิน</h4></div>
                 <div className="col-6 col-md-3 col-xl-2"><h4><font color="green">ลาพักร้อน {data_entLeave.out_pak_ron} วัน</font></h4></div>
                 <div className="col-6 col-md-3 col-xl-2"><h4><font color="blue">ลากิจ {data_entLeave.out_la_kit} วัน</font></h4></div>
                 <div className="col-6 col-md-3 col-xl-2"><h4><font color="red">ล่าป่วย {data_entLeave.out_sick} วัน</font></h4></div>
                 <div className="col-6 col-md-3 col-xl-2"><h4>ประจำปี  {"  "}
                {parseInt(moment(new Date()).format('YYYY')) + 543}</h4></div>
              </div> */}
            </div>
            <div className="card-body" style={{ whiteSpace: "nowrap" }}>
              <div className="row">
                <div
                  className="col-1 col-md-1 col-xl-1"
                  style={{ textAlign: "right" }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id="pak_ron_rdo"
                    name="type_staus"
                    onClick={() => {
                      setdata_leave({
                        ...data_leave,
                        leave_status: "ลาพักร้อน",
                      });
                    }}
                  />
                </div>
                <div className="col-5 col-md-1 col-xl-1">
                  <label htmlFor="pak_ron_rdo">ลาพักร้อน</label>
                </div>

                <div
                  className="col-1    col-md-0.5 col-xl-0.5"
                  style={{ textAlign: "right" }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id="la_kit_rdo"
                    name="type_staus"
                    onClick={() => {
                      setdata_leave({ ...data_leave, leave_status: "ลากิจ" });
                    }}
                  />
                </div>
                <div className="col-5 col-md-1 col-xl-1">
                  <label htmlFor="la_kit_rdo">ลากิจ</label>
                </div>

                <div
                  className="col-1 col-md-0.5 col-xl-0.5"
                  style={{ textAlign: "right" }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id="sick_rdo"
                    name="type_staus"
                    onClick={() => {
                      setdata_leave({ ...data_leave, leave_status: "ลาป่วย" });
                    }}
                  />
                </div>
                <div className="col-5  col-md-1 col-xl-1">
                  <label htmlFor="sick_rdo">ลาป่วย</label>
                </div>

                <div
                  className="col-1 col-md-0.5 col-xl-0.5"
                  style={{ textAlign: "right" }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id="out_site_rdo"
                    name="type_staus"
                    onClick={() => {
                      setdata_leave({
                        ...data_leave,
                        leave_status: "ทำงานนอกสถานที่",
                      });
                    }}
                  />
                </div>
                <div className="col-1 col-md-1 col-xl-1">
                  <label htmlFor="out_site_rdo">ปฏิบัติงานนอกสถานที่</label>
                </div>
              </div>

              <br />
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10 col-md-6 col-xl-6">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      value={data_leave.detail}
                      onChange={(e) => {
                        setdata_leave({
                          ...data_leave,
                          detail: e.target.value,
                        });
                      }}
                    />
                    <Label htmlFor="">
                      {" "}
                      รายละเอียด{" "}
                      {data_leave.detail === "" ||
                      data_leave.detail === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </Label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-1 col-md-1 col-xl-1"
                  style={{ textAlign: "right" }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id="full_day_rdo"
                    name="type_staus_day"
                    onClick={() => {
                      setdata_leave({ ...data_leave, day_status: "full_day" });
                    }}
                  />
                </div>
                <div className="col-5 col-md-1 col-xl-1">
                  <label htmlFor="full_day_rdo">ลาทั้งวัน</label>
                </div>

                <div
                  className="col-1 col-md-1 col-xl-1"
                  style={{ textAlign: "right" }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id="half_day_rdo"
                    name="type_staus_day"
                    onClick={() => {
                      setdata_leave({ ...data_leave, day_status: "half_day" });
                    }}
                  />
                </div>
                <div className="col-5 col-md-1 col-xl-1">
                  <label htmlFor="half_day_rdo">ลาช่วงเวลา</label>
                </div>

                <div
                  className="col-1 col-md-1 col-xl-1"
                  style={{ textAlign: "right" }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id="full_day_rdo"
                    name="type_staus_day"
                    onClick={() => {
                      setdata_leave({
                        ...data_leave,
                        day_status: "morning_day",
                      });
                    }}
                  />
                </div>
                <div className="col-5 col-md-1 col-xl-1">
                  <label htmlFor="full_day_rdo">ลาครึ่งเช้า</label>
                </div>

                <div
                  className="col-1 col-md-1 col-xl-1"
                  style={{ textAlign: "right" }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id="full_day_rdo"
                    name="type_staus_day"
                    onClick={() => {
                      setdata_leave({
                        ...data_leave,
                        day_status: "afternoon_day",
                      });
                    }}
                  />
                </div>
                <div className="col-5 col-md-1 col-xl-1">
                  <label htmlFor="full_day_rdo">ลาครึ่งบ่าย</label>
                </div>
              </div>

              <div className="row">
                <div className="col-1"></div>

                <div
                  className="col-11 col-md-3 col-xl-2"
                  style={{ "z-index": dateZindex.date1 }}
                >
                  <div
                    className="form-group "
                    onClick={() => {
                      setdateZindex({
                        ...dateZindex,
                        time2: "98",
                        time1: "98",
                        date1: "99",
                        date2: "98",
                      });
                      dateZindex.time2 = "98";
                      dateZindex.time1 = "98";
                      dateZindex.date1 = "99";
                      dateZindex.date2 = "98";
                    }}
                  >
                    <DatePicker
                      selected={data_leave.start_date}
                      className="form-control"
                      dateFormat={"dd-MM-yyyy"}
                      customInput={<CustomInput />}
                      onChange={async (date) => {
                        setdata_leave({
                          ...data_leave,
                          start_date: date,
                        });
                      }}
                      maxDate={data_leave.finish_date}
                    />
                    <label>
                      วันที่เริ่มลา{" "}
                      {data_leave.start_date === "" ||
                      data_leave.start_date === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                      {/*    {data_leave.lev_start_date === "" ? (
                <span style={{ color: "red" }}> *</span>
            ) : (
                ""
            )} */}
                    </label>{" "}
                  </div>
                </div>

                <div className="col-1 col-md-0.5 col-xl-0.5"></div>

                <div
                  className="col-11 col-md-3 col-xl-2"
                  style={{ "z-index": dateZindex.date2 }}
                >
                  <div
                    className="form-group "
                    onClick={() => {
                      setdateZindex({
                        ...dateZindex,
                        time2: "98",
                        time1: "98",
                        date1: "98",
                        date2: "99",
                      });
                      dateZindex.time2 = "98";
                      dateZindex.time1 = "98";
                      dateZindex.date1 = "98";
                      dateZindex.date2 = "99";
                    }}
                  >
                    <DatePicker
                      selected={data_leave.finish_date}
                      className="form-control"
                      dateFormat={"dd-MM-yyyy"}
                      customInput={<CustomInput />}
                      //onChange={async (date) => SetDate_end_fg(date)}
                      onChange={async (date) => {
                        setdata_leave({
                          ...data_leave,
                          finish_date: date,
                        });
                      }}
                      minDate={data_leave.start_date}
                    />
                    <label>
                      วันที่สิ้นสุดการลา{" "}
                      {data_leave.finish_date === "" ||
                      data_leave.finish_date === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                      {/*  {data_leave.lev_end_date === "" ? (
                <span style={{ color: "red" }}> *</span>
            ) : (
                ""
            )} */}
                    </label>{" "}
                  </div>
                </div>
              </div>

              {data_leave.day_status == "half_day" ? (
                <>
                  <div className="row">
                    <div className="col-1"></div>

                    <div
                      className="col-11 col-md-3 col-xl-2"
                      style={{ "z-index": dateZindex.time1 }}
                    >
                      <div
                        className="form-group "
                        onClick={() => {
                          setdateZindex({
                            ...dateZindex,
                            time1: "99",
                            time2: "98",
                            date1: "98",
                            date2: "98",
                          });
                          dateZindex.time1 = "99";
                          dateZindex.time2 = "98";
                          dateZindex.date1 = "98";
                          dateZindex.date2 = "98";
                        }}
                      >
                        <DatePicker
                          selected={data_leave.start_time}
                          className="form-control"
                          /*   dateFormat={"HH:mm"} */
                          showTimeSelect
                          showTimeSelectOnly
                          customInput={<CustomInput />}
                          onChange={async (time) => {
                            console.log(time);
                            setdata_leave({
                              ...data_leave,
                              start_time: time,
                            });
                          }}
                          timeCaption="เวลา"
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          /*        maxTime={data_leave.finish_time} */
                        />
                        <label>เวลาเริ่มต้น</label>{" "}
                      </div>
                    </div>
                    <div className="col-1 col-md-0.5 col-xl-0.5"></div>
                    <div
                      className="col-11 col-md-3 col-xl-2"
                      style={{ "z-index": dateZindex.time2 }}
                    >
                      <div
                        className="form-group "
                        onClick={() => {
                          setdateZindex({
                            ...dateZindex,
                            time2: "99",
                            time1: "98",
                            date1: "98",
                            date2: "98",
                          });
                          dateZindex.time2 = "99";
                          dateZindex.time1 = "98";
                          dateZindex.date1 = "98";
                          dateZindex.date2 = "98";
                        }}
                      >
                        <DatePicker
                          selected={data_leave.finish_time}
                          className="form-control"
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          showTimeSelect
                          showTimeSelectOnly
                          customInput={<CustomInput />}
                          timeCaption="เวลา"
                          onChange={async (time) => {
                            console.log(time);
                            setdata_leave({
                              ...data_leave,
                              finish_time: time,
                            });
                          }}
                          /*  minTime={data_leave.start_time} */
                        />
                        <label>เวลาสิ้นสุด</label>{" "}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="row">
                <div className="col-1"></div>
                <div className="col-10 col-md-6 col-xl-6">
                  <label htmlFor="">แนบไฟล์ที่เกี่ยวข้อง</label>
                  {/* <small style={{ color: "red" }}>
                          ** Excel Form template **
                        </small> */}
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        // onClick={clickclear}
                        type="file"
                        required
                        className="custom-file-input"
                        id="exampleInputFile"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,image/png, image/jpeg"
                        onChange={upload_file}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="exampleInputFile"
                      >
                        {data_leave.file_name !== null &&
                        data_leave.file_name !== "" &&
                        data_leave.file_name !== undefined
                          ? data_leave.file_name
                          : "Select File"}
                      </label>
                    </div>
                  </div>
                  <br />
                  {/*  <div className="row">
                    <div className="col-12 col-md-12 col-xl-12">
                  <label htmlFor="exampleInputFile"> </label>
                  <img
                    id="img"
                    alt="..."
                    className="img-fluid rounded shadow border-0"
                    src={
                      data_leave.file_path !== "" &&
                      data_leave.file_path !== null &&
                      data_leave.file_path !== undefined
                        ? Configs.API_URL_IMG + data_leave.file_path
                        : userdefault_img.imgs
                    }
                    style={{
                      width: "300px",
                      height: "200px",
                    
                    }}
                  />
                  <br/>
                  <span style={{ color: "red", fontSize: "12px" }}>
                   
                  </span>
                  
                </div>
                  </div> */}
                </div>
              </div>
            </div>

            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Leave_information_admin_mgt;
