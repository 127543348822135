import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../Utils/Common";
import axios from "axios";
import Configs from "../../../config";
import moment from "moment";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

function Cost_Center_AEV({ mode }) {
  const { costCenterId } = useParams();

  const [isDisabled, setIsDisabled] = useState(false);

  const [saveData, setSaveData] = useState({
    company_id: getUser().com,
    oem_id: getOem(),
    user_id: getUser().fup,
    cost_center_name: "",
    cost_center_remark: "",
    cost_center_fc: false,
    cost_center_cp: false,
    cost_center_cos: false,
    project_list: [],
  });

  const [selectDropdownProject, setSelectDropdownProject] = useState([]);

  const [dropdownProject, setDropdownProject] = useState({
    data: [],
  });

  async function dropdownProjectDataDefault() {
    const tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/Project/dropdownProjectDataDefault",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        const options = [];

        for (const item of response.data.data) {
          let element = {};

          element["value"] = item.acc_project_id;
          element["label"] = item.acc_project_name;

          options.push(element);
        }

        setDropdownProject({ data: options });
        dropdownProject.data = options;
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "warning",
          // title: "กรุณากรอกข้อมูลใหม่",
          html: error.response.data.error.message,
        });
      });
  }

  async function viewCostCenterData() {
    const tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      costCenterId: costCenterId,
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/CostCenter/viewCostCenterData",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        setSaveData({
          ...saveData,
          cost_center_name: response.data.data[0]?.acc_cc_name,
          cost_center_remark: response.data.data[0]?.acc_cc_remark,
          cost_center_fc: response.data.data[0]?.acc_cc_is_fc,
          cost_center_cp: response.data.data[0]?.acc_cc_is_cp,
          cost_center_cos: response.data.data[0]?.acc_cc_is_cos,
          project_list: response.data.data[0]?.project_list,
        });

        const selectDropdownProjectTemp = dropdownProject.data.filter(
          (item) => {
            return response.data.data[0]?.project_list.includes(item.value);
          }
        );

        setSelectDropdownProject(selectDropdownProjectTemp);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "warning",
          // title: "กรุณากรอกข้อมูลใหม่",
          html: error.response.data.error.message,
        });
      });
  }

  async function saveUpdateCostCenterData() {
    Swal.fire({
      title: "คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const error_list = [];

        if (
          saveData.cost_center_name == null ||
          saveData.cost_center_name?.trim() == ""
        ) {
          const temp_err = {
            message: "กรุณากรอกชื่อรายจ่าย",
          };
          error_list.push(temp_err);
        }

        if (
          saveData.cost_center_fc == false &&
          saveData.cost_center_cp == false &&
          saveData.cost_center_cos == false
        ) {
          const temp_err = {
            message: "กรุณาเลือกประเภทค่าใช้จ่าย",
          };
          error_list.push(temp_err);
        }

        // if (
        //   saveData.cost_center_cp == true &&
        //   saveData.project_list?.length == 0
        // ) {
        //   const temp_err = {
        //     message: "กรุณาเลือก Project",
        //   };
        //   error_list.push(temp_err);
        // }

        if (error_list.length > 0) {
          let err_message = "";

          for (let err = 0; err < error_list.length; err++) {
            err_message += "<br/>" + error_list[err].message;
          }

          Swal.fire("Error", err_message, "error");
        } else {
          let axiosData = {};

          const tempData = JSON.parse(JSON.stringify(saveData));

          if (mode == "Add") {
            axiosData = {
              method: "post",
              url:
                Configs.API_URL_hrMagenatement +
                "/api/CostCenter/saveCostCenterData",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: tempData,
            };
          } else if (mode == "Edit") {
            tempData["costCenterId"] = costCenterId;

            axiosData = {
              method: "post",
              url:
                Configs.API_URL_hrMagenatement +
                "/api/CostCenter/updateCostCenterData",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: tempData,
            };
          }

          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async (result) => {
            await axios(axiosData)
              .then(function (response) {
                Swal.fire({
                  icon: "success",
                  title: "บันทึกสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                }).then(() => {
                  window.location.href = "/accounting/cost_center";
                });
              })
              .catch(async function (error) {
                console.log(error);
                Swal.fire({
                  icon: "warning",
                  // title: "กรุณากรอกข้อมูลใหม่",
                  html: error.response.data.error.message,
                });
              });
          });
        }
      }
    });
  }

  function cancelOption() {
    if (mode == "View") {
      window.location.href = "/accounting/cost_center";
    } else {
      Swal.fire({
        title: "คุณมั่นใจว่าจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/accounting/cost_center";
        }
      });
    }
  }

  useEffect(async () => {
    await dropdownProjectDataDefault();

    if (mode == "View") {
      setIsDisabled(true);
    }

    if (mode != "Add") {
      await viewCostCenterData();
    }
  }, []);

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  {mode} Cost Center
                  {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Accounting</li>
                  <li className="breadcrumb-item active">{mode} Cost Center</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              {mode != "View" ? (
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={saveUpdateCostCenterData}
                    class="btn btn-block btn-success "
                  >
                    บันทึก
                  </button>
                </div>
              ) : (
                <></>
              )}

              <div className="col-6 col-md-3 col-xl-2">
                <button
                  type="button"
                  onClick={cancelOption}
                  class="btn btn-block btn-danger "
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              {/* <h3 className="card-title"></h3> */}
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="cost_center_name"
                      value={saveData.cost_center_name}
                      // placeholder=""
                      disabled={isDisabled}
                      onChange={(e) => {
                        setSaveData({
                          ...saveData,
                          cost_center_name: e.target.value,
                        });
                      }}
                      style={{ padding: "0px 10px" }}
                    />
                    <label>
                      ชื่อรายจ่าย<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <textarea
                      type="text"
                      className="form-control"
                      required
                      id="cost_center_remark"
                      style={{
                        height: "150px",
                        fontSize: "25px",
                        padding: "5px 10px",
                      }}
                      value={saveData.cost_center_remark}
                      // placeholder=""
                      disabled={isDisabled}
                      onChange={(e) => {
                        setSaveData({
                          ...saveData,
                          cost_center_remark: e.target.value,
                        });
                      }}
                    />
                    <label>Remark</label>
                  </div>
                </div>
              </div>

              <div
                className="row"
                style={{ padding: "10px 0px", margin: "0px 0px 16px" }}
              >
                <div className="col-12">
                  <label>
                    ประเภทค่าใช้จ่าย
                    {/* <span style={{ color: "red" }}> *</span> */}
                  </label>
                </div>

                <div
                  className="custom-control custom-control-alternative custom-checkbox"
                  style={{ margin: "0px 5px" }}
                >
                  <input
                    id={`checkbox_item_fix_cost`}
                    className="form-check-input"
                    type="checkbox"
                    disabled={isDisabled}
                    // value={}
                    checked={saveData.cost_center_fc}
                    onChange={(event) => {
                      setSaveData({
                        ...saveData,
                        cost_center_fc: !saveData.cost_center_fc,
                      });
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="checkbox_item_fix_cost"
                  >
                    Fix Cost
                  </label>
                </div>

                <div
                  className="custom-control custom-control-alternative custom-checkbox"
                  style={{ margin: "0px 10px" }}
                >
                  <input
                    id={`checkbox_item_cost_project`}
                    className="form-check-input"
                    type="checkbox"
                    disabled={isDisabled}
                    // value={}
                    checked={saveData.cost_center_cp}
                    onChange={(event) => {
                      setSaveData({
                        ...saveData,
                        cost_center_cp: !saveData.cost_center_cp,
                        project_list: [],
                      });

                      setSelectDropdownProject();
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="checkbox_item_cost_project"
                  >
                    Cost Project
                  </label>
                </div>

                <div
                  className="custom-control custom-control-alternative custom-checkbox"
                  style={{ margin: "0px 5px" }}
                >
                  <input
                    id={`checkbox_item_cost_out_sourcing`}
                    className="form-check-input"
                    type="checkbox"
                    disabled={isDisabled}
                    // value={}
                    checked={saveData.cost_center_cos}
                    onChange={(event) => {
                      setSaveData({
                        ...saveData,
                        cost_center_cos: !saveData.cost_center_cos,
                      });
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="checkbox_item_cost_out_sourcing"
                  >
                    Cost out sourcing
                  </label>
                </div>
              </div>

              {/* {saveData.cost_center_cp == true ? (
                <div className="row">
                  <div className="col-12 col-md-6 col-xl-4">
                    <div class="form-group">
                      <Select
                        isMulti
                        isDisabled={isDisabled}
                        options={dropdownProject.data}
                        isOptionDisabled={(option) => option.disabled}
                        formatOptionLabel={(label) => label.html}
                        filterOption={(option, searchText) => {
                          if (
                            option.data.label
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                            || option.data.value
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        }}
                        value={selectDropdownProject}
                        onChange={(e) => {
                          setSelectDropdownProject(e);

                          if (e !== null) {
                            const projectId = e.map((item) => item.value);

                            setSaveData({
                              ...saveData,
                              project_list: projectId,
                            });
                          } else {
                            setSaveData({
                              ...saveData,
                              project_list: [],
                            });
                          }
                        }}
                      />
                      <label>
                        Project <span style={{ color: "red" }}> *</span>
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )} */}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Cost_Center_AEV;
