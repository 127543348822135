import React, { Component, useEffect, useRef, useState } from "react";

import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import { getFeature, getOem, getToken, getUser } from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
import Resizer from "react-image-file-resizer";
import liff from '@line/liff';
import logo from '../../assets/img/userlogo/user1.jpg';
// import { initLine } from "../Human_Resource/InitLine";
function Profile(mode) {

  const linkLineButton = useRef();
  const clicklinkLineButton = () => {
    linkLineButton.current.click();
  }
  const [company, setCompany] = useState([]);
  const [test, setTest] = useState({ test: "" });
  const [pageMode, setPageMode] = useState(false);
  const [disable, setdisable] = useState(true);
  const [profile, setprofile] = useState({
    username: "",
    name: "",
    sirname: "",
    email: "",
    user_profile: "",
    user_profile_name: "",
    user_profile_path: "",
    detail: "",
    mobile1: "",
    mobile2: "",


  });

  const [pass, setpass] = useState({
    old_pass: "",
    new_pass: "",
    new_pass2: "",
  });

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [feature, setFeature] = useState([]);
  useEffect(() => {
    GetCompany();
    //console.log("id:"+id);
    GetProfile_view();
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return (element.menu === "Profile");
    })
    setFeature(feature_data);
    console.log('feature_data', feature_data);
  }, []);


  const GetProfile_view = async () => {


    axios({
      method: "get",
      url: Configs.API_URL + "/users/getUserById",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        console.log(response.data);
        setprofile({
          ...profile,
          username: response.data.username,
          name: response.data.name,
          sirname: response.data.sirname,
          email: response.data.email,
          user_profile: response.data.user_profile,
          user_profile_name: response.data.user_profile_name,
          user_profile_path: response.data.user_profile_path,
          detail: response.data.detail,
          mobile1: response.data.mobile1,
          mobile2: response.data.mobile2,
        })

        const checkFeature = feature.find(el => el.feature === "line_oa");
        if (checkFeature) {
          if (!response.data.statusLine) {
            const tab1 = document.getElementById("custom-tabs-four-normal-tab");
            document.getElementById("custom-tabs-four-normal").classList.remove("active", "show");
            tab1.classList.remove("active");
            tab1.setAttribute("aria-selected", false);
            const tab2 = document.getElementById("custom-tabs-four-line-tab");
            document.getElementById("custom-tabs-four-line").classList.add("active", "show");
            tab2.classList.add("active");
            tab2.setAttribute("aria-selected", true);
          } else if (response.data.statusLine) {
            setPictureUrl(response.data.lineByUser.line_img);
            setDisplayName(response.data.lineByUser.line_name);
            setUserId(response.data.lineByUser.line_id);
            setPageMode(true);
          }
        }
        localStorage.removeItem("id");
      })
      .catch(function (error) {
        console.log(error);
      });


  }
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        200,
        150,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);

        },
        // "base64"
      );
    });
  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  function isFileImage(file) {
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

    return file && acceptedImageTypes.includes(file['type'])
  }

  async function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onloadend = function () {
        // The result is in the reader.result property
        resolve(reader.result);
      };
  
      reader.onerror = function (error) {
        reject(error);
      };
  
      // Read the file as a data URL (Base64 encoded string)
      reader.readAsDataURL(file);
    });
  }

  async function UploadProfile(e) {
    if (e.target.files[0] !== null) {

      if (profile.username !== "") {
        const file = e.target.files[0];


        const regex = /([\u0E00-\u0E7F]+)/gmu;
        const str = file.name;
        let m = regex.exec(str);
        if (m !== null || isFileImage(file) === false) {
          //console.log("ชื่อไฟล์ไม่ถูกต้อง"); 
          Swal.fire(
            'Error',
            'File name or type are not correct!!!',
            'error'
          )
        } else {
          const image = await convertFileToBase64(file);// await resizeFile(file);

          var file_image = dataURLtoFile(image, file.name);

          const data = new FormData();
          data.append("Profile", file_image);
          data.append("typeFile", profile.username);

          const config_uploadProfile = {
            method: "post",
            url: Configs.API_URL + "/upload/profile",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios(config_uploadProfile)
            .then(async (response) => {
              if (response.data.status) {
                console.log(response.data)
                setprofile({
                  ...profile,
                  user_profile: response.data.data,
                  user_profile_name: response.data.data.orgin_name,
                  user_profile_path: response.data.data.path,
                });
              }
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("check Name of files", "Name of files cannot be in Thai for cannot has space", "warning");
            });
        }

      } else {
        Swal.fire("check UserName", "UserName can't null", "warning");
      }
    }
  }
  function changePS() {
    const error_list = [];
    if (pass.old_pass == "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [รหัสผ่านเดิม]."
      }
      error_list.push(temp_err);
    }
    if (pass.new_pass == "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [รหัสผ่านใหม่]."
      }
      error_list.push(temp_err);
    }
    if (pass.new_pass2 == "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [ยืนยันรหัสผ่านใหม่]."
      }
      error_list.push(temp_err);
    }

    if (pass.new_pass2 !== pass.new_pass) {
      let temp_err = {
        message: "[รหัสผ่านใหม่] และ [ยืนยันรหัสผ่านใหม่] ไม่ตรงกัน."
      }
      error_list.push(temp_err);
    }

    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        axios({
          method: "post",
          url: Configs.API_URL + "/users/changePS",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: pass,
        })
          .then(function (response) {
            if (response.data) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href =
                  "/Profile";
              });
            }

            //console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", error.response.data.message, "error");
          });
      });
    }
  }
  function saveOption(params) {
    const error_list = [];
    if (profile.name == "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [Name]."
      }
      error_list.push(temp_err);
    }
    if (profile.sirname == "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [Sirname]."
      }
      error_list.push(temp_err);
    }
    if (profile.email == "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [Email]."
      }
      error_list.push(temp_err);
    }

    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {

      const temp = {

        name: profile.name,
        sirname: profile.sirname,
        email: profile.email,
        mobile1: profile.mobile1,
        mobile2: profile.mobile2,
        detail: profile.detail,
        user_profile: profile.user_profile,
        user_profile_name: profile.user_profile_name,
        user_profile_path: profile.user_profile_path,

      }
      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        axios({
          method: "post",
          url: Configs.API_URL + "/users/update",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(function (response) {
            if (response.data) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href =
                  "/Profile";
              });
            }

            //console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", error.response.data.message, "error");
          });
      });
    }



  }

  function cancelOption(params) {
    Swal.fire(
      "Do you want to cancel?",
      "Wait",
      "warning"
    ).then(() => {
      window.location.href =
        "/Warehouse_Management/Factory_Master_Data/Project";
    }
    );
  }
  const [pictureUrl, setPictureUrl] = useState(logo);
  const [idToken, setIdToken] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [userId, setUserId] = useState("");

  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Profile</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Profile</li>

                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">

          </div>
        </section>
        <section className="content">

          <div className="row">





            <div className="col-4">
              <div className="card">

                <div class="card-body box-profile">
                  <div class="text-center">
                    <img class="profile-user-img img-fluid img-circle" src={Configs.API_URL_IMG + profile.user_profile_path} alt="User profile picture" />
                  </div>
                  <h3 class="profile-username text-center">{profile.name + " " + profile.sirname}</h3>
                  <p class="text-muted text-center">Role comming soon</p>
                  <ul class="list-group list-group-unbordered mb-3">
                    <li class="list-group-item">
                      <b>comming soon</b> <a class="float-right">xxx</a>
                    </li>
                    <li class="list-group-item">
                      <b>comming soon</b> <a class="float-right">xxx</a>
                    </li>
                    <li class="list-group-item">
                      <b>comming soon</b> <a class="float-right">xxx</a>
                    </li>
                  </ul>
                  {/*        <a href="#" class="btn btn-primary btn-block"><b>Follow</b></a> */}
                </div>





              </div>
            </div>
            <div className="col-8">
              <div className="card card-primary card-outline card-outline-tabs">
                <div className="card-header p-0 border-bottom-0">
                  <ul
                    className="nav nav-tabs"
                    id="custom-tabs-four-tab"
                    role="tablist"
                  >

                    <li className="nav-item">
                      <a
                        className={"nav-link active"}
                        id={"custom-tabs-four-normal-tab"}
                        data-toggle="pill"
                        href={"#custom-tabs-four-normal"}
                        role="tab"
                        //onClick={()=>{setmaterail_group_view({...materail_group_view,supplier_id:e.supplier_id})}}
                        aria-controls={"custom-tabs-four-normal"}
                        aria-selected="false"
                      >
                        ข้อมูลทั่วไป
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={"nav-link"}
                        id={"custom-tabs-four-advance-tab"}
                        data-toggle="pill"
                        href={"#custom-tabs-four-advance"}
                        role="tab"
                        //onClick={()=>{setmaterail_group_view({...materail_group_view,supplier_id:e.supplier_id})}}
                        aria-controls={"custom-tabs-four-advance"}
                        aria-selected="false"
                      >
                        เปลี่ยนรหัสผ่าน
                      </a>
                    </li>

                    {
                      feature.map(el => {
                        if (el.feature === "line_oa") {
                          return (
                            <>
                              <li className="nav-item">
                                <a
                                  className={"nav-link"}
                                  id={"custom-tabs-four-line-tab"}
                                  data-toggle="pill"
                                  href={"#custom-tabs-four-line"}
                                  role="tab"
                                  //onClick={()=>{setmaterail_group_view({...materail_group_view,supplier_id:e.supplier_id})}}
                                  aria-controls={"custom-tabs-four-line"}
                                  aria-selected="false"
                                >
                                  เชื่อมต่อ Line
                                </a>
                              </li>
                            </>
                          )
                        }
                      })
                    }

                  </ul>
                </div>

                <div className="tab-content" id="custom-tabs-four-tabContent">
                  <div
                    className={"tab-pane fade show active"}
                    id={"custom-tabs-four-normal"}
                    role="tabpanel"
                    aria-labelledby={"custom-tabs-four-normal-tab"}
                  >

                    <div className="card-body">

                      <div className="container-fluid">

                        <div className="row">
                          <div className="col-3">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                disabled={disable}
                                id=""
                                required
                                value={profile.name}
                                onChange={(e) => {
                                  setprofile({ ...profile, name: e.target.value });
                                }}
                              />
                              <label htmlFor="">
                                Name
                              </label>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                disabled={disable}
                                id=""
                                required
                                value={profile.sirname}
                                onChange={(e) => {
                                  setprofile({ ...profile, sirname: e.target.value });
                                }}
                              />
                              <label htmlFor="">Sirname</label>
                            </div>
                          </div>

                          <div className="col-sm-2">
                            <div className="form-group">
                              <button
                                type="button"
                                onClick={() => {
                                  if (disable) {
                                    setdisable(false);
                                  } else {
                                    setdisable(true);
                                  }

                                }}
                                class="btn btn-block btn-primary "
                              >
                                {disable ? "Open Edit mode" : "Cancle Edit mode"}
                              </button>
                            </div>
                          </div>
                        </div>



                        <div className="row">
                          <div className="col-3">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"

                                id=""
                                required
                                disabled={disable}
                                value={profile.email}
                                onChange={(e) => {
                                  setprofile({ ...profile, email: e.target.value });
                                }}
                              />
                              <label htmlFor="">
                                Email
                              </label>
                            </div>
                          </div>

                        </div>

                        <div className="row">
                          <div className="col-3">
                            <div className="form-group">


                              <NumberFormat
                                required
                                className="form-control"
                                id="moblie1"
                                disabled={disable}
                                value={profile.mobile1}
                                onChange={(e) => {
                                  setprofile({ ...profile, mobile1: e.target.value });
                                }}
                                format="+66 ##-###-####" mask="_"
                              />
                              <label htmlFor="">Moblie</label>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">


                              <NumberFormat
                                required
                                className="form-control"
                                id="moblie1"
                                disabled={disable}
                                value={profile.mobile2}
                                onChange={(e) => {
                                  setprofile({ ...profile, mobile2: e.target.value });
                                }}
                                format="+66 ##-###-####" mask="_"
                              />
                              <label htmlFor="">Moblie2</label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-8">
                            <div className="form-group">
                              <textarea
                                type="text"
                                className="form-control"

                                id=""
                                required
                                disabled={disable}
                                value={profile.detail}
                                onChange={(e) => {
                                  setprofile({ ...profile, detail: e.target.value });
                                }}
                                style={{ resize: "none", height: "100px" }}
                              />
                              <label htmlFor="">Detail</label>
                            </div>
                          </div>
                        </div>


                        <div className="row">
                          <div className="col-4">
                            <div className="none-nolmalinput">
                              <label htmlFor="">User Profile:</label>
                              <small className="reccoment-position">200 x 150 px</small>
                              <div className="input-group">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    disabled={disable}

                                    id="exampleInputFile"
                                    accept="image/*"
                                    onChange={UploadProfile}
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="exampleInputFile"
                                  >
                                    {profile.user_profile_name}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>




                        </div>
                        <br />


                        <div className="row mb-2">
                          <div className="col-sm-2">
                            <button
                              type="button"
                              disabled={disable}
                              onClick={saveOption}
                              class="btn btn-block btn-success "
                            >
                              Save Changes
                            </button>

                          </div>
                        </div>



                      </div>
                    </div>

                  </div>
                  <div
                    className={"tab-pane fade"}
                    id={"custom-tabs-four-advance"}
                    role="tabpanel"
                    aria-labelledby={"custom-tabs-four-advance-tab"}
                  >

                    <div className="card-body">

                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-3">
                            <div className="form-group">
                              <input
                                type="password"
                                className="form-control"
                                //disabled={disable}
                                id=""
                                required
                                value={pass.old_pass}
                                onChange={(e) => {
                                  setpass({ ...pass, old_pass: e.target.value });
                                }}
                              />
                              <label htmlFor="">
                                รหัสผ่านเดิม
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-3">
                            <div className="form-group">
                              <input
                                type="password"
                                className="form-control"


                                required
                                value={pass.new_pass}
                                onChange={(e) => {
                                  setpass({ ...pass, new_pass: e.target.value });
                                }}
                              />
                              <label htmlFor="">
                                รหัสผ่านใหม่
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-3">
                            <div className="form-group">
                              <input
                                type="password"
                                className="form-control"

                                required
                                value={pass.new_pass2}
                                onChange={(e) => {
                                  setpass({ ...pass, new_pass2: e.target.value });
                                }}
                              />
                              <label htmlFor="">
                                ยืนยันรหัสผ่านใหม่
                              </label>
                            </div>
                          </div>
                        </div>


                        <div className="row mb-2">
                          <div className="col-sm-2">
                            <button
                              type="button"
                              onClick={changePS}
                              class="btn btn-block btn-success "
                            >
                              Save Change
                            </button>

                          </div>
                        </div>


                      </div>
                    </div>











                  </div>
                  {
                    feature.map(el => {
                      if (el.feature === "line_oa") {
                        <div
                          className={"tab-pane fade"}
                          id={"custom-tabs-four-line"}
                          role="tabpanel"
                          aria-labelledby={"custom-tabs-four-line-tab"}
                        >

                          <div className="card-body">

                            <div className="container-fluid">
                              <div className="row mb-2">
                                <div className="col-3 col-sm-6">
                                  <button
                                    type="button"
                                    // disabled={pageMode}
                                    className="btn btn-block btn-success "
                                    onClick={() => {
                                      clicklinkLineButton()
                                    }}
                                  >
                                    <a
                                      style={{ color: 'white' }}
                                      // target="_blank"
                                      ref={linkLineButton}
                                      href={"/hr_line/" + getUser().fup}
                                      id="btn_edit"
                                    // key={team[index].id + "edit"}
                                    // className=" btn btn-xs "
                                    >
                                      {pageMode ? 'Update ' : 'Connect '} Line
                                    </a>
                                  </button>

                                </div>
                              </div>
                              <div className="row">
                                <div className="col-3 col-sm-6">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      disabled="true"
                                      value={userId}
                                      onChange={() => { }}
                                    >
                                    </input>
                                    <label htmlFor="">
                                      User ID
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-3 col-sm-6">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      disabled="true"
                                      value={displayName}
                                      onChange={() => { }}
                                    >
                                    </input>
                                    <label htmlFor="">
                                      displayName
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-3 col-sm-6">
                                  <div className="form-group">
                                    <img
                                      src={pictureUrl}
                                      style={{ border: "2px solid black", width: "150px", height: "150px" }}
                                    >
                                    </img>
                                    <label htmlFor="">
                                      image profile
                                    </label>
                                  </div>
                                </div>
                              </div>


                            </div>
                          </div>











                        </div>
                      }
                    })
                  }

                </div>
              </div>









            </div>



          </div>



        </section>
      </div>
      {/*  <Footter/> */}
    </div>
  );
}
export default Profile;
