import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../Utils/Common";
import axios from "axios";
import Configs from "../../../../config";
import moment from "moment";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import Select from "react-select";
import user_room from "../../../../assets/img/booking/user_room.png";

function Booking_AEV({ fromMode, type, mode, previousURL }) {
  const { bookId } = useParams();

  const [saveData, setSaveData] = useState({
    company_id: getUser().com,
    oem_id: getOem(),
    user_id: getUser().fup,
    selectDate: "",
    selectStartTime: "",
    selectEndTime: "",
    inputDetail: "",
  });

  const [checkAbandon, setCheckAbandon] = useState({
    is_cancel: false,
    created_by: "",
  });

  const [accessoriesList, setAccessoriesList] = useState([]);

  const [roomList, setRoomList] = useState([]);

  const [viewMode, setViewMode] = useState(false);

  async function GetRoomAccessories() {
    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/booking/getRoomAccessories",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data:{
         oem_id: getOem(),
         company_id: getUser().com,
      }
    })
      .then(async (response) => {
        setAccessoriesList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetRoomHotel() {
    const tempData = {
      selectStartTime: saveData.selectStartTime,
      selectEndTime: saveData.selectEndTime,
      oem_id: getOem(),
      company_id: getUser().com,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/booking/getHotelRoom",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        const options = [];

        for (const item of response.data.data) {
          let element = {};

          element["value"] = item.bhr_id;
          element["label"] = `${item.bhr_name} ( ${
            item?.booking_list?.length > 0 ? "เต็ม" : "ว่าง"
          } )`;
          element["html"] = (
            <>
              {item.bhr_name}{" "}
              <span
                style={{
                  color: item?.booking_list?.length > 0 ? "#FF0024" : "#1DC256",
                }}
              >{`( ${
                item?.booking_list?.length > 0 ? "เต็ม" : "ว่าง"
              } )`}</span>
            </>
          );
          element["disabled"] =
            item?.booking_list?.length > 0 &&
            saveData.selectRoom != item?.bhr_id
              ? true
              : false;

          options.push(element);
        }

        setRoomList(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function SaveUpdateBookingData() {
    Swal.fire({
      title: "คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const error_list = [];

        if (
          type == "hotel" &&
          saveData.objectiveBox1 == false &&
          saveData.objectiveBox2 == false
        ) {
          const temp_err = {
            message: "กรุณาเลือกวัตถุประสงค์การจอง",
          };
          error_list.push(temp_err);
        }

        if (saveData.selectDate == null || saveData.selectDate == "") {
          const temp_err = {
            message: "กรุณาเลือกวันที่จอง",
          };
          error_list.push(temp_err);
        }

        if (
          saveData.selectStartTime == null ||
          saveData.selectStartTime == ""
        ) {
          const temp_err = {
            message: "กรุณาเลือกเวลาที่เริ่มต้นจอง",
          };
          error_list.push(temp_err);
        }

        if (saveData.selectEndTime == null || saveData.selectEndTime == "") {
          const temp_err = {
            message: "กรุณาเลือกเวลาที่สิ้นสุดจอง",
          };
          error_list.push(temp_err);
        }

        if (
          saveData.inputDetail == null ||
          saveData.inputDetail?.trim() == ""
        ) {
          const temp_err = {
            message: "กรุณากรอกรายละเอียด",
          };
          error_list.push(temp_err);
        }

        if (
          type == "hotel" &&
          (saveData.selectRoom == null || saveData.selectRoom == "")
        ) {
          const temp_err = {
            message: "กรุณาเลือกช่องนอน",
          };
          error_list.push(temp_err);
        }

        if (error_list.length > 0) {
          let err_message = "";

          for (let err = 0; err < error_list.length; err++) {
            err_message += "<br/>" + error_list[err].message;
          }

          Swal.fire("Error", err_message, "error");
        } else {
          let axiosData = {};

          const tempData = JSON.parse(JSON.stringify(saveData));
          tempData["type"] = type;

          if (mode == "add") {
            axiosData = {
              method: "post",
              url:
                Configs.API_URL_hrMagenatement + "/api/booking/saveBookingData",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: tempData,
            };
          } else if (mode == "edit") {
            tempData["bookingID"] = bookId;

            axiosData = {
              method: "post",
              url:
                Configs.API_URL_hrMagenatement +
                "/api/booking/updateBookingData",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: tempData,
            };
          }

          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async (result) => {
            await axios(axiosData)
              .then(function (response) {
                Swal.fire({
                  icon: "success",
                  title: "บันทึกสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                }).then(() => {
                  window.location.href = previousURL;
                });
              })
              .catch(async function (error) {
                console.log(error);
                Swal.fire({
                  icon: "warning",
                  // title: "กรุณากรอกข้อมูลใหม่",
                  html: error.response.data.error.message,
                });

                if (error.response.data.error.message.includes("TTT Hotel")) {
                  await GetRoomHotel();
                }
              });
          });
        }
      }
    });
  }

  async function ViewBookingData() {
    const tempData = {
      bookingID: bookId,
      type: type,
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/booking/viewBookingData",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        if (type == "hotel") {
          setSaveData({
            ...saveData,
            selectDate: new Date(response.data.data[0]?.bh_book_date),
            selectStartTime: new Date(response.data.data[0]?.bh_start),
            selectEndTime: new Date(response.data.data[0]?.bh_end),
            inputDetail: response.data.data[0]?.bh_detail,
            selectEndDate: new Date(response.data.data[0]?.bh_end_date),
            objectiveBox1: response.data.data[0]?.bh_is_sick,
            objectiveBox2: response.data.data[0]?.bh_is_overnight,
            selectRoom: response.data.data[0]?.bh_bhr_id,
          });
          saveData.selectDate = new Date(response.data.data[0]?.bh_book_date);
          saveData.selectStartTime = new Date(response.data.data[0]?.bh_start);
          saveData.selectEndTime = new Date(response.data.data[0]?.bh_end);
          saveData.selectEndDate = new Date(response.data.data[0]?.bh_end_date);
          saveData.selectRoom = response.data.data[0]?.bh_bhr_id;
          setCheckAbandon({
            is_cancel: response.data.data[0]?.bh_is_cancel,
            created_by: response.data.data[0]?.bh_created_by,
          });
        } else if (type == "hall") {
          setSaveData({
            ...saveData,
            selectDate: new Date(response.data.data[0]?.bhall_book_date),
            selectStartTime: new Date(response.data.data[0]?.bhall_start),
            selectEndTime: new Date(response.data.data[0]?.bhall_end),
            inputDetail: response.data.data[0]?.bhall_detail,
            selectAccessories: response.data.data[0]?.room_accessories,
          });
          saveData.selectDate = new Date(
            response.data.data[0]?.bhall_book_date
          );
          saveData.selectStartTime = new Date(
            response.data.data[0]?.bhall_start
          );
          saveData.selectEndTime = new Date(response.data.data[0]?.bhall_end);
          setCheckAbandon({
            is_cancel: response.data.data[0]?.bhall_is_cancel,
            created_by: response.data.data[0]?.bhall_created_by,
          });
        } else if (type == "hotel_meet") {
          setSaveData({
            ...saveData,
            selectDate: new Date(response.data.data[0]?.bhm_book_date),
            selectStartTime: new Date(response.data.data[0]?.bhm_start),
            selectEndTime: new Date(response.data.data[0]?.bhm_end),
            inputDetail: response.data.data[0]?.bhm_detail,
            selectAccessories: response.data.data[0]?.room_accessories,
          });
          saveData.selectDate = new Date(response.data.data[0]?.bhm_book_date);
          saveData.selectStartTime = new Date(response.data.data[0]?.bhm_start);
          saveData.selectEndTime = new Date(response.data.data[0]?.bhm_end);
          setCheckAbandon({
            is_cancel: response.data.data[0]?.bhm_is_cancel,
            created_by: response.data.data[0]?.bhm_created_by,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "warning",
          // title: "กรุณากรอกข้อมูลใหม่",
          html: error.response.data.error.message,
        });
      });
  }

  async function AbandonBookingData() {
    Swal.fire({
      title: "คุณมั่นใจว่าจะยกเลิกการจองใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const tempData = {
          bookingID: bookId,
          type: type,
          company_id: getUser().com,
          oem_id: getOem(),
          user_id: getUser().fup,
        };

        await axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement + "/api/booking/abandonBookingData",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: tempData,
        })
          .then(function (response) {
            Swal.fire({
              icon: "success",
              title: "ยกเลิกสำเร็จ",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              window.location.href = previousURL;
            });
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire({
              icon: "warning",
              // title: "กรุณากรอกข้อมูลใหม่",
              html: error.response.data.error.message,
            });
          });
      }
    });
  }

  function cancelOption() {
    if (mode == "view") {
      window.location.href = previousURL;
    } else {
      Swal.fire({
        title: "คุณมั่นใจว่าจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = previousURL;
        }
      });
    }
  }

  useEffect(async () => {
    if (type == "hotel") {
      setSaveData({
        ...saveData,
        selectStartTime: new Date(moment("17:30:00", "HH:mm:ss")),
        selectEndTime: new Date(moment("08:30:00", "HH:mm:ss")),
        selectEndDate: "",
        objectiveBox1: false,
        objectiveBox2: true,
        selectRoom: null,
      });
    } else {
      setSaveData({
        ...saveData,
        selectAccessories: [],
      });
    }

    if (mode == "view" || mode == "edit") {
      if (mode == "view") {
        setViewMode(true);
      }

      await ViewBookingData();
    }

    if (type == "hotel") {
      await GetRoomHotel();
    } else {
      await GetRoomAccessories();
    }
  }, []);

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  จองห้อง{" "}
                  {type == "hall"
                    ? "TTT Hall"
                    : type == "hotel_meet"
                    ? "TTT Hotel Meeting"
                    : type == "hotel"
                    ? "TTT Hotel"
                    : ""}
                  {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Booking</li>
                  <li className="breadcrumb-item active">
                    Booking{" "}
                    {type == "hall"
                      ? "TTT Hall"
                      : type == "hotel_meet"
                      ? "TTT Hotel Meeting"
                      : type == "hotel"
                      ? "TTT Hotel"
                      : ""}
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode == "view" ? (
              <div className="row mb-2">
                {fromMode == "normal" &&
                saveData.user_id == checkAbandon.created_by &&
                checkAbandon.is_cancel == false &&
                moment(new Date()).isBefore(saveData.selectStartTime) ? (
                  <div className="col-6 col-md-3 col-xl-2">
                    <button
                      type="button"
                      onClick={AbandonBookingData}
                      class="btn btn-block btn-primary"
                    >
                      ยกเลิกการจอง
                    </button>
                  </div>
                ) : (
                  <></>
                )}

                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={cancelOption}
                    class="btn btn-block btn-danger"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={SaveUpdateBookingData}
                    class="btn btn-block btn-success"
                  >
                    บันทึก
                  </button>
                </div>

                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={cancelOption}
                    class="btn btn-block btn-danger"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">
                {/* จองห้อง{" "}
                {type == "hall"
                  ? "TTT Hall"
                  : type == "hotel_meet"
                  ? "TTT Hotel Meeting"
                  : type == "hotel"
                  ? "TTT Hotel"
                  : ""}{" "}
                {mode == "add"
                  ? "Add"
                  : mode == "view"
                  ? "View"
                  : mode == "edit"
                  ? "Edit"
                  : ""} */}
              </h3>
            </div>

            <div className="card-body">
              {type == "hotel" ? (
                <div className="row">
                  <div className="col-12">
                    <label>
                      วัตถุประสงค์การจอง{" "}
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    {/* <h4 className="">วัตถุประสงค์การจอง</h4> */}

                    <div className="row">
                      <div className="col-12 col-md-auto">
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            id="checkbox_item_2"
                            className="form-check-input"
                            type="radio"
                            name="objectiveUse"
                            disabled={viewMode}
                            checked={saveData.objectiveBox2}
                            onChange={() => {
                              const modifySelectStartTime = new Date(
                                moment("17:30:00", "HH:mm:ss")
                                  .date(
                                    moment(
                                      saveData.selectDate || new Date()
                                    ).date()
                                  )
                                  .month(
                                    moment(
                                      saveData.selectDate || new Date()
                                    ).month()
                                  )
                                  .year(
                                    moment(
                                      saveData.selectDate || new Date()
                                    ).year()
                                  )
                                  .second(0)
                                  .millisecond(0)
                              );
                              const modifySelectEndDate = saveData.selectDate
                                ? new Date(
                                    moment(saveData.selectDate)
                                      .add(1, "day")
                                      .startOf("day")
                                  )
                                : "";
                              const modifySelectEndTime = new Date(
                                moment("08:30:00", "HH:mm:ss")
                                  .date(
                                    moment(
                                      saveData.selectDate || new Date()
                                    ).add(1, 'day').date()
                                  )
                                  .month(
                                    moment(
                                      saveData.selectDate || new Date()
                                    ).add(1, 'day').month()
                                  )
                                  .year(
                                    moment(
                                      saveData.selectDate || new Date()
                                    ).add(1, 'day').year()
                                  )
                                  .second(0)
                                  .millisecond(0)
                              );

                              setSaveData({
                                ...saveData,
                                selectStartTime: modifySelectStartTime,
                                selectEndTime: modifySelectEndTime,
                                selectEndDate: modifySelectEndDate,
                                objectiveBox1: !saveData.objectiveBox1,
                                objectiveBox2: !saveData.objectiveBox2,
                              });
                              saveData.selectStartTime = modifySelectStartTime;
                              saveData.selectEndTime = modifySelectEndTime;
                              saveData.selectEndDate = modifySelectEndDate;
                              saveData.objectiveBox1 = !saveData.objectiveBox1;
                              saveData.objectiveBox2 = !saveData.objectiveBox2;

                              if (saveData.selectDate) {
                                GetRoomHotel();
                              }
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="chk_remember1"
                          >
                            ค้างคืน
                          </label>
                        </div>
                      </div>

                      <div className="col-12 col-md-auto">
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            id="checkbox_item_1"
                            className="form-check-input"
                            type="radio"
                            name="objectiveUse"
                            disabled={viewMode}
                            checked={saveData.objectiveBox1}
                            onChange={() => {
                              // const modifySelectEndTime = saveData.selectEndTime
                              //   ? new Date(
                              //       moment(saveData.selectEndTime)
                              //         .date(moment(saveData.selectDate).date())
                              //         .month(
                              //           moment(saveData.selectDate).month()
                              //         )
                              //         .year(moment(saveData.selectDate).year())
                              //         .second(0)
                              //         .millisecond(0)
                              //     )
                              //   : "";

                              setSaveData({
                                ...saveData,
                                selectStartTime: "",
                                selectEndTime: "",
                                selectEndDate: "",
                                objectiveBox1: !saveData.objectiveBox1,
                                objectiveBox2: !saveData.objectiveBox2,
                              });
                              saveData.selectStartTime = "";
                              saveData.selectEndTime = "";
                              saveData.selectEndDate = "";
                              saveData.objectiveBox1 = !saveData.objectiveBox1;
                              saveData.objectiveBox2 = !saveData.objectiveBox2;

                              GetRoomHotel();
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="chk_remember1"
                          >
                            ป่วย
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="row">
                <div
                  className="col-12 col-md-6 col-xl-4"
                  style={{ "z-index": "99" }}
                >
                  <div className="form-group input-group">
                    <DatePicker
                      id="inputDate"
                      className="form-control"
                      wrapperClassName="form-control"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DD/MM/YYYY"
                      popperModifiers={{
                        flip: { behavior: ["bottom"] },
                        preventOverflow: { enabled: false },
                        hide: { enabled: false },
                      }}
                      disabled={viewMode}
                      selected={saveData.selectDate}
                      minDate={new Date()}
                      onChange={async (date) => {
                        const modifySelectDate = new Date(
                          moment(date).startOf("day")
                        );
                        const modifySelectStartTime = saveData.selectStartTime
                          ? new Date(
                              moment(saveData.selectStartTime)
                                .date(moment(modifySelectDate).date())
                                .month(moment(modifySelectDate).month())
                                .year(moment(modifySelectDate).year())
                                .second(0)
                                .millisecond(0)
                            )
                          : "";

                        if (type == "hotel" && saveData.objectiveBox2) {
                          const modifySelectEndDate = new Date(
                            moment(date).add(1, "day").startOf("day")
                          );
                          const modifySelectEndTime = saveData.selectEndTime
                            ? new Date(
                                moment(saveData.selectEndTime)
                                  .date(moment(modifySelectEndDate).date())
                                  .month(moment(modifySelectEndDate).month())
                                  .year(moment(modifySelectEndDate).year())
                                  .second(0)
                                  .millisecond(0)
                              )
                            : "";

                          setSaveData({
                            ...saveData,
                            selectDate: modifySelectDate,
                            selectStartTime: modifySelectStartTime,
                            selectEndTime: modifySelectEndTime,
                            selectEndDate: modifySelectEndDate,
                          });
                          saveData.selectDate = modifySelectDate;
                          saveData.selectStartTime = modifySelectStartTime;
                          saveData.selectEndTime = modifySelectEndTime;
                          saveData.selectEndDate = modifySelectEndDate;
                        } else {
                          const modifySelectEndTime = saveData.selectEndTime
                            ? new Date(
                                moment(saveData.selectEndTime)
                                  .date(moment(modifySelectDate).date())
                                  .month(moment(modifySelectDate).month())
                                  .year(moment(modifySelectDate).year())
                                  .second(0)
                                  .millisecond(0)
                              )
                            : "";

                          setSaveData({
                            ...saveData,
                            selectDate: modifySelectDate,
                            selectStartTime: modifySelectStartTime,
                            selectEndTime: modifySelectEndTime,
                          });
                          saveData.selectDate = modifySelectDate;
                          saveData.selectStartTime = modifySelectStartTime;
                          saveData.selectEndTime = modifySelectEndTime;
                        }

                        if (
                          type == "hotel" &&
                          saveData.selectStartTime &&
                          saveData.selectEndTime
                        ) {
                          GetRoomHotel();
                        }
                      }}
                    />
                    <label>
                      เลือกวันที่
                      {type == "hotel" && saveData.objectiveBox2
                        ? "เริ่มต้น"
                        : ""}{" "}
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="far fa-calendar-alt" />
                      </span>
                    </div>
                  </div>
                </div>

                {type == "hotel" && saveData.objectiveBox2 ? (
                  <div
                    className="col-12 col-md-6 col-xl-4"
                    style={{ "z-index": "98" }}
                  >
                    <div className="form-group input-group">
                      <DatePicker
                        id="inputDate"
                        className="form-control"
                        wrapperClassName="form-control"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                        popperModifiers={{
                          flip: { behavior: ["bottom"] },
                          preventOverflow: { enabled: false },
                          hide: { enabled: false },
                        }}
                        disabled={true}
                        selected={saveData.selectEndDate}
                      />
                      <label>วันที่สิ้นสุด</label>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="far fa-calendar-alt" />
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div className="row">
                <div
                  className="col-12 col-md-6 col-xl-4"
                  style={{ "z-index": "97" }}
                >
                  <div className="form-group input-group">
                    <DatePicker
                      id="inputStartTime"
                      className="form-control"
                      wrapperClassName="form-control"
                      dateFormat="HH.mm"
                      timeFormat="HH.mm"
                      placeholderText="HH.MM"
                      popperModifiers={{
                        flip: { behavior: ["bottom"] },
                        preventOverflow: { enabled: false },
                        hide: { enabled: false },
                      }}
                      timeCaption="Time"
                      timeIntervals={10}
                      showTimeSelect
                      showTimeSelectOnly
                      minTime={
                        type == "hotel" && saveData.objectiveBox2
                          ? null
                          : new Date(
                              moment(saveData.selectEndTime).startOf("day")
                            )
                      }
                      maxTime={
                        type == "hotel" && saveData.objectiveBox2
                          ? null
                          : new Date(saveData.selectEndTime)
                      }
                      disabled={viewMode || saveData.objectiveBox2}
                      selected={saveData.selectStartTime}
                      onChange={async (date) => {
                        const modifySelectStartTime = date
                          ? new Date(
                              moment(date)
                                .date(
                                  moment(
                                    saveData.selectDate || new Date()
                                  ).date()
                                )
                                .month(
                                  moment(
                                    saveData.selectDate || new Date()
                                  ).month()
                                )
                                .year(
                                  moment(
                                    saveData.selectDate || new Date()
                                  ).year()
                                )
                                .second(0)
                                .millisecond(0)
                            )
                          : "";

                        if (type == "hotel" && saveData.objectiveBox2) {
                          setSaveData({
                            ...saveData,
                            selectStartTime: modifySelectStartTime,
                          });
                          saveData.selectStartTime = modifySelectStartTime;
                        } else {
                          if (
                            !saveData.selectEndTime ||
                            moment(modifySelectStartTime).isSameOrBefore(
                              moment(saveData.selectEndTime)
                            )
                          ) {
                            setSaveData({
                              ...saveData,
                              selectStartTime: modifySelectStartTime,
                            });
                            saveData.selectStartTime = modifySelectStartTime;
                          }
                        }

                        if (
                          type == "hotel" &&
                          saveData.selectDate &&
                          saveData.selectEndTime
                        ) {
                          GetRoomHotel();
                        }
                      }}
                    />
                    <label>
                      เลือกเวลาเริ่มต้น <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="far fa-calendar-alt" />
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 col-md-6 col-xl-4"
                  style={{ "z-index": "96" }}
                >
                  <div className="form-group input-group">
                    <DatePicker
                      id="inputEndTime"
                      className="form-control"
                      wrapperClassName="form-control"
                      dateFormat="HH.mm"
                      timeFormat="HH.mm"
                      placeholderText="HH.MM"
                      popperModifiers={{
                        flip: { behavior: ["bottom"] },
                        preventOverflow: { enabled: false },
                        hide: { enabled: false },
                      }}
                      timeCaption="Time"
                      timeIntervals={10}
                      showTimeSelect
                      showTimeSelectOnly
                      minTime={
                        type == "hotel" && saveData.objectiveBox2
                          ? null
                          : new Date(saveData.selectStartTime)
                      }
                      maxTime={
                        type == "hotel" && saveData.objectiveBox2
                          ? null
                          : new Date(
                              moment(saveData.selectStartTime).endOf("day")
                            )
                      }
                      disabled={viewMode || saveData.objectiveBox2}
                      selected={saveData.selectEndTime}
                      onChange={async (date) => {
                        if (type == "hotel" && saveData.objectiveBox2) {
                          const modifySelectEndTime = date
                            ? new Date(
                                moment(date)
                                  .date(
                                    moment(
                                      saveData.selectEndDate || new Date()
                                    ).date()
                                  )
                                  .month(
                                    moment(
                                      saveData.selectEndDate || new Date()
                                    ).month()
                                  )
                                  .year(
                                    moment(
                                      saveData.selectEndDate || new Date()
                                    ).year()
                                  )
                                  .second(0)
                                  .millisecond(0)
                              )
                            : "";

                          setSaveData({
                            ...saveData,
                            selectEndTime: modifySelectEndTime,
                          });
                          saveData.selectEndTime = modifySelectEndTime;
                        } else {
                          const modifySelectEndTime = date
                            ? new Date(
                                moment(date)
                                  .date(
                                    moment(
                                      saveData.selectDate || new Date()
                                    ).date()
                                  )
                                  .month(
                                    moment(
                                      saveData.selectDate || new Date()
                                    ).month()
                                  )
                                  .year(
                                    moment(
                                      saveData.selectDate || new Date()
                                    ).year()
                                  )
                                  .second(0)
                                  .millisecond(0)
                              )
                            : "";

                          if (
                            !saveData.selectStartTime ||
                            moment(modifySelectEndTime).isSameOrAfter(
                              moment(saveData.selectStartTime)
                            )
                          ) {
                            setSaveData({
                              ...saveData,
                              selectEndTime: modifySelectEndTime,
                            });
                            saveData.selectEndTime = modifySelectEndTime;
                          }
                        }

                        if (
                          type == "hotel" &&
                          saveData.selectStartTime &&
                          saveData.selectDate
                        ) {
                          GetRoomHotel();
                        }
                      }}
                    />
                    <label>
                      เลือกเวลาสิ้นสุด <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="far fa-calendar-alt" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div class="form-group">
                    <textarea
                      type="text"
                      className="form-control"
                      required
                      id="inputDetail"
                      style={{
                        height: "50px",
                        fontSize: "25px",
                        padding: "5px 10px",
                      }}
                      // placeholder=""
                      disabled={viewMode}
                      value={saveData.inputDetail}
                      onChange={(e) => {
                        setSaveData({
                          ...saveData,
                          inputDetail: e.target.value,
                        });
                      }}
                    />
                    <label>
                      รายละเอียด <span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                </div>
              </div>

              {type == "hotel" ? (
                <>
                  <div className="row">
                    <div className="col-12 col-md-6 col-xl-4">
                      <div class="form-group">
                        <Select
                          isDisabled={viewMode}
                          options={roomList}
                          isOptionDisabled={(option) => option.disabled}
                          formatOptionLabel={(label) => label.html}
                          filterOption={(option, searchText) => {
                            if (
                              option.data.label
                                .toLowerCase()
                                .includes(searchText.toLowerCase())
                              // || option.data.value
                              //   .toLowerCase()
                              //   .includes(searchText.toLowerCase())
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          }}
                          value={roomList.filter((e) => {
                            return e.value === saveData.selectRoom;
                          })}
                          onChange={(e) => {
                            if (e !== null) {
                              setSaveData({
                                ...saveData,
                                selectRoom: e.value,
                              });
                            }
                          }}
                        />
                        <label>
                          เลือกช่องนอน <span style={{ color: "red" }}> *</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12" style={{ textAlign: "center" }}>
                      <img
                        src={user_room}
                        alt="user_room.png"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <label>เลือกอุปกรณ์ที่ใช้</label>
                    {/* <h4 className="">เลือกอุปกรณ์ที่ใช้</h4> */}

                    {accessoriesList.map((item, index) => {
                      return (
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            id={`checkbox_item_${item?.bacc_id}`}
                            className="form-check-input"
                            type="checkbox"
                            disabled={viewMode}
                            value={item?.bacc_id}
                            checked={saveData.selectAccessories.includes(
                              item?.bacc_id
                            )}
                            onChange={(event) => {
                              let tempData = JSON.parse(
                                JSON.stringify(saveData.selectAccessories)
                              );

                              if (tempData.includes(event.target.value)) {
                                tempData = tempData.filter((item) => {
                                  return item != event.target.value;
                                });
                              } else {
                                tempData.push(event.target.value);
                              }

                              setSaveData({
                                ...saveData,
                                selectAccessories: tempData,
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`checkbox_item_${item?.bacc_id}`}
                          >
                            {item?.bacc_name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Booking_AEV;
