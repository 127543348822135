import React, { useEffect, useRef, useState } from "react";
import Configs from "../../../config";
import axios from "axios";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { getOem, getToken, getUser, getAcademy } from "../../../Utils/Common";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Button as ButtonAnt, Image, Upload } from "antd";
import NumberFormat from "react-number-format";
import moment from "moment";
import { Stage, Layer, Line } from "react-konva";
import "./Sign.css";

function Sign() {
  const [checkEdit, setCheckEdit] = useState(false);

  // const divRef = useRef(null);

  const [tool, setTool] = useState("pen");
  const [lines, setLines] = useState([]);
  const isDrawing = useRef(false);
  const stageRef = useRef(null);

  const [saveConfig, setSaveConfig] = useState({
    company_id: getUser().com,
    oem_id: getOem(),
    user_id: getUser().fup,
    accept: false,
  });

  const handleMouseDown = (e) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { tool, points: [pos.x, pos.y] }]);
  };

  const handleMouseMove = (e) => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];

    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  function cancelOption() {
    Swal.fire({
      title: "คุณมั่นใจว่าจะยกเลิกใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }

  async function getSignConfig() {
    const tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/signConfig/getSignConfig",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        if (response.data.data.length !== 0) {
          setSaveConfig({
            ...saveConfig,
            accept: true,
          });

          setLines(JSON.parse(response.data.data[0].sign_detail));
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "warning",
          // title: "กรุณากรอกข้อมูลใหม่",
          html: error.response.data.error.message,
        });
      });
  }

  async function saveSignConfig() {
    if (lines.length > 0) {
      Swal.fire({
        title: "คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const error_list = [];

          if (!saveConfig.accept) {
            const temp_err = {
              message: "กรุณายอมรับเงื่อนไขและนโยบายความเป็นส่วนตัว",
            };
            error_list.push(temp_err);
          }

          if (error_list.length > 0) {
            let err_message = "";

            for (let err = 0; err < error_list.length; err++) {
              err_message += "<br/>" + error_list[err].message;
            }

            Swal.fire("Error", err_message, "error");
          } else {
            Swal.fire({
              title: "Saving",
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 1000,
              onOpen: () => {
                Swal.showLoading();
              },
            }).then(async (result) => {
              const tempData = JSON.parse(JSON.stringify(saveConfig));

              tempData["lines"] = lines;
              tempData["dataImage"] = stageRef.current.toDataURL();

              await axios({
                method: "post",
                url:
                  Configs.API_URL_hrMagenatement +
                  "/api/signConfig/saveSignConfig",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: tempData,
              })
                .then(function (response) {
                  Swal.fire({
                    icon: "success",
                    title: "บันทึกสำเร็จ",
                    showConfirmButton: false,
                    timer: 1500,
                  }).then(() => {
                    window.location.reload();
                  });
                })
                .catch(async function (error) {
                  console.log(error);
                  Swal.fire({
                    icon: "warning",
                    // title: "กรุณากรอกข้อมูลใหม่",
                    html: error.response.data.error.message,
                  });
                });
            });
          }
        }
      });
    } else {
      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then(function (response) {
        Swal.fire({
          icon: "error",
          title: "โปรดกรอกลายเซ็น",
          showConfirmButton: false,
          timer: 1500,
        });
      });
    }
  }

  // useEffect(() => {
  //   if (divRef.current?.offsetHeight && divRef.current?.offsetWidth) {
  //     setDimensions({
  //       width: divRef.current.offsetWidth,
  //       height: divRef.current.offsetHeight
  //     })
  //   }
  // }, [])

  useEffect(() => {
    getSignConfig();
  }, []);

  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Master Sign</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Accounting</li>
                  <li className="breadcrumb-item active">Master Sign</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <div className="container-fluid">
          <div className="row mb-2">
            {checkEdit ? (
              <>
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-success"
                    disabled={!saveConfig.accept}
                    onClick={() => {
                      saveSignConfig();
                    }}
                  >
                    บันทึก
                  </button>
                </div>

                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={() => {
                      cancelOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </>
            ) : (
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      setCheckEdit(true);
                    }}
                  >
                    แก้ไข
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        <section className="content">
          <div className="card">
            {/* <div className="card-header">
              <h1 className="card-title"></h1>
            </div> */}

            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <span style={{ fontSize: "20px", fontWeight: "400" }}>
                    กรุณาลงลายมือชื่อในช่องด้านล่าง
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "400",
                      color: "#FF0024",
                    }}
                  >
                    หมายเหตุ :
                    เพื่อนำไปใช้ประกอบการออกเอกสารทำเบิกจ่ายในทางบัญชีของบริษัท
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  {checkEdit ? (
                    <>
                      <button
                        className={`btn sign_btn ${
                          tool == "pen" ? "sign_btn_click" : ""
                        }`}
                        style={{ margin: "10px 5px 5px 20px" }}
                        disabled={!checkEdit}
                        onClick={() => {
                          setTool("pen");
                        }}
                      >
                        <i class="fas fa-pencil-alt"></i>
                      </button>

                      <button
                        className={`btn sign_btn ${
                          tool == "eraser" ? "sign_btn_click" : ""
                        }`}
                        style={{ margin: "10px 5px 5px 5px" }}
                        disabled={!checkEdit}
                        onClick={() => {
                          setTool("eraser");
                        }}
                      >
                        <i class="fas fa-eraser"></i>
                      </button>

                      <button
                        className={`btn sign_btn`}
                        style={{ margin: "10px 5px 5px 5px" }}
                        disabled={!checkEdit}
                        onClick={() => {
                          setLines([]);
                        }}
                      >
                        Clear
                      </button>
                    </>
                  ) : (
                    <></>
                  )}

                  <div
                    // ref={divRef}
                    style={{
                      // maxWidth: "485px",
                      // maxHeight: "285px",
                      // width: "485px",
                      // height: "285px",
                      // border: "solid 2px black",
                      // borderRadius: "10px",
                      margin: "10px 20px 20px 20px",
                      touchAction: "none", // Add this to disable touch scrolling
                    }}
                    onTouchStart={(e) => e.preventDefault()} // Prevent default touch scroll behavior
                    onTouchMove={(e) => e.preventDefault()}
                    onTouchEnd={(e) => e.preventDefault()}
                  >
                    {checkEdit ? (
                      <Stage
                        // width={divRef.current.offsetWidth}
                        // height={divRef.current.offsetHeight}
                        className="sign_canvas"
                        ref={stageRef}
                        width={485}
                        height={285}
                        onMouseDown={handleMouseDown}
                        onMousemove={handleMouseMove}
                        onMouseup={handleMouseUp}
                        onTouchStart={handleMouseDown}
                        onTouchMove={handleMouseMove}
                        onTouchEnd={handleMouseUp}
                      >
                        <Layer>
                          {lines.map((line, i) => (
                            <Line
                              key={i}
                              points={line.points}
                              stroke="#1a237e"
                              strokeWidth={line.tool === "eraser" ? 5 : 6}
                              tension={0.5}
                              lineCap="round"
                              lineJoin="round"
                              globalCompositeOperation={
                                line.tool === "eraser"
                                  ? "destination-out"
                                  : "source-over"
                              }
                            />
                          ))}
                        </Layer>
                      </Stage>
                    ) : (
                      <Stage
                        // width={divRef.current.offsetWidth}
                        // height={divRef.current.offsetHeight}
                        className="disable_sign_canvas"
                        width={485}
                        height={285}
                      >
                        <Layer>
                          {lines.map((line, i) => (
                            <Line
                              key={i}
                              points={line.points}
                              stroke="#1a237e"
                              strokeWidth={line.tool === "eraser" ? 5 : 6}
                              tension={0.5}
                              lineCap="round"
                              lineJoin="round"
                              globalCompositeOperation={
                                line.tool === "eraser"
                                  ? "destination-out"
                                  : "source-over"
                              }
                            />
                          ))}
                        </Layer>
                      </Stage>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <span style={{ fontSize: "20px", fontWeight: "400" }}>
                    กรุณาเลือกการยอมรับ
                  </span>
                </div>

                <div className="col-12">
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "400",
                      color: "#FF0024",
                    }}
                  >
                    โดยการกดปุ่ม “ยอมรับ” ด้านล่างนี้ ข้าพเจ้ายินยอมให้
                    ทำการเก็บรวบรวม ใช้งาน และประมวลผลข้อมูลส่วนตัวของข้าพเจ้า
                    เพื่อวัตถุประสงค์ ในการปรับปรุงประสบการณ์ผู้ใช้งาน
                    และการให้บริการ
                  </span>
                </div>

                <div
                  className="custom-control custom-control-alternative custom-checkbox"
                  style={{ margin: "0px 5px" }}
                >
                  <input
                    id={`checkbox_item`}
                    className="form-check-input"
                    type="checkbox"
                    style={{ marginTop: ".45rem" }}
                    disabled={!checkEdit}
                    // value={}
                    checked={saveConfig.accept}
                    onChange={(event) => {
                      setSaveConfig({
                        ...saveConfig,
                        accept: !saveConfig.accept,
                      });
                    }}
                  />
                  <label className="form-check-label" htmlFor="checkbox_item">
                    ยอมรับเงื่อนไขและนโยบายความเป็นส่วนตัว
                  </label>
                </div>
              </div>
            </div>

            {/* <div className="card-footer"></div> */}
          </div>
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Sign;
