import React, { Component, useEffect, useState, useRef } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import Configs from "../../../../config";
import { useLocation, useParams } from "react-router";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import NumberFormat from "react-number-format";
import "../../../../assets/css/product_incentive.css";
import {
    getOem,
    getToken,
    getUser,
    getAcademy,
} from "../../../../Utils/Common";

import "../../../../assets/css/ProductService.css";

import { LightningBolt, Arrow } from "../Incentive_User_icon_img/All_Icon";
import Select from "react-select";
import PointCartLine from "../Incentive_User_component/Point_Cart_Line";
import CategoryCard from "../Incentive_User_component/Category_Card";
import ProductServiceCard from "../Incentive_User_component/Product_Service_Card";
import ProductServiceCardImageSlider from "../Incentive_User_component/Product_Service_Card_Image_Slider";
import ProductServiceCardMultiSlider from "../Incentive_User_component/Product_Service_Card_Multi_Slider";

import CustomerService from "../Incentive_User_component/CustomerService";
import { useHistory } from 'react-router-dom';

function Pay_product_detail_buyer() {
    const location = useLocation();
    const history = useHistory();
    const dataReceived = location.state;
    const handleClick = () => {
        const arr = address_emp.data.find(el => el.emp_checkbox === true);
        const arrLis = []
        if (arr)
            arrLis.push(arr);
        const dataToSend = {
            product: dataReceived.product,
            emp_address: arrLis
        };
        history.push({
            pathname: '/Human_Resource/incentive_user/product_service/confirm_product',
            state: dataToSend
        });
    };

    useEffect(async () => {
        console.log('dataReceived', dataReceived);
    }, []);

    const [address_emp, setAddress_emp] = useState({
        data: [],
    });

    const [addAddress_emp, setAddAddress_emp] = useState({
        emp_name: '',
        emp_lastname: '',
        emp_mail: '',
        emp_phone: '',
        emp_address: '',
        default_status: false,
        emp_checkbox: false,

    });

    const [modeAndIndexModal, setModeAndIndexModal] = useState({
        mode: null,
        index: null,
    });

    useEffect(async () => {
        await getDetailsAddressOfEmp();

        const temp = {
            emp_name: 'เดชบดินทร์',
            emp_lastname: 'ลำพาย',
            emp_mail: '',
            emp_phone: '0829299524',
            emp_address: '80/2 หมู่ 8 ถนน ลำลูกกา ตำบล ลาดสวาย อำเภอ ลำลูกกา',
            default_status: true
        }
        /* const arr = [];
        arr.push(temp);
        setAddress_emp({ data: arr });
        address_emp.data = arr; */
    }, []);

    const closeModal = useRef();

    const onClickAddAddress = () => {
        closeModal.current.click();
    }


    const checkSwalErrorAdd = () => {
        var error_list = [];
        if (addAddress_emp.emp_name == "") {
            let temp_err = {
                message: "กรุณากรอก ช่องชื่อ",
            };
            error_list.push(temp_err);
        }

        if (addAddress_emp.emp_lastname == "") {
            let temp_err = {
                message: "กรุณากรอก ช่องนามสกุล",
            };
            error_list.push(temp_err);
        }

        if (addAddress_emp.emp_address == "") {
            let temp_err = {
                message: "กรุณากรอก ช่องที่อยู่",
            };
            error_list.push(temp_err);
        }

        let phoneLength = addAddress_emp.emp_phone
            ?.replace(/-/g, "")
            ?.trim().length;

        if (addAddress_emp.emp_phone == "") {
            let temp_err = {
                message: "กรุณากรอก ช่องเบอร์โทรศัพท์",
            };
            error_list.push(temp_err);
        } else if (addAddress_emp.emp_phone != "") {
            if (phoneLength < 10) {
                let temp_err = {
                    message: "ช่องเบอร์โทรศัพท์ ต้องมี 10 ตัวเลข",
                };
                error_list.push(temp_err);
            }
        }

        return error_list;

    }

    const Add_AddressEmp = () => {
        const errorList = checkSwalErrorAdd();
        if (errorList.length > 0) {
            var err_message = "";
            for (var e = 0; e < errorList.length; e++) {
                err_message += "<br/>" + errorList[e].message;
            }
            Swal.fire("Error", err_message, "error");
            return;
        }

        const temp = {
            emp_name: addAddress_emp.emp_name,
            emp_lastname: addAddress_emp.emp_lastname,
            emp_mail: addAddress_emp.emp_mail || '',
            emp_phone: addAddress_emp.emp_phone,
            emp_address: addAddress_emp.emp_address,
            default_status: addAddress_emp.default_status,
            emp_checkbox: addAddress_emp.emp_checkbox
        }
        const arr = address_emp.data;
        setAddress_emp({ data: [] });
        if (modeAndIndexModal.mode && modeAndIndexModal.index) {
            const indexEdit = modeAndIndexModal.index;
            // console.log('arr[indexEdit]', arr[indexEdit])
            arr[indexEdit].emp_name = temp.emp_name;
            arr[indexEdit].emp_lastname = temp.emp_lastname;
            arr[indexEdit].emp_mail = temp.emp_mail;
            arr[indexEdit].emp_phone = temp.emp_phone;
            arr[indexEdit].emp_address = temp.emp_address;
            arr[indexEdit].default_status = temp.default_status;
            arr[indexEdit].emp_checkbox = temp.emp_checkbox;
        } else {
            arr.push(temp);
        }
        setAddress_emp({ data: arr });
        address_emp.data = arr;


        saveAndUpdate(modeAndIndexModal.mode === null ? [temp] : [arr[modeAndIndexModal.index]]);
        // if (result.isConfirmed) {
        // }
        setModeAndIndexModal({
            mode: null,
            index: null,
        })
        modeAndIndexModal.mode = null;
        modeAndIndexModal.index = null;
        onClickAddAddress();
    }

    const changeDefault_Status = (idx) => {
        const arr = address_emp.data;
        setAddress_emp({ data: [] });

        for (let item of arr) {
            item.default_status = false;
        }
        arr[idx].default_status = true;
        if (idx !== 1 && idx !== 0) {
            const temp = arr[idx];
            arr[idx] = arr[1]
            arr[1] = temp;
        }
        /* arr.sort((a, b) => b.default_status - a.default_status);
        console.log('arr', arr); */
        /* arr.sort((a, b) => b.is_active - a.is_active);
        arr.unshift(temp); */
        setAddress_emp({ data: arr });
        address_emp.data = arr;

        saveAndUpdate(address_emp.data);
        // }


    }

    const saveAndUpdate = (addressess) => {
        var employees = {
            oem_id: getOem(),
            company_id: getUser().com,
            fup: getUser().emp_id,
            employess: addressess,
        }
        axios({
            method: "post",
            url: Configs.API_URL_incentive + "/api/detailsAddressEmployee/saveAddressEmployee",
            headers: {
                Authorization: 'Bearer ' + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: employees,
        })
            .then(function (response) {
                console.log(response.data.employee_address_list, "ds");
                getDetailsAddressOfEmp();
                // setselect_category(response.data.category_list);
                // getDetailsAddressOfEmp();
                // handleClick();
                // window.location.href = "/Human_Resource/incentive_user/product_service/confirm_product"
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire({
                    icon: "warning",
                    title: "เกิดข้อผิดพลาด",
                    text: error.response.data.error.errorDis,
                });
            });
    }

    const handleCheckChange = (idx) => {
        const arr = address_emp.data;
        setAddress_emp({ data: [] });

        for (let item of arr) {
            item.emp_checkbox = false;
        }
        arr[idx].emp_checkbox = true;
        setAddress_emp({ data: arr });
        address_emp.data = arr;
    }

    const deleteEmpAddress = (idx) => {

        Swal.fire({
            title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
            text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อลบข้อมูล",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ตกลง",
            cancelButtonText: `ไม่`,
        }).then((result) => {
            if (result.isConfirmed) {
                console.log("test delete");
                if (address_emp.data[idx].ea_id) {
                    axios({
                        method: "get",
                        url:
                            Configs.API_URL_incentive +
                            "/api/detailsAddressEmployee/deleteEmpAddress/" + address_emp.data[idx].ea_id,
                        headers: {
                            Authorization: 'Bearer ' + getToken(),
                            "Content-Type": "application/json",
                            "X-TTT": Configs.API_TTT,
                        },
                    })
                        .then(function (response) {
                            console.log(response);
                            Swal.fire({
                                icon: "success",
                                text: "เสร็จสิ้น",
                                showConfirmButton: false,
                                timer: 1500
                            }).then(() => {
                                // window.location.reload(); //.href = "/Human_Resource/intensive_TTT/manage_product_system";
                                const arr = address_emp.data;
                                setAddress_emp({ data: [] });
                                if (arr[idx].emp_checkbox === true) {
                                    arr.forEach(el => {
                                        if (el.default_status === true) {
                                            el.emp_checkbox = true;
                                        }
                                    });
                                }
                                const temp = arr.filter((el, index) => index !== idx);
                                setAddress_emp({ data: temp });
                                address_emp.data = temp;
                            });
                        })
                        .catch(function (error) {
                            console.log(error);
                            Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
                        });
                } else {
                    const arr = address_emp.data;
                    setAddress_emp({ data: [] });
                    const temp = arr.filter((el, index) => index !== idx);
                    setAddress_emp({ data: temp });
                    address_emp.data = temp;
                }
            }
        });
    }

    const getDetailsAddressOfEmp = async () => {
        var get_category = {
            oem_id: getOem(),
            company_id: getUser().com,
            fup: getUser().emp_id,
        }
        await axios({
            method: "post",
            url: Configs.API_URL_incentive + "/api/detailsAddressEmployee/getAddressByFup",
            headers: {
                Authorization: 'Bearer ' + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: get_category,
        })
            .then(function (response) {
                console.log(response.data.employee_address_list, "ds");
                // setselect_category(response.data.category_list);
                const arr = [];
                if (response.data.employee_address_list.length > 0) {
                    for (let item of response.data.employee_address_list) {
                        arr.push(item);
                    }
                }
                // const temp = arr.splice(0, 1);
                arr.sort((a, b) => b.default_status - a.default_status);
                /* console.log('arr', arr); */
                // arr.unshift(temp);
                setAddress_emp({ data: arr });
                address_emp.data = arr;
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire({
                    icon: "warning",
                    title: "เกิดข้อผิดพลาด",
                    text: error.response.data.error.errorDis,
                });
            });
    }

    function checkSwalErrorSave() {
        var error_list = [];
        if (addAddress_emp.length == 0) {
            let temp_err = {
                message: "กรุณาเพิ่มที่อยู่การจัดส่ง",
            };
            error_list.push(temp_err);
        }

        return error_list;
    }

    const saveEmpAddress = () => {
        const errorList = checkSwalErrorSave();
        if (errorList.length > 0) {
            var err_message = "";
            for (var e = 0; e < errorList.length; e++) {
                err_message += "<br/>" + errorList[e].message;
            }
            Swal.fire("Error", err_message, "error");
            return;
        } else {
            Swal.fire({
                title: "ยืนยันการเลือกที่อยู่จัดส่งนี้ ใช่หรือไม่",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: `ใช่`,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: `ไม่`,
            }).then((result) => {
                if (result.isConfirmed) {
                    var employees = {
                        oem_id: getOem(),
                        company_id: getUser().com,
                        fup: getUser().emp_id,
                        employess: address_emp.data
                    }
                    axios({
                        method: "post",
                        url: Configs.API_URL_incentive + "/api/detailsAddressEmployee/saveAddressEmployee",
                        headers: {
                            Authorization: 'Bearer ' + getToken(),
                            "X-TTT": Configs.API_TTT,
                            "Content-Type": "application/json",
                        },
                        data: employees,
                    })
                        .then(function (response) {
                            console.log(response.data.employee_address_list, "ds");
                            // setselect_category(response.data.category_list);
                            getDetailsAddressOfEmp();
                            handleClick();
                            // window.location.href = "/Human_Resource/incentive_user/product_service/confirm_product"
                        })
                        .catch(function (error) {
                            console.log(error);
                            Swal.fire({
                                icon: "warning",
                                title: "เกิดข้อผิดพลาด",
                                text: error.response.data.error.errorDis,
                            });
                        });
                }
            })
        }
    }

    return (
        <div className="wrapper">
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h1>สั่งซื้อสินค้า</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="/Welcome">Home</a>
                                    </li>

                                    <li className="breadcrumb-item active">Human Resource</li>
                                    <li className="breadcrumb-item active">แลกสินค้า</li>
                                    <li className="breadcrumb-item active">สั่งซื้อสินค้า</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="row">
                        <PointCartLine />
                    </div>

                    <div className='row'>
                        <div className='' style={{ backgroundColor: '#e4f4ff', height: 'auto', padding: '1em', margin: '1em', width: '100%', borderRadius: '20px' }}>
                            <h4 style={{ margin: '0', fontWeight: '800' }}>รายละเอียดการจัดส่ง</h4>
                        </div>
                    </div>
                    {
                        address_emp.data.map((el, index) => {
                            return <>
                                <div className='row' key={'address' + index}>
                                    <div className='' key={'address-col' + index} style={{ backgroundColor: el.citizen_id ? '#c6c6c6' : el.default_status ? "#a9dcff" : '#e4f4ff', height: 'auto', padding: '1em', margin: '0 1em', width: '100%', borderRadius: '20px', marginBottom: '1em' }}>
                                        <div className='row'>
                                            <div className='col-1 checkbox-center' style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div style={{ /* display: 'flex', alignSelf: 'center'marginTop: '0.2rem' */ }}>
                                                    <input
                                                        style={{ width: '20px', height: '20px' }}
                                                        className="larger dis-checkbox"
                                                        type="checkbox"
                                                        // id="select_all"
                                                        checked={el.emp_checkbox}
                                                        disabled={el.emp_checkbox ? "disabled" : ""}
                                                        onClick={() => {
                                                            handleCheckChange(index);
                                                        }}
                                                    /* onChange={(e) => {
                                                        // handleCheckChange(e);
                                                    }} */
                                                    ></input>
                                                </div>
                                            </div>
                                            <div className='col-11 col-sm-7 col-xl-7 col-md-7'>
                                                <div className='row'>
                                                    <div className='col-6'><h5><b>ชื่อ : </b>{el.emp_name}</h5></div>
                                                    <div className='col-6'><h5><b>นามสกุล : </b>{el.emp_lastname}</h5></div>
                                                    <div className='col-12'><h5><b>เบอร์โทร : </b>{el.emp_phone}</h5></div>

                                                    <div className='col-12'><h5><b>ที่อยู่ : </b>{el.emp_address}</h5></div>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-4 col-xl-4 col-md-4'>
                                                <div className='col-12 btn-default-row' /* style={{ display: 'flex', columnGap: '.3em', borderRadius: '20px', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }} */>
                                                    <button type="button"
                                                        className={"btn-hov " + (el.default_status ? "btn btn-dark" : "btn btn-outline-dark")}
                                                        disabled={el.default_status}
                                                        style={{ display: 'flex', height: '1.5em', alignItems: 'center', borderRadius: '15px', padding: '0 1.2em', fontSize: '1rem', whiteSpace: "pre", backgroundColor: el.default_status ? 'black' : 'white', color: el.default_status ? 'white' : 'black' }}
                                                        onClick={() => {
                                                            changeDefault_Status(index);
                                                        }}
                                                    >
                                                        ค่าเริ่มต้น
                                                    </button>
                                                    {el.citizen_id ? "" :
                                                        <>
                                                            <div style={{ backgroundColor: "#000000a3", width: '1px', height: '100%', minWidth: "1px", minHeight: "2rem" }}></div>
                                                            <button type="button" className="btn btn-outline-dark btn-hov" style={{ display: 'flex', height: '1.5em', alignItems: 'center', borderRadius: '15px', padding: '0 1.2em', fontSize: '1rem', whiteSpace: "pre", backgroundColor: 'white' }}
                                                                onClick={() => {
                                                                    setAddAddress_emp({
                                                                        emp_name: el.emp_name,
                                                                        emp_lastname: el.emp_lastname,
                                                                        emp_mail: el.emp_mail || '',
                                                                        emp_phone: el.emp_phone,
                                                                        emp_address: el.emp_address,
                                                                        default_status: el.default_status,
                                                                        emp_checkbox: el.emp_checkbox
                                                                    });
                                                                    addAddress_emp.emp_name = el.emp_name;
                                                                    addAddress_emp.emp_lastname = el.emp_lastname;
                                                                    addAddress_emp.emp_mail = el.emp_mail || '';
                                                                    addAddress_emp.emp_phone = el.emp_phone;
                                                                    addAddress_emp.emp_address = el.emp_address;
                                                                    addAddress_emp.default_status = el.default_status;
                                                                    addAddress_emp.emp_checkbox = el.emp_checkbox;
                                                                    setModeAndIndexModal({
                                                                        mode: 'edit',
                                                                        index: index,
                                                                    })
                                                                    modeAndIndexModal.mode = 'edit';
                                                                    modeAndIndexModal.index = index;
                                                                }}
                                                                data-toggle="modal"
                                                                data-target="#modal-add-supplier"
                                                                data-backdrop="static"
                                                                data-keyboard="false"
                                                            >
                                                                แก้ไข
                                                            </button>
                                                            <div style={{ backgroundColor: "#000000a3", width: '1px', height: '100%', minWidth: "1px", minHeight: "2rem" }}></div>
                                                            <div className=""
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    fontSize: ".8em",
                                                                    color: "#34347a",
                                                                    // width: "100%",
                                                                    height: "100%"
                                                                }}>

                                                                <>
                                                                    <a
                                                                        /* key={product_data[index].product_id}
                                                                        */
                                                                        style={{ width: '31.7px', height: '29.2px' }}
                                                                        id={"btn_delete" + (index + 1)}
                                                                        className=" btn btn-xs "
                                                                        onClick={() => {
                                                                            deleteEmpAddress(index);
                                                                        }}
                                                                    >
                                                                        {el.default_status === true ? "" :
                                                                            <i class="fas fa-trash-alt"
                                                                                style={{
                                                                                    border: "1px solid #7c7c7c",
                                                                                    padding: "5px",
                                                                                    background: "white",
                                                                                    borderRadius: "6px"
                                                                                }}
                                                                            ></i>
                                                                        }
                                                                    </a>
                                                                </>



                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {/* <h4 style={{ margin: '0', fontWeight: '800' }}>รายละเอียดการจัดส่ง</h4> */}
                                    </div>
                                </div>
                            </>
                        })
                    }
                    {/* {address_emp.data.length === 0 ? 'ไม่มีข้อมูลที่อยู่ กรุณาเพิ่มข้อมูล' :
                        
                    } */}

                    <div className='row' style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='col-5 col-xl-2 col-md-3' style={{ height: 'auto', padding: '1em', margin: '0', width: 'auto', borderRadius: '20px' }}>
                            <button
                                type="button"
                                // onClick={saveOption}
                                style={{ backgroundColor: '#001f50', borderRadius: '15px', padding: '.5em 1.5em', border: 'none' }}
                                className="btn btn-block btn-primary"
                                id="btn-add"
                                data-toggle="modal"
                                data-target="#modal-add-supplier"
                                data-backdrop="static"
                                data-keyboard="false"
                                // disabled={disable}
                                onClick={() => {
                                    setAddAddress_emp({
                                        emp_name: '',
                                        emp_lastname: '',
                                        emp_mail: '',
                                        emp_phone: '',
                                        emp_address: '',
                                        default_status: false,
                                        emp_checkbox: false
                                    });
                                    addAddress_emp.emp_name = '';
                                    addAddress_emp.emp_lastname = '';
                                    addAddress_emp.emp_mail = '';
                                    addAddress_emp.emp_phone = '';
                                    addAddress_emp.emp_address = '';
                                    addAddress_emp.default_status = false;
                                    addAddress_emp.emp_checkbox = false;

                                    setModeAndIndexModal({ mode: null, index: null })
                                    modeAndIndexModal.mode = null;
                                    modeAndIndexModal.index = null;
                                }}
                            >
                                เพิ่มที่อยู่ใหม่
                            </button>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='' style={{ height: 'auto', padding: '1em', margin: '0', width: 'auto', borderRadius: '20px' }}>
                            <h5 style={{ fontWeight: '800' }}>หมายเหตุ : </h5>
                            <ul>
                                <li><h5>กรุณาตรวจสอบรายละเอียดการจัดส่งให้ถูกต้อง เนื่องจากผู้ใช้จะไม่สามารถแก้ไขรายละเอียดการจัดส่งได้ หลังจากที่ผู้ใช้ได้ยืนยันการสั่งซื้อสินค้าแล้ว</h5></li>
                                <li><h5>หลังการยืนยันการสั่งซื้อ ระบบจะทำการตรวจสอบสินค้าว่ามีหรือไม่ และทางระบบจะแจ้งไปยังอีเมลผู้ที่ใช้งานได้ระบุไว้ข้างต้น หากไม่มีสินค้า ระบบจะทำการคืนคะแนนให้แก่ผู้ใช้งานอัตโนมัติ</h5></li>
                                <li><h5>สินค้าอาจมีการขนส่งล่าช้า ผู้ใช้งานสามารถตรวจสอบ เลขพัสดุ และสถานะของสินค้า ได้ในเมนูสถานะสินค้า โดยบริษัทฯ ขอสงวนลิขสิทธิ์ไม่รับผิดชอบต่อความล่าช้า และสูญหายทุกกรณี ต้องขออภัยมา ณ ที่นี้</h5></li>
                            </ul>
                        </div>
                    </div>

                    <div className='row' style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='col-5 col-xl-2 col-md-3' style={{ height: 'auto', padding: '1em', margin: '0', width: 'auto', borderRadius: '20px' }}>
                            <button
                                type="button"
                                // onClick={saveOption}
                                style={{ color: '#001f50', border: '1px solid #001f50', borderRadius: '15px', padding: '.5em 1.5em' }}
                                className="btn btn-block btn-outline-primary btn-hov"
                                id="btn-add"
                                onClick={() => {
                                    const temp = dataReceived.product.find(el => el.p_buynow === true);
                                    if (temp) {
                                        axios({
                                            method: "get",
                                            url:
                                                Configs.API_URL_incentive +
                                                "/api/detailsAddressEmployee/deleteProductInCart/" + temp.sc_id,
                                            headers: {
                                                Authorization: 'Bearer ' + getToken(),
                                                "Content-Type": "application/json",
                                                "X-TTT": Configs.API_TTT,
                                            },
                                        })
                                            .then(function (response) {
                                                console.log(response);
                                                window.location.href = "/Human_Resource/incentive_user/product_service/product_view_details/" + temp.sc_product_id;

                                            })
                                            .catch(function (error) {
                                                console.log(error);
                                                Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
                                                window.location.href = "/Human_Resource/incentive_user/product_service/product_view_details/" + temp.sc_product_id;
                                            });


                                    } else {
                                        history.push({
                                            pathname: '/Human_Resource/incentive_user/product_service/cart_product',
                                            state: dataReceived
                                        });
                                    }
                                }}
                            // disabled={disable}
                            >
                                ย้อนกลับ
                            </button>
                        </div>
                        <div className='col-5 col-xl-2 col-md-3' style={{ height: 'auto', padding: '1em', margin: '0', width: 'auto', borderRadius: '20px' }}>
                            <button
                                type="button"
                                // onClick={saveOption}
                                style={{ backgroundColor: '#001f50', borderRadius: '15px', padding: '.5em 1.5em', border: 'none', whiteSpace: 'nowrap' }}
                                className="btn btn-block btn-primary"
                                id="btn-add"
                                /*  data-toggle="modal"
                                 data-target="#modal-add-supplier"
                                 data-backdrop="static"
                                 data-keyboard="false" */
                                // disabled={disable}
                                onClick={() => {
                                    console.log('log');
                                    saveEmpAddress();
                                    /* const arr = address_emp.data;
                                    setAddress_emp({ data: [] });
                                    const temp = {
                                        emp_name: 'เดชบดินทร์1',
                                        emp_lastname: 'ลำพาย1',
                                        emp_mail: '',
                                        emp_phone: '0829299524',
                                        emp_address: '80/2 หมู่ 8 ถนน ลำลูกกา ตำบล ลาดสวาย อำเภอ ลำลูกกา',
                                        default_status: false
                                    }
                                    arr.push(temp);
                                    setAddress_emp({ data: arr });
                                    address_emp.data = arr; */
                                }}
                            >
                                ยืนยันที่อยู่จัดส่ง
                            </button>
                        </div>
                    </div>

                </section>


                {/* ///////////////////////////// MODAL ////////////////////////////////// */}
                <div className="modal fade" id="modal-add-supplier">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-label">รายละเอียด เพิ่มที่อยู่การจัดส่ง</h4>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    ref={closeModal}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">

                                    <div className="col-12 col-md-6 col-xl-3">

                                        <div className="form-group">
                                            <input
                                                allowNegative={false}
                                                type="text"
                                                className="form-control"
                                                /* disabled={disable}
                                            */
                                                value={addAddress_emp.emp_name}
                                                onChange={(e) =>
                                                    setAddAddress_emp({ ...addAddress_emp, emp_name: e.target.value })
                                                }
                                                required
                                            />

                                            <label htmlFor="">ชื่อ :
                                                {addAddress_emp.emp_name === "" ? (
                                                    <span style={{ color: "red" }}> *</span>
                                                ) : (
                                                    ""
                                                )}
                                            </label>
                                        </div>

                                    </div>
                                    <div className="col-12 col-md-6 col-xl-3">
                                        <div className="form-group">
                                            <input
                                                allowNegative={false}
                                                type="text"
                                                className="form-control"
                                                /* disabled={disable}
                                                */
                                                value={addAddress_emp.emp_lastname}
                                                onChange={(e) =>
                                                    setAddAddress_emp({ ...addAddress_emp, emp_lastname: e.target.value })
                                                }
                                                required
                                            />

                                            <label htmlFor="">นามสกุล :
                                                {addAddress_emp.emp_lastname === "" ? (
                                                    <span style={{ color: "red" }}> *</span>
                                                ) : (
                                                    ""
                                                )}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-6 col-xl-3">
                                        <div className="form-group ">
                                            <NumberFormat
                                                type="text"
                                                format={"###-###-####"}
                                                className="form-control"
                                                required
                                                placeholder=""
                                                /*
                                                disabled={disable}
                                                */
                                                value={addAddress_emp.emp_phone}
                                                onChange={(e) => {
                                                    setAddAddress_emp({
                                                        ...addAddress_emp,
                                                        emp_phone: e.target.value,
                                                    });
                                                }}
                                            />
                                            <label htmlFor="">
                                                เบอร์โทรศัพท์ :
                                                {addAddress_emp.emp_phone === "" ? (
                                                    <span style={{ color: "red" }}> *</span>
                                                ) : (
                                                    ""
                                                )}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-4">
                                        <div className="form-group">
                                            <input
                                                allowNegative={false}
                                                type="text"
                                                className="form-control"
                                                /* disabled={disable}
                                                */
                                                value={addAddress_emp.emp_mail}
                                                onChange={(e) =>
                                                    setAddAddress_emp({ ...addAddress_emp, emp_mail: e.target.value })
                                                }
                                                required
                                            />

                                            <label htmlFor="">อีเมล :</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-7 col-xl-8">
                                        <div className="form-group">
                                            {" "}
                                            <textarea
                                                className="form-control"
                                                rows={2}
                                                style={{
                                                    height: "100px",
                                                    fontSize: "25px",
                                                }}
                                                // placeholder="enter"
                                                /* disabled={disable}
                                                */
                                                value={
                                                    addAddress_emp.emp_address
                                                }
                                                onChange={(e) =>
                                                    setAddAddress_emp({
                                                        ...addAddress_emp,
                                                        emp_address: e.target.value,
                                                    })
                                                }
                                                defaultValue={""}
                                                required
                                            />
                                            <label>
                                                ที่อยู่ :
                                                {addAddress_emp.emp_address ===
                                                    "" ? (
                                                    <span style={{ color: "red" }}>
                                                        {" "}
                                                        *
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer justify-content-between">
                                <button
                                    type="button"
                                    /* disabled={(supplier_select.id === "" || supplier_select.buy_price === "") || disable ? true : false}
                                    hidden={disable}
                                    */
                                    style={{ backgroundColor: '#001f50', border: 'none' }}
                                    onClick={Add_AddressEmp}
                                    // data-dismiss="modal"
                                    className="btn btn-primary"
                                >
                                    {modeAndIndexModal.mode === "edit" ? "Update" : "Add"}
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-default"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>


                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default Pay_product_detail_buyer;