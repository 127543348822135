import React, { Component, useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import Configs from "../../../../config";
import { useParams } from "react-router";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import NumberFormat from "react-number-format";
import moment from "moment";

import {
  getOem,
  getToken,
  getUser,
  getAcademy,
} from "../../../../Utils/Common";

import "../../../../assets/css/ProductService.css";

import { LightningBolt, Arrow } from "../Incentive_User_icon_img/All_Icon";

import PointCartLine from "../Incentive_User_component/Point_Cart_Line";
import CategoryCard from "../Incentive_User_component/Category_Card";
import ProductServiceCard from "../Incentive_User_component/Product_Service_Card";
import ProductServiceCardImageSlider from "../Incentive_User_component/Product_Service_Card_Image_Slider";
import ProductServiceCardImageSlider2 from "../Incentive_User_component/Product_Service_Card_Image_Slider2";
import ProductServiceCardMultiSlider from "../Incentive_User_component/Product_Service_Card_Multi_Slider";

import ProductStatusCard from "../Incentive_User_component/Product_Status_Card";

import Pagination2 from "../Incentive_User_component/Pagination2";
import Pagination3 from "../Incentive_User_component/Pagination3";

import CustomerService from "../Incentive_User_component/CustomerService";

function Tracking_Status() {
  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [totalOrderData, setTotalOrderData] = useState(0);

  const [orderData, setOrderData] = useState([]);

  const [searchOption, setSearchOption] = useState({
    statusId: "",
    startDate: "",
    endDate: "",
  });

  const [statusOrderData, setStatusOrderData] = useState([]);

  const [limitData, setLimitData] = useState(10);

  async function GetStatusOrder() {
    axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/incentivePo/getStatusOrder",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        // console.log(response.data);
        setStatusOrderData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function FilterOrderList() {
    let startDataIndex = currentPage * limitData - limitData;
    let endDataIndex = limitData;

    let dataFilterOrderList = {
      oem_id: getOem(),
      company_id: getUser().com,
      user_id: getUser().emp_id,
      statusId: searchOption.statusId,
      startDate: searchOption.startDate,
      endDate: searchOption.endDate,
      startDataIndex: startDataIndex,
      endDataIndex: endDataIndex,
    };

    axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/incentivePo/filterOrderList",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: dataFilterOrderList,
    })
      .then(async (response) => {
        // console.log(response.data);
        setOrderData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function NumberOrderList() {
    let dataNumberOrderList = {
      oem_id: getOem(),
      company_id: getUser().com,
      user_id: getUser().emp_id,
      statusId: searchOption.statusId,
      startDate: searchOption.startDate,
      endDate: searchOption.endDate,
    };

    axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/incentivePo/NumberOrderList",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: dataNumberOrderList,
    })
      .then(async (response) => {
        setTotalOrderData(response.data.length);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function clearSearchData() {
    setSearchOption({
      statusId: "",
      startDate: "",
      endDate: "",
    });

    searchOption.statusId = "";
    searchOption.startDate = "";
    searchOption.endDate = "";

    await setCurrentPage(1);
    await NumberOrderList();
    await FilterOrderList();
  }

  useEffect(async () => {
    await GetStatusOrder();
    // await NumberOrderList();
    // await FilterOrderList();
  }, []);

  useEffect(async () => {
    await NumberOrderList();
    await FilterOrderList();
  }, [currentPage, limitData]);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h1>สถานะสินค้า</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Incentive Point</li>
                  <li className="breadcrumb-item active">สถานะสินค้า</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="row">
            <PointCartLine />
          </div>

          <div className="row mt-2" style={{ alignItems: "flex-end" }}>
            <div className="col-6 col-md-6 col-xl-2">
              <div className="form-group">
                <select
                  className="form-control custom-select select2"
                  type="text"
                  required
                  id="drop_employee_type"
                  value={searchOption.statusId}
                  onChange={(e) => {
                    setSearchOption({
                      ...searchOption,
                      statusId: e.target.value,
                    });
                  }}
                >
                  <option value="" disabled selected>
                    ---- เลือกทั้งหมด ----
                  </option>
                  {statusOrderData.map((item) => {
                    return <option value={item.os_id}>{item.os_name}</option>;
                  })}
                </select>
                <label htmlFor="">สถานะสินค้า</label>{" "}
              </div>
            </div>

            <div className="col-6 col-md-6 col-xl-3">
              <div className="form-group">
                <DatePicker
                  wrapperClassName="w-100"
                  locale="th"
                  id="text_start_work"
                  dateFormat={"dd-MM-yyyy"}
                  value={
                    searchOption.endDate
                      ? `${
                          searchOption.startDate !== ""
                            ? moment(searchOption.startDate).format(
                                "DD/MM/YYYY"
                              )
                            : ""
                        } - ${
                          searchOption.endDate !== ""
                            ? moment(searchOption.endDate).format("DD/MM/YYYY")
                            : ""
                        }`
                      : `${
                          searchOption.startDate !== ""
                            ? moment(searchOption.startDate).format(
                                "DD/MM/YYYY"
                              )
                            : ""
                        }`
                  }
                  selected={searchOption.startDate}
                  onChange={async (update) => {
                    setSearchOption({
                      ...searchOption,
                      startDate: update[0] || "",
                      endDate: update[1] || "",
                    });
                  }}
                  shouldCloseOnSelect={
                    !(
                      !searchOption.startDate ||
                      Boolean(searchOption.startDate && searchOption.endDate)
                    )
                  }
                  startDate={searchOption.startDate}
                  endDate={searchOption.endDate}
                  selectsRange={true}
                  // isClearable={true}
                  showMonthDropdown
                  showYearDropdown
                  disabledKeyboardNavigation
                  yearDropdownItemNumber={15}
                  scrollableYearDropdown
                  // todayButton="Today"
                  customInput={<CustomInput />}
                  popperPlacement="bottom-end"
                />
                <label htmlFor="">วันที่สั่งซื้อสินค้า</label>{" "}
              </div>
            </div>

            <div className="col-6 col-md-6 col-xl-2">
              <div className="form-group">
                <button
                  type="button"
                  id="btn_search"
                  className="btn btn-block btn-primary"
                  onClick={async () => {
                    await setCurrentPage(1);
                    await NumberOrderList();
                    await FilterOrderList();
                  }}
                >
                  ค้นหา
                </button>
              </div>
            </div>

            <div className="col-6 col-md-6 col-xl-2">
              <div className="form-group">
                <button
                  type="button"
                  id="btn_clear"
                  className="btn btn-block btn-primary"
                  onClick={async () => {
                    await clearSearchData();
                  }}
                >
                  ล้างการค้นหา
                </button>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "0px 0px 20px 0px",
            }}
          >
            <div style={{ width: "100%", borderBottom: "solid 1px #E0E0E0" }} />
          </div>

          <div className="row mb-3" style={{ alignItems: "baseline" }}>
            <div className="col-auto">
              <label htmlFor="">แสดงรายการ</label>
            </div>
            <div className="col-3 col-xs-2 col-md-2 col-xl-1">
              {/* <div className="form-group"> */}
              <select
                className="form-control custom-select select2"
                type="text"
                required
                id="drop_employee_type"
                value={limitData}
                onChange={async (e) => {
                  await setLimitData(e.target.value);
                  await setCurrentPage(1);
                }}
              >
                <option value="10" selected>
                  10
                </option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
              {/* <label htmlFor="">จำนวน</label>{" "} */}
              {/* </div> */}
            </div>
          </div>

          <div style={{ height: "100%" }}>
            {orderData.map((item) => {
              return (
                <>
                  <div style={{ padding: "0px 10px 10px 10px" }}>
                    <ProductStatusCard
                      data={item}
                      FilterOrderList={FilterOrderList}
                    />
                  </div>
                </>
              );
            })}
          </div>

          <div className="pagination_show_1">
            <Pagination2
              currentPage={currentPage}
              total={totalOrderData}
              limit={limitData}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>

          <div className="pagination_show_2">
            <Pagination3
              currentPage={currentPage}
              total={totalOrderData}
              limit={limitData}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </section>
      </div>
    </div>
  );
}

export default Tracking_Status;
