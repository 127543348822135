import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import {
  DeleteOutlined,
  BarChartOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { Progress } from "antd";
import styles from "./FineTune.module.css";
import Configs from "../../config";

function Fine_Tuning() {
  // State Variables
  const [detectedImage, setDetectedImage] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState(""); // เก็บชื่อไฟล์ที่อัปโหลด
  const [bboxes, setBboxes] = useState([]); // เก็บหลาย bounding boxes
  const [currentBbox, setCurrentBbox] = useState(null); // เก็บ bounding box ที่กำลังวาด
  const [selectedBbox, setSelectedBbox] = useState(null); // เก็บ bounding box ที่เลือกเพื่อแก้ไข
  const [isDrawing, setIsDrawing] = useState(false); // ติดตามสถานะการวาด
  const [labelInput, setLabelInput] = useState(""); // ช่องใส่ label สำหรับ bounding box
  const [isLabeling, setIsLabeling] = useState(false); // ติดตามสถานะการ labeling
  const [labelPosition, setLabelPosition] = useState({ x: 0, y: 0 }); // ตำแหน่งของ input label
  const [availableClasses, setAvailableClasses] = useState([]); // รายการคลาสจาก API
  const [newClassInput, setNewClassInput] = useState(""); // ช่องใส่ข้อมูลสำหรับเพิ่มคลาสใหม่
  const [classToDelete, setClassToDelete] = useState("");
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [approveDisabled, setApproveDisabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // หน้าปัจจุบัน
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const ITEMS_PER_PAGE = 6; // จำนวนการ์ดต่อหน้า
  const totalPages = Math.ceil(bboxes.length / ITEMS_PER_PAGE);
  const inputRefs = useRef([]);
  const canvasRef = useRef(null);

  // Function to handle card click
  const handleCardClick = (index) => {
    setSelectedCardIndex(selectedCardIndex === index ? null : index);
  };

  // Paginate bounding boxes
  const paginatedBboxes = bboxes.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  // Function to calculate overall accuracy based on confidence
  const calculateOverallAccuracy = () => {
    if (bboxes.length === 0) return 0; // คืนค่า 0% ถ้าไม่มี bounding boxes

    // รวมค่า confidence และหารด้วยจำนวน bounding boxes
    const totalConfidence = bboxes.reduce(
      (acc, bbox) => acc + (bbox.confidence || 0),
      0
    );
    return Math.round((totalConfidence / bboxes.length) * 100); // คืนค่าเปอร์เซ็นต์
  };

  // Get the overall accuracy for the Circular Progress Bar
  const overallAccuracy = calculateOverallAccuracy();

  // Calculate accuracy data for each label
  const calculateAccuracyData = () => {
    const labelAccuracyMap = bboxes.reduce((acc, bbox) => {
      if (!acc[bbox.label]) {
        acc[bbox.label] = { totalConfidence: 0, count: 0 };
      }
      acc[bbox.label].totalConfidence += bbox.confidence || 0;
      acc[bbox.label].count += 1;
      return acc;
    }, {});

    return Object.entries(labelAccuracyMap).map(([label, data]) => ({
      label,
      accuracy: data.count > 0 ? (data.totalConfidence / data.count) * 100 : 0,
      count: data.count,
    }));
  };

  const accuracyData = calculateAccuracyData();

  // Polling function for progress
  const pollProgress = () => {
    const updateInterval = 500;
    const intervalId = setInterval(async () => {
      try {
        const response = await fetch(Configs.API_URL_AI_COUNTING_FINE_TUNE + '/progress/');
        const progressData = await response.json();

        setProgress(progressData.progress);

        if (progressData.progress >= 100) {
          clearInterval(intervalId);
          setShowProgress(false);
          setApproveDisabled(false);
          Swal.fire(
            "การปรับแต่งเสร็จสมบูรณ์!",
            "โมเดลได้รับการปรับแต่งแล้ว.",
            "success"
          );
        }
      } catch (error) {
        console.error("Error fetching progress:", error);
      }
    }, updateInterval);
  };
  useEffect(() => {
    const storedData = JSON.parse(sessionStorage.getItem("setFocus")); // JSON ที่มีข้อมูล file_name
    if (storedData && storedData.file_name) {
      setUploadedFileName(storedData.file_name); // อัปเดต state ด้วย file_name
    }
  }, []);
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await fetch(
          Configs.API_URL_AI_COUNTING_FINE_TUNE + '/get_classes/',
          {
            method: "GET",
            headers: {
              "X-API-KEY": "TTT-OCR-SECRET-KEY",
            },
          }
        );
        const result = await response.json();
        if (response.ok) {
          setAvailableClasses(result.classes);
        } else {
          Swal.fire("Error", result.error, "error");
        }
      } catch (error) {
        Swal.fire("Error", "เกิดข้อผิดพลาดในการดึงข้อมูล classes", "error");
      }
    };
    fetchClasses();
  }, []);

  // Load detected image and bounding boxes from storage
  useEffect(() => {
    const storedImage = localStorage.getItem("detectedImage");
    if (sessionStorage.getItem("setFocus")) {
      const temp = JSON.parse(sessionStorage.getItem("setFocus"));

      // Map bbox to format expected by the canvas drawing
      const mappedBboxes = temp.bboxes.map((bbox) => ({
        x: bbox.bbox[0],
        y: bbox.bbox[1],
        width: bbox.bbox[2] - bbox.bbox[0],
        height: bbox.bbox[3] - bbox.bbox[1],
        label: bbox.label,
        text: bbox.text,
        confidence: bbox.confidence,
        color: getRandomColor(),
      }));

      setBboxes(mappedBboxes);

      // Set uploaded file name if available
      if (temp.filename) {
        setUploadedFileName(temp.filename);
      }
    }

    if (storedImage) {
      setDetectedImage(storedImage);
    }
  }, []);

  // Generate a random color for each bounding box
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Handle delete bounding box
  const handleDeleteBoundingBox = (index) => {
    // ลบ bounding box ตาม index ที่ระบุ
    const updatedBboxes = bboxes.filter((_, i) => i !== index);

    // อัปเดต bounding boxes และรีเซ็ต state ที่เกี่ยวข้อง
    setBboxes(updatedBboxes);

    // รีเซ็ต popup, selected item และ selected card index
    setIsLabeling(false);
    setSelectedBbox(null);
    setCurrentBbox(null);
    setLabelInput("");
    setSelectedCardIndex(null);
  };

  // Handle class deletion
  const handleDeleteClass = async () => {
    if (classToDelete && availableClasses.includes(classToDelete)) {
      try {
        const formData = new FormData();
        formData.append("class_name_to_remove", classToDelete);

        const response = await fetch(
          Configs.API_URL_AI_COUNTING_FINE_TUNE + '/remove_class/',
          {
            method: "POST",
            body: formData,
            headers: {
              "X-API-KEY": "TTT-OCR-SECRET-KEY",
            },
          }
        );

        const result = await response.json();
        console.log(result);

        if (response.ok) {
          Swal.fire("สำเร็จ!", result.yaml_update_result.message, "success");
          setAvailableClasses(
            availableClasses.filter((item) => item !== classToDelete)
          );
          setClassToDelete("");
        } else {
          Swal.fire(
            "Error",
            result.yaml_update_result.error || "เกิดข้อผิดพลาดในการลบคลาส",
            "error"
          );
        }
      } catch (error) {
        Swal.fire("Error", "เกิดข้อผิดพลาดในการลบคลาส", "error");
      }
    } else {
      Swal.fire("Warning", "กรุณาเลือกคลาสที่ถูกต้องสำหรับการลบ", "warning");
    }
  };

  const handleApprove = async () => {
    Swal.fire({
      title: "คุณแน่ใจหรือไม่?",
      text: "คุณต้องการยืนยันและบันทึกข้อมูลนี้หรือไม่?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const annotations = bboxes
            .map((bbox) => {
              const centerX =
                (bbox.x + bbox.width / 2) / canvasRef.current.width;
              const centerY =
                (bbox.y + bbox.height / 2) / canvasRef.current.height;
              const width = bbox.width / canvasRef.current.width;
              const height = bbox.height / canvasRef.current.height;

              return `${availableClasses.indexOf(
                bbox.label
              )} ${centerX} ${centerY} ${width} ${height}`;
            })
            .join("\n");

          const blob = await fetch(detectedImage).then((res) => res.blob());
          const imageFile = new File([blob], uploadedFileName || "image.jpg", {
            type: "image/jpeg",
          });
          const formData = new FormData();
          formData.append("image", imageFile);
          formData.append("annotations", annotations);
          formData.append("save_to_valid", "true");

          console.log(imageFile);

          const uploadResponse = await fetch(
            Configs.API_URL_AI_COUNTING_FINE_TUNE + '/upload_image_annotations/',
            {
              method: "POST",
              body: formData,
              headers: {
                "X-API-KEY": "TTT-OCR-SECRET-KEY",
              },
            }
          );

          if (uploadResponse.ok) {
            Swal.fire({
              title: "ยืนยันเรียบร้อย!",
              text: "ข้อมูลถูกบันทึกเรียบร้อยแล้ว.",
              icon: "success",
            }).then(() => {
              window.location.href = "/ai_feature/ocr";
            });
          } else {
            Swal.fire("Error", "เกิดข้อผิดพลาดในการบันทึก", "error");
          }
        } catch (error) {
          console.error("Error:", error);
          Swal.fire("Error", "เกิดข้อผิดพลาดในการบันทึกข้อมูล", "error");
        }
      }
    });
  };

  // Get correct mouse position relative to the canvas
  const getMousePos = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    return {
      x: (e.clientX - rect.left) * scaleX,
      y: (e.clientY - rect.top) * scaleY,
    };
  };

  // Check if the mouse is inside a bounding box
  const isMouseInsideBbox = (bbox, mousePos) => {
    return (
      mousePos.x > bbox.x &&
      mousePos.x < bbox.x + bbox.width &&
      mousePos.y > bbox.y &&
      mousePos.y < bbox.y + bbox.height
    );
  };

  const handleMouseDown = (e) => {
    const mousePos = getMousePos(e);

    // ตรวจสอบว่าคลิกใน bounding box ไหน
    const clickedBboxIndex = bboxes.findIndex((bbox) =>
      isMouseInsideBbox(bbox, mousePos)
    );

    if (clickedBboxIndex !== -1) {
      // อัปเดต selectedCardIndex และตำแหน่ง popup ตามการ์ด
      setSelectedCardIndex(clickedBboxIndex);
      setSelectedBbox(bboxes[clickedBboxIndex]);
      setLabelInput(bboxes[clickedBboxIndex].label || "");

      // ตั้งตำแหน่ง popup ตาม card
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      setLabelPosition({
        x: bboxes[clickedBboxIndex].x / (canvas.width / rect.width), // แปลง x
        y: bboxes[clickedBboxIndex].y / (canvas.height / rect.height), // แปลง y
      });

      setIsLabeling(true); // แสดง popup
      return;
    }

    // ถ้าไม่ได้คลิกที่ bounding box ให้เริ่มวาดใหม่
    const { x, y } = mousePos;
    setIsDrawing(true);
    setCurrentBbox({ x, y, width: 0, height: 0, color: getRandomColor() });
  };

  // Handle mouse move event to update bounding box dimensions
  const handleMouseMove = (e) => {
    if (!isDrawing || selectedBbox) return;

    const { x, y } = getMousePos(e);
    setCurrentBbox((bbox) => ({
      ...bbox,
      width: x - bbox.x,
      height: y - bbox.y,
    }));
  };

  // Handle mouse up event to stop drawing
  const handleMouseUp = () => {
    if (!isDrawing) return;

    if (currentBbox.width === 0 || currentBbox.height === 0) {
      Swal.fire({
        icon: "warning",
        title: "กรอบที่วาดไม่ถูกต้อง",
        text: "กรุณาวาดกรอบที่ถูกต้อง",
      });
      setIsDrawing(false);
      return;
    }

    // Activate labeling mode
    setIsDrawing(false);
    setIsLabeling(true);

    // Adjust label position
    const padding = 10;
    const newLabelPosition = {
      x: currentBbox.x + currentBbox.width + padding,
      y: currentBbox.y,
    };

    setLabelPosition(newLabelPosition);
  };

  const handleLabelSubmit = () => {
    if (!labelInput) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Label",
        text: "Please provide a label for the bounding box.",
      });
      return;
    }

    if (selectedBbox) {
      // Update label of selected bounding box
      const updatedBboxes = bboxes.map((bbox) =>
        bbox === selectedBbox ? { ...bbox, label: labelInput } : bbox
      );
      setBboxes(updatedBboxes);
      setSelectedBbox(null);
      setSelectedCardIndex(null); // Reset selected card index
    } else {
      // Add new bounding box with label
      setBboxes([...bboxes, { ...currentBbox, label: labelInput }]);
      setCurrentBbox(null);
    }

    setLabelInput("");
    setIsLabeling(false);
  };

  // Handle adding a new class
  const handleAddNewClass = async () => {
    if (!newClassInput.trim()) {
      Swal.fire({
        icon: "warning",
        title: "ข้อมูลไม่ถูกต้อง",
        text: "กรุณาป้อนชื่อคลาสก่อนเพิ่ม",
      });
      return;
    }

    if (!availableClasses.includes(newClassInput)) {
      try {
        const formData = new FormData();
        formData.append("new_class_name", newClassInput);

        const response = await fetch(
          Configs.API_URL_AI_COUNTING_FINE_TUNE + '/update_class/',
          {
            method: "POST",
            body: formData,
            headers: {
              "X-API-KEY": "TTT-OCR-SECRET-KEY",
            },
          }
        );

        const result = await response.json();
        console.log(result);

        if (response.ok) {
          Swal.fire("สำเร็จ!", result.message, "success");
          setAvailableClasses([...availableClasses, newClassInput]);
          setNewClassInput("");
        } else {
          Swal.fire("เกิดข้อผิดพลาด", result.error, "error");
        }
      } catch (error) {
        Swal.fire("เกิดข้อผิดพลาด", "ไม่สามารถอัปเดตคลาสได้", "error");
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "ข้อมูลซ้ำ",
        text: "ชื่อคลาสนี้มีอยู่แล้ว",
      });
    }
  };

  const handleCancelLabeling = () => {
    setIsLabeling(false);
    setSelectedBbox(null);
    setSelectedCardIndex(null); // Reset selected card index
    setCurrentBbox(null);
    setLabelInput("");
  };

  // Drawing bounding boxes on the canvas
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas && detectedImage) {
      const ctx = canvas.getContext("2d");

      const img = new Image();
      img.src = detectedImage;
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0);

        // Draw existing bounding boxes
        bboxes.forEach((bbox) => {
          ctx.strokeStyle = bbox.color;
          ctx.lineWidth = 4;
          ctx.strokeRect(bbox.x, bbox.y, bbox.width, bbox.height);
          ctx.font = "12px Arial";
          ctx.fillStyle = bbox.color;
          ctx.fillText(bbox.label, bbox.x + 5, bbox.y + 15);
        });

        // Draw the current bounding box
        if (currentBbox) {
          ctx.strokeStyle = currentBbox.color;
          ctx.lineWidth = 4;
          ctx.strokeRect(
            currentBbox.x,
            currentBbox.y,
            currentBbox.width,
            currentBbox.height
          );
        }

        // Highlight selected bounding box
        if (selectedBbox) {
          ctx.strokeStyle = "red";
          ctx.lineWidth = 4;
          ctx.setLineDash([5, 3]);
          ctx.strokeRect(
            selectedBbox.x,
            selectedBbox.y,
            selectedBbox.width,
            selectedBbox.height
          );
          ctx.setLineDash([]);
        }
      };
    }
  }, [detectedImage, bboxes, currentBbox, selectedBbox]);

  return (
    <div className="content-wrapper" style={{ padding: "20px" }}>
      {/* Fine Tuning Canvas and Accuracy */}
      <div className="row">
        {/* Left Column: Canvas */}
        <section className="col-12 col-md-8 mb-4">
          <div className="card" style={{ position: "relative" }}>
            <div className="card-body">
              {/* Top Bar with Back Button and Title */}
              <div
                className="row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                {/* Back Button */}
                <div
                  style={{
                    flex: "0 0 auto",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ArrowLeftOutlined
                    style={{
                      fontSize: "24px",
                      cursor: "pointer",
                      marginRight: "8px",
                    }}
                    onClick={() => window.history.back()}
                  />
                </div>

                {/* Fine Tuning Title */}
                <div style={{ flex: "1 0 auto", textAlign: "center" }}>
                  <h2
                    style={{
                      fontWeight: "bold",
                      margin: 0,
                      color: "#00408E",
                      fontSize: "30px",
                    }}
                  >
                    Fine Tuning
                  </h2>
                </div>

                {/* Placeholder to Balance Layout */}
                <div style={{ flex: "0 0 auto", visibility: "hidden" }}>
                  <button className="btn btn-secondary">Hidden</button>
                </div>
              </div>

              {/* Input for Adding Classes */}
              <div className="row" style={{ marginBottom: "20px" }}>
                <div className="col-12">
                  {/* Label for Input */}
                  <label
                    style={{
                      fontWeight: "medium",
                      marginBottom: "0px",
                      display: "block",
                      marginLeft: "2px",
                    }}
                  >
                    Add name class
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      type="text"
                      value={newClassInput}
                      onChange={(e) => setNewClassInput(e.target.value)}
                      placeholder="Enter your class"
                      className="form-control"
                      style={{ maxWidth: "300px" }}
                    />
                    <button
                      onClick={handleAddNewClass}
                      className="btn btn-primary"
                      style={{ minWidth: "75px" }}
                    >
                      Add
                    </button>
                    <button
                      onClick={handleDeleteClass}
                      className="btn btn-danger"
                      style={{ minWidth: "75px" }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>

              {/* Canvas Section */}
              {detectedImage ? (
                <div style={{ position: "relative" }}>
                  <canvas
                    ref={canvasRef}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    style={{
                      border: "2px solid #4A90E2",
                      cursor: isLabeling ? "default" : "crosshair",
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                  {/* Display uploaded file name at bottom right corner */}
                  {uploadedFileName && (
                    <div
                      style={{
                        position: "relative",
                        textAlign: "right",
                        marginTop: "5px",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#333",
                      }}
                    >
                      {uploadedFileName}
                    </div>
                  )}
                </div>
              ) : (
                <p>ไม่พบภาพสำหรับการปรับแต่ง</p>
              )}

              {isLabeling && (
                <div
                  style={{
                    position: "absolute",
                    left: `${labelPosition.x}px`,
                    top: `${labelPosition.y}px`,
                    background: "rgba(255, 255, 255, 0.9)",
                    borderRadius: "8px",
                    padding: "10px",
                    zIndex: 10,
                    boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                    width: "300px",
                    textAlign: "center", // จัดข้อความให้อยู่ตรงกลาง
                  }}
                >
                  <div
                    style={{
                      marginBottom: "10px",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Add class
                  </div>

                  <select
                    value={labelInput}
                    onChange={(e) => setLabelInput(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "8px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      marginBottom: "10px",
                    }}
                  >
                    <option value="">Choose Class</option>
                    {availableClasses.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <button
                      onClick={handleLabelSubmit}
                      style={{
                        padding: "8px 20px",
                        backgroundColor: "#1E90FF",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      Confirm
                    </button>
                    <button
                      onClick={handleCancelLabeling}
                      style={{
                        padding: "8px 20px",
                        backgroundColor: "#FF4D4F",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        {/* Right Column: Accuracy Card */}
        <section className="col-12 col-md-4 mb-4">
          <div className="card" style={{ height: "99.3%" }}>
            <div className="card-body text-center">
              {/* Circular Progress Bar with Ant Design */}
              <div
                style={{
                  marginTop: "90px",
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {/* Ant Design Progress */}
                <Progress
                  type="circle"
                  percent={overallAccuracy}
                  strokeColor={{
                    "0%": "#FF0000", // สีแดง
                    "25%": "#FFA500", // สีส้ม
                    "50%": "#FFFF00", // สีเหลือง
                    "100%": "#00FF00", // สีเขียว
                  }}
                  trailColor="#e0e0e0"
                  strokeWidth={14}
                  width={200}
                  format={(percent) => (
                    <span style={{ fontSize: "30px" }}>{percent}%</span>
                  )}
                />
                <p
                  style={{
                    fontSize: "25px",
                    fontWeight: "bold",
                    margin: "20px 0 0",
                    color: "#000",
                  }}
                >
                  Overall Accuracy
                </p>
              </div>

              {/* Object Count Header */}
              <h2
                style={{
                  fontSize: "24px", // ขนาดข้อความ
                  fontWeight: "bold", // น้ำหนักข้อความ
                  marginBottom: "0px", // ระยะห่างจากการ์ดด้านล่าง
                  color: "#333", // สีข้อความ
                  textAlign: "left", // จัดข้อความชิดซ้าย
                  marginLeft: "2px",
                }}
              >
                Object Count
              </h2>

              {/* Object Count */}
              <div>
                {accuracyData.map((item, idx) => (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "column", // จัดเรียงข้อความในแนวตั้ง
                      padding: "16px",
                      backgroundColor: "#ffffff",
                      borderRadius: "10px",
                      marginBottom: "12px",
                      border: "1px solid #000000",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)",
                      transition: "transform 0.2s ease, box-shadow 0.2s ease",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = "scale(1.02)";
                      e.currentTarget.style.boxShadow =
                        "0 6px 12px rgba(0, 0, 0, 0.1)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                      e.currentTarget.style.boxShadow =
                        "0 4px 8px rgba(0, 0, 0, 0.05)";
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        marginBottom: "0px", // เพิ่มระยะห่างด้านล่างข้อความ
                      }}
                    >
                      <span
                        style={{
                          fontSize: "30px",
                          fontWeight: "bold",
                          color: "#00408E",
                        }}
                      >
                        {item.label}
                      </span>
                      <span
                        style={{
                          fontSize: "30px",
                          fontWeight: "bold",
                          color: "#333",
                        }}
                      >
                        {item.count}
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: "18px",
                        color: "#555",
                        fontWeight: "500",
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      Accuracy: {item.accuracy.toFixed(2)}%
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div
              className="card-body"
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  flex: "1 0 auto",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginBottom: "20px",
                }}
              >
                {/* Icon */}
                <BarChartOutlined style={{ fontSize: "35px", color: "#333" }} />

                {/* Title */}
                <h2
                  style={{
                    fontWeight: "bold",
                    margin: 0,
                    marginBottom: "0px",
                    fontSize: "35px",
                  }}
                >
                  Detection
                </h2>
              </div>

              <div
                className="row"
                style={{ gap: "20px", display: "flex", flexWrap: "wrap" }}
              >
                {paginatedBboxes.map((bbox, index) => (
                  <div
                    key={index}
                    style={{
                      flexBasis: "48%", // ครอบครอง 48% ของพื้นที่ (2 คอลัมน์ต่อแถว)
                      maxWidth: "48%",
                      padding: "15px",
                      backgroundColor:
                        selectedCardIndex === index
                          ? "rgba(0, 171, 14, 0.25)"
                          : "#fff", // สีพื้นหลังเมื่อเลือก (opacity 25%)
                      boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                      border: `2px solid ${
                        selectedCardIndex === index
                          ? "rgba(0, 171, 14, 0.25)"
                          : bbox.color || "#fff"
                      }`, // เปลี่ยนสีขอบเมื่อเลือก (opacity 25%)
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      height: "180px",
                      cursor: "pointer", // เปลี่ยน cursor เป็น pointer
                      transition: "background-color 0.3s ease", // เพิ่มเอฟเฟกต์เปลี่ยนสี
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      if (selectedCardIndex === index) {
                        // ยกเลิกการเลือก
                        setSelectedCardIndex(null);
                        setIsLabeling(false);
                        setSelectedBbox(null);
                      } else {
                        // เลือก item ใหม่
                        setSelectedCardIndex(index);
                        setLabelInput(bbox.label || "");
                        setSelectedBbox(bbox);

                        // อัปเดตตำแหน่ง popup
                        const canvas = canvasRef.current;
                        const rect = canvas.getBoundingClientRect();
                        setLabelPosition({
                          x: bbox.x / (canvas.width / rect.width), // แปลง x
                          y: bbox.y / (canvas.height / rect.height), // แปลง y
                        });

                        setIsLabeling(true); // แสดง popup
                      }
                    }}
                  >
                    {/* ไอคอน Delete */}
                    <DeleteOutlined
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        fontSize: "24px",
                        color: "#ff4d4f",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation(); // ป้องกันการคลิกไอคอนลบส่งผลต่อการเลือกการ์ด
                        handleDeleteBoundingBox(index); // เรียกฟังก์ชัน handleDeleteBoundingBox
                      }}
                    />

                    {/* วงกลมสีและ Label */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          backgroundColor: bbox.color || "#ccc",
                          marginRight: "10px",
                        }}
                      ></div>
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "22px",
                          color: "#333",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {bbox.label || "Unlabeled"}
                      </div>
                    </div>

                    {/* Input Field */}
                    <div style={{ fontSize: "18px", marginBottom: "10px" }}>
                      <label
                        style={{
                          marginBottom: "5px",
                          display: "block",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Text:
                      </label>
                      <input
                        ref={(el) => (inputRefs.current[index] = el)} // เก็บ reference ของ input
                        type="text"
                        value={bbox.text || ""}
                        onChange={(e) => {
                          const updatedBboxes = bboxes.map((b, i) =>
                            i === index ? { ...b, text: e.target.value } : b
                          );
                          setBboxes(updatedBboxes);
                        }}
                        style={{
                          fontSize: "16px",
                          padding: "8px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          width: "100%",
                        }}
                      />
                    </div>

                    {/* Confidence และ Position */}
                    <div
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        fontSize: "18px",
                        lineHeight: "1.5",
                        marginBottom: "5px",
                      }}
                    >
                      <div style={{ marginRight: "10px" }}>
                        <span>Confidence: </span>
                        <span style={{ whiteSpace: "nowrap" }}>
                          {bbox.confidence
                            ? `${(bbox.confidence * 100).toFixed(2)}%`
                            : "N/A"}
                        </span>
                      </div>
                      <div>
                        <span>Position: </span>
                        <span>
                          x: {bbox.x.toFixed(2)}, y: {bbox.y.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div
                className="d-flex justify-content-center align-items-center mt-4"
                style={{ gap: "10px" }} // เพิ่มช่องว่างระหว่างปุ่ม
              >
                {/* ปุ่ม Previous */}
                <button
                  className="btn"
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  style={{
                    borderRadius: "8px",
                    padding: "8px 16px",
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#00408E", // สีข้อความ
                    border: "1px solid #00408E", // สีขอบปุ่ม
                    backgroundColor: "transparent", // พื้นหลังโปร่งใส
                    cursor: currentPage === 1 ? "not-allowed" : "pointer", // เปลี่ยน cursor เมื่อถึงหน้าแรก
                    opacity: currentPage === 1 ? 0.5 : 1, // ลดความโปร่งใสเมื่อ disabled
                  }}
                >
                  Previous
                </button>

                {/* Pagination Buttons */}
                <div className="d-flex gap-2" style={{ gap: "10px" }}>
                  {Array.from({ length: totalPages }, (_, idx) => {
                    const isActive = currentPage === idx + 1; // ตรวจสอบว่าปุ่มนี้คือหน้าปัจจุบันหรือไม่
                    return (
                      <button
                        key={idx}
                        onClick={() => setCurrentPage(idx + 1)}
                        style={{
                          borderRadius: "8px",
                          padding: "8px 16px",
                          fontSize: "16px",
                          fontWeight: "500",
                          color: isActive ? "#FFFFFF" : "#00408E", // สีข้อความ
                          backgroundColor: isActive ? "#00408E" : "#E6ECF4", // สีพื้นหลัง
                          border: "1px solid #00408E", // สีขอบปุ่ม
                          cursor: "pointer",
                        }}
                      >
                        {idx + 1}
                      </button>
                    );
                  })}
                </div>

                {/* ปุ่ม Next */}
                <button
                  className="btn"
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                  style={{
                    borderRadius: "8px",
                    padding: "8px 16px",
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#00408E", // สีข้อความ
                    border: "1px solid #00408E", // สีขอบปุ่ม
                    backgroundColor: "transparent", // พื้นหลังโปร่งใส
                    cursor:
                      currentPage === totalPages ? "not-allowed" : "pointer", // เปลี่ยน cursor เมื่อถึงหน้าสุดท้าย
                    opacity: currentPage === totalPages ? 0.5 : 1, // ลดความโปร่งใสเมื่อ disabled
                  }}
                >
                  Next
                </button>
              </div>

              <div className="row mt-4">
                <div className="col-12 text-center">
                  {showProgress && (
                    <div className="mb-3">
                      <p>Progress: {progress}%</p>
                      <progress
                        value={progress}
                        max="100"
                        style={{ width: "100%" }}
                      ></progress>
                    </div>
                  )}
                  <button
                    className="btn btn-primary btn-lg"
                    onClick={handleApprove}
                    disabled={approveDisabled}
                    style={{
                      backgroundColor: "#00408E", // สีพื้นหลัง
                      color: "#fff", // สีตัวอักษร
                      fontSize: "24px", // ขนาดฟอนต์
                      width: "601.06px", // ความกว้าง
                      height: "67px", // ความสูง
                      borderRadius: "10px", // มุมโค้ง
                      border: "1px solid #00408E", // เส้นขอบ
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // เงา
                      fontWeight: "bold", // ตัวอักษรหนา
                    }}
                  >
                    Approve
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fine_Tuning;
