import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../Utils/Common";
import axios from "axios";
import Configs from "../../../config";
import moment from "moment";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

function Project_AEV({ mode }) {
  const { projectId } = useParams();

  const [isDisabled, setIsDisabled] = useState(false);

  const [saveData, setSaveData] = useState({
    company_id: getUser().com,
    oem_id: getOem(),
    user_id: getUser().fup,
    project_name: "",
    project_remark: "",
  });

  async function viewProjectData() {
    const tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      projectId: projectId,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/project/viewProjectData",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        setSaveData({
          ...saveData,
          project_name: response.data.data[0]?.acc_project_name,
          project_remark: response.data.data[0]?.acc_project_remark,
        });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "warning",
          // title: "กรุณากรอกข้อมูลใหม่",
          html: error.response.data.error.message,
        });
      });
  }

  async function saveUpdateProjectData() {
    Swal.fire({
      title: "คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const error_list = [];

        if (
          saveData.project_name == null ||
          saveData.project_name?.trim() == ""
        ) {
          const temp_err = {
            message: "กรุณากรอกชื่อ Project",
          };
          error_list.push(temp_err);
        }

        if (error_list.length > 0) {
          let err_message = "";

          for (let err = 0; err < error_list.length; err++) {
            err_message += "<br/>" + error_list[err].message;
          }

          Swal.fire("Error", err_message, "error");
        } else {
          let axiosData = {};

          const tempData = JSON.parse(JSON.stringify(saveData));

          if (mode == "Add") {
            axiosData = {
              method: "post",
              url:
                Configs.API_URL_hrMagenatement + "/api/project/saveProjectData",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: tempData,
            };
          } else if (mode == "Edit") {
            tempData["projectId"] = projectId;

            axiosData = {
              method: "post",
              url:
                Configs.API_URL_hrMagenatement +
                "/api/project/updateProjectData",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: tempData,
            };
          }

          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async (result) => {
            await axios(axiosData)
              .then(function (response) {
                Swal.fire({
                  icon: "success",
                  title: "บันทึกสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                }).then(() => {
                  window.location.href = "/accounting/project_acc";
                });
              })
              .catch(async function (error) {
                console.log(error);
                Swal.fire({
                  icon: "warning",
                  // title: "กรุณากรอกข้อมูลใหม่",
                  html: error.response.data.error.message,
                });
              });
          });
        }
      }
    });
  }

  function cancelOption() {
    if (mode == "View") {
      window.location.href = "/accounting/project_acc";
    } else {
      Swal.fire({
        title: "คุณมั่นใจว่าจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/accounting/project_acc";
        }
      });
    }
  }

  useEffect(() => {
    if (mode == "View") {
      setIsDisabled(true);
    }

    if (mode != "Add") {
      viewProjectData();
    }
  }, []);

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  {mode} Project
                  {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Accounting</li>
                  <li className="breadcrumb-item active">{mode} Project</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              {mode != "View" ? (
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={saveUpdateProjectData}
                    class="btn btn-block btn-success "
                  >
                    บันทึก
                  </button>
                </div>
              ) : (
                <></>
              )}

              <div className="col-6 col-md-3 col-xl-2">
                <button
                  type="button"
                  onClick={cancelOption}
                  class="btn btn-block btn-danger "
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              {/* <h3 className="card-title"></h3> */}
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="project_name"
                      value={saveData.project_name}
                      // placeholder=""
                      disabled={isDisabled}
                      onChange={(e) => {
                        setSaveData({
                          ...saveData,
                          project_name: e.target.value,
                        });
                      }}
                      style={{ padding: "0px 10px" }}
                    />
                    <label>
                      ชื่อ Project<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <textarea
                      type="text"
                      className="form-control"
                      required
                      id="project_remark"
                      style={{
                        height: "150px",
                        fontSize: "25px",
                        padding: "5px 10px",
                      }}
                      value={saveData.project_remark}
                      // placeholder=""
                      disabled={isDisabled}
                      onChange={(e) => {
                        setSaveData({
                          ...saveData,
                          project_remark: e.target.value,
                        });
                      }}
                    />
                    <label>Remark</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Project_AEV;
