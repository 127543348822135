import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
// import "react-circular-progressbar/dist/styles.css";
import { DeleteOutlined, CheckCircleOutlined, WarningOutlined, CloseCircleOutlined, BarChartOutlined } from "@ant-design/icons";
import './CountingFeature.module.css';
import Configs from "../../config";
import { Tooltip, Progress } from 'antd';
const ITEMS_PER_PAGE = 6;

function Counting_FineTuning() {
    const [detectedImage, setDetectedImage] = useState(null);
    const canvasRef = useRef(null);
    const [bboxes, setBboxes] = useState([]);
    const [currentBbox, setCurrentBbox] = useState(null);
    const [selectedBbox, setSelectedBbox] = useState(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [labelInput, setLabelInput] = useState("");
    const [isLabeling, setIsLabeling] = useState(false);
    const [labelPosition, setLabelPosition] = useState({ x: 0, y: 0 });
    const [availableClasses, setAvailableClasses] = useState(["hexagonal_bar", "hollowround_bar", "round_bar", "square_bar", "steel_bar"]);
    const [newClassInput, setNewClassInput] = useState("");
    const [classToDelete, setClassToDelete] = useState("");
    const [showProgress, setShowProgress] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isApproving, setIsApproving] = useState(false);
    const [approveDisabled, setApproveDisabled] = useState(false);
    const [objectCount, setObjectCount] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [editMode, setEditMode] = useState(false);


    const overallAccuracy = bboxes.length > 0
        ? (bboxes.reduce((sum, bbox) => sum + (bbox.confidence || 0), 0) / bboxes.length) * 100
        : 0;

    const classCounts = bboxes.reduce((acc, bbox) => {
        acc[bbox.label] = (acc[bbox.label] || 0) + 1;
        return acc;
    }, {});

    const totalPages = Math.ceil(bboxes.length / ITEMS_PER_PAGE);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const bboxesToDisplay = bboxes.slice(startIndex, endIndex);
    const averageConfidence = bboxes.length > 0
        ? (bboxes.reduce((sum, bbox) => sum + (bbox.confidence || 0), 0) / bboxes.length) * 100
        : 0;

    const pollProgress = () => {
        const updateInterval = 500;
        const intervalId = setInterval(async () => {
            try {
                const response = await fetch(Configs.API_URL_AI_COUNTING +'/progress/');
                const progressData = await response.json();

                setProgress(progressData.progress);

                if (progressData.progress >= 100) {
                    clearInterval(intervalId);
                    setShowProgress(false);
                    setApproveDisabled(false);
                    Swal.fire('การปรับแต่งเสร็จสมบูรณ์!', 'โมเดลได้รับการปรับแต่งแล้ว.', 'success');
                }
            } catch (error) {
                console.error("Error fetching progress:", error);
            }
        }, updateInterval);
    };

    const calculateGradient = (accuracy) => {
        if (accuracy <= 33) return "#00FF00"; // Green
        if (accuracy <= 66) return "#FFFF00"; // Yellow
        return "#FF0000"; // Red
    };


    useEffect(() => {
        // โหลดข้อมูลรูปภาพและ Bounding Boxes
        const storedData = localStorage.getItem("detectedImageData");
        if (storedData) {
            const { detected_image, detected_objects } = JSON.parse(storedData)?.counting_result;

            // ตรวจสอบและตั้งค่ารูปภาพ
            if (detected_image) {
                setDetectedImage(detected_image);
            } else {
                console.error("No detected_image found in storedData.");
            }

            // ตรวจสอบและตั้งค่า Bounding Boxes
            if (detected_objects) {
                setBboxes(detected_objects.map(obj => ({
                    x: obj.bbox.x,
                    y: obj.bbox.y,
                    width: obj.bbox.width,
                    height: obj.bbox.height,
                    label: obj.label,
                    confidence: obj.confidence || 0,
                    color: obj.color || getRandomColor()
                })));
            } else {
                console.error("No detected_objects found in storedData.");
            }
        } else {
            console.warn("No detectedImageData found in localStorage.");
        }
    }, []);

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const handleAddClass = async () => {
        if (!newClassInput.trim()) {
            Swal.fire({
                icon: "warning",
                title: "ชื่อคลาสไม่ถูกต้อง",
                text: "กรุณากรอกชื่อคลาสที่ถูกต้อง",
            });
            return;
        }

        if (availableClasses.includes(newClassInput.trim())) {
            Swal.fire({
                icon: "warning",
                title: "ชื่อคลาสซ้ำ",
                text: "คลาสนี้มีอยู่ในระบบแล้ว",
            });
            return;
        }

        try {
            const response = await fetch(Configs.API_URL_AI_COUNTING +"/add_class/", { // เปลี่ยน URL ตรงนี้
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'x-api-key': 'TTT_AI_COUNTING'
                },
                body: JSON.stringify({ label: newClassInput.trim() }),
            });

            const result = await response.json();

            if (response.ok) {
                Swal.fire({
                    icon: "success",
                    title: "เพิ่มคลาสสำเร็จ",
                    text: `คลาส "${newClassInput.trim()}" ถูกเพิ่มเรียบร้อยแล้ว`,
                });
                setAvailableClasses([...availableClasses, newClassInput.trim()]);
                setNewClassInput("");
            } else {
                Swal.fire({
                    icon: "error",
                    title: "เกิดข้อผิดพลาด",
                    text: result.error || "ไม่สามารถเพิ่มคลาสได้ กรุณาลองอีกครั้ง",
                });
            }
        } catch (error) {
            console.error("Error adding class:", error);
            Swal.fire({
                icon: "error",
                title: "เกิดข้อผิดพลาด",
                text: "ไม่สามารถเพิ่มคลาสได้ กรุณาลองใหม่อีกครั้ง",
            });
        }
    };

    const handleApprove = async () => {
        Swal.fire({
            title: 'คุณแน่ใจหรือไม่?',
            text: "คุณต้องการยืนยันและบันทึกข้อมูลนี้หรือไม่?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setApproveDisabled(true); // ปิดปุ่มขณะดำเนินการ
                    console.log('detectedImage', detectedImage);

                    // เตรียมข้อมูล annotations สำหรับอัพโหลด
                    const annotations = bboxes.map((bbox) => {
                        const centerX = (bbox.x + bbox.width / 2) / canvasRef.current.width;
                        const centerY = (bbox.y + bbox.height / 2) / canvasRef.current.height;
                        const width = bbox.width / canvasRef.current.width;
                        const height = bbox.height / canvasRef.current.height;

                        return `${availableClasses.indexOf(bbox.label)} ${centerX} ${centerY} ${width} ${height}`;
                    }).join("\n");

                    const blob = await fetch(detectedImage).then((res) => res.blob());
                    const imageFile = new File([blob], "image.jpg", { type: "image/jpeg" });

                    const formData = new FormData();
                    formData.append("file", imageFile); // "file" ต้องตรงกับ backend
                    formData.append("annotations", annotations);

                    // อัพโหลดไฟล์และ annotations
                    const uploadResponse = await fetch(Configs.API_URL_AI_COUNTING +'/upload/', { // เปลี่ยน URL ตรงนี้
                        method: "POST",
                        headers: {
                            'x-api-key': 'TTT_AI_COUNTING'
                        },
                        body: formData,
                    });

                    if (uploadResponse.ok) {
                        const uploadedData = await uploadResponse.json();

                        Swal.fire({
                            title: 'ยืนยันเรียบร้อย!',
                            text: 'ข้อมูลถูกบันทึกแล้ว คุณต้องการทำอะไรต่อ?',
                            icon: 'success',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Fine-Tune ทันที',
                            cancelButtonText: 'กลับหน้าหลัก',
                        }).then(async (nextStep) => {
                            if (nextStep.isConfirmed) {
                                // เรียก Fine-Tune API
                                try {
                                    const fineTuneResponse = await fetch(Configs.API_URL_AI_COUNTING +'/fine_tune/', { // เปลี่ยน URL ตรงนี้
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'x-api-key': 'TTT_AI_COUNTING'
                                        },
                                        body: JSON.stringify({
                                            labeled_objects: [
                                                {
                                                    image_path: uploadedData.file_path, // Path จากการอัพโหลด
                                                    annotations: bboxes.map((bbox) => ({
                                                        class_index: availableClasses.indexOf(bbox.label),
                                                        bbox: [bbox.x, bbox.y, bbox.width, bbox.height],
                                                    })),
                                                },
                                            ],
                                        }),
                                    });

                                    const fineTuneResult = await fineTuneResponse.json();
                                    if (fineTuneResponse.ok) {
                                        Swal.fire('สำเร็จ!', 'Fine-Tuning เรียบร้อยแล้ว', 'success');
                                    } else {
                                        Swal.fire('ข้อผิดพลาด', fineTuneResult.error || 'เกิดข้อผิดพลาดในการ Fine-Tuning', 'error');
                                    }
                                } catch (error) {
                                    console.error("Error during fine-tuning:", error);
                                    Swal.fire('ข้อผิดพลาด', 'ไม่สามารถเชื่อมต่อกับ Fine-Tuning ได้', 'error');
                                }
                            } else {
                                // กลับหน้าหลัก
                                window.location.href = '/ai_feature/counting';
                            }
                        });
                    } else {
                        const error = await uploadResponse.json();
                        Swal.fire('ข้อผิดพลาด', error.error || 'ไม่สามารถอัพโหลดข้อมูลได้', 'error');
                    }
                } catch (error) {
                    console.error("Error:", error);
                    Swal.fire('ข้อผิดพลาด', 'ไม่สามารถบันทึกข้อมูลได้', 'error');
                } finally {
                    setApproveDisabled(false);
                }
            }
        });
    };

    const getMousePos = (e) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;

        return {
            x: (e.clientX - rect.left) * scaleX,
            y: (e.clientY - rect.top) * scaleY
        };
    };

    const isMouseInsideBbox = (bbox, mousePos) => {
        return (
            mousePos.x > bbox.x &&
            mousePos.x < bbox.x + bbox.width &&
            mousePos.y > bbox.y &&
            mousePos.y < bbox.y + bbox.height
        );
    };

    const handleMouseDown = (e) => {
        if (isLabeling) return;

        const mousePos = getMousePos(e);
        const clickedBbox = bboxes.find(bbox => isMouseInsideBbox(bbox, mousePos));

        if (clickedBbox) {
            setSelectedBbox(clickedBbox);
            setLabelInput(clickedBbox.label);
            setLabelPosition({ x: clickedBbox.x + 10, y: clickedBbox.y + 10 });
            setEditMode(true); // เปิดโหมดแก้ไข
            setIsLabeling(true); // เปิดโหมดการใส่ Label
            return;
        }

        const { x, y } = mousePos;
        setIsDrawing(true);
        setEditMode(false);
        setCurrentBbox({ x, y, width: 0, height: 0, color: getRandomColor() });
    };

    const handleMouseMove = (e) => {
        if (!isDrawing || selectedBbox) return;

        const { x, y } = getMousePos(e);
        setCurrentBbox((bbox) => ({
            ...bbox,
            width: x - bbox.x,
            height: y - bbox.y
        }));
    };

    const handleMouseUp = () => {
        if (!isDrawing) return;

        if (currentBbox.width === 0 || currentBbox.height === 0) {
            Swal.fire({
                icon: 'warning',
                title: 'กรอบไม่ถูกต้อง',
                text: 'โปรดวาดกรอบที่ถูกต้อง.',
            });
            setIsDrawing(false);
            return;
        }

        setIsDrawing(false);
        setIsLabeling(true);

        const padding = 10;
        const newLabelPosition = {
            x: currentBbox.x + currentBbox.width + padding,
            y: currentBbox.y
        };

        setLabelPosition(newLabelPosition);
    };


    const handleEditSubmit = () => {
        if (selectedBbox) {
            const updatedBboxes = bboxes.map((bbox) =>
                bbox === selectedBbox ? { ...bbox, label: labelInput } : bbox
            );
            setBboxes(updatedBboxes);
            setEditMode(false);
            setSelectedBbox(null);
            setIsLabeling(false);
        }
    };


    const handleLabelSubmit = () => {
        if (!labelInput) {
            Swal.fire({
                icon: 'warning',
                title: 'ป้ายชื่อไม่ถูกต้อง',
                text: 'โปรดระบุป้ายชื่อสำหรับกรอบที่คุณวาด.',
            });
            return;
        }

        if (selectedBbox) {
            const updatedBboxes = bboxes.map((bbox) =>
                bbox === selectedBbox ? { ...bbox, label: labelInput } : bbox
            );
            setBboxes(updatedBboxes);
            setSelectedBbox(null);
        } else {
            setBboxes([...bboxes, { ...currentBbox, label: labelInput }]);
            setCurrentBbox(null);
        }

        setLabelInput("");
        setIsLabeling(false);
    };


    const handleBoxDelete = () => {
        if (selectedBbox) {
            const updatedBboxes = bboxes.filter(bbox => bbox !== selectedBbox);
            setBboxes(updatedBboxes);
            setSelectedBbox(null);
            setIsLabeling(false);
            setLabelInput("");
        } else if (currentBbox) {

            setCurrentBbox(null);
            setIsDrawing(false);
            setIsLabeling(false);
        }
    };

    const handleCancelLabeling = () => {
        setIsLabeling(false);
        setSelectedBbox(null);
        setCurrentBbox(null);
        setLabelInput("");
    };



    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas && detectedImage) {
            const ctx = canvas.getContext("2d");
            const img = new Image();
            img.src = detectedImage;
            console.log(detectedImage);
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, 0, 0);

                bboxes.forEach((bbox) => {
                    ctx.strokeStyle = bbox.color;
                    ctx.lineWidth = 4;
                    ctx.strokeRect(bbox.x, bbox.y, bbox.width, bbox.height);
                    ctx.font = "40px Arial";
                    ctx.fillStyle = bbox.color;
                    ctx.fillText(bbox.label, bbox.x + 5, bbox.y);
                });

                if (currentBbox) {
                    ctx.strokeStyle = currentBbox.color;
                    ctx.lineWidth = 4;
                    ctx.strokeRect(currentBbox.x, currentBbox.y, currentBbox.width, currentBbox.height);
                }
                if (selectedBbox) {
                    ctx.strokeStyle = "red";
                    ctx.lineWidth = 4;
                    ctx.setLineDash([5, 3]);
                    ctx.strokeRect(selectedBbox.x, selectedBbox.y, selectedBbox.width, selectedBbox.height);
                    ctx.setLineDash([]);
                }
            };
        }
    }, [detectedImage, bboxes, currentBbox, selectedBbox]);

    return (
        <div className="content-wrapper" style={{ padding: '20px' }}>
            <div className="row">
                <section className="col-12 col-md-8 mb-4" >
                    <div className="card" style={{ maxHeight: 'auto' }}>
                        <div className="card-header">
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <div>
                                    <button
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            cursor: 'pointer',
                                            padding: 0,
                                        }}
                                        onClick={() => window.history.back()}
                                    >
                                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>←</span>
                                    </button>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <h3 style={{
                                        margin: 0,
                                        fontSize: '30px',
                                        fontWeight: 'bold',
                                        color: '#00408E',
                                        width: '100%',
                                        textAlign: 'center'
                                    }}>
                                        Fine Tuning
                                    </h3>
                                </div>

                            </div>
                        </div>

                        <div className="card-body" style={{ position: "relative" }}>
                            {detectedImage ? (
                                <canvas
                                    ref={canvasRef}
                                    onMouseDown={handleMouseDown}
                                    onMouseMove={handleMouseMove}
                                    onMouseUp={handleMouseUp}
                                    style={{
                                        border: "2px solid #4A90E2",
                                        cursor: isLabeling ? "default" : "crosshair",
                                        width: "100%",
                                        height: "auto",
                                        borderRadius: "8px"
                                    }}
                                />
                            ) : (
                                <p>ไม่พบภาพสำหรับการปรับแต่ง</p>
                            )}
                            <div style={{ textAlign: 'right' }}>
                                {detectedImage ? detectedImage.split("/").pop() : "No Image"}
                            </div>
                            {isLabeling && (
                                <div
                                    style={{
                                        position: "absolute",
                                        left: `${labelPosition.x / 10}px`,
                                        top: `${labelPosition.y / 6}px`,
                                        background: "rgba(255, 255, 255, 0.9)",
                                        borderRadius: "5px",
                                        padding: "15px",
                                        zIndex: 10,
                                        boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
                                        width: "250px",
                                    }}
                                >
                                    <div style={{ marginBottom: "10px", fontSize: "18px" }}>
                                        {editMode ? "Edit Class" : "Add Class"}
                                    </div>

                                    <div>
                                        <select
                                            value={labelInput}
                                            onChange={(e) => setLabelInput(e.target.value)}
                                            style={{
                                                width: "100%",
                                                padding: "8px",
                                                fontSize: "14px",
                                                border: "1px solid #ccc",
                                                borderRadius: "6px",
                                                boxSizing: "border-box",
                                            }}
                                        >
                                            <option value="">Choose bar type</option>
                                            {availableClasses.map((item, index) => (
                                                <option key={index} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div
                                        style={{
                                            marginTop: "15px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            gap: "10px",
                                        }}
                                    >
                                        <button
                                            style={{
                                                flex: 1,
                                                padding: "10px",
                                                backgroundColor: "#00408E",
                                                color: "#fff",
                                                border: "none",
                                                borderRadius: "6px",
                                                fontSize: "14px",
                                                cursor: "pointer",
                                                fontWeight: "bold",
                                            }}
                                            onClick={() => {
                                                if (editMode) {
                                                    handleEditSubmit();
                                                } else {
                                                    handleLabelSubmit();
                                                }
                                            }}
                                        >
                                            {editMode ? "Save" : "Confirm"}
                                        </button>

                                        <button
                                            style={{
                                                flex: 1,
                                                padding: "10px",
                                                backgroundColor: "#E73535",
                                                color: "#fff",
                                                border: "none",
                                                borderRadius: "6px",
                                                fontSize: "14px",
                                                cursor: "pointer",
                                                fontWeight: "bold",
                                            }}
                                            onClick={handleCancelLabeling}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            )}

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    gap: "10px",
                                    width: "100%",
                                }}
                            >
                                <label
                                    htmlFor="newClassInput"
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Add name class
                                </label>

                                <div
                                    style={{
                                        display: "flex",
                                        gap: "10px",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <input
                                        id="newClassInput"
                                        type="text"
                                        placeholder="Enter class name"
                                        value={newClassInput}
                                        onChange={(e) => setNewClassInput(e.target.value)}
                                        style={{
                                            flex: "1",
                                            padding: "10px",
                                            border: "1px solid #ccc",
                                            borderRadius: "4px",
                                            fontSize: "16px",
                                            minWidth: "200px",
                                            maxWidth: "300px",
                                        }}
                                    />

                                    <button
                                        onClick={handleAddClass}
                                        style={{
                                            padding: "8px 20px",
                                            backgroundColor: "#00408E",
                                            color: "#fff",
                                            border: "none",
                                            borderRadius: "4px",
                                            cursor: "pointer",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            minWidth: "80px",
                                        }}
                                    >
                                        Add
                                    </button>

                                    <button
                                        style={{
                                            padding: "8px 20px",
                                            backgroundColor: "#E73535",
                                            color: "#fff",
                                            border: "none",
                                            borderRadius: "4px",
                                            cursor: "pointer",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            minWidth: "80px",
                                        }}
                                        onClick={handleBoxDelete}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="col-12 col-md-4 mb-4" >
                    <div className="card" style={{ height: '100%' }}>
                        <div className="card-body">
                            <Tooltip title={`Accuracy Score: ${Math.floor(averageConfidence)}%`}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%",
                                        padding: "20px",
                                    }}
                                >

                                    <Progress
                                        type="circle"
                                        percent={Math.floor(overallAccuracy)}
                                        strokeColor={{
                                            "0%": "#FF0000",
                                            "25%": "#FFA500",
                                            "50%": "#FFFF00",
                                            "100%": "#00FF00",
                                        }}
                                        trailColor="#e0e0e0"
                                        strokeWidth={14}
                                        width={window.innerWidth < 768 ? 150 : 200}
                                        format={(percent) => (
                                            <span style={{ fontSize: window.innerWidth < 768 ? "24px" : "30px" }}>
                                                {Math.floor(percent)}%
                                            </span>
                                        )}
                                    />


                                    <h4
                                        style={{
                                            fontWeight: "bold",
                                            color: "#333",
                                            marginTop: "15px",
                                            fontSize: window.innerWidth < 768 ? "16px" : "25px", // ฟอนต์ที่ปรับตามหน้าจอ
                                            textAlign: "center",
                                        }}
                                    >
                                        Overall Accuracy
                                    </h4>
                                </div>
                            </Tooltip>

                            <div style={{ marginBottom: '15px' }}>
                                <h5
                                    style={{
                                        color: '#333',
                                        fontSize: '24px',
                                        marginBottom: '15px',
                                        textAlign: 'left',
                                    }}
                                >
                                    Object Counts
                                </h5>
                                {Object.entries(classCounts).map(([label, count], index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            padding: '16px',
                                            backgroundColor: '#FFFFFF',
                                            borderRadius: '10px',
                                            marginBottom: '16px',
                                            border: '1px solid #ddd',
                                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                            cursor: 'pointer',
                                        }}
                                        onMouseEnter={(e) => {
                                            e.currentTarget.style.transform = 'scale(1.01)';
                                            e.currentTarget.style.boxShadow = '0 6px 16px rgba(0, 0, 0, 0.15)';
                                        }}
                                        onMouseLeave={(e) => {
                                            e.currentTarget.style.transform = 'scale(1)';
                                            e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.1)';
                                        }}
                                    >

                                        <div>
                                            <span
                                                style={{
                                                    fontWeight: 'bold',
                                                    color: '#00408E',
                                                    fontSize: '24px',
                                                }}
                                            >
                                                {label}
                                            </span>
                                        </div>


                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '32px',
                                                color: '#333',
                                            }}
                                        >
                                            {count}
                                        </span>
                                    </div>
                                ))}

                            </div>

                        </div>
                    </div>
                </section>

                <section className="col-12 col-md-12">
                    <div className="card">
                        <div className="card-header text-left" style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <BarChartOutlined style={{ fontSize: "35px", color: "#000000" }} />
                            <strong style={{ fontSize: "34px", color: "#000000" }}>Detection</strong>
                        </div>
                        <div className="card-body">
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                                    gap: "15px",
                                }}
                            >
                                {bboxesToDisplay.map((bbox, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            padding: "15px",
                                            backgroundColor: "#fff",
                                            borderRadius: "8px",
                                            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                                            border: `2px solid ${bbox.color}`,
                                            position: "relative",
                                            transition: "transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease",
                                        }}
                                        onMouseEnter={(e) => {
                                            e.currentTarget.style.transform = "scale(1.02)";
                                            e.currentTarget.style.boxShadow = "0 6px 12px rgba(0, 0, 0, 0.2)";
                                            e.currentTarget.style.backgroundColor = `${bbox.color}33`;
                                        }}
                                        onMouseLeave={(e) => {
                                            e.currentTarget.style.transform = "scale(1)";
                                            e.currentTarget.style.boxShadow = "0 2px 5px rgba(0,0,0,0.1)";
                                            e.currentTarget.style.backgroundColor = "#fff";
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    backgroundColor: bbox.color,
                                                    borderRadius: "50%",
                                                    marginRight: "10px",
                                                }}
                                            ></div>
                                            <div
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "24px",
                                                    color: "#333",
                                                }}
                                            >
                                                {bbox.label}
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                fontSize: "16px",
                                                color: "#555",
                                            }}
                                        >
                                            <div style={{ marginBottom: "5px", fontSize: "18px" }}>
                                                <span>Confidence:</span>{" "}
                                                {bbox.confidence
                                                    ? (bbox.confidence * 100).toFixed(2) + "%"
                                                    : "N/A"}
                                            </div>
                                            <div style={{ fontSize: "18px" }}>
                                                <span>Position:</span>{" "}
                                                x: {bbox.x.toFixed(2)}, y: {bbox.y.toFixed(2)}
                                            </div>
                                        </div>

                                        <DeleteOutlined
                                            style={{
                                                position: "absolute",
                                                top: "10px",
                                                right: "10px",
                                                fontSize: "20px",
                                                color: "#ff4d4f",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                const updatedBboxes = bboxes.filter((_, i) => i !== index);
                                                setBboxes(updatedBboxes);
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>


                            <div className="d-flex justify-content-center mt-4">
                                <button
                                    className="btn btn-secondary mx-2"
                                    disabled={currentPage === 1}
                                    onClick={() => handlePageChange(currentPage - 1)}
                                >
                                    Previous
                                </button>

                                <div
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        display: "flex",
                                        alignItems: "center",
                                        overflowX: "auto",
                                        gap: "5px",
                                    }}
                                >
                                    {Array.from({ length: totalPages }, (_, idx) => {
                                        if (
                                            idx + 1 === 1 ||
                                            idx + 1 === totalPages ||
                                            (idx + 1 >= currentPage - 2 && idx + 1 <= currentPage + 2)
                                        ) {
                                            return (
                                                <button
                                                    key={idx}
                                                    className={`btn ${currentPage === idx + 1
                                                        ? "btn-primary selected-page"
                                                        : "btn-outline-primary"
                                                        }`}
                                                    onClick={() => handlePageChange(idx + 1)}
                                                >
                                                    {idx + 1}
                                                </button>
                                            );
                                        }


                                        if (
                                            idx + 1 === currentPage - 3 ||
                                            idx + 1 === currentPage + 3
                                        ) {
                                            return (
                                                <span key={idx} style={{ margin: "0 5px" }}>
                                                    ...
                                                </span>
                                            );
                                        }

                                        return null;
                                    })}
                                </div>

                                <button
                                    className="btn btn-secondary mx-2"
                                    disabled={currentPage === totalPages}
                                    onClick={() => handlePageChange(currentPage + 1)}
                                >
                                    Next
                                </button>
                            </div>


                            <div className="mt-4 text-center">
                                {showProgress && (
                                    <div className="mb-3">
                                        <p className="mb-2">Adjusting Model... {progress}%</p>
                                        <progress
                                            value={progress}
                                            max="100"
                                            style={{
                                                width: "100%",
                                                height: "20px",
                                                borderRadius: "10px",
                                            }}
                                        ></progress>
                                    </div>
                                )}
                                <button
                                    className={`btn btn-block ${approveDisabled ? "btn-secondary" : "btn-success"
                                        }`}
                                    onClick={handleApprove}
                                    disabled={approveDisabled}
                                    style={{
                                        backgroundColor: "#00408E",
                                        color: "#fff",
                                        fontSize: "17px",
                                    }}
                                >
                                    Approve
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    );
}

export default Counting_FineTuning;
