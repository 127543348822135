import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import BarChart from "./BarChart";
import { Button, Card, DatePicker, Modal, Tooltip } from "antd";
import DoughnutChart from "./DoughnutChart";
import img_header_ticket from "../../../assets/img/IMG_Ticket_Management/img_header_ticket.png";
import Select from "react-select";
import { getOem, getToken, getUser } from "../../../Utils/Common";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../config";
import Table_All_Project from "./table_dashboard";
import moment from "moment";
import dayjs from "dayjs";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    fontSize: 16,
  }),
};

const data_severity_db = [
  {
    project_no: 'test1',
    minor: '0',
    average: '2',
    major: '1',
    critical: '0',
    no_status: '0',
    sum: '0'
  },
  {
    project_no: 'test2',
    minor: '3',
    average: '0',
    major: '1',
    critical: '0',
    no_status: '0',
    sum: '0'
  },
  {
    project_no: 'test3',
    minor: '0',
    average: '0',
    major: '4',
    critical: '1',
    no_status: '0',
    sum: '0'
  },
  {
    project_no: 'test4',
    minor: '0',
    average: '5',
    major: '0',
    critical: '1',
    no_status: '0',
    sum: '0'
  },
  {
    project_no: 'test1',
    minor: '0',
    average: '2',
    major: '2',
    critical: '0',
    no_status: '0',
    sum: '0'
  },
  {
    project_no: 'test2',
    minor: '0',
    average: '3',
    major: '0',
    critical: '1',
    no_status: '0',
    sum: '0'
  }
]

/* พบโปรแกรม Error (Bug)
พบฐานข้อมูลไม่ถูกต้อง
Config Customer site ไม่ตรง Site test
ผู้ใช้ไม่เข้าใจระบบ
ปัญหาเครื่องลูกข่าย
ให้คำปรึกษาแนะนำระบบ
สนับสนุนอื่นๆ
ปัญหาเครื่องแม่ข่าย */
const relation_key_ticket_type = [
  { id: '8a6963da-7b5b-4947-9cbb-17827cec8bac', key: 'error_bug' },
  { id: '6bffd6a0-2c31-4b3b-8a88-9669a0f4d2b2', key: 'invalid_database' },
  { id: '23a2993c-49ad-4edd-b65d-c73d9ae94657', key: 'config_cus_site' },
  { id: '5e291a16-64b8-485f-964f-ab97b4ad2596', key: 'user_not_understand' },
  { id: '818e7479-00ba-486a-a0a6-88858102e375', key: 'problem_child' },
  { id: 'bdbc1d7c-f109-4751-84a7-dd5fcdf72ad3', key: 'advice_system' },
  { id: 'ffd74165-fb9b-4416-9627-85dcbb938e4e', key: 'support_other' },
  { id: '35ada794-c94a-4525-a62c-fb970923a54b', key: 'problem_parent' },
]

function Dashboard_Ticket(params) {
  const { RangePicker } = DatePicker;

  const bar1Ref = useRef(null);
  const bar2Ref = useRef(null);
  const donut1Ref = useRef(null);
  const donut2Ref = useRef(null);

  const [roleUser, setRoleUser] = useState({ id: '' });

  const [isResponse, setIsResponse] = useState({
    lg: false
  });

  const [countChart, setCountChart] = useState({
    bar1: 0,
    bar2: 0,
    donut1: 0,
    donut2: 0,
    team_mgt: 0,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalMode, setIsModalMode] = useState("");
  const showModal = (key) => {
    handleSetState(key, 'current', 1);
    handleSetState(key, 'pageSize', 10);
    setIsModalMode(key);
    GetDataModal(key);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [configTable, setConfigTable] = useState({
    bar1: {
      key: 'bar1',
      current: 1,
      pageSize: 10,
      cardData: data_severity_db,
      onPageChange: () => {},
      total: data_severity_db.length,
      totalSum: 0,
      totalTicket: 0,
      minWidth: "650px"
    },
    bar2: {
      key: 'bar2',
      current: 1,
      pageSize: 10,
      cardData: [],
      onPageChange: () => {},
      total: 0,
      totalSum: 0,
      totalTicket: 0,
      minWidth: "1000px",
      headerTable: []
    },
    donut1: {
      key: 'donut1',
      current: 1,
      pageSize: 10,
      cardData: [],
      onPageChange: () => {},
      total: 0,
      totalTicket: 0,
      remain_ticket: 0,
      in_progress: 0,
      fail_sla: 0,
      minWidth: "650px",
      headerTable: [
        { value: '#', key: 'no_', col: '1' },
        { value: 'Project', key: 'project', col: '5' },
        { value: 'Remain Ticket', key: 'remain_ticket', col: '2' },
        { value: 'Fail (กำลังดำเนินการ ไม่ผ่านเกณฑ์ SLA)', key: 'fail_sla', col: '2' },
        { value: 'In progress', key: 'in_progress', col: '2' },
      ]
    },
    donut2: {
      key: 'donut2',
      current: 1,
      pageSize: 10,
      cardData: [],
      onPageChange: () => {},
      total: 0,
      totalTicket: 0,
      complete_ticket: 0,
      pass_sla: 0,
      fail_sla: 0,
      minWidth: "650px",
      headerTable: [
        { value: '#', key: 'no_', col: '1' },
        { value: 'Project', key: 'project', col: '5' },
        { value: 'Complete Ticket', key: 'complete_ticket', col: '2' },
        { value: 'Pass (SLA)', key: 'pass_sla', col: '2' },
        { value: 'Fail (กำลังดำเนินการ ไม่ผ่านเกณฑ์ SLA)', key: 'fail_sla', col: '2' },
      ]
    },
    team_mgt: {
      key: 'team_mgt',
      current: 1,
      pageSize: 10,
      cardData: [],
      onPageChange: () => {},
      total: 0,
      totalTicket: 0,
      minWidth: "500px",
      headerTable: [
        { value: '#', key: 'no_', col: '1' },
        { value: 'Responsible person', key: 'fullname', col: '3' },
        { value: 'Ticket Amount', key: 'amount', col: '2', color: '#007BFF' },
        { value: 'Pass (SLA)', key: 'pass_sla', col: '1', color: '#47BA6F' },
        { value: 'Fail (SLA)', key: 'fail_sla', col: '1', color: '#DD2025' },
        { value: 'In progress', key: 'in_process', col: '2', color: '#FF9900' },
        { value: 'Sum', key: 'sum_', col: '2' },
      ]
    }
  })

  const [bar1, setBar1] = useState({
    lables: [`Minor`, "Average", "Major", "Critical", "No status"],
    backgroundColor: ["#888888", "#FFA3A3", "#FFD54F", "#F44336", "#42A5F5"],
    data: [0, 0, 0, 0, 0],
    borderRadius: 10,
    barThickness: 30,
    isStyleLabel: false,
    // max: 25,
    count: 1,
    stepSize: 5
  });

  const [chartData, setChartData] = useState({
    labels: bar1.lables,
    datasets: [
      {
        label: '',
        backgroundColor: bar1.backgroundColor,
        borderColor: bar1.backgroundColor,
        // borderWidth: 1,
        borderRadius: bar1.borderRadius,
        data: bar1.data,
        barThickness: bar1.barThickness,
      },
    ],
  });

  const [bar2, setBar2] = useState({
    lables: [
      /* `(Bug)`,
      "Incorrect data",
      "Config site ",
      "User",
      "Server",
      "Client",
      "Advise",
      "Support",
      "UX/UI",
      "Requirement Change", */
    ],
    backgroundColor: ["#007BFF", "#007BFF", "#007BFF", "#007BFF", "#007BFF"],
    borderColor: ["#007BFF", "#007BFF", "#007BFF", "#007BFF", "#007BFF"],
    data: [/* 15, 8, 6, 9, 15, 15, 8, 6, 9, 15 */],
    borderRadius: 10,
    barThickness: 30,
    isStyleLabel: false,
    // max: 30,
    stepSize: 5,
  });

  const [chartData2, setChartData2] = useState({
    labels: bar2.lables,
    datasets: [
      {
        label: '',
        backgroundColor: bar2.backgroundColor,
        borderColor: bar2.backgroundColor,
        borderRadius: bar2.borderRadius,
        data: bar2.data,
        barThickness: bar2.barThickness,
      },
    ],
  });

  const [donutChartData, setDonutChartData] = useState({
    labels: [
      'Remain Ticket',
      'In progress',
      'Fail ( กำลังดำเนินการ ไม่ผ่าน SLA)'
    ],
    datasets: [{
      label: 'My First Dataset',
      data: [0, 0, 0],
      backgroundColor: [
        '#4072EC',
        '#FEC400',
        '#DD2025'
      ],
      hoverBackgroundColor: [
        '#4072EC95',
        '#FEC40095',
        '#DD202595'
      ],
      hoverBorderColor: [
        '#4072EC',
        '#FEC400',
        '#DD2025'
      ],
      hoverOffset: 4
    }]
  })
  
  const [donutChartData2, setDonutChartData2] = useState({
    labels: [
      'Complete Ticket',
      'Pass (SLA)',
      'Fail (ดำเนินการเรียบร้อย ไม่ผ่าน SLA)'
    ],
    datasets: [{
      label: 'My First Dataset',
      data: [0, 0, 0],
      backgroundColor: [
        '#4072EC',
        '#47BA6F',
        '#DD2025'
      ],
      hoverBackgroundColor: [
        '#4072EC95',
        '#47BA6F95',
        '#DD202595'
      ],
      hoverBorderColor: [
        '#4072EC',
        '#47BA6F',
        '#DD2025'
      ],
      hoverOffset: 4
    }]
  })

  const [project_dropdown, setProject_dropdown] = useState({ 
    data: [],
    select_project: "",
    project_start_date: new Date(moment().startOf('year')),
    project_end_date: new Date(moment().endOf('year'))
  });

  function onChangeBar1(page) {
    handleSetState('bar1', 'current', page);
    GetDataModal('bar1');
  }

  function onShowSizeChangeBar1(current, size) {
    handleSetState('bar1', 'pageSize', size);
    GetDataModal('bar1');
  }

  function onChangeBar2(page) {
    handleSetState('bar2', 'current', page);
    GetDataModal('bar2');
  }

  function onShowSizeChangeBar2(current, size) {
    handleSetState('bar2', 'pageSize', size);
    GetDataModal('bar2');
  }

  function onChangeTeamMGT(page) {
    handleSetState('team_mgt', 'current', page);
  }

  function onShowSizeChangeTeamMGT(current, size) {
    handleSetState('team_mgt', 'pageSize', size);
  }

  function onChangeDonut1(page) {
    handleSetState('donut1', 'current', page);
    GetDataModal('donut1');
  }

  function onShowSizeChangeDonut1(current, size) {
    handleSetState('donut1', 'pageSize', size);
    GetDataModal('donut1');
  }

  function onChangeDonut2(page) {
    handleSetState('donut2', 'current', page);
    GetDataModal('donut2');
  }

  function onShowSizeChangeDonut2(current, size) {
    handleSetState('donut2', 'pageSize', size);
    GetDataModal('donut2');
  }

  function getColorText(name, is_bg) {
    let str_return = is_bg ? '#D2E8FA' : '#42A5F5'
    if (name === 'minor') {
      str_return = is_bg ? '#DBDBDB' : '#808080'
    } else if (name === 'average') {
      str_return = is_bg ? '#FFE7E8' : '#FF7B7F'
    } else if (name === 'major') {
      str_return = is_bg ? '#FEC40026' : '#FEC400'
    } else if (name === 'critical') {
      str_return = is_bg ? '#DD202526' : '#DD2025'
    }
    return str_return;
  }
  const styleHeaderTable = (name) => ({
    fontSize: '16px',
    backgroundColor: !name ? undefined : getColorText(name, true),
    padding: !name ? undefined : '2px 10px',
    borderRadius: !name ? undefined : '50px',
    color: !name ? undefined : getColorText(name),
  });

  const cardData = [], sumTotal = {
    minor: 0,
    average: 0,
    major: 0,
    critical: 0,
    no_status: 0,
  };
  if (isModalMode === 'bar1' /* table bar1 */) {
    cardData.push({
      id: '',
      content: (
        <div
          className="row"
          style={{ alignItems: "center", height: "100%", padding: '15px 0' }}
        >
          <div className="col-1">
            <span style={{ ...styleHeaderTable() }}>#</span>
          </div>
          <div className="col-2">
            <span style={{ ...styleHeaderTable() }}>Project No.</span>
          </div>
          <div style={{ textAlign: 'center' }} className="col-1">
            <span style={{ ...styleHeaderTable('minor') }}>Minor</span>
          </div>
          <div style={{ textAlign: 'center' }} className="col-2">
          <span style={{ ...styleHeaderTable('average') }}>Average</span>
          </div>
          <div style={{ textAlign: 'center' }} className="col-1">
          <span style={{ ...styleHeaderTable('major') }}>Major</span>
          </div>
          <div style={{ textAlign: 'center' }} className="col-2">
          <span style={{ ...styleHeaderTable('critical') }}>Critical</span>
          </div>
          <div style={{ textAlign: 'center' }} className="col-2">
          <span style={{ ...styleHeaderTable('no_status') }}>No Status</span>
          </div>
          <div style={{ textAlign: 'center' }} className="col-1">
          <span style={{ ...styleHeaderTable() }}>Sum</span>
          </div>
        </div>
      )
    })
    configTable.bar1.cardData.forEach(async (item, item_index) => {
      if (Number(item.minor)) 
        sumTotal.minor += Number(item.minor);

      if (Number(item.average)) 
        sumTotal.average += Number(item.average);

      if (Number(item.major)) 
        sumTotal.major += Number(item.major);

      if (Number(item.critical)) 
        sumTotal.critical += Number(item.critical);

      if (Number(item.no_status)) 
        sumTotal.no_status += Number(item.no_status);

      cardData.push({
        id: '',
        content: (
          <div
          className="row"
          style={{ alignItems: "center", height: "100%", padding: '10px 0px 30px 0px' }}
        >
          <div className="col-1">
            <span style={{  }}>{item_index + 1 + ((configTable.bar1.current - 1) * configTable.bar1.pageSize)}</span>
          </div>
          <div className="col-2" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Tooltip
              title={`${item.project_no}`}
              placement="top"
            >
              <span style={{ whiteSpace: 'nowrap' }}>{item.project_no}</span>
            </Tooltip>
            </div>
          <div style={{ textAlign: 'center', color: getColorText('minor') }} className="col-1">
            <span>{item.minor}</span>
          </div>
          <div style={{ textAlign: 'center', color: getColorText('average') }} className="col-2">
            <span>{item.average}</span>
          </div>
          <div style={{ textAlign: 'center', color: getColorText('major') }} className="col-1">
            <span>{item.major}</span>
          </div>
          <div style={{ textAlign: 'center', color: getColorText('critical') }} className="col-2">
            <span>{item.critical}</span>
          </div>
          <div style={{ textAlign: 'center', color: getColorText('no_status') }} className="col-2">
            <span>{item.no_status}</span>
          </div>
          <div style={{ textAlign: 'center' }} className="col-1">
            <span>{Number(item.minor) + Number(item.average) + Number(item.major) + Number(item.critical) + Number(item.no_status)}</span>
          </div>
        </div>
        )
      })
    })
    cardData.push({
      id: '',
      content: (
        <div
        className="row"
        style={{ alignItems: "center", height: "100%", padding: '15px 0' }}
      >
        <div className="col-1">
          <span style={{  }}></span>
        </div>
        <div className="col-2">
          <span style={{  }}>Sum</span>
        </div>
        <div style={{ textAlign: 'center', color: getColorText('minor') }} className="col-1">
          <span>{configTable.bar1.totalSum.count_minor || 0}</span>
        </div>
        <div style={{ textAlign: 'center', color: getColorText('average') }} className="col-2">
          <span>{configTable.bar1.totalSum.count_average || 0}</span>
        </div>
        <div style={{ textAlign: 'center', color: getColorText('major') }} className="col-1">
          <span>{configTable.bar1.totalSum.count_major || 0}</span>
        </div>
        <div style={{ textAlign: 'center', color: getColorText('critical') }} className="col-2">
          <span>{configTable.bar1.totalSum.count_critical || 0}</span>
        </div>
        <div style={{ textAlign: 'center', color: getColorText('no_status') }} className="col-2">
          <span>{configTable.bar1.totalSum.count_none || 0}</span>
        </div>
        <div style={{ textAlign: 'center' }} className="col-1">
          <span>{Number(configTable.bar1.totalSum.count_minor || 0) + Number(configTable.bar1.totalSum.count_average || 0) + Number(configTable.bar1.totalSum.count_major || 0) + Number(configTable.bar1.totalSum.count_critical || 0) + Number(configTable.bar1.totalSum.count_none || 0)}</span>
        </div>
      </div>
      )
    })
  }

  const cardData2 = [], sumTotal2 = {
    error_bug: 0,
    invalid_database: 0,
    config_cus_site: 0,
    user_not_understand: 0,
    problem_child: 0,
    advice_system: 0,
    support_other: 0,
    problem_parent: 0,
  };
  if (isModalMode === 'bar2' /* table bar2 */) {
    cardData2.push({
      id: '',
      content: (
        <div
          className=""
          style={{ display: 'flex', gap: '10px', alignItems: "start", height: "100%", padding: '15px 0' }}
        >
          {
            configTable.bar2.headerTable.map((item, item_index) => (
              <div 
                style={{ 
                  textAlign: item.value === 'Project No.' ? 'left' : 'center' ,
                  minWidth: item.value === '#' ? '100px' : item.value === 'Sum' ? '100px' : item.value === 'Project No.' ? '200px' : '100px' 
                }} 
                // className={`col-${item.value === '#' ? '1' : item.value === 'Sum' ? '1' : item.value === 'Project No.' ? '2' : '1' }`}
              >
                <span style={{ fontSize: '14px' }}>{item.value}</span>
              </div>
            ))
          }
        </div>
      )
    })
    let sum_total = 0;
    configTable.bar2.cardData.forEach(async (item, item_index) => {
      let sum_in_line = 0;
      cardData2.push({
        id: '',
        content: ( 
          <div
            className=""
            style={{ display: 'flex', gap: '10px', alignItems: "center", height: "100%", padding: '10px 0px 30px 0px' }}
          >
            {
              configTable.bar2.headerTable.map((head, head_index) => {
                if (typeof item[head.key] == "number") {
                  sum_in_line += Number(item[head.key] || 0);
                }
                if (sumTotal2[head.key] === undefined) sumTotal2[head.key] = 0;
                sumTotal2[head.key] += Number(item[head.key] || 0);

                sum_total += Number(item[head.key] || 0);
                // if (sumTotal2[head.key]) {
                // }

                return (
                  <div 
                    style={{ 
                      textAlign: head.value === 'Project No.' ? 'left' : 'center',
                      overflow: head.key === 'project_no_' ? 'hidden' : undefined,
                      textOverflow: head.key === 'project_no_' ? 'ellipsis' : undefined,
                      minWidth: item.value === '#' ? '100px' : item.value === 'Sum' ? '100px' : item.value === 'Project No.' || head.key === 'project_no_' ? '200px' : '100px' 
                    }} 
                    // className={`col-${head.value === '#' ? '1' : head.value === 'Sum' ? '1' : head.value === 'Project No.' ? '2' : '1' }`}
                  >
                    {
                      head.key === 'project_no_'
                      ? (
                        <Tooltip
                          title={`${item[head.key]}`}
                          placement="top"
                        >
                          <span style={{ whiteSpace: 'nowrap', fontSize: '14px' }}>{item[head.key]}</span>
                        </Tooltip>
                      )
                      : (
                          <span style={{ fontSize: '14px' }}>
                            {
                              head.key === 'no_' 
                              ? item_index + 1 + ((configTable.bar2.current - 1) * configTable.bar2.pageSize) 
                              : head.key === 'sum_' 
                                ? sum_in_line || 0
                                : item[head.key] || 0
                            }
                          </span>
                        )
                    }
                  </div>
                )
              })
            }
          </div>
        )
      })
    })
    cardData2.push({
      id: '',
      content: (
        <div
          className=""
          style={{ display: 'flex', gap: '10px', alignItems: "start", height: "100%", padding: '15px 0' }}
        >
          {
            configTable.bar2.headerTable.map((item, item_index) => (
              <div 
                style={{ 
                  textAlign: item.value === 'Project No.' ? 'left' : 'center' ,
                  minWidth: item.value === '#' ? '100px' : item.value === 'Sum' ? '100px' : item.value === 'Project No.' ? '200px' : '100px' 
                }} 
                // className={`col-${item.value === '#' ? '1' : item.value === 'Sum' ? '1' : item.value === 'Project No.' ? '2' : '1' }`}
              >
                <span style={{ fontSize: '14px' }}>
                  {item.value === '#' ? '' : item.value === 'Project No.' ? 'Sum' : item.value === 'Sum' ? configTable.bar2.totalSum[item.value] || 0 : configTable.bar2.totalSum[`${item.key}_sum`] || 0 }
                </span>
              </div>
            ))
          }
        </div>
      )
    })
  }

  const cardData3 = [], sumTotal3 = {
    amount: 0,
    pass_sla: 0,
    fail_sla: 0,
    in_process: 0,
    sum_: 0,
  };
  if (true /* table team_mgt */) {
    cardData3.push({
      id: '',
      content: (
        <div
          className="row"
          style={{ alignItems: "start", height: "100%", padding: '15px 0' }}
        >
          {
            configTable.team_mgt.headerTable.map((item, item_index) => (
              <div 
                style={{ textAlign: item.key === 'fullname' ? 'left' : 'center' }} 
                className={`col-${item.col}`}
              >
                <span style={{ fontSize: '14px' }}>{item.value}</span>
              </div>
            ))
          }
        </div>
      )
    })
    let sum_total = 0;
    configTable.team_mgt.cardData.forEach(async (item, item_index) => {
      let sum_in_line = 0;
      cardData3.push({
        id: '',
        content: ( 
          <div
            className="row"
            style={{ alignItems: "center", height: "100%", padding: '10px 0px 30px 0px' }}
          >
            {
              configTable.team_mgt.headerTable.map((head, head_index) => {
                if (sumTotal3[head.key] !== undefined && Number(item[head.key])) {
                  sum_in_line += Number(item[head.key]);
                  sumTotal3[head.key] += Number(item[head.key])
                  sum_total += Number(item[head.key]);
                }
                // console.log('item[head.key] :>> ', item[head.key]);
                return (
                  <div 
                    style={{ textAlign: head.key === 'fullname' ? 'left' : 'center', overflow: head.key === 'fullname' ? 'hidden' : undefined, textOverflow: head.key === 'fullname' ? 'ellipsis' : undefined}} 
                    className={`col-${head.col}`}
                  >
                    {
                      head.key === 'fullname'
                      ? (
                        <Tooltip
                          title={`${item[head.key]}`}
                          placement="top"
                        >
                          <span style={{ whiteSpace: 'nowrap', fontSize: '14px' }}>{item[head.key]}</span>
                        </Tooltip>
                      )
                      : (
                          <span style={{ fontSize: '14px', color: head.color ? head.color : undefined }}>
                            {
                              head.key === 'no_' 
                              ? item_index + 1 + ((configTable.team_mgt.current - 1) * configTable.team_mgt.pageSize) 
                              : head.key === 'sum_' 
                                ? item[head.key] ? (item[head.key].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' %') : (0 + ' %')
                                : item[head.key]
                            }
                          </span>
                        )
                    }
                  </div>
                )
              })
            }
          </div>
        )
      })
    })
    cardData3.push({
      id: '',
      content: (
        <div
          className="row"
          style={{ alignItems: "start", height: "100%", padding: '15px 0' }}
        >
          {
            configTable.team_mgt.headerTable.map((item, item_index) => (
              <div 
                style={{ textAlign: item.key === 'fullname' ? 'left' : 'center' }} 
                className={`col-${item.col}`}
              >
                <span style={{ fontSize: '14px', color: item.color ? item.color : undefined }}>
                  {item.key === 'no_' ? '' : item.key === 'fullname' ? 'Sum' : item.key === 'sum_' ? (isNaN(sumTotal3.pass_sla / sumTotal3.amount * 100) ? 0 : (sumTotal3.pass_sla / sumTotal3.amount * 100)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' %' : Number(sumTotal3[item.key] || 0)?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }
                </span>
              </div>
            ))
          }
        </div>
      )
    })
  }

  const cardData4 = [], sumTotal4 = {
    remain_ticket: 0,
    in_progress: 0,
    fail_sla: 0,
    sum_: 0,
  };
  if (isModalMode === 'donut1' /* table donut1 */) {
    cardData4.push({
      id: '',
      content: (
        <div
          className="row"
          style={{ alignItems: "start", height: "100%", padding: '15px 0' }}
        >
          {
            configTable.donut1.headerTable.map((item, item_index) => (
              <div 
                style={{ textAlign: item.key === 'project' ? 'left' : 'center' }} 
                className={`col-${item.col}`}
              >
                <span style={{ fontSize: '14px' }}>{item.value}</span>
              </div>
            ))
          }
        </div>
      )
    })
    let sum_total = 0;
    configTable.donut1.cardData.forEach(async (item, item_index) => {
      let sum_in_line = 0;
      cardData4.push({
        id: '',
        content: ( 
          <div
            className="row"
            style={{ alignItems: "center", height: "100%", padding: '10px 0px 30px 0px' }}
          >
            {
              configTable.donut1.headerTable.map((head, head_index) => {
                /* if (sumTotal4[head.key] !== undefined && Number(item[head.key])) {
                  sum_in_line += Number(item[head.key]);
                  sumTotal4[head.key] += Number(item[head.key])
                  sum_total += Number(item[head.key]);
                } */

                return (
                  <div 
                    style={{ textAlign: head.key === 'project' ? 'left' : 'center', overflow: head.key === 'project' ? 'hidden' : undefined, textOverflow: head.key === 'project' ? 'ellipsis' : undefined}} 
                    className={`col-${head.col}`}
                  >
                    {
                      head.key === 'project'
                      ? (
                        <Tooltip
                          title={`${item[head.key]}`}
                          placement="top"
                        >
                          <span style={{ whiteSpace: 'nowrap', fontSize: '14px' }}>{item[head.key]}</span>
                        </Tooltip>
                      )
                      : (
                          <span style={{ fontSize: '14px', color: head.color ? head.color : undefined }}>
                            {
                              head.key === 'no_' 
                              ? item_index + 1 + ((configTable.donut1.current - 1) * configTable.donut1.pageSize) 
                              : head.key === 'sum_' 
                                ? (item[head.key]).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' %'
                                : item[head.key]
                            }
                          </span>
                        )
                    }
                  </div>
                )
              })
            }
          </div>
        )
      })
    })

    cardData4.push({
      id: '',
      content: (
        <div
          className="row"
          style={{ alignItems: "start", height: "100%", padding: '15px 0' }}
        >
          {
            configTable.donut1.headerTable.map((item, item_index) => (
              <div 
                style={{ textAlign: item.key === 'project' ? 'left' : 'center' }} 
                className={`col-${item.col}`}
              >
                <span style={{ fontSize: '14px', color: item.color ? item.color : undefined }}>
                  {item.key === 'no_' ? '' : item.key === 'project' ? 'Sum' : Number(configTable.donut1[item.key])?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }
                </span>
              </div>
            ))
          }
        </div>
      )
    })

    cardData4.push({
      id: '',
      content: (
        <div
          className="row"
          style={{ alignItems: "start", height: "100%", padding: '15px 0' }}
        >
          {
            configTable.donut1.headerTable.map((item, item_index) => (
              <div 
                style={{ textAlign: item.key === 'project' ? 'left' : 'center' }} 
                className={`col-${item.col}`}
              >
                <span style={{ fontSize: '14px', color: item.color ? item.color : undefined }}>
                  {item.key === 'no_' ? '' : item.key === 'project' ? 'Percentage' :  Number(configTable.donut1[item.key] / countChart.bar2 * 100)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' %' }
                </span>
              </div>
            ))
          }
        </div>
      )
    })
  }

  const cardData5 = [], sumTotal5 = {
    complete_ticket: 0,
    pass_sla: 0,
    fail_sla: 0,
    sum_: 0,
  };
  // console.log('isModalMode :>> ', isModalMode);
  if (isModalMode === 'donut2' /* table donut2 */) {
    cardData5.push({
      id: '',
      content: (
        <div
          className="row"
          style={{ alignItems: "start", height: "100%", padding: '15px 0' }}
        >
          {
            configTable.donut2.headerTable.map((item, item_index) => (
              <div 
                style={{ textAlign: item.key === 'project' ? 'left' : 'center' }} 
                className={`col-${item.col}`}
              >
                <span style={{ fontSize: '14px' }}>{item.value}</span>
              </div>
            ))
          }
        </div>
      )
    })
    let sum_total = 0;
    configTable.donut2.cardData.forEach(async (item, item_index) => {
      let sum_in_line = 0;
      cardData5.push({
        id: '',
        content: ( 
          <div
            className="row"
            style={{ alignItems: "center", height: "100%", padding: '10px 0px 30px 0px' }}
          >
            {
              configTable.donut2.headerTable.map((head, head_index) => {
                if (sumTotal5[head.key] !== undefined && Number(item[head.key])) {
                  sum_in_line += Number(item[head.key]);
                  sumTotal5[head.key] += Number(item[head.key])
                  sum_total += Number(item[head.key]);
                }

                return (
                  <div 
                    style={{ textAlign: head.key === 'project' ? 'left' : 'center', overflow: head.key === 'project' ? 'hidden' : undefined, textOverflow: head.key === 'project' ? 'ellipsis' : undefined}} 
                    className={`col-${head.col}`}
                  >
                    {
                      head.key === 'project'
                      ? (
                        <Tooltip
                          title={`${item[head.key]}`}
                          placement="top"
                        >
                          <span style={{ whiteSpace: 'nowrap', fontSize: '14px' }}>{item[head.key]}</span>
                        </Tooltip>
                      )
                      : (
                          <span style={{ fontSize: '14px', color: head.color ? head.color : undefined }}>
                            {
                              head.key === 'no_' 
                              ? item_index + 1 + ((configTable.donut2.current - 1) * configTable.donut2.pageSize) 
                              : head.key === 'sum_' 
                                ? item[head.key].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' %'
                                : item[head.key]
                            }
                          </span>
                        )
                    }
                  </div>
                )
              })
            }
          </div>
        )
      })
    })

    cardData5.push({
      id: '',
      content: (
        <div
          className="row"
          style={{ alignItems: "start", height: "100%", padding: '15px 0' }}
        >
          {
            configTable.donut2.headerTable.map((item, item_index) => (
              <div 
                style={{ textAlign: item.key === 'project' ? 'left' : 'center' }} 
                className={`col-${item.col}`}
              >
                <span style={{ fontSize: '14px', color: item.color ? item.color : undefined }}>
                  {item.key === 'no_' ? '' : item.key === 'project' ? 'Sum' :  Number(configTable.donut2[item.key])?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }
                </span>
              </div>
            ))
          }
        </div>
      )
    })

    cardData5.push({
      id: '',
      content: (
        <div
          className="row"
          style={{ alignItems: "start", height: "100%", padding: '15px 0' }}
        >
          {
            configTable.donut2.headerTable.map((item, item_index) => (
              <div 
                style={{ textAlign: item.key === 'project' ? 'left' : 'center' }} 
                className={`col-${item.col}`}
              >
                <span style={{ fontSize: '14px', color: item.color ? item.color : undefined }}>
                  {item.key === 'no_' ? '' : item.key === 'project' ? 'Percentage' :  Number(configTable.donut2[item.key] / countChart.bar2 * 100)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' %' }
                </span>
              </div>
            ))
          }
        </div>
      )
    })
  }

  function handleSetState(key1, key2, value) {
    const _configTable = {...configTable};
    _configTable[key1][key2] = value;
    setConfigTable(_configTable);
    configTable[key1][key2] = value;
  }

  async function CheckRolePage() {
    const id = JSON.parse(sessionStorage.getItem("user"));
    return axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/getByidChckeRole/${id.emp_id}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: {
        oem_id: getOem(),
        com_id: getUser().com
      }
    })
      .then(function (response) {
        // console.log("response: ", response.data[0].iu_ps_id);
        if (
          response.data.length > 0
        ) {
          setRoleUser({ id: response.data[0].iu_ps_id });
          roleUser.id = response.data[0].iu_ps_id;
          return response.data[0].iu_ps_id;
        } else {
          return false;
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
        return false;
      });
  }

  async function GetDataModal(key) {
    Swal.fire({
      title: "ระบบ",
      text: `ระบบกำลังอัพเดตข้อมูล`,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    if (key === 'bar1') {
      await GetSeverityAllForDashboard();
    } else if (key === 'bar2') {
      await getAllTicketType();
      await GetTypeTicketAllForDashboard();
    } else if (key === 'donut1') {
      await GetRemainTicketAllForDashboard();
    } else if (key === 'donut2') {
      await GetCompleteTicketAllForDashboard();
    }
    Swal.close()
  }

  async function GetDropDownProject(role_id) {
    Swal.fire({
      title: "ระบบ",
      text: `ระบบกำลังอัพเดตข้อมูล`,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    const sessionDataString = sessionStorage.getItem("user");
    const sessionData = JSON.parse(sessionDataString);
    const fupValue = sessionData.emp_id;

    let temp = {
      user_id: fupValue,
      role_id,
      oem_id: getOem(),
      com_id: getUser().com
    };
    await axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/GetDropDownProject`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        if (Array.isArray(response.data) && response.data.length > 0) {
          let select_project = null;
          const _data = response.data.map((el, index) => {
            // if (!select_project) select_project = el.pr_id;
            return { value: el.pr_id, label: el.pr_project_code };
          })
          _data.unshift({ value: null, label: 'All' });
          setProject_dropdown({
            ...project_dropdown,
            data: _data,
            select_project
          });
          project_dropdown.data = _data;
          project_dropdown.select_project = select_project;
        }
        Swal.close();
        return true
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
        return false
      });
  }

  async function GetSeverityAllForDashboard() {
    await axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/GetSeverityAllForDashboard`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: { 
        pr_id: project_dropdown.select_project,
        current: configTable.bar1.current || 1,
        pageSize: configTable.bar1.pageSize || 10,
        start_date: project_dropdown.project_start_date,
        end_date: project_dropdown.project_end_date,
        user_id: getUser().emp_id,
        role_id: roleUser.id,
        oem_id: getOem(),
        com_id: getUser().com
      },
    })
      .then(function (response) {
        if (Array.isArray(response.data.data)) {
          const _data = response.data.data.map(item => {
            return {
              project_no: `${item.pr_project_code}-${item.pr_name}`,
              minor: item.count_minor,
              average: item.count_average,
              major: item.count_major,
              critical: item.count_critical,
              no_status: item.count_none
            }
          });
          const _configTable = { ...configTable };
          _configTable.bar1.cardData = _data;
          _configTable.bar1.totalSum = response.data.data_sum;
          _configTable.bar1.total = response.data.count;
          _configTable.bar1.totalTicket = response.data.number_ticket;
          setConfigTable(_configTable);
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  async function GetSeverityForDashboard() {
    await axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/GetSeverityForDashboard`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: { 
        pr_id: project_dropdown.select_project,
        start_date: project_dropdown.project_start_date,
        end_date: project_dropdown.project_end_date,
        user_id: getUser().emp_id,
        role_id: roleUser.id,
        oem_id: getOem(),
        com_id: getUser().com
      },
    })
      .then(function (response) {
        if (Array.isArray(response.data) && response.data.length > 0) {
          const _data = response.data[0];
          const dataset_data = [Number(_data.count_minor), Number(_data.count_average), Number(_data.count_major), Number(_data.count_critical), Number(_data.count_none)];
          const coutSum = Number(_data.count_minor) + Number(_data.count_average) + Number(_data.count_major) + Number(_data.count_critical) + Number(_data.count_none);
          // console.log('object :>> ', Math.max(...dataset_data));
          setCountChart({
            ...countChart,
            bar1: coutSum
          })
          countChart.bar1 = coutSum;

          const _chartData = { ...chartData };
          _chartData.datasets[0].data = dataset_data;
          let max = Math.max(...dataset_data);
          if (max > 25) {
            max *= 1.25;
            setBar1({
              ...bar1,
              max: max,
              stepSize: max / 5
            })
            bar1.max = max;
            bar1.stepSize = max / 5;
          }
          // _chartData.datasets = dataset_data;
          setChartData(_chartData);
          if (bar1Ref.current) {
            bar1Ref.current.data.datasets[0].data = dataset_data;
            bar1Ref.current.update();
          }
        }
        
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }
  
  async function GetTypeTicketForDashboard() {
    await axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/GetTypeTicketForDashboard`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: { 
        pr_id: project_dropdown.select_project,
        start_date: project_dropdown.project_start_date,
        end_date: project_dropdown.project_end_date,
        user_id: getUser().emp_id,
        role_id: roleUser.id,
        oem_id: getOem(),
        com_id: getUser().com
      },
    })
      .then(function (response) {
        if (Array.isArray(response.data) && response.data.length > 0) {
          const _data = response.data;
          const _chartData = { ...chartData2 };
          let coutSum = 0;

          _chartData.labels = [];
          _chartData.datasets[0].data = [];
          let max = 0;
          for (let item of _data) {
            _chartData.labels.push(item.tt_name);
            _chartData.datasets[0].data.push(Number(item.count_type));
            coutSum += Number(item.count_type);
            max = Math.max(max, Number(item.count_type));
          }
          // console.log('max :>> ', max);
          if (max > 25) {
            max *= 1.25;
            setBar2({
              ...bar2,
              max: max,
              stepSize: max / 5
            });
            bar2.max = max;
            bar2.stepSize = max / 5;
          }
          setCountChart({
            ...countChart,
            bar2: coutSum
          })
          countChart.bar2 = coutSum;

          setChartData2(_chartData);
          if (bar2Ref.current) {
            bar2Ref.current.data = _chartData;
            bar2Ref.current.update();
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  async function getAllTicketType() {
    await axios({
      method: "get",
      url: `${Configs.API_Ticket}/api/ticket_type/getAllTicketTypeDashboard`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        if (Array.isArray(response.data) && response.data.length > 0) {
          const _configTable = { ...configTable };
          // _configTable.bar2.headerTable 
          const _data = response.data.map(item => {

            // const _key = relation_key_ticket_type.find(el => el.id === item.tt_id)
            return { value: item.tt_name, key: item.tt_id };
          });

          _data.unshift({ value: 'Project No.', key: 'project_no_' });
          _data.unshift({ value: '#', key: 'no_' });
          _data.push({ value: 'Sum', key: 'sum_' });

          _configTable.bar2.headerTable = _data;
          setConfigTable(_configTable);
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  async function GetTypeTicketAllForDashboard() {
    await axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/GetTypeTicketAllForDashboard`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: {
        pr_id: project_dropdown.select_project,
        current: configTable.bar2.current || 1,
        pageSize: configTable.bar2.pageSize || 10,
        start_date: project_dropdown.project_start_date,
        end_date: project_dropdown.project_end_date,
        user_id: getUser().emp_id,
        role_id: roleUser.id,
        oem_id: getOem(),
        com_id: getUser().com
      },
    })
      .then(function (response) {
        if (Array.isArray(response.data.data)) {
          let itemSumObj = {}, totalSum = 0;

          response.data.data_sum.forEach(item => {
            if (!itemSumObj[`${item.tt_id}_sum`]) itemSumObj[`${item.tt_id}_sum`] = 0;
            totalSum += Number(item.count_data);
            itemSumObj[`${item.tt_id}_sum`] += Number(item.count_data);
          });

          const _data = response.data.data.map(item => {
            let itemObj = {};
            item.data_list.forEach(item2 => { 
              itemObj[item2.tt_id] = item2.count;

            });
            return {
              project_no_: `${item.pr_project_code}-${item.pr_name}`,
              ...itemObj,
            }
          });
          itemSumObj['Sum'] = totalSum
          // console.log('_data :>> ', _data);
          const _configTable = { ...configTable };
          _configTable.bar2.cardData = _data;
          _configTable.bar2.totalSum = itemSumObj;
          _configTable.bar2.total = response.data.count;
          // _configTable.bar2.totalTicket = response.data.number_ticket;
          setConfigTable(_configTable);
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  async function GetRemainTicketAllForDashboard() {
    await axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/GetRemainTicketAllForDashboard`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: {
        pr_id: project_dropdown.select_project,
        current: configTable.donut1.current || 1,
        pageSize: configTable.donut1.pageSize || 10,
        start_date: project_dropdown.project_start_date,
        end_date: project_dropdown.project_end_date,
        user_id: getUser().emp_id,
        role_id: roleUser.id,
        oem_id: getOem(),
        com_id: getUser().com
      },
    })
      .then(function (response) {
        if (Array.isArray(response.data.data)) {
          const _configTable = { ...configTable };
          const _data = response.data.data.map((item, index) => {
            if (!index) {
              _configTable.donut1.remain_ticket = Number(item.count_fail_all) + Number(item.count_inprocess_all);
              _configTable.donut1.in_progress = Number(item.count_inprocess_all);
              _configTable.donut1.fail_sla = Number(item.count_fail_all);
            }
            return {
              project: `${item.pr_project_code}-${item.pr_name}`,
              remain_ticket: Number(item.count_fail) + Number(item.count_inprocess),
              fail_sla: item.count_fail,
              in_progress: item.count_inprocess,
              ...item
            }
          });
          // console.log('_data :>> ', _data);

          _configTable.donut1.cardData = _data;
          _configTable.donut1.total = response.data.count;
          setConfigTable(_configTable);
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }
  
  async function GetCompleteTicketAllForDashboard() {
    await axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/GetCompleteTicketAllForDashboard`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: {
        pr_id: project_dropdown.select_project,
        current: configTable.donut2.current || 1,
        pageSize: configTable.donut2.pageSize || 10,
        start_date: project_dropdown.project_start_date,
        end_date: project_dropdown.project_end_date,
        user_id: getUser().emp_id,
        role_id: roleUser.id,
        oem_id: getOem(),
        com_id: getUser().com
      },
    })
      .then(function (response) {
        if (Array.isArray(response.data.data)) {
          const _configTable = { ...configTable };
          const _data = response.data.data.map((item, index) => {
            if (!index) {
              _configTable.donut2.complete_ticket = Number(item.count_fail_all) + Number(item.count_pass_all);
              _configTable.donut2.pass_sla = Number(item.count_pass_all);
              _configTable.donut2.fail_sla = Number(item.count_fail_all);
            }
            return {
              project: `${item.pr_project_code}-${item.pr_name}`,
              complete_ticket: Number(item.count_fail) + Number(item.count_pass),
              fail_sla: item.count_fail,
              pass_sla: item.count_pass,
              ...item
            }
          });
          // console.log('_data :>> ', _data);
          _configTable.donut2.cardData = _data;
          _configTable.donut2.total = response.data.count;
          setConfigTable(_configTable);
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  async function GetRemainTicketForDashboard() {
    await axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/GetRemainTicketForDashboard`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: { 
        pr_id: project_dropdown.select_project,
        start_date: project_dropdown.project_start_date,
        end_date: project_dropdown.project_end_date,
        user_id: getUser().emp_id,
        role_id: roleUser.id,
        oem_id: getOem(),
        com_id: getUser().com
      },
    })
      .then(function (response) {
        if (Array.isArray(response.data)) {
          const _data = response.data?.[0];
          const sum = Number(_data?.count_inprocess || 0) + Number(_data?.count_fail || 0)
          const _chartData = { ...donutChartData };
          _chartData.labels[0] = `(${sum}) Remain Ticket`
          _chartData.labels[1] = `(${Number(_data?.count_inprocess || 0)}) In progress`
          _chartData.labels[2] = `(${Number(_data?.count_fail || 0)}) Fail ( กำลังดำเนินการ ไม่ผ่าน SLA)`
          _chartData.datasets[0].data = [];
          _chartData.datasets[0].data.push(sum);
          _chartData.datasets[0].data.push(Number(_data?.count_inprocess || 0));
          _chartData.datasets[0].data.push(Number(_data?.count_fail || 0));

          setCountChart({
            ...countChart,
            donut1: sum,
          });
          countChart.donut1 = sum;
          const _configTable = { ...configTable };
          _configTable.donut1.totalTicket = sum;
          setConfigTable(_configTable);
          setDonutChartData(_chartData);
          if (donut1Ref.current) {
            donut1Ref.current.data = _chartData;
            donut1Ref.current.update();
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  async function GetCompleteTicketForDashboard() {
    await axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/GetCompleteTicketForDashboard`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: { 
        pr_id: project_dropdown.select_project,
        start_date: project_dropdown.project_start_date,
        end_date: project_dropdown.project_end_date,
        user_id: getUser().emp_id,
        role_id: roleUser.id,
        oem_id: getOem(),
        com_id: getUser().com
      },
    })
      .then(function (response) {
        if (Array.isArray(response.data)) {
          const _data = response.data?.[0];
          const sum = Number(_data?.count_pass || 0) + Number(_data?.count_fail || 0)
          const _chartData = { ...donutChartData2 };
          _chartData.labels[0] = `(${sum}) Complete Ticket`
          _chartData.labels[1] = `(${Number(_data?.count_pass || 0)}) Pass (SLA)`
          _chartData.labels[2] = `(${Number(_data?.count_fail || 0)}) Fail (ดำเนินการเรียบร้อย ไม่ผ่าน SLA)`
          _chartData.datasets[0].data = [];
          _chartData.datasets[0].data.push(sum);
          _chartData.datasets[0].data.push(Number(_data?.count_pass || 0));
          _chartData.datasets[0].data.push(Number(_data?.count_fail || 0));

          setCountChart({
            ...countChart,
            donut2: sum,
          });
          countChart.donut2 = sum;
          const _configTable = { ...configTable };
          _configTable.donut2.totalTicket = sum;
          setConfigTable(_configTable);
          setDonutChartData2(_chartData);
          if (donut2Ref.current) {
            donut2Ref.current.data = _chartData;
            donut2Ref.current.update();
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  async function GetTeamMGTTicketForDashboard() {
    await axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/GetTeamMGTTicketForDashboard`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: {
        pr_id: project_dropdown.select_project,
        current: configTable.team_mgt.current || 1,
        pageSize: configTable.team_mgt.pageSize || 10,
        start_date: project_dropdown.project_start_date,
        end_date: project_dropdown.project_end_date,
        user_id: getUser().emp_id,
        role_id: roleUser.id,
        oem_id: getOem(),
        com_id: getUser().com
      },
    })
      .then(function (response) {
        if (Array.isArray(response.data.data)) {
          const _data = response.data.data.map(item => {
            const sum = Number(item.count_pass || 0) + Number(item.count_inprocess || 0) + Number(item.count_fail || 0);
            // console.log('sum :>> ', sum);
            // console.log('Number(item.count_pass || 0) / sum * 100 :>> ', Number(item.count_pass || 0) / sum * 100);
            return {
              fullname: `${item.iu_firstname} ${item.iu_lastname}`,
              amount: sum,
              pass_sla: Number(item.count_pass),
              fail_sla: Number(item.count_fail),
              in_process: Number(item.count_inprocess),
              sum_: Number(item.count_pass || 0) ? Number(item.count_pass || 0) / sum * 100 : 0,
              ...item
            }
          });
          const _configTable = { ...configTable };
          _configTable.team_mgt.cardData = _data;
          _configTable.team_mgt.total = response.data.count;
          setConfigTable(_configTable);
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      }); 
  }

  function CallApiChart() {
    Swal.fire({
      title: "ระบบ",
      text: `ระบบกำลังอัพเดตข้อมูล`,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    GetSeverityForDashboard();
    GetTypeTicketForDashboard();
    GetRemainTicketForDashboard();
    GetCompleteTicketForDashboard();
    GetTeamMGTTicketForDashboard();
    Swal.close()
  }


  const history = useHistory();

  useEffect(() => {
    CheckRolePage().then(function (responseData) {
      if (responseData) {
        GetDropDownProject(responseData).then(() => {
          CallApiChart();
        });
      } else {
        history.push("/NonPermission", {
          module: "",
          menu: "Dashboard",
        });
      }
    })

    setIsResponse({
      ...isResponse,
      lg: window.innerWidth < 1240
    });

    const handleResize = () => {
      setIsResponse({
        ...isResponse,
        lg: window.innerWidth < 1240
      });
    };
  
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-12 col-sm-4 col-xl-6"
              style={{ display: "flex" }}
            >
              <img
                alt="..."
                className="img_header_ticket"
                src={img_header_ticket}
                width={30}
                height={30}
              />
              <h1
                style={{
                  fontWeight: 700,
                  fontSize: "55px",
                  marginTop: "-16px",
                  marginLeft: "18px",
                }}
              >
                Dashboard
              </h1>
            </div>
            <div className="col-12 col-sm-8 col-xl-6 ">
              <ol className="breadcrumb float-sm-right ">
                <li className="breadcrumb-item active">
                  Home
                  {/* <a href="/Welcome">Home</a> */}
                </li>
                <li className="breadcrumb-item">Dashboard</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="row mb-2 pl-2 pr-2">
          <div className="col-12 col-sm-12 col-md-6 col-lg-3" style={{ marginBottom: '10px' }}>
            <span style={{ fontSize: "24px", fontWeight: '600', margin: "0", whiteSpace: 'nowrap' }}>
            Project
            </span>
            <Select
              className="select_ProjectCode"
              placeholder="All"
              id="select_ProjectCode"
              styles={customStyles}
              options={project_dropdown.data}
              value={project_dropdown.data.find((item, key) => {
                return item.value === project_dropdown.select_project;
              })}
              onChange={(e) => {
                if (e) {
                  setProject_dropdown({
                    ...project_dropdown,
                    select_project: e.value,
                  })
                  project_dropdown.select_project = e.value;
                }
              }}
              // isClearable={true}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-3" style={{ marginBottom: '10px' }}>
            <span style={{ fontSize: "24px", fontWeight: '600', margin: "0", whiteSpace: 'nowrap' }}>
            Range Date
            </span>
            <RangePicker
              className="InputStyle"
              style={{ marginTop: "0", width: "100%" }}
              // allowClear={false}
              size="large"
              allowEmpty
              format="DD-MM-YYYY"
              value={[
                project_dropdown.project_start_date
                  ? dayjs(project_dropdown.project_start_date)
                  : "",
                  project_dropdown.project_end_date
                  ? dayjs(project_dropdown.project_end_date)
                  : "",
              ]}
              onChange={(date) => {
                setProject_dropdown({
                  ...project_dropdown,
                  project_start_date: date?.[0] ? new Date(date[0]) : "",
                  project_end_date: date?.[1] ? new Date(date[1]) : "",
                });
                project_dropdown.project_start_date = date?.[0] ? new Date(date[0]) : "";
                project_dropdown.project_end_date = date?.[1] ? new Date(date[1]) : "";
              }}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-2" style={{ marginBottom: '10px', marginTop: 'auto' }}>
                  <Button
                    size="large"
                    type="primary"
                    style={{
                      border: "none",
                      backgroundColor: "#007BFF",
                      width: '100%',display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '5px',
                      height: '45px',
                      borderRadius: '5px'
                    }}
                    onClick={() => {
                      CallApiChart();
                    }}
                  >
                    Search
                  </Button>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-2" style={{ marginBottom: '10px', marginTop: 'auto' }}>
                  <Button
                    size="large"
                    type="primary"
                    style={{
                      border: "none",
                      backgroundColor: "#007BFF",
                      width: '100%',display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '5px',
                      height: '45px',
                      borderRadius: '5px'
                    }}
                    onClick={() => {
                      // const start_date = new Date(moment().startOf('year'));
                      // const end_date = new Date(moment().endOf('year'));
                      setProject_dropdown({
                        ...project_dropdown,
                        select_project: null,
                        project_start_date: '',
                        project_end_date: '',
                      });
                      project_dropdown.select_project = null;
                      project_dropdown.project_start_date = '';
                      project_dropdown.project_end_date = '';
                      CallApiChart();
                    }}
                  >
                    Clear
                  </Button>
                </div>
        </div>
        <div className="row pl-2 pr-2">
          <div
            className="col-12 col-lg-4 mb-2"
            style={{}}
          >
            <Card bodyStyle={{ height: "100%",/*   minWidth: '420px', overflow: 'hidden' */ }} style={{ height: "100%" }}>
              <div
                style={{
                }}
              >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="row mb-2"
                  style={{ /* display: "flex", */ justifyContent: "space-between"/* , gap: '5px' */, padding: '0px 5px' }}
                >
                  <div className="col-auto p-0 mt-1">
                    <span
                      style={{
                        marginBottom: "10px",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Severity Dashboard ( {countChart.bar1} Ticket ){" "}
                    </span>
                  </div>
                  <div className="mt-1">
                    <Button style={{ borderRadius: "13px" }} type="primary" onClick={() => showModal('bar1')}>
                      All Project
                    </Button>
                  </div>
                </div>
                <div style={{ overflow: 'auto' }}>
                  <BarChart key="bar1" data={bar1} barRef={bar1Ref} chartData={chartData} minWidth={'350px'} />
                </div>
              </div>
              </div>
            </Card>
          </div>
          <div
            className="col-12 col-lg-8 mb-2"
            style={{}}
          >
            <Card bodyStyle={{ height: "100%" }} style={{ height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="row mb-2"
                  style={{ /* display: "flex", */ justifyContent: "space-between"/* , gap: '5px' */, padding: '0px 5px' }}
                >
                  <div className="col-auto p-0 mt-1">
                    <span
                      style={{
                        marginBottom: "10px",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Type Ticket Dashboard ( {countChart.bar2} Ticket ){" "}
                    </span>
                  </div>
                  <div className="mt-1">
                    <Button 
                      style={{ borderRadius: "13px" }} 
                      type="primary"
                      onClick={() => {
                        showModal('bar2')
                      }}
                    >
                      All Project
                    </Button>
                  </div>
                </div>
                <div style={{ overflow: 'auto' }}>
                  <BarChart key="bar2" data={bar2} barRef={bar2Ref} chartData={chartData2} minWidth={'760px'}/>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="row pl-2 pr-2">
          <div className={`col-12 col-md-${isResponse.lg ? 6 : 3} mb-2`}>
            <Card bodyStyle={{ height: "100%" }} style={{ height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  // justifyContent: "space-between",
                  gap: '20px'
                }}
              >
                <div
                  className="row mb-2"
                  style={{ /* display: "flex", */ justifyContent: "space-between"/* , gap: '5px' */, padding: '0px 5px' }}
                >
                  <div className="col-auto p-0">
                    <span
                      style={{
                        marginBottom: "10px",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Remain All Ticket ( {countChart.donut1} Ticket ){" "}
                    </span>
                  </div>
                  <div className="">
                    <Button style={{ borderRadius: "13px" }} type="primary" onClick={() => showModal('donut1')}>
                      All Project
                    </Button>
                  </div>
                </div>
                <div>
                  <DoughnutChart key="donut1" donutRef={donut1Ref} chartData={donutChartData} />
                </div>
              </div>
            </Card>
          </div>
          <div className={`col-12 col-md-${isResponse.lg ? 6 : 3} mb-2`}>
            <Card bodyStyle={{ height: "100%" }} style={{ height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  gap: '20px'
                  // justifyContent: "space-between",
                }}
              >
                <div
                  className="row mb-2"
                  style={{ /* display: "flex", */ justifyContent: "space-between"/* , gap: '5px' */, padding: '0px 5px' }}
                >
                  <div className="col-auto p-0">
                    <span
                      style={{
                        marginBottom: "10px",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Complete All Ticket ( {countChart.donut2} Ticket ){" "}
                    </span>
                  </div>
                  <div className="">
                    <Button style={{ borderRadius: "13px" }} type="primary" onClick={() => showModal('donut2')}>
                      All Project
                    </Button>
                  </div>
                </div>
                <div>
                  <DoughnutChart key="donut2" donutRef={donut2Ref} chartData={donutChartData2}  />
                </div>
              </div>
            </Card>
          </div>
          <div className={`col-12 col-md-${isResponse.lg ? 12 : 6} mb-2`}>
            <Card bodyStyle={{ height: "100%" }} style={{ height: "100%" }}>
              <div
                  className="row mb-2"
                  style={{ /* display: "flex", */ justifyContent: "space-between"/* , gap: '5px' */, padding: '0px 5px' }}
                >
                <div className="col-12 p-0">
                  <span
                    style={{
                      marginBottom: "10px",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Team Management Ticket Dashboard {/*  ( {countChart.donut2} Ticket ){" "} */}
                  </span>
                </div>
                <div style={{ overflowX: 'auto', overflowY: /* !isResponse.lg ? 'auto' :  */'hidden' }}>
                  <Table_All_Project
                    minHeight={""}
                    fixHeight={!isResponse.lg}
                    key={configTable.team_mgt.key}
                    currentPage={configTable.team_mgt.current}
                    pageSize={configTable.team_mgt.pageSize}
                    onChange={onChangeTeamMGT}
                    cardData={cardData3}
                    onPageChange={configTable.team_mgt.onPageChange}
                    onShowSizeChange={onShowSizeChangeTeamMGT}
                    total={configTable.team_mgt.total}
                    minWidth={configTable.team_mgt.minWidth}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </section>

      <Modal
        title={(
          <div style={{ display: 'flex', alignItems: isModalMode === 'donut1' || isModalMode === 'donut2' ? 'start' : 'center', gap: '5px', justifyContent: 'space-between', flexDirection: isModalMode === 'donut1' || isModalMode === 'donut2' ? 'column' : undefined }}>
            {/* <span style={{ fontWeight: '900', color: '#007BFF', background: '#007BFF' }}>|</span> */}
            <span style={{ fontWeight: '600', fontSize: '20px' }}>{isModalMode === 'bar2' ? 'Type Ticket Dashboard' : isModalMode === 'donut1' || isModalMode === 'donut2' ? 'Summary Ticket Dashboard' : 'Severity Dashboard'}</span>
            {
              isModalMode === 'bar2' 
              ? <></>
              : <span style={{ fontWeight: '600', fontSize: '20px' }}>{isModalMode === 'donut1' ? 'Remain All Ticket (In progress)' : isModalMode === 'donut2' ? 'Complete All Ticket (Pass)' : 'All Ticket'} <span style={{ color: '#007BFF'}}>{configTable[isModalMode]?.totalTicket}</span> Ticket (T)</span>
            }
          </div>
        )}
        closable={false}
        open={isModalOpen}
        // cancelButtonProps={{ hidden: true }}
        // cancelText={"Close"}
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        onOk={handleOk}
        onCancel={handleCancel}
        width={isModalMode === 'bar2' ? 1250 : 1100}
      >
        {
          isModalMode === 'bar2'
          ? (
            <Table_All_Project
              key={configTable.bar2.key}
              currentPage={configTable.bar2.current}
              pageSize={configTable.bar2.pageSize}
              onChange={onChangeBar2}
              cardData={cardData2}
              onPageChange={configTable.bar2.onPageChange}
              onShowSizeChange={onShowSizeChangeBar2}
              total={configTable.bar2.total}
              minWidth={configTable.bar2.minWidth}
              fitContent={'fit-content'}
            />
          ) : isModalMode === 'donut1' 
            ? (
              <Table_All_Project
                key={configTable.donut1.key}
                currentPage={configTable.donut1.current}
                pageSize={configTable.donut1.pageSize}
                onChange={onChangeDonut1}
                cardData={cardData4}
                onPageChange={configTable.donut1.onPageChange}
                onShowSizeChange={onShowSizeChangeDonut1}
                total={configTable.donut1.total}
                minWidth={configTable.donut1.minWidth}
              />
            )
            : isModalMode === 'donut2' 
            ? (
              <Table_All_Project
                key={configTable.donut2.key}
                currentPage={configTable.donut2.current}
                pageSize={configTable.donut2.pageSize}
                onChange={onChangeDonut2}
                cardData={cardData5}
                onPageChange={configTable.donut2.onPageChange}
                onShowSizeChange={onShowSizeChangeDonut2}
                total={configTable.donut2.total}
                minWidth={configTable.donut2.minWidth}
              />
            )
            : (
            <Table_All_Project
              key={configTable.bar1.key}
              currentPage={configTable.bar1.current}
              pageSize={configTable.bar1.pageSize}
              onChange={onChangeBar1}
              cardData={cardData}
              onPageChange={configTable.bar1.onPageChange}
              onShowSizeChange={onShowSizeChangeBar1}
              total={configTable.bar1.total}
              minWidth={configTable.bar1.minWidth}
            />
          )
        }
        <div className="row mt-3"></div>
      </Modal>
    </div>
  );
}
export default Dashboard_Ticket;
