let config = {
  // ---------- USE OUTER ---------- //
  configpathfile: "https://pmrp-academy.com/api/",

  DTC_HOST: "http://devweb.dtcgps.com:8087",
  DTC_TOKEN: "18FGEREW9PF7P2CVZ5HNSKZ31BB6R8VQ94U3AC2WTX4USHGMTK65LYQLADJN7JXD",
  // ---------- USE OUTER ---------- //

  // ---------- USE INNER ---------- //
  API_TTT: "20EC2A2BA3ED2470C11AC4E78461189769DEEAFBDC0D209709897902E6042D44",

  API_URL: "http://203.154.184.163:4001",
  API_URL_IMG: "http://203.154.184.163:4001/static/",

  // API_URL_rawmatInOutCTS: "http://203.154.184.163:5002",

  // API_URL_externalData: "http://203.154.184.163:5003",

  // API_URL_reportPDF: "http://203.154.184.163:5004",

  // API_URL_snManagement: "http://203.154.184.163:5005",

  // API_URL_planningCTS: "http://203.154.184.163:5006",

  API_URL_hrMagenatement: "http://203.154.184.163:5007",

  API_URL_hrMagenatement_public: "http://203.154.184.163:5009",

  API_URL_incentive: "http://203.154.184.163:9999",
  API_URL_IMG_incentive: "http://203.154.184.163:9999/static/",

  API_URL_cuteplus: "http://203.154.184.163:5005",
  API_URL_IMG_cuteplus: "http://203.154.184.163:5006",

  API_URL_cuteplus_sales: "http://203.154.184.163:5002",

  API_Ticket: "http://203.154.184.163:5555",

  API_URL_IoT_Connect: "http://203.154.184.163:6682",

  API_URL_AIOI_Back: "http://203.154.184.163:6681",

  API_URL_AI_OCR: "http://203.154.184.162:6704",
  API_URL_AI_COUNTING: "http://203.154.184.162:6703",
  API_URL_AI_COUNTING_FINE_TUNE: "http://203.154.184.162:6702",

  // API_LINE: "https://fa41-49-49-218-104.ap.ngrok.io",
  // ---------- USE INNER ---------- //
};

export default Object.freeze(Object.assign({}, config));
