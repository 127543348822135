import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import axios from "axios";
import Configs from "../../../config";
import { getOem, getToken, getUser, getAcademy } from "../../../Utils/Common";
import Select from "react-select";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { Button as ButtonAnt, Image, Upload } from "antd";
import moment from "moment";
import { Radio, Space, Row, Col } from "antd";
function Withholding_Tax_Account_AEV(mode) {
  const [filterDataTable, setFilterDataTable] = useState({ data: null });
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const { id } = useParams();
  const [taxAccount, setTaxAccount] = useState({
    wta_id: "",
    wta_name: "",
    wta_name_dropdown: "",
    wta_number: "",
    wta_group: "",
    wta_alley: "",
    wta_road: "",
    wta_subdistrict_id: "",
    wta_subdistrict: "",
    wta_district_id: "",
    wta_district: "",
    wta_province_id: "",
    wta_province: "",
    wta_zip_code: "",
    wta_phone: "",
    wta_tax_payer: "",
    wta_type: "",
    wta_amount: "",
    wta_tax: "",
    wta_date: "",
    wta_check_address: true,
    wta_name_deli: "",
    wta_number_deli: "",
    wta_group_deli: "",
    wta_alley_deli: "",
    wta_road_deli: "",
    wta_subdistrict_deli_id: "",
    wta_subdistrict_deli: "",
    wta_district_deli_id: "",
    wta_district_deli: "",
    wta_province_deli_id: "",
    wta_province_deli: "",
    wta_zip_code_deli: "",
    wta_phone_deli: "",
    wta_status: "",
    wta_update_date: "",
    wta_contact: "",
    wta_et_id: "",
    wta_et_name: "",
    wta_img_name: "",
    wta_img_path: "",
    wta_doc_vol: 0,
  });
  const [tempTaxAccount, setTempTaxAccount] = useState(null);

  const [heightOfContent, setHeightOfContent] = useState({
    data: 0,
  });
  const [getSub, setGetSub] = useState({ data: [] });
  const [getSubDeli, setGetSubDeli] = useState({ data: [] });
  const [getDis, setGetDis] = useState({ data: [] });
  const [getDisDeli, setGetDisDeli] = useState({ data: [] });
  const [getPro, setGetPro] = useState({ data: [] });
  const [getHolding, setGetHolding] = useState({ data: [] });
  const [getName, setGetName] = useState({ data: [] });
  const [filterExpenseTopic, setFilterExpenseTopic] = useState({ data: [] });
  const [isLog, setIsLog] = useState({ bool: false });
  const [showModal, setShowModal] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [checkIMG, setCheckIMG] = useState(0);
  const [delIMG, setDelIMG] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const firstRow = getHolding.data.slice(0, 4);
  const secondRow = getHolding.data.slice(4, 7);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleUploadSuccess = (file) => {
    const updatedFileList = file.fileList;
    setFileList(updatedFileList);
    if (updatedFileList.length > 0) {
      setCheckIMG(1);
    }
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter(
      (item) => item.uid !== (file.uid || file.originFileObj.uid)
    );
    setDelIMG([...delIMG, file]);
    setFileList(newFileList);
    if (newFileList.length === 0) {
      setCheckIMG(0);
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);

    setPreviewOpen(true);
  };

  const toggleModal = (event = null) => {
    const id = event?.target?.id || "";
    if (id === "btn-cancel") {
      setTaxAccount(tempTaxAccount);
      // setTaxAccount({
      //   ...taxAccount,
      //   wta_name_dropdown: "",
      //   wta_number: "",
      //   wta_group: "",
      //   wta_alley: "",
      //   wta_road: "",
      //   wta_subdistrict_id: "",
      //   wta_subdistrict: "",
      //   wta_district_id: "",
      //   wta_district: "",
      //   wta_province_id: "",
      //   wta_province: "",
      //   wta_zip_code: "",
      //   wta_phone: "",
      //   wta_tax_payer: "",
      //   wta_type: "",
      //   wta_amount: "",
      //   wta_tax: "",
      //   wta_date: "",
      //   wta_check_address: true,
      //   wta_name_deli: "",
      //   wta_number_deli: "",
      //   wta_group_deli: "",
      //   wta_alley_deli: "",
      //   wta_road_deli: "",
      //   wta_subdistrict_deli_id: "",
      //   wta_subdistrict_deli: "",
      //   wta_district_deli_id: "",
      //   wta_district_deli: "",
      //   wta_province_deli_id: "",
      //   wta_province_deli: "",
      //   wta_zip_code_deli: "",
      //   wta_phone_deli: "",
      //   wta_status: "",
      //   wta_update_date: "",
      //   wta_contact: "",
      //   wta_et_id: "",
      //   wta_et_name: "",
      //   wta_img_name: "",
      //   wta_img_path: "",
      //   wta_doc_vol: 0,
      // });
    } else if (!showModal) {
      setTempTaxAccount({ ...taxAccount });
    }

    setShowModal(!showModal);
  };

  useEffect(async () => {
    if (mode.mode === "Add") {
      setdisable(false);
      setPageMode("Add");
    } else if (mode.mode === "View") {
      setdisable(true);
      setPageMode("View");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
    await GetProvince();
    await GetHoldingType();
    getExpenseTopicByID();
    getDataTaxByID(id);
    runNumberToMonth(currentMonth);

    if (mode.mode !== "Add") {
      const data2 = sessionStorage.getItem("state_filter_withoding_tax");
      if (data2) {
        setFilterDataTable({ data: data2 });
        filterDataTable.data = data2;
        sessionStorage.removeItem("state_filter_withoding_tax");
      }
    }
  }, []);

  const GetSubdistrict = async (id_dis, check) => {
    let id = { district_id: id_dis };
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/Withholding/getSubdistrict",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: id,
    })
      .then(async function (response) {
        if (check === "District") {
          setGetSub({ data: response.data.data });
        } else if (check === "District2") {
          setGetSubDeli({ data: response.data.data });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const GetDistrict = async (id_pro, check) => {
    let id = { province_id: id_pro };
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/Withholding/getDistrict",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: id,
    })
      .then(async function (response) {
        if (check === "Province") {
          setGetDis({ data: response.data.data });
        } else if (check === "Province2") {
          setGetDisDeli({ data: response.data.data });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const GetProvince = async () => {
    axios({
      method: "get",
      url: Configs.API_URL_hrMagenatement + "/api/Withholding/getProvince",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      // data: filterteam,
    })
      .then(async function (response) {
        setGetPro({ data: response.data.data });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  function getExpenseTopicByID(emp) {
    if (mode.mode == "Add" || mode.mode == "Edit") {
      // var temp = {
      //     acc_cc_is_fc: emp.acc_cc_is_fc,
      //     acc_cc_is_cp: emp.acc_cc_is_cp,
      //     acc_cc_is_cos: emp.acc_cc_is_cos,
      // };
      axios({
        method: "post",
        url:
          Configs.API_URL_hrMagenatement +
          "/api/orgzExpenses/filterExpenseTopic",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data: {
          oem_id: getOem(),
          company_id: getUser().com,
        },
      })
        .then(function (response) {
          if (response.data.data && Array.isArray(response.data.data)) {
            setFilterExpenseTopic({
              ...filterExpenseTopic,
              data: response.data.data,
            });
            filterExpenseTopic.data = response.data.data;
          } else {
            setFilterExpenseTopic({ ...filterExpenseTopic, data: [] });
            filterExpenseTopic.data = [];
          }
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
        });
    }
  }

  const GetHoldingType = async () => {
    axios({
      method: "get",
      url: Configs.API_URL_hrMagenatement + "/api/Withholding/getHoldingType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      // data: filterteam,
    })
      .then(async function (response) {
        setGetHolding({ data: response.data.data });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  useEffect(async () => {
    if (taxAccount.wta_province_id) {
      await GetDistrict(taxAccount.wta_province_id, "Province");
    }
    if (taxAccount.wta_district_id) {
      await GetSubdistrict(taxAccount.wta_district_id, "District");
    }
    if (taxAccount.wta_province_deli_id) {
      await GetDistrict(taxAccount.wta_province_deli_id, "Province2");
    }
    if (taxAccount.wta_district_deli_id) {
      await GetSubdistrict(taxAccount.wta_district_deli_id, "District2");
    }
  }, [
    taxAccount.wta_province_id,
    taxAccount.wta_district_id,
    taxAccount.wta_province_deli_id,
    taxAccount.wta_district_deli_id,
  ]);

  function cancle_tax_account(params) {
    if (
      mode.mode != "View" &&
      taxAccount.wta_status != "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
    ) {
      Swal.fire({
        title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `บันทึก`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ยกเลิก`,
      }).then((result) => {
        if (filterDataTable.data) {
          sessionStorage.setItem(
            "state_filter_withoding_tax",
            filterDataTable.data
          );
        }
        if (result.isConfirmed) {
          window.location.href = "/accounting/withholding";
        }
      });
    } else {
      if (filterDataTable.data) {
        sessionStorage.setItem(
          "state_filter_withoding_tax",
          filterDataTable.data
        );
      }
      window.location.href = "/accounting/withholding";
    }
  }

  const filterName = async (name) => {
    let data = { name: name, oem_id: getOem(), company_id: getUser().com };
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/Withholding/filterName",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then(async function (response) {
        if (response.data.data.length > 0) {
          let obj = response.data.data;
          let arr = [],
            obj_dup = {};
          for (let index = 0; index < obj.length; index++) {
            if (!obj_dup[obj[index].acc_wta_name]) {
              obj_dup[obj[index].acc_wta_name] = obj[index].acc_wta_name;
              arr.push(obj[index]);
            }
          }
          setGetName({ data: arr });
          toggleModal();
        } else {
          Swal.fire({
            title: "ไม่พบข้อมูล",
            text: "ไม่มีชื่อบริษัทฯ และชื่อบุคคลที่ค้นหา กรุณากรอกรายละเอียด",
            icon: "warning",
            confirmButtonText: "ตกลง",
          });
          // return false;
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  function getDataTaxByID(id) {
    if (mode.mode == "View" || mode.mode == "Edit") {
      var data = { wta_id: id };
      axios({
        method: "post",
        url: Configs.API_URL_hrMagenatement + "/api/Withholding/getDataTaxByID",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: data,
      })
        .then(async function (response) {
          let check = false;
          if (
            (response.data.data[0].acc_wta_name ===
              response.data.data[0].acc_wta_name_2 &&
              response.data.data[0].acc_wta_house_num ===
                response.data.data[0].acc_wta_house_num_2 &&
              response.data.data[0].acc_wta_group_num) ||
            null === response.data.data[0].acc_wta_group_num_2 ||
            (null && response.data.data[0].acc_wta_alley) ||
            (null && response.data.data[0].acc_wta_alley_2) ||
            (null && response.data.data[0].acc_wta_street) ||
            null === response.data.data[0].acc_wta_street_2 ||
            (null &&
              response.data.data[0].acc_wta_sub_id ===
                response.data.data[0].acc_wta_sub_id_2 &&
              response.data.data[0].acc_wta_dis_id ===
                response.data.data[0].acc_wta_dis_id_2 &&
              response.data.data[0].acc_wta_pro_id ===
                response.data.data[0].acc_wta_pro_id_2 &&
              response.data.data[0].acc_wta_postcode ===
                response.data.data[0].acc_wta_postcode_2 &&
              response.data.data[0].acc_wta_phone_num ===
                response.data.data[0].acc_wta_phone_num_2)
          ) {
            check = true;
          }
          const tempFile = response.data.data
            .filter((image) => image.acc_wta_img_path && image.acc_wta_img_name)
            .map((image) => {
              const url = `${Configs.API_URL_IMG + image.acc_wta_img_path}`;

              return {
                name: image.acc_wta_img_name,
                status: "done",
                url: url,
                thumbUrl: url,
              };
            });

          if (tempFile.length > 0) {
            setCheckIMG(1);
          }

          setFileList(tempFile);

          setTaxAccount({
            ...taxAccount,
            wta_id: response.data.data[0].acc_wta_id || "",
            wta_name: response.data.data[0].acc_wta_name || "",
            wta_name_dropdown: response.data.data[0].acc_wta_name || "",
            wta_number: response.data.data[0].acc_wta_house_num || "",
            wta_group: response.data.data[0].acc_wta_group_num || "",
            wta_alley: response.data.data[0].acc_wta_alley || "",
            wta_road: response.data.data[0].acc_wta_street || "",
            wta_subdistrict_id: response.data.data[0].acc_wta_sub_id || "",
            wta_subdistrict: response.data.data[0].sub_1 || "",
            wta_district_id: response.data.data[0].acc_wta_dis_id || "",
            wta_district: response.data.data[0].dis_1 || "",
            wta_province_id: response.data.data[0].acc_wta_pro_id || "",
            wta_province: response.data.data[0].pro_1 || "",
            wta_zip_code: response.data.data[0].acc_wta_postcode || "",
            wta_phone: response.data.data[0].acc_wta_phone_num || "",
            wta_tax_payer: response.data.data[0].acc_wta_cardid || "",
            wta_type: response.data.data[0].acc_wta_ht_id || "",
            wta_name_deli: response.data.data[0].acc_wta_name_2 || "",
            wta_number_deli: response.data.data[0].acc_wta_house_num_2 || "",
            wta_group_deli: response.data.data[0].acc_wta_group_num_2 || "",
            wta_alley_deli: response.data.data[0].acc_wta_alley_2 || "",
            wta_road_deli: response.data.data[0].acc_wta_street_2 || "",
            wta_subdistrict_deli_id:
              response.data.data[0].acc_wta_sub_id_2 || "",
            wta_subdistrict_deli: response.data.data[0].sub_2 || "",
            wta_district_deli_id: response.data.data[0].acc_wta_dis_id_2 || "",
            wta_district_deli: response.data.data[0].dis_2 || "",
            wta_province_deli_id: response.data.data[0].acc_wta_pro_id_2 || "",
            wta_province_deli: response.data.data[0].pro_2 || "",
            wta_zip_code_deli: response.data.data[0].acc_wta_postcode_2 || "",
            wta_phone_deli: response.data.data[0].acc_wta_phone_num_2 || "",
            wta_check_address: check,
            wta_amount: response.data.data[0].acc_wta_amount || "",
            wta_date: response.data.data[0].acc_wta_paid_date
              ? new Date(response.data.data[0].acc_wta_paid_date)
              : "",
            wta_status: response.data.data[0].acc_wta_status_id || "",
            wta_update_date: response.data.data[0].acc_wta_updated_date
              ? new Date(response.data.data[0].acc_wta_updated_date)
              : "",
            wta_contact: response.data.data[0].acc_wta_contact || "",
            wta_et_id: response.data.data[0].acc_wta_et_id || "",
            wta_et_name: response.data.data[0].acc_et_name || "",
            wta_img_name: response.data.data[0].acc_wta_img_name || "",
            wta_img_path: response.data.data[0].acc_wta_img_path || "",
          });
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
        });
    }
  }

  const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, "0");
  const runNumberToMonth = async (month) => {
    let data = { month: month, oem_id: getOem(), company_id: getUser().com };
    if (mode.mode === "Add") {
      axios({
        method: "post",
        url: Configs.API_URL_hrMagenatement + "/api/Withholding/runNumber",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: data,
      })
        .then(async function (response) {
          setTaxAccount({
            ...taxAccount,
            wta_doc_vol:
              response.data.data.length > 0
                ? Number(response.data.data[0].acc_wta_doc_vol) + 1
                : 1,
          });
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
        });
    }
  };

  function save_tax_account(check) {
    const error_list = [];
    if (taxAccount.wta_name.trim() == "" || taxAccount.wta_name == null) {
      let temp_err = {
        message: "กรุณาระบุชื่อบริษัท/ชื่อ-นามสกุล",
      };
      error_list.push(temp_err);
    }
    if (taxAccount.wta_number.trim() == "" || taxAccount.wta_number == null) {
      let temp_err = {
        message: "กรุณาระบุเลขที่",
      };
      error_list.push(temp_err);
    }
    if (
      taxAccount.wta_subdistrict == "" ||
      taxAccount.wta_subdistrict == null
    ) {
      let temp_err = {
        message: "กรุณาระบุแขวง/ตำบล",
      };
      error_list.push(temp_err);
    }
    if (taxAccount.wta_district == "" || taxAccount.wta_district == null) {
      let temp_err = {
        message: "กรุณาระบุเขต/อำเภอ",
      };
      error_list.push(temp_err);
    }
    if (taxAccount.wta_province == "" || taxAccount.wta_province == null) {
      let temp_err = {
        message: "กรุณาระบุจังหวัด",
      };
      error_list.push(temp_err);
    }
    if (
      taxAccount.wta_zip_code.trim() == "" ||
      taxAccount.wta_zip_code == null
    ) {
      let temp_err = {
        message: "กรุณาระบุรหัสไปรษณีย์",
      };
      error_list.push(temp_err);
    }
    if (taxAccount.wta_phone.trim() == "" || taxAccount.wta_phone == null) {
      let temp_err = {
        message: "กรุณาระบุเบอร์โทรติดต่อ",
      };
      error_list.push(temp_err);
    }
    if (
      taxAccount.wta_name_deli.trim() == "" ||
      taxAccount.wta_name_deli == null
    ) {
      let temp_err = {
        message: "กรุณาระบุชื่อบริษัท/ชื่อ-นามสกุลสำหรับจัดส่งเอกสาร",
      };
      error_list.push(temp_err);
    }
    if (
      taxAccount.wta_number_deli == "" ||
      taxAccount.wta_number_deli == null
    ) {
      let temp_err = {
        message: "กรุณาระบุเลขที่สำหรับจัดส่งเอกสาร",
      };
      error_list.push(temp_err);
    }
    if (
      taxAccount.wta_subdistrict_deli == "" ||
      taxAccount.wta_subdistrict_deli == null
    ) {
      let temp_err = {
        message: "กรุณาระบุแขวง/ตำบลสำหรับจัดส่งเอกสาร",
      };
      error_list.push(temp_err);
    }
    if (
      taxAccount.wta_district_deli == "" ||
      taxAccount.wta_district_deli == null
    ) {
      let temp_err = {
        message: "กรุณาระบุเขต/อำเภอสำหรับจัดส่งเอกสาร",
      };
      error_list.push(temp_err);
    }
    if (
      taxAccount.wta_province_deli == "" ||
      taxAccount.wta_province_deli == null
    ) {
      let temp_err = {
        message: "กรุณาระบุจังหวัดสำหรับจัดส่งเอกสาร",
      };
      error_list.push(temp_err);
    }
    if (
      taxAccount.wta_zip_code_deli == "" ||
      taxAccount.wta_zip_code_deli == null
    ) {
      let temp_err = {
        message: "กรุณาระบุรหัสไปรษณีย์สำหรับจัดส่งเอกสาร",
      };
      error_list.push(temp_err);
    }
    if (taxAccount.wta_phone_deli == "" || taxAccount.wta_phone_deli == null) {
      let temp_err = {
        message: "กรุณาระบุเบอร์โทรติดต่อสำหรับจัดส่งเอกสาร",
      };
      error_list.push(temp_err);
    }
    if (taxAccount.wta_tax_payer == "" || taxAccount.wta_tax_payer == null) {
      let temp_err = {
        message: "กรุณาระบุเเลขประจำตัวผู้เสียภาษีอากร / เลขบัตรประชาชน",
      };
      error_list.push(temp_err);
    }
    if (taxAccount.wta_type == "" || taxAccount.wta_type == null) {
      let temp_err = {
        message: "กรุณาระบุประเภท",
      };
      error_list.push(temp_err);
    }
    if (taxAccount.wta_contact.trim() == "" || taxAccount.wta_contact == null) {
      let temp_err = {
        message: "กรุณาระบุชื่อผู้ติดต่อ",
      };
      error_list.push(temp_err);
    }
    if (taxAccount.wta_amount.trim() == "" || taxAccount.wta_amount == null) {
      let temp_err = {
        message: "กรุณาระบุยอดเงินสุทธิ",
      };
      error_list.push(temp_err);
    }
    if (taxAccount.wta_date == "" || taxAccount.wta_date == null) {
      let temp_err = {
        message: "กรุณาระบุวันที่ทำจ่าย",
      };
      error_list.push(temp_err);
    }
    if (taxAccount.wta_et_id == "" || taxAccount.wta_et_id == null) {
      let temp_err = {
        message: "กรุณาระบุหัวข้อค่าใช้จ่าย",
      };
      error_list.push(temp_err);
    }
    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
      return;
    }
    Swal.fire({
      title: "คุณต้องการที่จะบันทึกหรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `บันทึก`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ยกเลิก`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (filterDataTable.data) {
          sessionStorage.setItem(
            "state_filter_withoding_tax",
            filterDataTable.data
          );
        }
        if (mode.mode === "Add") {
          const temp = {
            acc_wta_name: taxAccount.wta_name.trim(),
            acc_wta_house_num: taxAccount.wta_number.trim(),
            acc_wta_group_num: taxAccount.wta_group.trim(),
            acc_wta_alley: taxAccount.wta_alley.trim(),
            acc_wta_road: taxAccount.wta_road.trim(),
            acc_wta_sub_id: taxAccount.wta_subdistrict_id,
            acc_wta_dis_id: taxAccount.wta_district_id,
            acc_wta_pro_id: taxAccount.wta_province_id,
            acc_wta_postcode: taxAccount.wta_zip_code.trim(),
            acc_wta_phone_num: taxAccount.wta_phone.trim(),
            acc_wta_cardid: taxAccount.wta_tax_payer.trim(),
            acc_wta_ht_id: taxAccount.wta_type,
            acc_wta_amount: taxAccount.wta_amount.trim(),
            acc_wta_tax_amount: taxAccount.wta_tax,
            acc_wta_et_id: null,
            acc_wta_paid_date: taxAccount.wta_date,
            acc_wta_name_2: taxAccount.wta_name_deli.trim(),
            acc_wta_house_num_2: taxAccount.wta_number_deli.trim(),
            acc_wta_group_num_2: taxAccount.wta_group_deli.trim(),
            acc_wta_alley_2: taxAccount.wta_alley_deli.trim(),
            acc_wta_road_2: taxAccount.wta_road_deli.trim(),
            acc_wta_sub_id_2: taxAccount.wta_subdistrict_deli_id,
            acc_wta_dis_id_2: taxAccount.wta_district_deli_id,
            acc_wta_pro_id_2: taxAccount.wta_province_deli_id,
            acc_wta_postcode_2: taxAccount.wta_zip_code_deli.trim(),
            acc_wta_phone_num_2: taxAccount.wta_phone_deli.trim(),
            user_id: getUser().fup,
            acc_wta_contact: taxAccount.wta_contact.trim(),
            acc_wta_et_id: taxAccount.wta_et_id,
            acc_wta_doc_no: moment(taxAccount.wta_date).format("MM"),
            acc_wta_doc_vol: taxAccount.wta_doc_vol,
            oem_id: getOem(),
            company_id: getUser().com,
          };
          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then((result) => {
            axios({
              method: "post",
              url:
                Configs.API_URL_hrMagenatement +
                "/api/Withholding/addTaxAccount",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            })
              .then(function (response) {
                if (response.data.data !== false) {
                  Swal.fire({
                    icon: "success",
                    title: "Save",
                    showConfirmButton: false,
                    timer: 1500,
                  }).then((result) => {
                    window.location.href = "/accounting/withholding";
                  });
                } else {
                  Swal.fire("Error", "ชื่อไฟล์นี้มีอยู่ในระบบแล้ว", "error");
                }
              })
              .catch(function (error) {
                console.log(error);
                Swal.fire(
                  "Error",
                  "เกิดข้อผิดพลาด: " + error.response.data.error[0].errorDis,
                  "error"
                );
              });
          });
        }
        if (mode.mode === "Edit") {
          const data = new FormData();
          for (let item of fileList) {
            data.append("Profile", item.originFileObj);
          }
          data.append("typeFile", "withholding_upload_file");
          await axios({
            method: "post",
            url: Configs.API_URL + "/upload/document",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: data,
          }).then(async function (response) {
            if (
              response.data.status === true ||
              response.data.status === false
            ) {
              let status = "";
              if (check === "บันทึก") {
                status = "b1c8f95a-fcaf-409f-b108-405c650a52b6";
              } else if (check === "จัดส่ง") {
                status = "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5";
              }
              let file_list = {};
              if (response.data.data) {
                file_list = response.data.data?.map((el) => {
                  return { name: el.orgin_name, path: el.path };
                });
              } else {
                file_list = {
                  name: taxAccount.wta_img_name,
                  path: taxAccount.wta_img_path,
                };
              }
              const temp = {
                acc_wta_id: id,
                acc_wta_name: taxAccount.wta_name.trim(),
                acc_wta_house_num: taxAccount.wta_number.trim(),
                acc_wta_group_num: taxAccount.wta_group.trim(),
                acc_wta_alley: taxAccount.wta_alley.trim(),
                acc_wta_road: taxAccount.wta_road.trim(),
                acc_wta_sub_id: taxAccount.wta_subdistrict_id,
                acc_wta_dis_id: taxAccount.wta_district_id,
                acc_wta_pro_id: taxAccount.wta_province_id,
                acc_wta_postcode: taxAccount.wta_zip_code.trim(),
                acc_wta_phone_num: taxAccount.wta_phone.trim(),
                acc_wta_cardid: taxAccount.wta_tax_payer.trim(),
                acc_wta_ht_id: taxAccount.wta_type,
                acc_wta_amount: taxAccount.wta_amount.trim(),
                acc_wta_tax_amount: taxAccount.wta_tax,
                acc_wta_et_id: null,
                acc_wta_paid_date: taxAccount.wta_date,
                acc_wta_name_2: taxAccount.wta_name_deli.trim(),
                acc_wta_house_num_2: taxAccount.wta_number_deli.trim(),
                acc_wta_group_num_2: taxAccount.wta_group_deli.trim(),
                acc_wta_alley_2: taxAccount.wta_alley_deli.trim(),
                acc_wta_road_2: taxAccount.wta_road_deli.trim(),
                acc_wta_sub_id_2: taxAccount.wta_subdistrict_deli_id,
                acc_wta_dis_id_2: taxAccount.wta_district_deli_id,
                acc_wta_pro_id_2: taxAccount.wta_province_deli_id,
                acc_wta_postcode_2: taxAccount.wta_zip_code_deli.trim(),
                acc_wta_phone_num_2: taxAccount.wta_phone_deli.trim(),
                image_file: Array.isArray(file_list)
                  ? file_list
                  : [file_list] || null,
                user_id: getUser().fup,
                acc_wta_status: status,
                check: check,
                acc_wta_contact: taxAccount.wta_contact.trim(),
                acc_wta_et_id: taxAccount.wta_et_id,
              };
              await axios({
                method: "post",
                url:
                  Configs.API_URL_hrMagenatement +
                  "/api/Withholding/editTaxAccount",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: temp,
              })
                .then(function (response) {
                  if (response.data.data !== false) {
                    if (check === "จัดส่ง") {
                      Swal.fire({
                        icon: "success",
                        title: "จัดส่งสำเร็จ!",
                        text: "เอกสารถูกจัดส่งเรียบร้อยแล้ว",
                        showConfirmButton: false,
                        timer: 1500,
                      }).then(() => {
                        window.location.href = "/accounting/withholding";
                      });
                    } else {
                      Swal.fire({
                        icon: "success",
                        title: "บันทึกข้อมูลสำเร็จ!",
                        showConfirmButton: false,
                        timer: 1500,
                      }).then(() => {
                        window.location.href = "/accounting/withholding";
                      });
                    }
                  } else {
                    Swal.fire("Error", "ชื่อไฟล์นี้มีอยู่ในระบบแล้ว", "error");
                  }
                })
                .catch(function (error) {
                  console.log(error);
                  Swal.fire(
                    "Error",
                    "เกิดข้อผิดพลาด: " + error.response.data.error[0].errorDis,
                    "error"
                  );
                });
            }
          });
        }
      }
    });
  }

  useEffect(() => {
    setTimeout(() => {
      const sidebar = document.querySelector(".main-sidebar");
      if (sidebar) {
        const height = sidebar.offsetHeight - 50;
        setHeightOfContent({ data: height });
        heightOfContent.data = height;
      }
    }, 1000);
  }, []);

  return (
    <div className="wrapper">
      <div
        className="content-wrapper"
        style={
          heightOfContent.data ? { minHeight: `${heightOfContent.data}px` } : {}
        }
      >
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{pageMode} Withholding tax account</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Accounting</li>
                  <li className="breadcrumb-item active">
                    Withholding tax account
                  </li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode.mode === "View" ||
            taxAccount.wta_status === "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5" ? (
              <div className="row mb-2">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    onClick={cancle_tax_account}
                    className="btn btn-block btn-danger "
                    id="btn-cancle"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={() => save_tax_account("บันทึก")}
                    className="btn btn-block btn-success"
                    // style={{ display: `${mode.mode === "view" ? "none" : ""}` }}
                    id="btn-save"
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={cancle_tax_account}
                    className="btn btn-block btn-danger"
                    id="btn-cancle"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{pageMode} Withholding tax account</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6 col-xl-4">
                  <label>
                    ชื่อบริษัท / ชื่อ-นามสกุล{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <h5 style={{ fontSize: "14px" }}>
                    (กรณีออกในนามบริษัทให้กรอกชื่อบริษัท แต่หากออกในนามบุคคล
                    ให้กรอกชื่อ-นามสกุล)
                  </h5>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="wta_name"
                      value={taxAccount.wta_name}
                      disabled={
                        disable ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      onChange={(e) => {
                        if (taxAccount.wta_check_address) {
                          setTaxAccount({
                            ...taxAccount,
                            wta_name: e.target.value,
                            wta_name_deli: e.target.value,
                          });
                        } else {
                          setTaxAccount({
                            ...taxAccount,
                            wta_name: e.target.value,
                            wta_name_deli: "",
                          });
                        }
                      }}
                    />
                    <i
                      className="fa fa-search"
                      style={{
                        marginLeft: "8px",
                        backgroundColor: "rgba(53, 121, 246, 0.15)",
                        padding: "9px",
                        borderRadius: "5px",
                        color:
                          taxAccount.wta_name === "" ||
                          disable ||
                          taxAccount.wta_status ===
                            "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                            ? "rgba(200, 200, 200, 0.5)"
                            : "rgba(53, 121, 246, 1)",
                        cursor:
                          taxAccount.wta_name === "" ||
                          disable ||
                          taxAccount.wta_status ===
                            "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                            ? "not-allowed"
                            : "pointer",
                      }}
                      onClick={
                        taxAccount.wta_name === "" ||
                        disable ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                          ? null
                          : async () => {
                              await filterName(taxAccount.wta_name);
                              // console.log('found',getName.data.length);
                              // if (getName.data > 0) {
                              //   toggleModal();
                              // }
                            }
                      }
                    ></i>
                    <Modal
                      centered
                      isOpen={showModal}
                      toggle={() =>
                        toggleModal({ target: { id: "btn-cancel" } })
                      }
                    >
                      <ModalHeader
                        style={{
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <span style={{ fontSize: "24px", fontWeight: "800" }}>
                          ชื่อบริษัทฯ และชื่อบุคคลทั้งหมด
                        </span>
                      </ModalHeader>
                      <ModalBody style={{ padding: "0" }}>
                        <div>
                          <hr style={{ margin: "0px" }} />
                          <div style={{ padding: "15px" }}>
                            <div className="row justify-content-center">
                              {/* This will center the select component on larger screens */}
                              <div className="col-12 col-md-8 col-xl-6">
                                <div className="form-group">
                                  <Select
                                    isDisabled={
                                      disable ||
                                      taxAccount.wta_status ===
                                        "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                                    }
                                    options={[
                                      ...getName.data.map((el) => ({
                                        value: el.acc_wta_id,
                                        label: el.acc_wta_name,
                                        data: el,
                                      })),
                                    ]}
                                    value={
                                      taxAccount.wta_id != null
                                        ? {
                                            value: taxAccount.wta_id,
                                            label: taxAccount.wta_name_dropdown,
                                          }
                                        : null
                                    }
                                    onChange={(selectedOptions) => {
                                      setTaxAccount({
                                        ...taxAccount,
                                        wta_id: selectedOptions.value || "",
                                        wta_name: selectedOptions.label || "",
                                        wta_name_dropdown:
                                          selectedOptions.label || "",
                                        wta_number:
                                          selectedOptions.data
                                            .acc_wta_house_num || "",
                                        wta_amount:
                                          selectedOptions.data.acc_wta_amount ||
                                          "",
                                        wta_group:
                                          selectedOptions.data
                                            .acc_wta_group_num || "",
                                        wta_alley:
                                          selectedOptions.data.acc_wta_alley ||
                                          "",
                                        wta_road:
                                          selectedOptions.data.acc_wta_street ||
                                          "",
                                        wta_subdistrict_id:
                                          selectedOptions.data.acc_wta_sub_id ||
                                          "",
                                        wta_subdistrict:
                                          selectedOptions.data.sub_1 || "",
                                        wta_district_id:
                                          selectedOptions.data.acc_wta_dis_id ||
                                          "",
                                        wta_district:
                                          selectedOptions.data.dis_1 || "",
                                        wta_province_id:
                                          selectedOptions.data.acc_wta_pro_id ||
                                          "",
                                        wta_province:
                                          selectedOptions.data.pro_1 || "",
                                        wta_zip_code:
                                          selectedOptions.data
                                            .acc_wta_postcode || "",
                                        wta_phone:
                                          selectedOptions.data
                                            .acc_wta_phone_num || "",
                                        wta_tax_payer:
                                          selectedOptions.data.acc_wta_cardid ||
                                          "",
                                        wta_type:
                                          selectedOptions.data.acc_wta_ht_id ||
                                          "",
                                        wta_name_deli:
                                          selectedOptions.data.acc_wta_name_2 ||
                                          "",
                                        wta_number_deli:
                                          selectedOptions.data
                                            .acc_wta_house_num_2 || "",
                                        wta_group_deli:
                                          selectedOptions.data
                                            .acc_wta_group_num_2 || "",
                                        wta_alley_deli:
                                          selectedOptions.data
                                            .acc_wta_alley_2 || "",
                                        wta_road_deli:
                                          selectedOptions.data
                                            .acc_wta_street_2 || "",
                                        wta_subdistrict_deli_id:
                                          selectedOptions.data
                                            .acc_wta_sub_id_2 || "",
                                        wta_subdistrict_deli:
                                          selectedOptions.data.sub_2 || "",
                                        wta_district_deli_id:
                                          selectedOptions.data
                                            .acc_wta_dis_id_2 || "",
                                        wta_district_deli:
                                          selectedOptions.data.dis_2 || "",
                                        wta_province_deli_id:
                                          selectedOptions.data
                                            .acc_wta_pro_id_2 || "",
                                        wta_province_deli:
                                          selectedOptions.data.pro_2 || "",
                                        wta_zip_code_deli:
                                          selectedOptions.data
                                            .acc_wta_postcode_2 || "",
                                        wta_phone_deli:
                                          selectedOptions.data
                                            .acc_wta_phone_num_2 || "",
                                      });
                                    }}
                                  />
                                  <label
                                    style={{
                                      fontSize: "16px",
                                      marginTop: "-5px",
                                    }}
                                  >
                                    ชื่อบริษัทฯ และชื่อบุคคล{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        {mode.mode === "View" ? (
                          <div className="row w-100">
                            <div className="col-6">
                              <button
                                className="btn btn-block btn-danger"
                                onClick={(event) => toggleModal()}
                                id="btn-cancel"
                              >
                                ยกเลิก
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="row w-100">
                            <div className="col-6">
                              <button
                                className="btn btn-block btn-success"
                                onClick={(event) => toggleModal(event)}
                                id="btn-save"
                              >
                                บันทึก
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                className="btn btn-block btn-danger"
                                onClick={(event) => toggleModal(event)}
                                id="btn-cancel"
                              >
                                ยกเลิก
                              </button>
                            </div>
                          </div>
                        )}
                      </ModalFooter>
                    </Modal>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-12">
                  <label>
                    ที่อยู่บริษัท / ที่อยู่ตามบัตรประชาชน{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <h5 style={{ fontSize: "14px", marginBottom: "10px" }}>
                    (กรณีในนามบริษัทให้กรอกที่อยู่ออกใบหักภาษี ณ ที่จ่าย
                    แต่หากในนามบุคคล ให้กรอกรายละเอียดตามบัตรประชาชน)
                  </h5>
                  {/* <div className="form-group" style={{ padding: 0 }}>
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="wta_address"
                      value={taxAccount.wta_address}
                      // placeholder="กรอกยอดเงิน"
                      disabled={disable}
                      onChange={(e) => {
                        setTaxAccount({
                          ...taxAccount,
                          wta_address: e.target.value,
                        });
                      }}
                    />
                  </div> */}
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="wta_number"
                      disabled={
                        disable ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      value={taxAccount.wta_number}
                      // placeholder="กรอกยอดเงิน"
                      onChange={(e) => {
                        if (taxAccount.wta_check_address) {
                          setTaxAccount({
                            ...taxAccount,
                            wta_number: e.target.value,
                            wta_number_deli: e.target.value,
                          });
                        } else {
                          setTaxAccount({
                            ...taxAccount,
                            wta_number: e.target.value,
                            wta_number_deli: "",
                          });
                        }
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>
                      เลขที่ <span style={{ color: "red" }}>*</span>
                    </label>
                    {/* {storeNotNull ? (
                      <span style={{ color: "red" }}>
                        *กรุณากรอกชื่อร้านค้า
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="wta_group"
                      disabled={
                        disable ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      value={taxAccount.wta_group}
                      // placeholder="กรอกยอดเงิน"
                      onChange={(e) => {
                        if (taxAccount.wta_check_address) {
                          setTaxAccount({
                            ...taxAccount,
                            wta_group: e.target.value,
                            wta_group_deli: e.target.value,
                          });
                        } else {
                          setTaxAccount({
                            ...taxAccount,
                            wta_group: e.target.value,
                            wta_group_deli: "",
                          });
                        }
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>หมู่ที่</label>
                    {/* {storeNotNull ? (
                      <span style={{ color: "red" }}>
                        *กรุณากรอกชื่อร้านค้า
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="wta_alley"
                      disabled={
                        disable ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      value={taxAccount.wta_alley}
                      // placeholder="กรอกยอดเงิน"
                      onChange={(e) => {
                        if (taxAccount.wta_check_address) {
                          setTaxAccount({
                            ...taxAccount,
                            wta_alley: e.target.value,
                            wta_alley_deli: e.target.value,
                          });
                        } else {
                          setTaxAccount({
                            ...taxAccount,
                            wta_alley: e.target.value,
                            wta_alley_deli: "",
                          });
                        }
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>ซอย</label>
                    {/* {storeNotNull ? (
                      <span style={{ color: "red" }}>
                        *กรุณากรอกชื่อร้านค้า
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="wta_road"
                      disabled={
                        disable ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      value={taxAccount.wta_road}
                      // placeholder="กรอกยอดเงิน"
                      onChange={(e) => {
                        if (taxAccount.wta_check_address) {
                          setTaxAccount({
                            ...taxAccount,
                            wta_road: e.target.value,
                            wta_road_deli: e.target.value,
                          });
                        } else {
                          setTaxAccount({
                            ...taxAccount,
                            wta_road: e.target.value,
                            wta_road_deli: "",
                          });
                        }
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>ถนน</label>
                    {/* {storeNotNull ? (
                      <span style={{ color: "red" }}>
                        *กรุณากรอกชื่อร้านค้า
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <Select
                      isDisabled={
                        disable ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5" ||
                        taxAccount.wta_district_id === ""
                      }
                      options={[
                        ...getSub.data.map((el) => ({
                          value: el.id,
                          label: el.tambon_name,
                          // isDisabled: el.emp_id === dataHrlog.rl_usr_id_filed,
                        })),
                      ]}
                      value={
                        taxAccount.wta_subdistrict_id != null
                          ? {
                              value: taxAccount.wta_subdistrict_id,
                              label: taxAccount.wta_subdistrict,
                            }
                          : null
                      }
                      onChange={(selectedOptions) => {
                        if (taxAccount.wta_check_address) {
                          setTaxAccount({
                            ...taxAccount,
                            wta_subdistrict_id: selectedOptions.value || "",
                            wta_subdistrict: selectedOptions.label || "",
                            wta_subdistrict_deli_id:
                              selectedOptions.value || "",
                            wta_subdistrict_deli: selectedOptions.label || "",
                          });
                        } else {
                          setTaxAccount({
                            ...taxAccount,
                            wta_subdistrict_id: selectedOptions.value || "",
                            wta_subdistrict: selectedOptions.label || "",
                            wta_subdistrict_deli_id: "",
                            wta_subdistrict_deli: "",
                          });
                        }
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>
                      แขวง/ตำบล <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <Select
                      isDisabled={
                        disable ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5" ||
                        taxAccount.wta_province_id === ""
                      }
                      options={[
                        ...getDis.data.map((el) => ({
                          value: el.id,
                          label: el.district_name,
                          // isDisabled: el.emp_id === dataHrlog.rl_usr_id_filed,
                        })),
                      ]}
                      value={
                        taxAccount.wta_district_id != null
                          ? {
                              value: taxAccount.wta_district_id,
                              label: taxAccount.wta_district,
                            }
                          : null
                      }
                      onChange={(selectedOptions) => {
                        if (taxAccount.wta_check_address) {
                          setTaxAccount({
                            ...taxAccount,
                            wta_district_id: selectedOptions.value || "",
                            wta_district: selectedOptions.label || "",
                            wta_district_deli_id: selectedOptions.value || "",
                            wta_district_deli: selectedOptions.label || "",
                          });
                        } else {
                          setTaxAccount({
                            ...taxAccount,
                            wta_district_id: selectedOptions.value || "",
                            wta_district: selectedOptions.label || "",
                            wta_district_deli_id: "",
                            wta_district_deli: "",
                          });
                        }
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>
                      เขต/อำเภอ <span style={{ color: "red" }}>*</span>
                    </label>
                    {/* {storeNotNull ? (
                      <span style={{ color: "red" }}>
                        *กรุณากรอกชื่อร้านค้า
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <Select
                      isDisabled={
                        disable ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      options={[
                        ...getPro.data.map((el) => ({
                          value: el.province_id,
                          label: el.province_name,
                          // isDisabled: el.emp_id === dataHrlog.rl_usr_id_filed,
                        })),
                      ]}
                      value={
                        taxAccount.wta_province_id != null
                          ? {
                              value: taxAccount.wta_province_id,
                              label: taxAccount.wta_province,
                            }
                          : null
                      }
                      onChange={(selectedOptions) => {
                        if (taxAccount.wta_check_address) {
                          setTaxAccount({
                            ...taxAccount,
                            wta_province_id: selectedOptions.value || "",
                            wta_province: selectedOptions.label || "",
                            wta_province_deli_id: selectedOptions.value || "",
                            wta_province_deli: selectedOptions.label || "",
                            wta_district_id: "",
                            wta_district: "",
                            wta_subdistrict_id: "",
                            wta_subdistrict: "",
                          });
                        } else {
                          setTaxAccount({
                            ...taxAccount,
                            wta_province_id: selectedOptions.value || "",
                            wta_province: selectedOptions.label || "",
                            wta_province_deli_id: "",
                            wta_province_deli: "",
                            wta_district_id: "",
                            wta_district: "",
                            wta_subdistrict_id: "",
                            wta_subdistrict: "",
                          });
                        }
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>
                      จังหวัด <span style={{ color: "red" }}>*</span>
                    </label>
                    {/* {storeNotNull ? (
                      <span style={{ color: "red" }}>
                        *กรุณากรอกชื่อร้านค้า
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="wta_zip_code"
                      disabled={
                        disable ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      value={taxAccount.wta_zip_code}
                      // placeholder="กรอกยอดเงิน"
                      onChange={(e) => {
                        if (taxAccount.wta_check_address) {
                          setTaxAccount({
                            ...taxAccount,
                            wta_zip_code: e.target.value,
                            wta_zip_code_deli: e.target.value,
                          });
                        } else {
                          setTaxAccount({
                            ...taxAccount,
                            wta_zip_code: e.target.value,
                            wta_zip_code_deli: "",
                          });
                        }
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>
                      รหัสไปรษณีย์ <span style={{ color: "red" }}>*</span>
                    </label>
                    {/* {storeNotNull ? (
                      <span style={{ color: "red" }}>
                        *กรุณากรอกชื่อร้านค้า
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="wta_phone"
                      value={taxAccount.wta_phone}
                      disabled={
                        disable ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      // placeholder="กรอกยอดเงิน"
                      onChange={(e) => {
                        if (taxAccount.wta_check_address) {
                          setTaxAccount({
                            ...taxAccount,
                            wta_phone: e.target.value,
                            wta_phone_deli: e.target.value,
                          });
                        } else {
                          setTaxAccount({
                            ...taxAccount,
                            wta_phone: e.target.value,
                            wta_phone_deli: "",
                          });
                        }
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>
                      เบอร์โทรติดต่อ <span style={{ color: "red" }}>*</span>
                    </label>
                    {/* {storeNotNull ? (
                      <span style={{ color: "red" }}>
                        *กรุณากรอกชื่อร้านค้า
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="wta_tax_payer"
                      value={taxAccount.wta_tax_payer}
                      maxLength={13}
                      disabled={
                        disable ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      // placeholder="กรอกยอดเงิน"
                      onChange={(e) => {
                        setTaxAccount({
                          ...taxAccount,
                          wta_tax_payer: e.target.value,
                        });
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>
                      เลขประจำตัวผู้เสียภาษีอากร / เลขบัตรประชาชน{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    {/* {true ? (
                      <span style={{ color: "red" }}>
                        *
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-12 col-xl-12">
                  <label htmlFor="status" style={{ marginTop: "-4px" }}>
                    ประเภท
                    <span style={{ color: "red" }}>{!disable ? " *" : ""}</span>
                  </label>
                </div>
                {/* {getHolding.data.map((holding) => (
                  <div className="col-12 col-md-3 col-xl-3" style={{ display: 'flex' }}>
                    <div className="form-group">
                      <div id="status" className="status-options d-flex flex-column">
                        <div key={holding.emp_ht_id} className="status-item d-flex flex-row">
                          <div>
                            <input
                              type="radio"
                              id={`status-${holding.emp_ht_id}`}
                              value="wta_type"
                              disabled={disable || taxAccount.wta_status === '8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5'}
                              checked={taxAccount.wta_type === holding.emp_ht_id}
                              onChange={(e) => {
                                setTaxAccount({
                                  ...taxAccount,
                                  wta_type: e.target.checked ? holding.emp_ht_id : "",
                                });
                              }}
                            />
                          </div>
                          <label
                            htmlFor={`status-${holding.emp_ht_id}`}
                            className="status-text"
                            style={{ marginTop: "-2mm", fontSize: "15pt", paddingLeft: "8pt", whiteSpace: 'nowrap' }}
                          >
                            {holding.emp_ht_name}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ))} */}
                <Radio.Group
                  onChange={(e) => {
                    const selectedType = e.target.value;
                    setTaxAccount({
                      ...taxAccount,
                      wta_type: selectedType,
                    });
                  }}
                  style={{ paddingLeft: "7px", paddingBottom: "20px" }}
                  value={taxAccount.wta_type}
                  disabled={
                    disable ||
                    taxAccount.wta_status ===
                      "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                  }
                >
                  <Row gutter={[16, 16]}>
                    {/* First row of Radio buttons */}
                    {firstRow.map((holding) => (
                      <Col
                        key={holding.emp_ht_id}
                        xs={24} // Full width on extra-small screens (mobile)
                        sm={24} // Full width on small screens (tablets, e.g., iPad)
                        md={6} // 1/4 width on medium screens and above (desktop)
                      >
                        <Radio value={holding.emp_ht_id}>
                          {holding.emp_ht_name}
                        </Radio>
                      </Col>
                    ))}

                    {/* Second row of Radio buttons */}
                    {secondRow.map((holding) => (
                      <Col
                        key={holding.emp_ht_id}
                        xs={24} // Full width on extra-small screens
                        sm={24} // Full width on small screens (e.g., iPad)
                        md={6} // 1/4 width on medium screens and above
                      >
                        <Radio value={holding.emp_ht_id}>
                          {holding.emp_ht_name}
                        </Radio>
                      </Col>
                    ))}
                  </Row>
                </Radio.Group>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <NumberFormat
                      className="form-control"
                      required
                      allowNegative={false}
                      decimalScale={2}
                      thousandSeparator={true}
                      disabled={
                        disable ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      value={taxAccount.wta_amount}
                      onValueChange={(values) => {
                        const { value } = values;
                        const numericValue = Number(value);
                        const amount_tax = (numericValue * 3) / 100;

                        setTaxAccount({
                          ...taxAccount,
                          wta_amount: value,
                          wta_tax: amount_tax.toFixed(2),
                        });
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>
                      ยอดเงินสุทธิ <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <NumberFormat
                      className="form-control"
                      required
                      allowNegative={false}
                      decimalScale={2}
                      thousandSeparator={true}
                      disabled={true}
                      value={taxAccount.wta_tax}
                    />
                    <label style={{ fontSize: "16px" }}>
                      ภาษีที่หักและนำส่งไว้
                    </label>
                  </div>
                </div>
                <div
                  className="col-12 col-md-6 col-xl-3"
                  style={{
                    display: "flex",
                    flex: "warp",
                    alignItems: "center",
                  }}
                >
                  <label
                    style={{
                      margin: 0,
                      color: "rgba(53, 121, 246, 1)",
                      marginTop: "-11px",
                    }}
                  >
                    อัตราคำนวน 3%
                  </label>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-12 col-md-6 col-xl-3"
                  // style={{ "z-index": "99" }}
                >
                  {/* <h5
                    style={{
                      borderBottom: "2px solid rgba(53, 121, 246, 1)",
                      width: "100%",
                      fontWeight: "bold",
                    }}
                  >
                    กำหนดประเภทค่าใช้จ่าย
                  </h5> */}
                  <div className="form-group" /*  style={{ zIndex: "99" }} */>
                    <Select
                      filterOption={(option, searchText) => {
                        if (
                          option.data.label
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                          // || option.data.value
                          //   .toLowerCase()
                          //   .includes(searchText.toLowerCase())
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      isDisabled={
                        disable ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      options={[
                        ...filterExpenseTopic.data.map((el) => ({
                          value: el.acc_et_id,
                          label: el.acc_et_name,
                          // check: el.acc_et_is_doc_travel,
                        })),
                      ]}
                      value={
                        taxAccount.wta_et_id != null
                          ? {
                              value: taxAccount.wta_et_id,
                              label: taxAccount.wta_et_name,
                            }
                          : null
                      }
                      onChange={(selectedOptions) => {
                        setTaxAccount({
                          ...taxAccount,
                          wta_et_id: selectedOptions.value || "",
                          wta_et_name: selectedOptions.label || "",
                        });
                      }}
                    />
                    <label style={{ margin: "-5px" }}>
                      หัวข้อค่าใช้จ่าย <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-3">
                  <div
                    className="form-group input-group" /* style={{ zIndex: "98" }} */
                  >
                    <DatePicker
                      id="inputDate"
                      className="form-control"
                      wrapperClassName="form-control"
                      dateFormat="dd/MM/yyyy"
                      disabled={
                        disable ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      selected={taxAccount.wta_date}
                      onChange={async (date) => {
                        setTaxAccount({
                          ...taxAccount,
                          wta_date: date,
                        });
                      }}
                    />
                    <label style={{ marginTop: "-4px" }}>
                      วันที่ทำจ่าย <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="far fa-calendar-alt" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-12 col-md-6 col-xl-12"
                  // style={{ "z-index": "99" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h5
                      style={{
                        borderBottom: "2px solid rgba(53, 121, 246, 1)",
                        width: "100%",
                        fontWeight: "bold",
                        marginRight: "10px",
                      }}
                    >
                      กรอกที่อยู่สำหรับจัดส่งเอกสาร
                    </h5>
                  </div>
                  <div className="col-12 col-md-3 col-xl-12">
                    <div className="form-group">
                      <div id="status" className="status-options d-flex">
                        <label
                          htmlFor="status-pending"
                          className="status-text"
                          style={{
                            marginTop: "-1.5mm",
                            fontSize: "15pt",
                            paddingLeft: "10pt",
                            whiteSpace: "nowrap",
                          }}
                        >
                          ที่อยู่ตามด้านบน
                        </label>
                        <div className="">
                          <input
                            type="checkbox"
                            id="status-pending"
                            value="wta_check_address"
                            disabled={
                              disable ||
                              taxAccount.wta_status ===
                                "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                            }
                            checked={taxAccount.wta_check_address === true}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setTaxAccount({
                                  ...taxAccount,
                                  wta_check_address: e.target.checked,
                                  wta_name_deli: taxAccount.wta_name,
                                  wta_number_deli: taxAccount.wta_number,
                                  wta_group_deli: taxAccount.wta_group,
                                  wta_alley_deli: taxAccount.wta_alley,
                                  wta_road_deli: taxAccount.wta_road,
                                  wta_subdistrict_deli_id:
                                    taxAccount.wta_subdistrict_id,
                                  wta_subdistrict_deli:
                                    taxAccount.wta_subdistrict,
                                  wta_district_deli_id:
                                    taxAccount.wta_district_id,
                                  wta_district_deli: taxAccount.wta_district,
                                  wta_province_deli_id:
                                    taxAccount.wta_province_id,
                                  wta_province_deli: taxAccount.wta_province,
                                  wta_zip_code_deli: taxAccount.wta_zip_code,
                                  wta_phone_deli: taxAccount.wta_phone,
                                });
                              } else {
                                setTaxAccount({
                                  ...taxAccount,
                                  wta_check_address: e.target.checked,
                                  wta_name_deli: "",
                                  wta_number_deli: "",
                                  wta_group_deli: "",
                                  wta_alley_deli: "",
                                  wta_road_deli: "",
                                  wta_subdistrict_deli_id: "",
                                  wta_subdistrict_deli: "",
                                  wta_district_deli_id: "",
                                  wta_district_deli: "",
                                  wta_province_deli_id: "",
                                  wta_province_deli: "",
                                  wta_zip_code_deli: "",
                                  wta_phone_deli: "",
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="wta_name_deli"
                      disabled={
                        disable ||
                        taxAccount.wta_check_address ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      value={taxAccount.wta_name_deli}
                      // placeholder="กรอกยอดเงิน"
                      onChange={(e) => {
                        setTaxAccount({
                          ...taxAccount,
                          wta_name_deli: e.target.value,
                        });
                      }}
                    />
                    <label>
                      ชื่อบริษัท / ชื่อ-นามสกุล{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="wta_contact"
                      disabled={
                        disable ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      value={taxAccount.wta_contact}
                      // placeholder="กรอกยอดเงิน"
                      onChange={(e) => {
                        setTaxAccount({
                          ...taxAccount,
                          wta_contact: e.target.value,
                        });
                      }}
                    />
                    <label>
                      ชื่อผู้ติดต่อ <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="wta_name_person_deli"
                      disabled={disable || taxAccount.wta_check_address}
                      value={taxAccount.wta_name_person_deli}
                      // placeholder="กรอกยอดเงิน"
                      onChange={(e) => {
                        setTaxAccount({
                          ...taxAccount,
                          wta_name_person_deli: e.target.value,
                        });
                      }}
                    />
                    <label style={{ fontSize: '16px' }}>ชื่อบุคคล หรือผู้ติดต่อสำหรับจัดส่งเอกสาร</label>
                  </div>
                </div>
              </div> */}

              <div className="row">
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="wta_number_deli"
                      disabled={
                        disable ||
                        taxAccount.wta_check_address ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      value={taxAccount.wta_number_deli}
                      // placeholder="กรอกยอดเงิน"
                      onChange={(e) => {
                        setTaxAccount({
                          ...taxAccount,
                          wta_number_deli: e.target.value,
                        });
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>
                      เลขที่ <span style={{ color: "red" }}>*</span>
                    </label>
                    {/* {storeNotNull ? (
                      <span style={{ color: "red" }}>
                        *กรุณากรอกชื่อร้านค้า
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="wta_group_deli"
                      disabled={
                        disable ||
                        taxAccount.wta_check_address ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      value={taxAccount.wta_group_deli}
                      // placeholder="กรอกยอดเงิน"
                      onChange={(e) => {
                        setTaxAccount({
                          ...taxAccount,
                          wta_group_deli: e.target.value,
                        });
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>หมู่ที่</label>
                    {/* {storeNotNull ? (
                      <span style={{ color: "red" }}>
                        *กรุณากรอกชื่อร้านค้า
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="wta_alley_deli"
                      disabled={
                        disable ||
                        taxAccount.wta_check_address ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      value={taxAccount.wta_alley_deli}
                      // placeholder="กรอกยอดเงิน"
                      onChange={(e) => {
                        setTaxAccount({
                          ...taxAccount,
                          wta_alley_deli: e.target.value,
                        });
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>ซอย</label>
                    {/* {storeNotNull ? (
                      <span style={{ color: "red" }}>
                        *กรุณากรอกชื่อร้านค้า
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="wta_road_deli"
                      disabled={
                        disable ||
                        taxAccount.wta_check_address ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      value={taxAccount.wta_road_deli}
                      // placeholder="กรอกยอดเงิน"
                      onChange={(e) => {
                        setTaxAccount({
                          ...taxAccount,
                          wta_road_deli: e.target.value,
                        });
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>ถนน</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <Select
                      isDisabled={
                        disable ||
                        taxAccount.wta_check_address ||
                        taxAccount.wta_district_deli_id === "" ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      options={[
                        ...getSubDeli.data.map((el) => ({
                          value: el.id,
                          label: el.tambon_name,
                          // isDisabled: el.emp_id === dataHrlog.rl_usr_id_filed,
                        })),
                      ]}
                      value={
                        taxAccount.wta_subdistrict_deli_id != null
                          ? {
                              value: taxAccount.wta_subdistrict_deli_id,
                              label: taxAccount.wta_subdistrict_deli,
                            }
                          : null
                      }
                      onChange={(selectedOptions) => {
                        setTaxAccount({
                          ...taxAccount,
                          wta_subdistrict_deli_id: selectedOptions.value || "",
                          wta_subdistrict_deli: selectedOptions.label || "",
                        });
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>
                      แขวง/ตำบล <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <Select
                      isDisabled={
                        disable ||
                        taxAccount.wta_check_address ||
                        taxAccount.wta_province_deli_id === "" ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      options={[
                        ...getDisDeli.data.map((el) => ({
                          value: el.id,
                          label: el.district_name,
                          // isDisabled: el.emp_id === dataHrlog.rl_usr_id_filed,
                        })),
                      ]}
                      value={
                        taxAccount.wta_district_deli_id != null
                          ? {
                              value: taxAccount.wta_district_deli_id,
                              label: taxAccount.wta_district_deli,
                            }
                          : null
                      }
                      onChange={(selectedOptions) => {
                        setTaxAccount({
                          ...taxAccount,
                          wta_district_deli_id: selectedOptions.value || "",
                          wta_district_deli: selectedOptions.label || "",
                        });
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>
                      เขต/อำเภอ <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <Select
                      isDisabled={
                        disable ||
                        taxAccount.wta_check_address ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      options={[
                        ...getPro.data.map((el) => ({
                          value: el.province_id,
                          label: el.province_name,
                          // isDisabled: el.emp_id === dataHrlog.rl_usr_id_filed,
                        })),
                      ]}
                      value={
                        taxAccount.wta_province_id != null
                          ? {
                              value: taxAccount.wta_province_deli_id,
                              label: taxAccount.wta_province_deli,
                            }
                          : null
                      }
                      onChange={(selectedOptions) => {
                        setTaxAccount({
                          ...taxAccount,
                          wta_province_deli_id: selectedOptions.value || "",
                          wta_province_deli: selectedOptions.label || "",
                          wta_district_deli_id: "",
                          wta_district_deli: "",
                          wta_subdistrict_deli_id: "",
                          wta_subdistrict_deli: "",
                        });
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>
                      จังหวัด <span style={{ color: "red" }}>*</span>
                    </label>
                    {/* {storeNotNull ? (
                      <span style={{ color: "red" }}>
                        *กรุณากรอกชื่อร้านค้า
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="wta_zip_code_deli"
                      disabled={
                        disable ||
                        taxAccount.wta_check_address ||
                        taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                      }
                      value={taxAccount.wta_zip_code_deli}
                      // placeholder="กรอกยอดเงิน"
                      onChange={(e) => {
                        setTaxAccount({
                          ...taxAccount,
                          wta_zip_code_deli: e.target.value,
                        });
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>
                      รหัสไปรษณีย์ <span style={{ color: "red" }}>*</span>
                    </label>
                    {/* {storeNotNull ? (
                      <span style={{ color: "red" }}>
                        *กรุณากรอกชื่อร้านค้า
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="wta_phone_deli"
                      disabled={mode.mode === "View"}
                      // disabled={disable || taxAccount.wta_check_address || taxAccount.wta_status === '8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5'}
                      value={taxAccount.wta_phone_deli}
                      // placeholder="กรอกยอดเงิน"
                      onChange={(e) => {
                        setTaxAccount({
                          ...taxAccount,
                          wta_phone_deli: e.target.value,
                        });
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>
                      เบอร์โทรติดต่อ <span style={{ color: "red" }}>*</span>
                    </label>
                    {/* {storeNotNull ? (
                      <span style={{ color: "red" }}>
                        *กรุณากรอกชื่อร้านค้า
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div>

              {(mode.mode === "Edit" || mode.mode === "View") && (
                <div className="row">
                  <div className="col-12">
                    <h5
                      style={{
                        borderBottom: "2px solid rgba(53, 121, 246, 1)",
                        width: "100%",
                        fontWeight: "bold",
                        display: `${
                          mode.mode === "View" &&
                          taxAccount.wta_status !=
                            "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                            ? "none"
                            : ""
                        }`,
                      }}
                    >
                      แนบหลักฐานการจัดส่งเอกสาร
                    </h5>
                  </div>
                  <div className="col-12 col-md-6 col-xl-5">
                    <label
                      htmlFor=""
                      style={{
                        display: `${
                          mode.mode === "View" &&
                          taxAccount.wta_status !=
                            "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                            ? "none"
                            : ""
                        }`,
                        margin: 0,
                      }}
                    >
                      ใบเสร็จ
                    </label>
                    <div
                      style={{
                        marginTop: `${mode.mode === "View" ? "-35px" : ""}`,
                      }}
                    >
                      <Upload
                        beforeUpload={() => false}
                        className="custom-upload"
                        listType="picture"
                        fileList={fileList}
                        onRemove={handleRemove}
                        onPreview={handlePreview}
                        onChange={handleUploadSuccess}
                        accept={[".jpg", ".png"]}
                        maxCount={1}
                        disabled={
                          disable ||
                          taxAccount.wta_status ===
                            "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                        }
                        style={{ width: "100%" }}
                      >
                        {mode.mode != "View" ? (
                          <ButtonAnt
                            type="primary"
                            style={{
                              backgroundColor: "#3579F626",
                              color: "#3579F6",
                              width: "100%",
                            }}
                            disabled={
                              disable ||
                              taxAccount.wta_status ===
                                "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                            }
                          >
                            Choose files
                          </ButtonAnt>
                        ) : (
                          <></>
                        )}
                      </Upload>
                    </div>
                  </div>
                  {checkIMG > 0 && (
                    <div
                      className={
                        taxAccount.wta_status ===
                        "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
                          ? "col-12 col-md-6 col-xl-5"
                          : "col-12 col-md-6 col-xl-2"
                      }
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <button
                          disabled={
                            disable ||
                            (taxAccount.wta_status ===
                              "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5" &&
                              checkIMG > 0)
                          }
                          className="btn btn-block btn-success"
                          onClick={() => save_tax_account("จัดส่ง")}
                          id="btn-cancel"
                        >
                          จัดส่ง
                        </button>
                        {taxAccount.wta_status ===
                          "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5" && (
                          <div className="ml-2" style={{ width: "100%" }}>
                            วันที่ :{" "}
                            {taxAccount.wta_update_date.toLocaleDateString(
                              "en-GB"
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {(mode.mode === "Edit" || mode.mode === "View") &&
                previewImage && (
                  <div
                    className="expenses-image-popup"
                    id="expenses-image-popup"
                  >
                    <Image
                      className="image"
                      wrapperStyle={{ display: "none", zIndex: 1051 }}
                      preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) =>
                          !visible && setPreviewImage(""),
                      }}
                      src={previewImage}
                    />
                  </div>
                )}

              {/* card-body */}
            </div>
            {/* card */}
          </div>
        </section>
      </div>
    </div>
  );
}

export default Withholding_Tax_Account_AEV;
