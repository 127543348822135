import React, { useEffect, useState } from "react";
import Configs from "../../../config";
import axios from "axios";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { getOem, getToken, getUser, getAcademy } from "../../../Utils/Common";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Button as ButtonAnt, Image, Upload } from "antd";
import NumberFormat from "react-number-format";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

const ACCOUNTING_STATUS = {
  draft: "3d04de87-1ded-4968-b533-e8b2dda0c4d8", // แบบร่าง
  waiting: "a86edd8a-5b08-445c-8377-9def93ceb8c0", // รอดำเนินการ
  approve_not_upload: "99c1692b-8baa-46b3-983a-c5839a48cf84", // อนุมัติ	// อนุมัติแบบไม่ upload
  approve_uploaded: "d4329102-39f6-420a-820f-7c00f98cbcb3", // อนุมัตื	// อนุมัติแบบ upload
  not_approve: "8ab5629d-754c-4eda-8b8e-bd2920fa6167", // ไม่อนุมัติ
};

function Emp_Expenses_AEV({ mode, previousURL, checkEmp }) {
  const { id } = useParams();
  const [allEmployee, setAllEmployee] = useState({ data: [], emp_name_th: "" });
  const [disable, setdisable] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [delIMG, setDelIMG] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const [isVersion, setIsVersion] = useState(false);

  const [empData, setEmpData] = useState({
    emp_id: "",
    company_id: getUser().com,
    oem_id: getOem(),
    user_id: getUser().fup,
    cost_date: "",
    amount: "",
    cus_id: getUser().emp_id,
    cost_topic_id: "",
    cost_topic_check: false,
    cost_detail: "",
    store: "",
  });

  const [status, setStatus] = useState({ data: "" });
  const [filterDataTable, setFilterDataTable] = useState({ data: null });

  const [dropdownExpenseTopic, setDropdownExpenseTopic] = useState({
    origin: [],
    version: [],
  });
  const [dropdownVersion, setDropdownVersion] = useState([]);
  const [selectVersion, setSelectVersion] = useState([]);
  const [modalState, setModalState] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [dataForSave, setDataForSave] = useState({
    id: "",
    remark_not_approve: "",
    data: "",
  });
  const [hasSign, setHasSign] = useState(false);
  const getSignData = async () => {
    try {
      const response = await axios({
        method: "post",
        url:
          Configs.API_URL_hrMagenatement + "/api/orgzExpenses/getSignCheckUser",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data: {
          user_id: getUser().fup,
          company_id: getUser().com,
          oem_id: getOem(),
        },
      });

      if (response.data && response.data.data.length > 0) {
        setHasSign(true);
      } else {
        setHasSign(false);
      }
    } catch (error) {
      console.log(error);
      Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
    }
  };

  function GoBackPage() {
    if (mode != "View") {
      Swal.fire({
        title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `บันทึก`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ยกเลิก`,
      }).then((result) => {
        {
          Swal.fire({
            icon: "success",
            title: "บันทึกข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        if (filterDataTable.data) {
          sessionStorage.setItem("state_filter_expenses", filterDataTable.data);
        }
        window.location.href = previousURL;
        if (result.isConfirmed) {
          window.location.href = previousURL;
        }
      });
    } else {
      if (filterDataTable.data) {
        sessionStorage.setItem("state_filter_expenses", filterDataTable.data);
      }
      window.location.href = previousURL;
    }
  }

  async function DropdownExpenseTopic() {
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/ExpenseTopic/dropdownExpenseTopicDataDefault",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: {
        company_id: getUser().com,
        oem_id: getOem(),
      },
    })
      .then(function (response) {
        const options = [];

        for (const item of response.data.data) {
          let element = {};

          element["value"] = item.acc_et_id;
          element["label"] = item.acc_et_name;
          element["check"] = item.acc_et_is_doc_travel;

          options.push(element);
        }

        setDropdownExpenseTopic({
          ...dropdownExpenseTopic,
          origin: options,
        });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function DropdownVersion() {
    let temp = {
      exp_id: id,
      company_id: getUser().com,
      oem_id: getOem(),
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/ExpenseLog/getAllLogExpenses",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(function (response) {
        const version_list = response.data.data;

        const version_list_dropdown = [
          { value: id, label: "Latest", is_not_log: true },
        ];

        setSelectVersion(version_list_dropdown[0]);

        for (let index = 0; index < version_list.length; index++) {
          if (
            index == 0 &&
            (status.data == ACCOUNTING_STATUS.waiting ||
              status.data == ACCOUNTING_STATUS.approve_uploaded ||
              status.data == ACCOUNTING_STATUS.approve_not_upload)
          ) {
            continue;
          } else {
            version_list_dropdown.push({
              value: version_list[index].emp_exp_log_id,
              label: version_list[index].emp_exp_log_version,
            });
          }
        }

        setDropdownVersion(version_list_dropdown);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function GetDataExpensesByID() {
    const tempData = {
      exp_id: id,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/EmpExpense/getEmpExpenseByID",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: tempData,
    })
      .then(function (response) {
        const tempFile = response.data.data.Image.map((image) => {
          const url = `${Configs.API_URL_IMG + image.emp_file_path}`;

          return {
            uid: image.emp_upload_id,
            name: image.emp_file_name,
            img_id: image.emp_exp_id,
            status: "done",
            url: url,
            thumbUrl: url,
          };
        });

        setFileList(tempFile);

        setEmpData({
          ...empData,
          emp_id: response.data.data.Expenses[0].emp_exp_id || "",
          cost_date: response.data.data.Expenses[0].emp_exp_start_date
            ? new Date(response.data.data.Expenses[0].emp_exp_start_date)
            : null,
          amount: response.data.data.Expenses[0].emp_exp_amount || "",
          cost_topic_id: response.data.data.Expenses[0].emp_exp_et_id || "",
          cost_topic_check: response.data.data.Expenses[0].acc_et_is_doc_travel,
          cost_detail: response.data.data.Expenses[0].emp_exp_detail || "",
          store: response.data.data.Expenses[0].emp_exp_shop_name || "",
          status: response.data.data.Expenses[0].emp_exp_status || "",
        });

        setStatus({
          data: response.data.data.Expenses[0].emp_exp_status || "",
        });
        status.data = response.data.data.Expenses[0].emp_exp_status || "";
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function GetDataExpensesVersionByID(is_not_log, exp_id) {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 1000,
      onOpen: () => {
        Swal.showLoading();
      },
    }).then(async (result) => {
      let axiosConfig = {};

      if (is_not_log) {
        const tempData = {
          exp_id: exp_id,
          oem_id: getOem(),
          company_id: getUser().com,
        };

        axiosConfig = {
          method: "post",
          url:
            Configs.API_URL_hrMagenatement +
            "/api/EmpExpense/getEmpExpenseByID",
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: tempData,
        };
      } else {
        const tempData = {
          id: exp_id,
          type_log: "2",
          oem_id: getOem(),
          company_id: getUser().com,
        };

        axiosConfig = {
          method: "post",
          url:
            Configs.API_URL_hrMagenatement +
            "/api/ExpenseLog/getLogExpensesByID",
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: tempData,
        };
      }

      await axios(axiosConfig)
        .then(function (response) {
          if (is_not_log) {
            const tempFile = response.data.data.Image.map((image) => {
              const url = `${Configs.API_URL_IMG + image.emp_file_path}`;

              return {
                uid: image.emp_upload_id,
                name: image.emp_file_name,
                img_id: image.emp_exp_id,
                status: "done",
                url: url,
                thumbUrl: url,
              };
            });

            setFileList(tempFile);

            setIsVersion(false);

            setEmpData({
              ...empData,
              cost_date: response.data.data.Expenses[0].emp_exp_start_date
                ? new Date(response.data.data.Expenses[0].emp_exp_start_date)
                : null,
              amount: response.data.data.Expenses[0].emp_exp_amount || "",
              cost_topic_id: response.data.data.Expenses[0].emp_exp_et_id || "",
              cost_topic_check:
                response.data.data.Expenses[0].acc_et_is_doc_travel,
              cost_detail: response.data.data.Expenses[0].emp_exp_detail || "",
              store: response.data.data.Expenses[0].emp_exp_shop_name || "",
            });
          } else {
            const tempFile = response.data.data.image.map((image) => {
              const url = `${Configs.API_URL_IMG + image.emp_file_path}`;

              return {
                uid: image.emp_upload_id,
                name: image.emp_file_name,
                img_id: image.emp_exp_id,
                status: "done",
                url: url,
                thumbUrl: url,
              };
            });

            setFileList(tempFile);

            setIsVersion(true);

            const dd_topic = {
              value: response.data.data.project?.[0]?.emp_exp_log_et_id || "",
              label: response.data.data.project?.[0]?.emp_exp_log_et_id || "",
            };

            setDropdownExpenseTopic({
              ...dropdownExpenseTopic,
              version: [dd_topic],
            });

            setEmpData({
              ...empData,
              cost_date: response.data.data.project[0].emp_exp_start_date
                ? new Date(response.data.data.project[0].emp_exp_start_date)
                : null,
              amount: response.data.data.project[0].emp_exp_amount || "",
              cost_topic_id:
                response.data.data.project[0].emp_exp_log_et_id || "",
              cost_topic_check:
                response.data.data.project[0].acc_et_is_doc_travel,
              cost_detail: response.data.data.project[0].emp_exp_detail || "",
              store: response.data.data.project[0].emp_exp_shop_name || "",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
        });
    });
  }

  async function sendApproveFromEmpToOrgz(emp_id) {
    const tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      emp_id_list: [emp_id],
      status_id: ACCOUNTING_STATUS.waiting,
      remark: "",
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/EmpExpense/sendApproveFromEmpToOrgz",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async function (response) {})
      .catch(function (error) {
        console.log(error);
        Swal.fire(
          "Error",
          "เกิดข้อผิดพลาด: " + error.response.data.error[0].errorDis,
          "error"
        );
      });
  }

  async function SaveUpdateEmpExpensesData(isApprove) {
    Swal.fire({
      title: `คุณต้องการที่จะ${isApprove ? "ส่งอนุมัติ" : "บันทึก"}หรือไม่`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: isApprove ? "ใช่" : `บันทึก`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: isApprove ? "ไม่" : `ยกเลิก`,
    }).then(async (result) => {
      if (filterDataTable.data) {
        sessionStorage.setItem("state_filter_expenses", filterDataTable.data);
      }
      if (result.isConfirmed) {
        const error_list = [];

        if (empData.cost_date == "" || empData.cost_date == null) {
          let temp_err = {
            message: "กรุณาเลือกวันที่เกิดค่าใช้จ่าย",
          };
          error_list.push(temp_err);
        }

        if (
          empData.amount == "" ||
          empData.amount == null ||
          empData.amount == 0
        ) {
          let temp_err = {
            message: "กรุณากรอกยอดเงิน",
          };
          error_list.push(temp_err);
        }

        if (empData.cost_topic_id == "" || empData.cost_topic_id == null) {
          let temp_err = {
            message: "กรุณาเลือกหัวข้อค่าใช้จ่าย",
          };
          error_list.push(temp_err);
        }

        if (empData.cost_detail == "" || empData.cost_detail == null) {
          let temp_err = {
            message: "กรุณากรอกรายละเอียดค่าใช้จ่าย",
          };
          error_list.push(temp_err);
        }

        if (empData.store == "" || empData.store == null) {
          let temp_err = {
            message: "กรุณากรอกชื่อร้านค้า",
          };
          error_list.push(temp_err);
        }

        if (fileList.length == 0) {
          let temp_err = {
            message: "กรุณาอัปโหลดรูปภาพ",
          };
          error_list.push(temp_err);
        }

        if (error_list.length > 0) {
          let err_message = "";

          for (let e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }

          Swal.fire("Error", err_message, "error");
        } else {
          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async () => {
            const tempImage = new FormData();

            for (let item of fileList) {
              if (item.originFileObj) {
                tempImage.append("Profile", item.originFileObj);
              }
            }
            tempImage.append("typeFile", "exp_upload_file");

            let tempData = JSON.parse(JSON.stringify(empData));
            let checkUpload = true;

            if (Array.from(tempImage.keys()).length > 0) {
              await axios({
                method: "post",
                url: Configs.API_URL + "/upload/document",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: tempImage,
              })
                .then(function (response) {
                  const file_list = response.data.data?.map((el) => {
                    return { name: el.orgin_name, path: el.path };
                  });
                  tempData["file_path"] = file_list;
                })
                .catch(function (error) {
                  console.log(error);
                  Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
                  checkUpload = false;
                });
            }
            if (checkUpload && mode === "Add") {
              await axios({
                method: "post",
                url:
                  Configs.API_URL_hrMagenatement +
                  "/api/EmpExpense/saveEmpExpensesData",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: tempData,
              })
                .then(async function (response) {
                  if (isApprove) {
                    await sendApproveFromEmpToOrgz(response.data.data);
                  }

                  Swal.fire({
                    icon: "success",
                    title: isApprove
                      ? "ส่งอนุมัติสำเร็จ"
                      : "บันทึกข้อมูลสำเร็จ",
                    showConfirmButton: false,
                    timer: 1500,
                  }).then((result) => {
                    if (filterDataTable.data) {
                      sessionStorage.setItem(
                        "state_filter_expenses",
                        filterDataTable.data
                      );
                    }
                    window.location.href = previousURL;
                  });
                })
                .catch(function (error) {
                  console.log(error);
                  Swal.fire(
                    "Error",
                    "เกิดข้อผิดพลาด: " + error.response.data.error[0].errorDis,
                    "error"
                  );
                });
            } else if (checkUpload && mode === "Edit") {
              tempData["exp_id"] = id;
              // tempData["file_path"] = [];
              if (!tempData["file_path"]) {
                tempData["file_path"] = [];
              }

              if (ACCOUNTING_STATUS.waiting === tempData.status) {
                tempData.status = ACCOUNTING_STATUS.draft;
              }

              const deleteIMG = delIMG.map((el) => {
                return { uid: el.uid, status: el.status };
              });

              tempData["remove"] = deleteIMG;

              await axios({
                method: "post",
                url:
                  Configs.API_URL_hrMagenatement +
                  "/api/EmpExpense/updateEmpExpensesData",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: tempData,
              })
                .then(async function (response) {
                  if (isApprove) {
                    await sendApproveFromEmpToOrgz(id);
                  }

                  Swal.fire({
                    icon: "success",
                    title: isApprove
                      ? "ส่งอนุมัติสำเร็จ"
                      : "บันทึกข้อมูลสำเร็จ",
                    showConfirmButton: false,
                    timer: 1500,
                  }).then((result) => {
                    if (filterDataTable.data) {
                      sessionStorage.setItem(
                        "state_filter_expenses",
                        filterDataTable.data
                      );
                    }
                    window.location.href = previousURL;
                  });
                })
                .catch(function (error) {
                  console.log(error);
                  Swal.fire(
                    "Error",
                    "เกิดข้อผิดพลาด: " + error.response.data.error[0].errorDis,
                    "error"
                  );
                });
            }
          });
        }
      }
    });
  }

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleUploadSuccess = (file) => {
    if (fileList.length > 0 && fileList[0].status == "done") {
      setDelIMG([...delIMG, fileList[0]]);
    }
    setFileList(file.fileList);
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter(
      (item) => item.uid !== (file.uid || file.originFileObj.uid)
    );

    setDelIMG([...delIMG, file]);

    setFileList(newFileList);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);

    setPreviewOpen(true);
  };

  const GetEmployee = async () => {
    var get_filler_employee = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_no: "",
      emp_name_th: "",
      emp_type_id: "",
      status_User: true,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterEmployee",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_employee,
    })
      .then(async function (response) {
        if (response.data && Array.isArray(response.data.data)) {
          const data = response.data.data.filter(
            (el) =>
              el.emp_status_type_id !==
                "9437e6c7-68aa-4ee6-b7da-4b54b14c07ba" && // ลาออก
              el.is_active !== false
          );
          const user_id = getUser().fup;
          const emp_name_th = data.find((el) => el.emp_id == user_id);
          setAllEmployee({
            ...allEmployee,
            data: data,
            emp_name_th: emp_name_th?.emp_name_th || "-",
          });
          allEmployee.data = data;
          allEmployee.emp_name_th = emp_name_th?.emp_name_th || "-";
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(async () => {
    await getSignData();
    await DropdownExpenseTopic();
    await GetEmployee();

    if (mode !== "Add") {
      const data2 = sessionStorage.getItem("state_filter_expenses");
      if (data2) {
        setFilterDataTable({ data: data2 });
        filterDataTable.data = data2;
        sessionStorage.removeItem("state_filter_expenses");
      }
      await GetDataExpensesByID();
    }

    if (mode === "View") {
      setdisable(true);
      await DropdownVersion();
    }
  }, []);

  const handleCloseModal = () => {
    setDataForSave({
      ...dataForSave,
      id: "",
      remark_not_approve: "",
    });
    dataForSave.id = "";
    dataForSave.remark_not_approve = "";
    setFileList([]);
    setShowModal(false);
  };

  const handleOpenModal = (state) => {
    setModalState(state);
    setShowModal(true);
  };

  async function SendStatusExpensesByOrgz(state) {
    const data = {
      emp_exp_id: dataForSave.id,
      status_id: "",
      remark: dataForSave.remark_not_approve.trim(),
      user_id: getUser().fup,
      data: "",
      draft_status: "",
      state: state,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    if (state === "not_aprrove") {
      data.status_id = ACCOUNTING_STATUS.not_approve;
      if (!data.remark) {
        Swal.fire("Error", `กรุณากรอก Remark`, "error");
        return;
      }
    } else if (state === "aprrove") {
      data.status_id = ACCOUNTING_STATUS.approve_not_upload;
      data.remark = null;
      data.data = dataForSave.data;
      data.draft_status = ACCOUNTING_STATUS.draft;
    }
    // handleCloseModal();
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/orgzExpenses/SendStatusExpensesByOrgz",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: data,
    })
      .then(function (response) {
        if (response.data.data) {
          Swal.fire({
            icon: "success",
            title: "ทำรายการสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            if (state === "not_aprrove") {
              if (filterDataTable.data) {
                sessionStorage.setItem(
                  "state_filter_expenses",
                  filterDataTable.data
                );
              }
              window.location.href = previousURL;
            } else if (state === "aprrove") {
              window.location.href =
                "/accounting/organization_expenses/edit/" + response.data.data;
            }
            setDataForSave({
              ...dataForSave,
              remark_not_approve: "",
            });
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{mode} Emp. expenses</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Accounting</li>
                  <li className="breadcrumb-item active">Emp. expenses</li>
                  <li className="breadcrumb-item active">{mode}</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode === "View" ? (
              empData.status === "a86edd8a-5b08-445c-8377-9def93ceb8c0" &&
              checkEmp === "org" ? (
                <div className="row mb-2">
                  <div className="col-6 col-md-4 col-xl-2">
                    <button
                      type="button"
                      onClick={GoBackPage}
                      className="btn btn-block btn-primary"
                      style={{ marginTop: "10px" }}
                      id="btn-cancle"
                    >
                      ย้อนกลับ
                    </button>
                  </div>
                  <div className="col-6 col-md-4 col-xl-2">
                    <button
                      type="button"
                      onClick={() => {
                        // alert('test')
                        Swal.fire({
                          title: "คุณต้องการที่จะอนุมัติหรือไม่",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonText: `บันทึก`,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          cancelButtonText: `ยกเลิก`,
                        }).then((result) => {
                          if (result.isConfirmed) {
                            setDataForSave({
                              ...dataForSave,
                              id: empData.emp_id,
                              data: empData,
                            });
                            dataForSave.id = empData.emp_id;
                            dataForSave.data = empData;
                            SendStatusExpensesByOrgz("aprrove");
                          }
                        });
                      }}
                      className="btn btn-block btn-success"
                      style={{ marginTop: "10px" }}
                      id="btn-cancle"
                    >
                      อนุมัติ
                    </button>
                  </div>
                  <div className="col-6 col-md-4 col-xl-2">
                    <button
                      type="button"
                      className="btn btn-block btn-danger"
                      onClick={() => {
                        Swal.fire({
                          title: "คุณต้องการที่จะไม่อนุมัติหรือไม่",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonText: `บันทึก`,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          cancelButtonText: `ยกเลิก`,
                        }).then((result) => {
                          if (result.isConfirmed) {
                            setDataForSave({
                              ...dataForSave,
                              id: empData.emp_id,
                              data: empData,
                            });
                            dataForSave.id = empData.emp_id;
                            dataForSave.data = empData;
                            handleOpenModal("not_approve");
                          }
                        });
                      }}
                      style={{ marginTop: "10px" }}
                      id="btn-cancle"
                    >
                      ไม่อนุมัติ
                    </button>
                  </div>

                  <div className="col-6 col-md-4 col-xl-2">
                    <div className="form-group" style={{ zIndex: "99" }}>
                      <Select
                        filterOption={(option, searchText) => {
                          if (
                            option.data.label
                              ?.toString()
                              .toLowerCase()
                              .includes(searchText?.toLowerCase())
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        }}
                        isDisabled={false}
                        options={dropdownVersion}
                        value={selectVersion}
                        onChange={(selectedOptions) => {
                          setSelectVersion({
                            ...selectedOptions,
                          });

                          GetDataExpensesVersionByID(
                            selectedOptions?.is_not_log || false,
                            selectedOptions.value
                          );
                        }}
                      />
                      <label htmlFor="" style={{ marginTop: "-4px" }}>
                        Version
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row mb-2">
                  <div className="col-6 col-md-4 col-xl-2">
                    <button
                      type="button"
                      onClick={GoBackPage}
                      className="btn btn-block btn-primary"
                      style={{ marginTop: "10px" }}
                      id="btn-cancle"
                    >
                      ย้อนกลับ
                    </button>
                  </div>

                  <div className="col-6 col-md-4 col-xl-2">
                    <div className="form-group" style={{ zIndex: "99" }}>
                      <Select
                        filterOption={(option, searchText) => {
                          if (
                            option.data.label
                              ?.toString()
                              .toLowerCase()
                              .includes(searchText?.toLowerCase())
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        }}
                        isDisabled={false}
                        options={dropdownVersion}
                        value={selectVersion}
                        onChange={(selectedOptions) => {
                          setSelectVersion({
                            ...selectedOptions,
                          });

                          GetDataExpensesVersionByID(
                            selectedOptions?.is_not_log || false,
                            selectedOptions.value
                          );
                        }}
                      />
                      <label htmlFor="" style={{ marginTop: "-4px" }}>
                        Version
                      </label>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="row mb-2">
                <div
                  className="col-6 col-md-4 col-xl-2"
                  style={{ marginBottom: "10px" }}
                >
                  <button
                    type="button"
                    disabled={!hasSign}
                    onClick={() => {
                      SaveUpdateEmpExpensesData(false);
                    }}
                    className="btn btn-block btn-success"
                    id="btn-save"
                  >
                    บันทึก
                  </button>
                </div>

                <div
                  className="col-6 col-md-4 col-xl-2"
                  style={{ marginBottom: "10px" }}
                >
                  <button
                    type="button"
                    onClick={GoBackPage}
                    className="btn btn-block btn-danger"
                    id="btn-cancle"
                  >
                    ยกเลิก
                  </button>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <button
                    type="button"
                    disabled={!hasSign}
                    onClick={() => {
                      SaveUpdateEmpExpensesData(true);
                    }}
                    className="btn btn-block btn-primary"
                    id="btn-info"
                  >
                    ส่งอนุมัติ
                  </button>
                </div>
              </div>
            )}
          </div>
          <div
            className="col-sm-6"
            style={{ display: "flex", alignItems: "center" }}
            hidden={hasSign}
          >
            <div style={{ color: "red" }}>ไม่พบลายเซ็น. </div>
            <button
              style={{
                border: "none",
                cursor: "pointer",
                backgroundColor: "transparent",
                color: "red",
                outline: "none",
                padding: "5px 10px",
                textDecoration: "underline",
              }}
              onClick={() => (window.location.href = "/accounting/sign_config")}
            >
              คลิกเพื่อเพิ่มลายเซ็น
            </button>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{mode} Emp. expenses</h3>
            </div>

            <div className="card-body">
              <div className="row">
                <div
                  className="col-12 col-md-6 col-xl-5"
                  // style={{ "z-index": "99" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h5
                      style={{
                        borderBottom: "2px solid rgba(53, 121, 246, 1)",
                        width: "100%",
                        fontWeight: "bold",
                      }}
                    >
                      รายละเอียดการใช้จ่าย
                    </h5>
                  </div>

                  <div
                    className="form-group input-group"
                    /* style={{ "z-index": "99" }} */
                  >
                    <DatePicker
                      id="inputDate"
                      className="form-control"
                      wrapperClassName="form-control"
                      dateFormat="dd/MM/yyyy"
                      // placeholderText="DD/MM/YYYY"
                      // popperModifiers={{
                      //   flip: { behavior: ["bottom"] },
                      //   preventOverflow: { enabled: false },
                      //   hide: { enabled: false },
                      // }}
                      disabled={disable}
                      selected={empData.cost_date}
                      onChange={async (date) => {
                        setEmpData({
                          ...empData,
                          cost_date: date,
                        });
                      }}
                    />
                    <label>
                      วันที่เกิดค่าใช้จ่าย
                      <span style={{ color: "red" }}>
                        {!disable ? " *" : ""}
                      </span>
                    </label>
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="far fa-calendar-alt" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-5">
                  <div className="form-group">
                    <NumberFormat
                      type="text"
                      className="form-control"
                      decimalScale={2}
                      allowNegative={false}
                      thousandsGroupStyle="thousand"
                      thousandSeparator=","
                      disabled={disable}
                      required
                      value={empData.amount}
                      id="award_point_name"
                      name="award_point_name"
                      onChange={(e) => {
                        setEmpData({
                          ...empData,
                          amount: e.target.value.replace(/,/g, ""),
                        });
                      }}
                    />
                    <label>
                      ยอดเงิน
                      <span style={{ color: "red" }}>
                        {!disable ? " *" : ""}
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-12 col-md-6 col-xl-5"
                  // style={{ "z-index": "99" }}
                >
                  <h5
                    style={{
                      borderBottom: "2px solid rgba(53, 121, 246, 1)",
                      width: "100%",
                      fontWeight: "bold",
                    }}
                  >
                    กำหนดประเภทค่าใช้จ่าย
                  </h5>
                  <div className="form-group" /*  style={{ zIndex: "99" }} */>
                    <Select
                      isDisabled={disable}
                      options={
                        isVersion
                          ? dropdownExpenseTopic.version
                          : dropdownExpenseTopic.origin
                      }
                      // isOptionDisabled={(option) => option.disabled}
                      // formatOptionLabel={(label) => label.html}
                      filterOption={(option, searchText) => {
                        if (
                          option.data.label
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                          // || option.data.value
                          //   .toLowerCase()
                          //   .includes(searchText.toLowerCase())
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      value={
                        isVersion
                          ? dropdownExpenseTopic.version.filter((e) => {
                              return e.value === empData.cost_topic_id;
                            })
                          : dropdownExpenseTopic.origin.filter((e) => {
                              return e.value === empData.cost_topic_id;
                            })
                      }
                      onChange={(selectedOptions) => {
                        if (selectedOptions.check === true) {
                          setEmpData({
                            ...empData,
                            cost_topic_id: selectedOptions.value || "",
                            cost_topic_check: true,
                            store: allEmployee.emp_name_th || "สด",
                          });
                        } else {
                          setEmpData({
                            ...empData,
                            cost_topic_id: selectedOptions.value || "",
                            cost_topic_check: false,
                            store: empData.cost_topic_check
                              ? ""
                              : empData.store,
                          });
                        }
                      }}
                    />
                    <label>
                      หัวข้อค่าใช้จ่าย
                      <span style={{ color: "red" }}>
                        {!disable ? " *" : ""}
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-5">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="award_point_name"
                      value={empData.cost_detail}
                      // placeholder="กรอกยอดเงิน"
                      disabled={disable}
                      onChange={(e) => {
                        if (
                          e.target.value.replace(
                            /[\u0E31\u0E34-\u0E3A\u0E47-\u0E4E]/g,
                            ""
                          ).length < 140
                        ) {
                          setEmpData({
                            ...empData,
                            cost_detail: e.target.value,
                          });
                        }
                      }}
                    />
                    <label>
                      รายละเอียดค่าใช้จ่าย
                      <span style={{ color: "red" }}>
                        {!disable ? " *" : ""}
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-5">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="award_point_name"
                      disabled={disable || empData.cost_topic_check}
                      value={empData.store}
                      onChange={(e) => {
                        setEmpData({
                          ...empData,
                          store: e.target.value,
                        });
                      }}
                    />
                    <label style={{ fontSize: "16px" }}>
                      ชื่อร้านค้า (สำหรับออก Vat ได้):หรือประเภทการเดินทาง
                      <span style={{ color: "red" }}>
                        {!disable ? " *" : ""}
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-5">
                  <label htmlFor="">
                    ใบเสร็จ
                    <span style={{ color: "red" }}>{!disable ? " *" : ""}</span>
                  </label>
                  <div
                    style={{
                      marginTop: `${mode === "View" ? "-35px" : ""}`,
                    }}
                  >
                    <Upload
                      beforeUpload={() => false}
                      className="custom-upload"
                      listType="picture"
                      fileList={fileList}
                      onRemove={handleRemove}
                      onPreview={handlePreview}
                      onChange={handleUploadSuccess}
                      accept=".jpg,.png,.jpeg"
                      maxCount={1}
                      disabled={disable}
                      style={{ width: "100%" }}
                    >
                      {mode != "View" ? (
                        <ButtonAnt
                          type="primary"
                          style={{
                            backgroundColor: "#3579F626",
                            color: "#3579F6",
                            width: "100%",
                          }}
                          disabled={disable}
                        >
                          Choose files
                        </ButtonAnt>
                      ) : (
                        <></>
                      )}
                    </Upload>
                  </div>
                </div>
              </div>
              <Modal
                centered
                isOpen={showModal}
                toggle={handleCloseModal}
                // style={{ width: "50%" }}
              >
                <ModalHeader
                  style={{
                    /* border: "none" */ paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  toggle={
                    modalState === "view_remark_not_approve" ||
                    modalState === "view_slips"
                      ? handleCloseModal
                      : undefined
                  }
                >
                  <span style={{ fontSize: "24px", fontWeight: "800" }}>
                    {modalState === "upload_slips"
                      ? "แนบหลักฐานการชำระเงิน"
                      : modalState === "view_remark_not_approve"
                      ? "รายละเอียดการไม่อนุมัติ"
                      : modalState === "view_slips"
                      ? "รายละเอียดการอนุมัติ"
                      : "Remark"}
                  </span>
                </ModalHeader>
                <ModalBody style={{ padding: "0" }}>
                  <div style={{ padding: "15px" }}>
                    <textarea
                      type="text"
                      className="form-control"
                      required
                      rows={4}
                      id="award_point_name"
                      value={dataForSave.remark_not_approve}
                      onChange={(e) => {
                        // check_rf_name(e);
                        setDataForSave({
                          ...dataForSave,
                          remark_not_approve: e.target.value,
                        });
                        dataForSave.remark_not_approve = e.target.value;
                      }}
                    />
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        display: "flex",
                        gap: "10px",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        type="button"
                        style={{ minWidth: "130px" }}
                        onClick={() => {
                          SendStatusExpensesByOrgz("not_aprrove");
                        }}
                        className="btn btn-success"
                        id="btn-save"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        style={{ minWidth: "130px" }}
                        onClick={handleCloseModal}
                        className="btn btn-danger"
                        id="btn-cancle"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
              {previewImage && (
                <div className="expenses-image-popup" id="expenses-image-popup">
                  <Image
                    className="image"
                    wrapperStyle={{ display: "none", zIndex: 1051 }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) =>
                        !visible && setPreviewImage(""),
                    }}
                    src={previewImage}
                  />
                </div>
              )}
              {/* card-body */}
            </div>
            {/* card */}
          </div>
        </section>
      </div>
    </div>
  );
}

export default Emp_Expenses_AEV;
