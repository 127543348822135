import React, { Component, useEffect, useState } from "react";

import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../../config";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
} from "../../../../Utils/Common";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { async } from "q";
import NumberFormat from "react-number-format";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function RedeemPointHistory() {
  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  function numberWithCommas(x) {
    return (
      <NumberFormat
        thousandSeparator={true}
        value={x || 0}
        displayType="text"
      />
    );
    /*  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
  }

  const [searchOption, setSearchOption] = useState({
    productNo: "",
    productName: "",
    startDate: "",
    endDate: "",
  });

  const [redeemHistoryData, setRedeemHistoryData] = useState([]);

  async function GetRedeemHistory() {
    var tempData = {
      oem_id: getOem(),
      company_id: getUser().com,
      user_id: getUser().emp_id,
      productNo: searchOption.productNo,
      productName: searchOption.productName,
      startDate: searchOption.startDate,
      endDate: searchOption.endDate,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/pointHistory/GetRedeemHistory",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        // console.log(response.data);
        setRedeemHistoryData(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const clearFilterAward = async () => {
    setSearchOption({
      productNo: "",
      productName: "",
      startDate: "",
      endDate: "",
    });

    searchOption.productNo = "";
    searchOption.productName = "";
    searchOption.startDate = "";
    searchOption.endDate = "";

    await GetRedeemHistory();
  };

  useEffect(async () => {
    await GetRedeemHistory();
  }, []);

  const row_redeem_data = [];

  for (let index = 0; index < redeemHistoryData.length; index++) {
    const element = {};

    element["no"] = index + 1;
    element["productImage"] = (
      <img
        id="img"
        alt={redeemHistoryData[index].product_image || "..."}
        className="img-fluid rounded shadow border-0"
        src={
          redeemHistoryData[index].product_image_path !== "" &&
          redeemHistoryData[index].product_image_path !== null &&
          redeemHistoryData[index].product_image_path !== undefined
            ? Configs.API_URL_IMG_incentive +
              redeemHistoryData[index].product_image_path
            : userdefault_img.imgs
        }
        style={{
          width: "120px",
          height: "100px",
          position: "relative",
          display: "block",
          "margin-left": "auto",
          "margin-right": "auto",
        }}
      />
    );
    element["productNo"] = redeemHistoryData[index].product_no;
    element["productName"] = redeemHistoryData[index].ol_product_name;
    element["productPoint"] = (
      <div style={{ textAlign: "end" }}>
        {numberWithCommas(redeemHistoryData[index].ol_point)}
      </div>
    );
    element["updateDate"] = moment(redeemHistoryData[index].ol_update_date).format("DD/MM/YYYY ::: HH:mm น.");
    element["productStatus"] = redeemHistoryData[index].os_name;
    row_redeem_data.push(element);
  }

  const redeem_data = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "รูปสินค้า",
        field: "productImage",
        sort: "asc",
        width: 50,
      },
      {
        label: "รหัสสินค้า",
        field: "productNo",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อสินค้า",
        field: "productName",
        sort: "asc",
        width: 50,
      },
      {
        label: "จำนวนคะแนน",
        field: "productPoint",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่อัพเดตข้อมูล",
        field: "updateDate",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานะสินค้า",
        field: "productStatus",
        sort: "asc",
        width: 50,
      },
    ],
    rows: row_redeem_data,
  };

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>ประวัติการแลกคะแนน</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Incentive Point</li>
                  <li className="breadcrumb-item active">ประวัติการแลกคะแนน</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div class="card">
            <div className="card-header">
              <h3 className="card-title">ประวัติการแลกคะแนน</h3>
            </div>
            <div class="card-body">
              <div class="tab-content" id="custom-tabs-four-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="custom-tabs-award-point"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-award-point"
                >
                  <div className="container-fluid">
                    <div className="row mb-2" style={{ whiteSpace: "nowrap" }}>
                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            id="text_ap_point_id"
                            required="false"
                            value={searchOption.productNo}
                            onChange={(e) => {
                              setSearchOption({
                                ...searchOption,
                                productNo: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">รหัสสินค้า</label>{" "}
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            id="text_ap_point_name"
                            required="false"
                            value={searchOption.productName}
                            onChange={(e) => {
                              setSearchOption({
                                ...searchOption,
                                productName: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">ชื่อสินค้า</label>{" "}
                        </div>
                      </div>
                      <div
                        className="col-1.5 ml-2"
                        style={{ textAlign: "right" }}
                      >
                        <label className="mt-3" htmlFor="">
                          วันที่
                        </label>
                      </div>
                      <div
                        className="col-xl-2 col-md-2 col-sm-5 mt-2"
                        style={{ "z-index": "99" }}
                      >
                        <DatePicker
                          selected={searchOption.startDate}
                          dateFormat={"dd-MM-yyyy"}
                          id="date-picker-start"
                          onChange={async (date) => {
                            if (
                              searchOption.endDate != "" &&
                              date > searchOption.endDate
                            ) {
                              setSearchOption({
                                ...searchOption,
                                endDate: date,
                              });
                              searchOption.endDate = "";
                            }
                            setSearchOption({
                              ...searchOption,
                              startDate: date,
                            });
                            searchOption.startDate = date;
                          }}
                          showYearDropdown
                          showMonthDropdown
                          customInput={<CustomInput />}
                        />
                      </div>
                      <div
                        className="col-1.5 ml-2"
                        style={{ textAlign: "right" }}
                      >
                        <label className="mt-3" htmlFor="">
                          ถึง
                        </label>
                      </div>
                      <div
                        className="col-xl-2 col-md-2 col-sm-5 mt-2"
                        style={{ "z-index": "99" }}
                      >
                        <DatePicker
                          selected={searchOption.endDate}
                          dateFormat={"dd-MM-yyyy"}
                          id="date-picker-end"
                          onChange={async (date) => {
                            setSearchOption({
                              ...searchOption,
                              endDate: date,
                            });
                            searchOption.endDate = date;
                          }}
                          showYearDropdown
                          showMonthDropdown
                          minDate={searchOption.startDate}
                          customInput={<CustomInput />}
                        />
                      </div>
                      <div className="col-md-1 col-xl-1">
                        <div className="form-group ">
                          <button
                            type="button"
                            id="btn_search"
                            className="btn btn-block btn-info  "
                            onClick={async () => {
                              await GetRedeemHistory();
                            }}
                          >
                            ค้นหา
                          </button>
                        </div>
                      </div>

                      <div className=" col-md-2 col-xl-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            id="btn_clear"
                            className="btn btn-block btn-info"
                            onClick={() => clearFilterAward()}
                          >
                            ล้างข้อมูลตัวกรอง
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="table-responsive "
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={redeem_data}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer"></div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default RedeemPointHistory;
