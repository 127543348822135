import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../Utils/Common";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Card, Label } from "reactstrap";
import { useParams } from "react-router";

function Leave_information_mgt(mode) {
  const [emp_id, setemp_id] = useState();
  const [pageMode, setpageMode] = useState();
  const { leave_id } = useParams();
  const [data_entLeave, setdata_entLeave] = useState({
    pak_ron: "",
    la_kit: "",
    sick: "",
    out_pak_ron: "",
    out_la_kit: "",
    out_sick: "",
  });

  const [data_leave, setdata_leave] = useState({
    name: "",
    leave_status: "",
    detail: "",
    day_status: "",
    start_date: "",
    finish_date: "",
    start_time: "",
    finish_time: "",
    file_name: "",
    file_path: "",
    is_hr: "",
    is_department: "",
  });

  const [dateZindex, setdateZindex] = useState({
    date1: "98",
    date2: "98",
    time1: "98",
    time2: "98",
  });

  async function getEntLeaveByEmpId() {
    let temp = {
      iden_id: getUser().emp_id,
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getEntLeaveByEmpIdenId",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        setemp_id(response.data.data.emp_id);
        setdata_entLeave({
          ...data_entLeave,
          pak_ron: response.data.data.ent_vacation_leave,
          la_kit: response.data.data.ent_personal_leave,
          sick: response.data.data.ent_sick_leave,
          out_pak_ron: response.data.data.out_vacation_leave,
          out_la_kit: response.data.data.out_personal_leave,
          out_sick: response.data.data.out_sick_leave,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getLeaveByLeaveId() {
    axios({
      method: "get",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getLeaveByLeaveId?id=" +
        leave_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        var start_time;
        var finish_time;
        if (
          response.data.data.employee_status_id ==
          "ee11eac2-23f7-4844-aba3-152ebe82c63a"
        ) {
          start_time = moment(response.data.data.start_date)
            .startOf("day")
            .add(response.data.data.start_time.split(":")[0], "hours")
            .add(response.data.data.start_time.split(":")[1], "minutes");
          finish_time = moment(response.data.data.start_date)
            .startOf("day")
            .add(response.data.data.end_time.split(":")[0], "hours")
            .add(response.data.data.end_time.split(":")[1], "minutes");
        }

        let day_status = "";
        if (response.data.data.leave_type === 1) {
          day_status = "full_day";
        }

        if (response.data.data.leave_half_day === 1) {
          day_status = "half_day_morning";
        } else if (response.data.data.leave_half_day === 2) {
          day_status = "half_day_afternoon";
        }

        setdata_leave({
          ...data_leave,
          leave_status: response.data.data.status_name,
          detail: response.data.data.remark,
          day_status: day_status,
          start_date: new Date(response.data.data.start_date),
          finish_date: new Date(response.data.data.ent_date),
          start_time: start_time ? new Date(start_time) : "",
          finish_time: finish_time ? new Date(finish_time) : "",
          file_name: response.data.data.import_file_name,
          file_path: response.data.data.import_file,
          is_hr: response.data.data.is_hr,
          is_department: response.data.data.is_department,
        });
        // console.log(response.data.data.status_name);

        /*     setdata_entLeave({
          ...data_entLeave,
          pak_ron: response.data.data.ent_vacation_leave,
          la_kit: response.data.data.ent_personal_leave,
          sick: response.data.data.ent_sick_leave,
          out_pak_ron: response.data.data.out_vacation_leave,
          out_la_kit: response.data.data.out_personal_leave,
          out_sick: response.data.data.out_sick_leave

        }); */
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    /* var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "ข้อมูลพนักงาน";
    }); */
    if (mode.mode !== "add") {
      getLeaveByLeaveId();
    }

    if (mode.mode == "edit") {
      setpageMode("แก้ไข");
    } else if (mode.mode == "read") {
      setpageMode("");
    } else {
      setpageMode("เพิ่ม");
    }

    getEntLeaveByEmpId();
  }, []);

  const upload_file = async (e) => {
    // console.log("filname", e.target.files);
    if (e.target.files[0]) {
      const file = e.target.files[0];
      console.log("ss", file);
      var data = new FormData();
      data.append("Profile", file);

      var config_uploadProfile = {
        method: "post",
        url: Configs.API_URL + "/upload/document",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config_uploadProfile)
        .then(function (response) {
          if (response.data.status) {
            /*  console.log("filre",response.data); */

            setdata_leave({
              ...data_leave,
              file_name: response.data.data[0].orgin_name,
              file_path: response.data.data[0].path,
              /* image: response.data.data, */
            });
          }
          Swal.fire({
            icon: "success",
            title: "อัปโหลดไฟล์สำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", "File name !!!", "error");
        });
      //  }
    }
  };

  function cancle() {
    Swal.fire({
      title: "ระบบ?",
      text: "คุณต้องการยกเลิกใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
          "/Human_Resource/ข้อมูลการปฏิบัติงาน/leave_information";
      }
    });
  }

  function GetCountDayToLeave() {
    return data_leave.finish_date !== "" &&
    data_leave.start_date !== ""
      ? data_leave.day_status === "full_day"
        ? moment(data_leave.finish_date).diff(
            moment(data_leave.start_date),
            "days"
          ) + 1
        : data_leave.day_status === "half_day_morning" ||
          data_leave.day_status === "half_day_afternoon"
        ? moment(data_leave.finish_date).diff(
            moment(data_leave.start_date),
            "days"
          ) *
            0.5 +
          0.5
        : 0
      : 0;
  }

  function GetNumberLeaveByType(leave_type) {
    return leave_type === "ลากิจ" 
      ? data_entLeave.la_kit
      : leave_type === "ลาพักร้อน"
      ? data_entLeave.pak_ron
      : leave_type === "ลาป่วย"
      ? data_entLeave.sick
      : 0
  }

  async function SaveLeaveEmpData() {
    // console.log('data_leave', data_leave);
    Swal.fire({
      title: "ระบบ?",
      text: "คุณต้องการบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
    }).then((result) => {
      if (result.isConfirmed) {
        const error_list = [];
        // console.log(data_leave, data_leave.leave_status);

        if (
          data_leave.leave_status === null ||
          data_leave.leave_status === ""
        ) {
          let temp_err = {
            message: "โปรดเลือกประเภทการลา.",
          };
          error_list.push(temp_err);
        }

        if (data_leave.leave_status !== "ลาพักร้อน") {
          console.log(data_leave, data_leave.leave_status);
          if (data_leave.detail === null || data_leave.detail.trim() === "") {
            let temp_err = {
              message: "กรอกข้อมูลรายละเอียด.",
            };
            error_list.push(temp_err);
          }
        }

        if (
          data_leave.leave_status !== "ทำงานนอกสถานที่" &&
          data_leave.leave_status !== "ปฏิบัติงานนอกสถานที่"
        ) {
          if (data_leave.day_status === null || data_leave.day_status === "") {
            let temp_err = {
              message: "โปรดเลือกว่าลาเต็มวันหรือลาเป็นช่วงเวลา.",
            };
            error_list.push(temp_err);
          }
        }

        if (data_leave.start_date === null || data_leave.start_date === "") {
          let temp_err = {
            message: "โปรดเลือกวันที่เริ่มลา.",
          };
          error_list.push(temp_err);
        }

        if (data_leave.finish_date === null || data_leave.finish_date === "") {
          let temp_err = {
            message: "โปรดเลือกวันที่สิ้นสุดการลา.",
          };
          error_list.push(temp_err);
        }

        if (
          data_leave.leave_status === "ทำงานนอกสถานที่" ||
          data_leave.leave_status === "ปฏิบัติงานนอกสถานที่"
        ) {
          if (data_leave.start_time === null || data_leave.start_time === "") {
            let temp_err = {
              message: "โปรดระบุเวลาเริ่มต้น",
            };
            error_list.push(temp_err);
          }

          if (
            data_leave.finish_time === null ||
            data_leave.finish_time === ""
          ) {
            let temp_err = {
              message: "โปรดระบุเวลาสิ้นสุด",
            };
            error_list.push(temp_err);
          }

          if (
            (data_leave.start_date !== null ||
              data_leave.start_date !== "" ||
              data_leave.finish_date !== null ||
              data_leave.finish_date !== "") &&
            (data_leave.start_time !== null ||
              data_leave.start_time !== "" ||
              data_leave.finish_time !== null ||
              data_leave.finish_time !== "") &&
            // moment(data_leave.start_date).format("DD-MM-YYYY") ===
            //   moment(data_leave.finish_date).format("DD-MM-YYYY") &&
            moment(data_leave.start_time).format("HH:mm") >=
              moment(data_leave.finish_time).format("HH:mm")
          ) {
            let temp_err = {
              message: "เวลาเริ่มต้น ต้องน้อยกว่าเวลาสิ้นสุด",
            };
            error_list.push(temp_err);
          }
        }

        if (
            error_list.length === 0 
            && (data_leave.leave_status !== "ปฏิบัติงานนอกสถานที่" || data_leave.leave_status !== "ทำงานนอกสถานที่")
            && GetCountDayToLeave() > GetNumberLeaveByType(data_leave.leave_status)
          ) {
            let temp_err = {
              message: `ทำรายการไม่สำเร็จ วัน${data_leave.leave_status}ของคุณมีไม่พอ กรุณาติดต่อ Hr`,
            };
            error_list.push(temp_err);
        }

        if (error_list.length > 0) {
          var err_message = "";
          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }

          Swal.fire("Warning", err_message, "warning");
        } else {
          if (
            data_leave.leave_status === "ทำงานนอกสถานที่" ||
            data_leave.leave_status === "ปฏิบัติงานนอกสถานที่"
          ) {
            data_leave["day_status"] = "";
          } else {
            data_leave["start_time"] = "";
            data_leave["finish_time"] = "";
          }

          if (mode.mode === "add") {
            var temp = {
              emp_id: emp_id,
              data_leave: data_leave,
              company_id: getUser().com,
              oem_id: getOem(),
              path_url: Configs.API_URL_IMG
            };
            axios({
              method: "post",
              url:
                Configs.API_URL_hrMagenatement +
                "/api/hrManagement/saveEmpLeaveByUser",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            })
              .then(function (response2) {
                if (response2.data.data) {
                  Swal.fire({
                    icon: "success",
                    title: "Save",
                    showConfirmButton: false,
                    timer: 1500,
                  }).then((result) => {
                    window.location.href =
                      "/Human_Resource/ข้อมูลการปฏิบัติงาน/leave_information";
                  });
                }
              })
              .catch(function (error) {
                console.log(error.response);
                Swal.fire({
                  title: "error",
                  icon: 'error',
                  html: error.response.data.error.message
                });
                // Swal.fire("Error", error.response.data.error.message, "error");
              });
          } else if (mode.mode === "edit") {
            var temp = {
              leave_id: leave_id,
              emp_id: emp_id,
              data_leave: data_leave,
            };
            axios({
              method: "post",
              url:
                Configs.API_URL_hrMagenatement +
                "/api/hrManagement/updateEmpLeaveByAdmin",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            })
              .then(function (response2) {
                if (response2.data.data) {
                  Swal.fire({
                    icon: "success",
                    title: "Save",
                    showConfirmButton: false,
                    timer: 1500,
                  }).then((result) => {
                    window.location.href =
                      "/Human_Resource/ข้อมูลการปฏิบัติงาน/leave_information";
                  });
                }
              })
              .catch(function (error) {
                console.log(error.response);
                Swal.fire("Error", error.response.data.error.message, "error");
              });
          }
        }
      }
    });
  }

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  {pageMode} ข้อมูลวันลา
                  {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">ข้อมูลวันลาพนักงาน</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              {mode.mode == "read" ? (
                <></>
              ) : (
                <>
                  <div className="col-6 col-md-3 col-xl-1">
                    <button
                      type="button"
                      id="btn_save"
                      onClick={SaveLeaveEmpData}
                      class="btn btn-block btn-success "
                    >
                      บันทึก
                    </button>
                  </div>
                </>
              )}

              <div className="col-6 col-md-3 col-xl-1">
                <button
                  type="button"
                  id="btn_cancle"
                  onClick={cancle}
                  class="btn btn-block btn-danger "
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>วันลาคงเหลือ</h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    <font color="green">
                      ลาพักร้อน {data_entLeave.pak_ron} วัน
                    </font>
                  </h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    <font color="blue">ลากิจ {data_entLeave.la_kit} วัน</font>
                  </h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    <font color="red">ลาป่วย {data_entLeave.sick} วัน</font>
                  </h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    ประจำปี {"  "}
                    {parseInt(moment(new Date()).format("YYYY")) + 543}
                  </h4>
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>วันลาที่เกิน</h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    <font color="green">
                      ลาพักร้อน {data_entLeave.out_pak_ron} วัน
                    </font>
                  </h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    <font color="blue">
                      ลากิจ {data_entLeave.out_la_kit} วัน
                    </font>
                  </h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    <font color="red">ลาป่วย {data_entLeave.out_sick} วัน</font>
                  </h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    ประจำปี {"  "}
                    {parseInt(moment(new Date()).format("YYYY")) + 543}
                  </h4>
                </div>
              </div>
            </div>
            <div className="card-body" style={{ whiteSpace: "nowrap" }}>
              <div className="row">
                <div
                  className="col-1 col-md-1 col-xl-1"
                  style={{ textAlign: "right" }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id="pak_ron_rdo"
                    name="type_staus"
                    checked={
                      data_leave.leave_status === "ลาพักร้อน" ? true : false
                    }
                    onClick={() => {
                      setdata_leave({
                        ...data_leave,
                        leave_status: "ลาพักร้อน",
                      });
                      data_leave.leave_status = "ลาพักร้อน";
                    }}
                    disabled={mode.mode == "read" ? true : false}
                  />
                </div>
                <div className="col-5 col-md-1 col-xl-1">
                  <label htmlFor="pak_ron_rdo">ลาพักร้อน</label>
                </div>

                <div
                  className="col-1    col-md-0.5 col-xl-0.5"
                  style={{ textAlign: "right" }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id="la_kit_rdo"
                    name="type_staus"
                    checked={data_leave.leave_status === "ลากิจ" ? true : false}
                    onClick={() => {
                      setdata_leave({ ...data_leave, leave_status: "ลากิจ" });
                      data_leave.leave_status = "ลากิจ";
                    }}
                    disabled={mode.mode == "read" ? true : false}
                  />
                </div>
                <div className="col-5 col-md-1 col-xl-1">
                  <label htmlFor="la_kit_rdo">ลากิจ</label>
                </div>

                <div
                  className="col-1 col-md-0.5 col-xl-0.5"
                  style={{ textAlign: "right" }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id="sick_rdo"
                    name="type_staus"
                    checked={
                      data_leave.leave_status === "ลาป่วย" ? true : false
                    }
                    onClick={() => {
                      setdata_leave({ ...data_leave, leave_status: "ลาป่วย" });
                      data_leave.leave_status = "ลาป่วย";
                    }}
                    disabled={mode.mode == "read" ? true : false}
                  />
                </div>
                <div className="col-5  col-md-1 col-xl-1">
                  <label htmlFor="sick_rdo">ลาป่วย</label>
                </div>

                <div
                  className="col-1 col-md-0.5 col-xl-0.5"
                  style={{ textAlign: "right" }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id="out_site_rdo"
                    name="type_staus"
                    checked={
                      data_leave.leave_status == "ทำงานนอกสถานที่" ||
                      data_leave.leave_status == "ปฏิบัติงานนอกสถานที่"
                        ? true
                        : false
                    }
                    onClick={() => {
                      setdata_leave({
                        ...data_leave,
                        leave_status: "ทำงานนอกสถานที่",
                      });
                      data_leave.leave_status = "ทำงานนอกสถานที่";
                    }}
                    disabled={mode.mode == "read" ? true : false}
                  />
                </div>
                <div className="col-1 col-md-1 col-xl-1">
                  <label htmlFor="out_site_rdo">ปฏิบัติงานนอกสถานที่</label>
                </div>
              </div>

              <br />
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10 col-md-6 col-xl-6">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      value={data_leave.detail}
                      onChange={(e) => {
                        setdata_leave({
                          ...data_leave,
                          detail: e.target.value,
                        });
                      }}
                      disabled={mode.mode == "read" ? true : false}
                    />
                    <Label htmlFor="">
                      รายละเอียด{" "}
                      {(data_leave.detail === "" ||
                        data_leave.detail === null) &&
                      data_leave.leave_status !== "ลาพักร้อน" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </Label>
                  </div>
                </div>
              </div>
              {data_leave.leave_status == "ทำงานนอกสถานที่" ||
              data_leave.leave_status == "ปฏิบัติงานนอกสถานที่" ? (
                <></>
              ) : (
                <div className="row">
                  <div
                    className="col-1 col-md-1 col-xl-1"
                    style={{ textAlign: "right" }}
                  >
                    <input
                      type="radio"
                      className="form-check-input"
                      id="full_day_rdo"
                      name="type_staus_day"
                      checked={
                        data_leave.day_status === "full_day" ? true : false
                      }
                      onClick={() => {
                        setdata_leave({
                          ...data_leave,
                          day_status: "full_day",
                        });
                      }}
                      disabled={mode.mode == "read" ? true : false}
                    />
                  </div>
                  <div className="col-5 col-md-1 col-xl-1">
                    <label htmlFor="full_day_rdo">ลาทั้งวัน</label>
                  </div>

                  {/*  <div
                        className="col-1 col-md-1 col-xl-1"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          type="radio"
                          className="form-check-input"
                          id="half_day_rdo"
                          name="type_staus_day"
                          checked={data_leave.day_status == "half_day" ? true : false}
                          onClick={() => {
                            setdata_leave({ ...data_leave, day_status: "half_day" });
                          }}
                          disabled={mode.mode == "read" ? true : false}
                        />
                      </div>
                      <div className="col-5 col-md-1 col-xl-1">
                        <label htmlFor="half_day_rdo">ลาช่วงเวลา</label>
                      </div> */}
                  <div
                    className="col-1 col-md-1 col-xl-1"
                    style={{ textAlign: "right" }}
                  >
                    <input
                      type="radio"
                      className="form-check-input"
                      id="half_day_morning"
                      name="type_staus_day"
                      checked={
                        data_leave.day_status === "half_day_morning"
                          ? true
                          : false
                      }
                      onClick={() => {
                        setdata_leave({
                          ...data_leave,
                          day_status: "half_day_morning",
                          // finish_date: data_leave.start_date,
                        });
                        // data_leave.finish_date = data_leave.start_date;
                      }}
                      disabled={mode.mode == "read" ? true : false}
                    />
                  </div>
                  <div className="col-5 col-md-1 col-xl-1">
                    <label htmlFor="half_day_morning">ลาครึ่งเช้า</label>
                  </div>
                  <div
                    className="col-1 col-md-1 col-xl-1"
                    style={{ textAlign: "right" }}
                  >
                    <input
                      type="radio"
                      className="form-check-input"
                      id="half_day_afternoon"
                      name="type_staus_day"
                      checked={
                        data_leave.day_status === "half_day_afternoon"
                          ? true
                          : false
                      }
                      onClick={() => {
                        setdata_leave({
                          ...data_leave,
                          day_status: "half_day_afternoon",
                          // finish_date: data_leave.start_date,
                        });
                        // data_leave.finish_date = data_leave.start_date;
                      }}
                      disabled={mode.mode == "read" ? true : false}
                    />
                  </div>
                  <div className="col-5 col-md-1 col-xl-1">
                    <label htmlFor="half_day_afternoon">ลาครึ่งบ่าย</label>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-1"></div>

                <div
                  className="col-11 col-md-3 col-xl-2"
                  style={{ "z-index": dateZindex.date1 }}
                >
                  <div
                    className="form-group "
                    onClick={() => {
                      setdateZindex({
                        ...dateZindex,
                        time2: "98",
                        time1: "98",
                        date1: "99",
                        date2: "98",
                      });
                      dateZindex.time2 = "98";
                      dateZindex.time1 = "98";
                      dateZindex.date1 = "99";
                      dateZindex.date2 = "98";
                    }}
                  >
                    <DatePicker
                      selected={data_leave.start_date}
                      className="form-control"
                      dateFormat={"dd-MM-yyyy"}
                      disabled={mode.mode == "read" ? true : false}
                      customInput={<CustomInput />}
                      onChange={async (date) => {
                        setdata_leave({
                          ...data_leave,
                          start_date: date,
                        });

                        if (
                          date !== data_leave.finish_date &&
                          data_leave.finish_date !== ""
                        ) {
                          setdata_leave({
                            ...data_leave,
                            start_date: date,
                            // day_status: "full_day",
                          });
                          data_leave.start_date = date;
                          // data_leave.day_status = "full_day";
                        } else {
                          setdata_leave({
                            ...data_leave,
                            start_date: date,
                          });
                          data_leave.start_date = date;
                        }
                      }}
                      maxDate={data_leave.finish_date}
                    />
                    <label>
                      วันที่เริ่มลา
                      {/*    {data_leave.lev_start_date === "" ? (
                <span style={{ color: "red" }}> *</span>
            ) : (
                ""
            )} */}
                    </label>{" "}
                  </div>
                </div>

                <div className="col-1 col-md-0.5 col-xl-0.5"></div>

                <div
                  className="col-11 col-md-3 col-xl-2"
                  style={{ "z-index": dateZindex.date2 }}
                >
                  <div
                    className="form-group "
                    onClick={() => {
                      setdateZindex({
                        ...dateZindex,
                        time2: "98",
                        time1: "98",
                        date1: "98",
                        date2: "99",
                      });
                      dateZindex.time2 = "98";
                      dateZindex.time1 = "98";
                      dateZindex.date1 = "98";
                      dateZindex.date2 = "99";
                    }}
                  >
                    <DatePicker
                      selected={data_leave.finish_date}
                      className="form-control"
                      dateFormat={"dd-MM-yyyy"}
                      disabled={mode.mode == "read" ? true : false}
                      customInput={<CustomInput />}
                      //onChange={async (date) => SetDate_end_fg(date)}
                      onChange={async (date) => {
                        // setdata_leave({
                        //   ...data_leave,
                        //   finish_date: date,
                        // });
                        if (
                          data_leave.start_date !== date &&
                          data_leave.start_date !== ""
                        ) {
                          setdata_leave({
                            ...data_leave,
                            finish_date: date,
                            // day_status: "full_day",
                          });
                          data_leave.finish_date = date;
                          // data_leave.day_status = "full_day";
                        } else {
                          setdata_leave({
                            ...data_leave,
                            finish_date: date,
                          });
                          data_leave.finish_date = date;
                        }
                      }}
                      // maxDate={
                      //   data_leave.leave_status === "ทำงานนอกสถานที่" ||
                      //   data_leave.leave_status === "ปฏิบัติงานนอกสถานที่" ||
                      //   data_leave.leave_status === "ลาพักร้อน"
                      //     ? ""
                      //     : data_leave.start_date
                      // }
                      minDate={data_leave.start_date}
                    />
                    <label>
                      วันที่สิ้นสุดการลา
                      {/*  {data_leave.lev_end_date === "" ? (
                <span style={{ color: "red" }}> *</span>
            ) : (
                ""
            )} */}
                    </label>{" "}
                  </div>
                </div>
                {data_leave.leave_status == "ทำงานนอกสถานที่" ||
                data_leave.leave_status == "ปฏิบัติงานนอกสถานที่" ? (
                  <></>
                ) : (
                  <div className="col-11 col-md-3 col-xl-2">
                    <div className="form-group ">
                      <input
                        type="text"
                        className="form-control"
                        id="text_remark"
                        required
                        readOnly
                        value={
                          GetCountDayToLeave()
                        }
                        /*  disabled={true} */
                        /*  onChange={(e) => {
                        set_data_leave({
                          ...data_leave,
                          lev_remark: e.target.value,
                        });
                      }} */
                      />
                      <label>
                        จำนวนเวลา {"(วัน)"}
                        {/* {data_leave.lev_remark.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                      </label>
                    </div>
                  </div>
                )}
              </div>

              {data_leave.leave_status == "ทำงานนอกสถานที่" ||
              data_leave.leave_status == "ปฏิบัติงานนอกสถานที่" ? (
                <>
                  <div className="row">
                    <div className="col-1"></div>

                    <div
                      className="col-11 col-md-3 col-xl-2"
                      style={{ "z-index": dateZindex.time1 }}
                    >
                      <div
                        className="form-group input-group"
                        onClick={() => {
                          setdateZindex({
                            ...dateZindex,
                            time1: "99",
                            time2: "98",
                            date1: "98",
                            date2: "98",
                          });
                          dateZindex.time1 = "99";
                          dateZindex.time2 = "98";
                          dateZindex.date1 = "98";
                          dateZindex.date2 = "98";
                        }}
                      >
                        <DatePicker
                          selected={data_leave.start_time}
                          className="form-control"
                          wrapperClassName="form-control"
                          showTimeSelect
                          showTimeSelectOnly
                          // customInput={<CustomInput />}
                          disabled={mode.mode == "read" ? true : false}
                          onChange={async (time) => {
                            setdata_leave({
                              ...data_leave,
                              start_time: time,
                            });
                          }}
                          timeCaption="เวลา"
                          dateFormat="HH.mm"
                          timeFormat="HH.mm"
                          timeIntervals={1}
                          placeholderText="00.00"
                          // maxTime={data_leave.finish_time}
                        />
                        <label>เวลาเริ่มต้น</label>{" "}
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="far fa-calendar-alt" />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-1 col-md-0.5 col-xl-0.5"></div>

                    <div
                      className="col-11 col-md-3 col-xl-2"
                      style={{ "z-index": dateZindex.time2 }}
                    >
                      <div
                        className="form-group input-group"
                        onClick={() => {
                          setdateZindex({
                            ...dateZindex,
                            time2: "99",
                            time1: "98",
                            date1: "98",
                            date2: "98",
                          });
                          dateZindex.time2 = "99";
                          dateZindex.time1 = "98";
                          dateZindex.date1 = "98";
                          dateZindex.date2 = "98";
                        }}
                      >
                        <DatePicker
                          selected={data_leave.finish_time}
                          className="form-control"
                          wrapperClassName="form-control"
                          dateFormat="HH.mm"
                          timeFormat="HH.mm"
                          placeholderText="00.00"
                          showTimeSelect
                          showTimeSelectOnly
                          // customInput={<CustomInput />}
                          timeCaption="เวลา"
                          disabled={mode.mode == "read" ? true : false}
                          onChange={async (time) => {
                            // console.log(time);
                            setdata_leave({
                              ...data_leave,
                              finish_time: time,
                            });
                          }}
                          timeIntervals={1}
                          // minTime={data_leave.start_time}
                        />
                        <label>เวลาสิ้นสุด</label>{" "}
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="far fa-calendar-alt" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="row">
                <div className="col-1"></div>
                <div className="col-10 col-md-6 col-xl-6">
                  <label htmlFor="">แนบไฟล์ที่เกี่ยวข้อง</label>
                  {/* <small style={{ color: "red" }}>
                          ** Excel Form template **
                        </small> */}
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        disabled={mode.mode == "read" ? true : false}
                        // onClick={clickclear}
                        type="file"
                        required
                        className="custom-file-input"
                        id="exampleInputFile"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,image/png, image/jpeg"
                        onChange={upload_file}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="exampleInputFile"
                      >
                        {data_leave.file_name !== null &&
                        data_leave.file_name !== "" &&
                        data_leave.file_name !== undefined
                          ? data_leave.file_name
                          : "Select File"}
                      </label>
                    </div>
                  </div>
                  <br />
                  {/*  <div className="row">
                    <div className="col-12 col-md-12 col-xl-12">
                  <label htmlFor="exampleInputFile"> </label>
                  <img
                    id="img"
                    alt="..."
                    className="img-fluid rounded shadow border-0"
                    src={
                      data_leave.file_path !== "" &&
                      data_leave.file_path !== null &&
                      data_leave.file_path !== undefined
                        ? Configs.API_URL_IMG + data_leave.file_path
                        : userdefault_img.imgs
                    }
                    style={{
                      width: "300px",
                      height: "200px",
                    
                    }}
                  />
                  <br/>
                  <span style={{ color: "red", fontSize: "12px" }}>
                   
                  </span>
                  
                </div>
                  </div> */}
                </div>
              </div>
            </div>

            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Leave_information_mgt;
