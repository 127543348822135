import React, { Children, Component, useEffect, useState } from "react";
import Configs from "../../../../config";
import axios from "axios";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
} from "../../../../Utils/Common";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import "../Hr_log/HrLog.css";
import Select from "react-select";
import HrLog from "./HrLog";

function HrLogAdd(mode) {
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const { id } = useParams();
  const [nameNotNull, setNameNotNull] = useState(false);
  const [dataFileDoc, setDataFileDoc] = useState({
    rf_name: "",
    rc_id: "",
    rc_path_image: "",
    rc_path_name: "",
    file: "",
    rp_rf_id: "",
  });
  const [rl_path_file_null, setrl_path_file_null] = useState(false);
  const [product_category, setproduct_category] = useState({
    product_category_name: "",
    rl_name_file: "",
    rl_path_file: "",
    product_category_file: "",
    product_category_type: "",
  });
  console.log('product_category: ', product_category);
  const [allEmployee, setAllEmployee] = useState({ data: [] });
  const [getLogType, setGetLogType] = useState({ data: [] });
  const [dataHrlog, setDataHrlog] = useState({
    rl_usr_id: "",
    rl_usr_name: "",
    rl_created_date: moment().format("YYYY-MM-DD"),
    rl_created_by: "",
    rl_updated_by: "",
    rl_date_filed: "",
    rl_start_filed: "",
    rl_end_filed: "",
    rl_rml: "",
    rl_rml_name: "",
    rl_remark: "",
    rl_usr_id_filed: "",
    rl_usr_name_filed: "",
    rl_is_use: true,
    rl_is_conside: "",
    rl_is_giving: "",
    rl_is_close: "",
  });

  console.log('dataHrlog', dataHrlog);


  const [filCategory, setFilterCategory] = useState([]);
  const [filterteam, setFilterTeam] = useState({
    file_name: "",
    rc_id: "",
  });

  const GetEmployee = async () => {
    var get_filler_employee = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_no: "",
      emp_name_th: "",
      emp_type_id: "",
      status_User: true,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterEmployee",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_employee,
    })
      .then(async function (response) {
        console.log(response.data)
        setAllEmployee({ data: response.data.data });
        allEmployee.data = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const GetLogType = async () => {
    await axios({
      method: "get",
      url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/GetLogType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        console.log(response.data)
        setGetLogType({ data: response.data.data });
        getLogType.data = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  const CustomInput = ({ value, onClick }) => (
    <div className="input-group" style={{ width: "100%" }}>
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
        data-provide="datepicker"
        data-date-language="th-th"
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  const EmployeeSelect = () => (
    <Select
      isMulti
      className="form-control custom-select select2"
      isDisabled={disable}
      options={allEmployee.data.map((el) => ({
        value: el.emp_id,
        label: el.emp_name_th,
      }))}
      value={allEmployee.data.filter((el) =>
        dataHrlog.rl_usr_id.includes(el.emp_id)
      )}
      onChange={(selectedOptions) => {
        const selectedIds = selectedOptions.map((option) => option.value);
        setDataHrlog({
          ...dataHrlog,
          rl_usr_id: selectedIds,
        });
      }}
    />
  );
  function cancle_add_award_point(params) {
    if (mode.mode === "view") {
      window.location.href = "/Human_Resource/company_regulations/hr_Log/";
    }
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/Human_Resource/company_regulations/hr_Log/";
      }
    });
  }

  function getDataByID(id) {
    if (mode.mode == "view" || mode.mode == "edit") {
      var temp = {
        rl_id: id,
      };
      axios({
        method: "post",
        url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/filterHrLogByID",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data: temp,
      })
        .then(function (response) {
          console.log("response: ", response.data.data);
          const urs = { value: "", label: "ไม่ทราบชื่อ" }
          if (response.data.data[0].rl_usr_id) {
            const emp = allEmployee.data.find(el => {
              return el.emp_id == response.data.data[0].rl_usr_id
            })
            if (emp) {
              urs.value = emp.emp_id
              urs.label = emp.emp_name_th
            }
          }
          console.log('allEmployee.data', allEmployee.data)
          const str_type = { value: "", label: "" }
          if (response.data.data[0].rl_rml) {
            const s_type = getLogType.data.find(el => {
              return el.rml_id == response.data.data[0].rl_rml
            })
            if (s_type) {
              str_type.value = s_type.rml_id
              str_type.label = s_type.rml_name
            }
          }
          const usr_1 = { value: "", label: "" }
          console.log('getLogType.data', getLogType.data)
          if (response.data.data[0].rl_usr_id_filed) {
            const emp = allEmployee.data.find(el => {
              return el.emp_id == response.data.data[0].rl_usr_id_filed
            })
            if (emp) {
              usr_1.value = emp.emp_id
              usr_1.label = emp.emp_name_th
            }
          }
          console.log('usr_1', usr_1)
          setDataHrlog({
            ...dataHrlog,
            rl_name_file: response.data.data[0].rc_path_name,
            rl_path_file: response.data.data[0].rc_path_image,
            rl_usr_id: urs.value,
            rl_usr_name: urs.label,
            rl_date_filed: new Date(response.data.data[0].rl_date_filed),
            rl_start_filed: new Date(response.data.data[0].rl_start_filed),
            rl_end_filed: new Date(response.data.data[0].rl_end_filed),
            rl_rml: str_type.value,
            rl_rml_name: str_type.label,
            rl_remark: response.data.data[0].rl_remark?.trim(),
            rl_usr_id_filed: usr_1.value,
            rl_usr_name_filed: usr_1.label,
            rl_is_use: response.data.data[0].rl_is_use,
            rl_is_conside: response.data.data[0].rl_is_conside ? "pending" : "",
            rl_is_giving: response.data.data[0].rl_is_giving ? "point" : "",
            rl_is_close: response.data.data[0].rl_is_close ? "closed" : "",
            rl_name_file: response.data.data[0].rl_name_file,
            rl_path_file: response.data.data[0].rl_path_file,
            fup: getUser().emp_id
          })
          setproduct_category({
            rl_name_file: response.data.data[0].rl_name_file,
            rl_path_file: response.data.data[0].rl_path_file
          })
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
        });
    }
  }

  function isFileImage(file) {
    const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];
    return file && acceptedImageTypes.includes(file["type"]);
  }
  function SaveDataFile(filePath) {
    if (mode.mode === "add") {
      const temp = {
        rl_usr_id: dataHrlog.rl_usr_id,
        rl_usr_name: dataHrlog.rl_usr_name,
        rl_date_filed: dataHrlog.rl_date_filed,
        rl_start_filed: dataHrlog.rl_start_filed,
        rl_end_filed: dataHrlog.rl_end_filed,
        rl_rml: dataHrlog.rl_rml,
        rl_rml_name: dataHrlog.rl_rml_name,
        rl_remark: dataHrlog.rl_remark?.trim(),
        rl_usr_id_filed: dataHrlog.rl_usr_id_filed,
        rl_usr_name_filed: dataHrlog.rl_usr_name_filed,
        rl_is_use: dataHrlog.rl_is_use,
        rl_is_conside: dataHrlog.rl_is_conside ? true : false,
        rl_is_giving: dataHrlog.rl_is_giving ? true : false,
        rl_is_close: dataHrlog.rl_is_close ? true : false,
        rl_name_file: product_category.rl_name_file,
        rl_path_file: filePath,
        fup: getUser().emp_id,
        company_id: getUser().com,
        oem_id: getOem(),
      };
      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        axios({
          method: "post",
          url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/addHrLog",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(function (response) {
            if (response.data.data !== false) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href = "/Human_Resource/company_regulations/hr_Log";
              });
            } else {
              Swal.fire(
                "Error",
                "หมวดหมู่นี้มีอยู่ในระบบแล้ว",
                "error"
              );
            }
            //console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire(
              "Error",
              "เกิดข้อผิดพลาด: " + error.response.data.error[0].errorDis,
              "error"
            );
          });
      });
    }


    if (mode.mode === "edit") {
      let temp;
      if (filePath) {
        temp = {
          /* rc_id: id,
          rc_name: product_category.product_category_name.trim(),
          rc_name_file: product_category.rl_name_file,
          rc_path_image: filePath,
          rc_file_type: product_category.product_category_type, */
          rl_id: id,
          rl_usr_id: dataHrlog.rl_usr_id,
          rl_usr_name: dataHrlog.rl_usr_name,
          rl_date_filed: dataHrlog.rl_date_filed,
          rl_start_filed: dataHrlog.rl_start_filed,
          rl_end_filed: dataHrlog.rl_end_filed,
          rl_rml: dataHrlog.rl_rml,
          rl_rml_name: dataHrlog.rl_rml_name,
          rl_remark: dataHrlog.rl_remark?.trim(),
          rl_usr_id_filed: dataHrlog.rl_usr_id_filed,
          rl_usr_name_filed: dataHrlog.rl_usr_name_filed,
          rl_is_use: dataHrlog.rl_is_use,
          rl_is_conside: dataHrlog.rl_is_conside ? true : false,
          rl_is_giving: dataHrlog.rl_is_giving ? true : false,
          rl_is_close: dataHrlog.rl_is_close ? true : false,
          rl_name_file: product_category.rl_name_file,
          rl_path_file: filePath,
          fup: getUser().emp_id
        };
      } else {
        temp = {
          rl_id: id,
          rl_usr_id: dataHrlog.rl_usr_id,
          rl_usr_name: dataHrlog.rl_usr_name,
          rl_date_filed: dataHrlog.rl_date_filed,
          rl_start_filed: dataHrlog.rl_start_filed,
          rl_end_filed: dataHrlog.rl_end_filed,
          rl_rml: dataHrlog.rl_rml,
          rl_rml_name: dataHrlog.rl_rml_name,
          rl_remark: dataHrlog.rl_remark?.trim(),
          rl_usr_id_filed: dataHrlog.rl_usr_id_filed,
          rl_usr_name_filed: dataHrlog.rl_usr_name_filed,
          rl_is_use: dataHrlog.rl_is_use,
          rl_is_conside: dataHrlog.rl_is_conside ? true : false,
          rl_is_giving: dataHrlog.rl_is_giving ? true : false,
          rl_is_close: dataHrlog.rl_is_close ? true : false,
          rl_name_file: product_category.rl_name_file,
          rl_path_file: product_category.rl_path_file,
          fup: getUser().emp_id
        };
      }
      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement + "/api/companyRegulations/editHrLog",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(function (response) {
            if (response.data.data !== false) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href = "/Human_Resource/company_regulations/hr_Log";
              });
            } else {
              Swal.fire(
                "Error",
                "หมวดหมู่นี้มีอยู่ในระบบแล้ว",
                "error"
              );
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire(
              "Error",
              "เกิดข้อผิดพลาด: " + error.response.data.error[0].errorDis,
              "error"
            );
          });
      });
    }
  }

  async function uploadFile() {
    const data = new FormData();
    data.append("Profile", product_category.product_category_file);
    data.append("typeFile", "hrlog_upload_file");
    Swal.fire({
      title: "คุณต้องการที่จะบันทึกหรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed === true) {
        const error_list = [];
        if (dataHrlog.rl_usr_id == null) {
          let temp_err = {
            message: "กรุณาเลือกผู้กระทำความผิด",
          };
          error_list.push(temp_err);
        }
        if (dataHrlog.rl_date_filed == "") {
          let temp_err = {
            message: "กรุณาเลือกวันที่",
          };
          error_list.push(temp_err);
        }
        if (dataHrlog.rl_start_filed == "") {
          let temp_err = {
            message: "กรุณาเลือกเวลาเริ่มต้น",
          };
          error_list.push(temp_err);
        }
        if (dataHrlog.rl_end_filed == "") {
          let temp_err = {
            message: "กรุณาเลือกเวลาสิ้นสุด",
          };
          error_list.push(temp_err);
        }
        if (dataHrlog.rl_rml == "") {
          let temp_err = {
            message: "กรุณาเลือกประเภท",
          };
          error_list.push(temp_err);
        }
        if (dataHrlog.rl_remark?.trim() == "") {
          let temp_err = {
            message: "กรุณากรอกรายละเอียด",
          };
          error_list.push(temp_err);
        }
        if (product_category.rl_name_file == "") {
          let temp_err = {
            message: "กรุณาอัปโหลดรูปภาพ",
          };
          error_list.push(temp_err);
        }
        if (dataHrlog.rl_usr_id_filed == "") {
          let temp_err = {
            message: "กรุณาเลือกผู้ให้ข้อมูล",
          };
          error_list.push(temp_err);
        }
        if (dataHrlog.rl_is_conside == "" && dataHrlog.rl_is_giving == "" && dataHrlog.rl_is_close == "") {
          let temp_err = {
            message: "กรุณาเลือกสถานะ",
          };
          error_list.push(temp_err);
        }
        if (error_list.length > 0) {
          var err_message = "";
          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }
          Swal.fire("Error", err_message, "error");
        } else {
          var config_uploadProfile = {
            method: "post",
            url: Configs.API_URL + "/upload/profile",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: data,
          };
          axios(config_uploadProfile)
            .then(function (response) {
              if (response.data.status) {
                setDataFileDoc({
                  ...dataFileDoc,
                  file_path: response.data.data.path,
                });
                SaveDataFile(response.data.data.path);
              } else if (!response.data.status && mode.mode === "edit") {
                SaveDataFile();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    })
  }

  async function check_rf_name(e) {
    if (e.target.value.trim() === "") {
      setproduct_category({
        ...product_category,
        product_category_name: e.target.value
      })
      setDataFileDoc({
        ...dataFileDoc,
        rf_name: e.target.value,
      });
      setNameNotNull(true);
    } else if (e.target.value.trim().length >= 200) {
      setNameNotNull(false);
    } else {
      setproduct_category({
        ...product_category,
        product_category_name: e.target.value
      })
      setNameNotNull(false);
    }
  }

  const GetAllCatgory = async () => {
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/filterCategoryAll",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filterteam, // Sending filterteam as the request body
    })
      .then(async function (response) {
        setFilterCategory(response.data.data)
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  // useEffect(() => {
  //   GetAllCatgory();
  // }, []);

  useEffect(async () => {
    await GetEmployee();
    await GetLogType();
    if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else if (mode.mode === "view") {
      setdisable(true);
      setPageMode("View");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }

    getDataByID(id);
    // let id = "ed61b4af-a1b2-4bae-90d2-94f7befaa430"
    // GetSubDepartmerntList(id);
    // GetDepartmentData();
  }, []);

  const upload_file = async (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      console.log('file', file);

      if (isFileImage(file) === true) {
        setproduct_category({
          ...product_category,
          rl_name_file: file.name,
          product_category_file: file,
          product_category_type: file.type,
          rl_path_file: URL.createObjectURL(file),
        });
        setrl_path_file_null(false)
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid File Type",
          text: "Please upload a file of type: JPG, PNG, or PDF only.",
        });
      }
    }
  };

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Log ความไม่เรียบร้อย</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">กฏระเบียบบริษัท</li>
                  <li className="breadcrumb-item active">Log ความไม่เรียบร้อย</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    onClick={cancle_add_award_point}
                    className="btn btn-block btn-danger "
                    id="btn-cancle"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-6 col-md-3 col-xl-1" hidden={disable}>
                  <button
                    type="button"
                    onClick={uploadFile}
                    className="btn btn-block btn-success"
                    id="btn-save"
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={cancle_add_award_point}
                    className="btn btn-block btn-danger"
                    id="btn-cancle"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div
                  className="col-12 col-md-6 col-xl-3"
                  style={{ "z-index": "99" }}
                >
                  <div className="form-group" style={{ zIndex: "99" }}>
                    <Select
                      isDisabled={disable}
                      options={[
                        ...allEmployee.data.map((el) => ({
                          value: el.emp_id,
                          label: el.emp_name_th,
                          isDisabled: el.emp_id === dataHrlog.rl_usr_id_filed,
                        })),
                        { value: null, label: 'ไม่ทราบชื่อ' },
                      ]}
                      value={dataHrlog.rl_usr_id != null
                        ? {
                          value: dataHrlog.rl_usr_id,
                          label: dataHrlog.rl_usr_name,
                        }
                        : null}
                      onChange={(selectedOptions) => {
                        console.log("selectedOptions", selectedOptions);

                        if (selectedOptions.value === null && selectedOptions.label === "ไม่ทราบชื่อ") {
                          setDataHrlog({
                            ...dataHrlog,
                            rl_usr_id: selectedOptions.value || "",
                            rl_usr_name: selectedOptions.label || "",
                            rl_is_close: "closed",
                            rl_is_conside: "",
                            rl_is_giving: "",
                          });
                        } else {
                          // Normal handling for other selections
                          setDataHrlog({
                            ...dataHrlog,
                            rl_usr_id: selectedOptions.value || "",
                            rl_usr_name: selectedOptions.label || "",
                          });
                        }
                      }}
                    />
                    <label htmlFor="" style={{ marginTop: "-4px" }}>
                      ชื่อผู้กระทำความผิด
                      <span style={{ color: "red" }}>{!disable ? " *" : ""}</span>
                    </label>
                  </div>

                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-3">
                  <div class={`form-group hr-log-add-datepicker-custom ${disable ? "hr-log-date-disable" : ""}`} style={{ zIndex: "98" }}>
                    <DatePicker
                      // disabled={true}
                      selected={dataHrlog.rl_date_filed
                        // new Date(moment(new Date()).add(1, "day"))
                      }
                      required
                      locale="th"
                      id="text_start_work"
                      dateFormat={"dd-MM-yyyy"}
                      disabled={disable}
                      onChange={async (date) => {
                        setDataHrlog({
                          ...dataHrlog,
                          rl_date_filed: date,
                        });
                      }}
                      // minDate={new Date(forgetdate.startDate)}
                      customInput={<CustomInput />}
                    ></DatePicker>
                    <label>เลือกวันที่
                    <span style={{ color: "red" }}>{!disable ? " *" : ""}</span>
                    </label>
                  </div>

                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-3 col-xl-2">
                  <div class={`form-group hr-log-add-datepicker-custom ${disable ? "hr-log-date-disable" : ""}`} style={{ zIndex: "96" }}>
                    <DatePicker
                      // disabled={true}
                      selected={dataHrlog.rl_start_filed
                        // new Date(moment(new Date()).add(1, "day"))
                      }
                      required
                      locale="th"
                      id="text_start_work"
                      dateFormat="HH.mm"
                      disabled={disable}
                      showTimeSelect
                      showTimeSelectOnly
                      onChange={async (date) => {
                        setDataHrlog({
                          ...dataHrlog,
                          rl_start_filed: date,
                        });
                      }}
                      timeCaption="เวลา"
                      timeFormat="HH.mm"
                      timeIntervals={1}
                      placeholderText="00.00"
                      maxTime={dataHrlog.rl_end_filed ? new Date(dataHrlog.rl_end_filed) : undefined}
                      minTime={dataHrlog.rl_end_filed ? new Date(moment(dataHrlog.rl_end_filed).startOf("D")) : undefined}
                      customInput={<CustomInput />}
                    ></DatePicker>
                    <label>เวลาเริ่มต้น
                    <span style={{ color: "red" }}>{!disable ? " *" : ""}</span>
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-3 col-xl-2">
                  <div class={`form-group hr-log-add-datepicker-custom ${disable ? "hr-log-date-disable" : ""}`} style={{ zIndex: "95" }}>
                    <DatePicker
                      // disabled={true}
                      selected={dataHrlog.rl_end_filed
                        // new Date(moment(new Date()).add(1, "day"))
                      }
                      required
                      locale="th"
                      id="text_start_work"
                      dateFormat="HH.mm"
                      disabled={disable}
                      showTimeSelect
                      showTimeSelectOnly
                      onChange={async (date) => {
                        setDataHrlog({
                          ...dataHrlog,
                          rl_end_filed: date,
                        });
                      }}
                      timeCaption="เวลา"
                      timeFormat="HH.mm"
                      timeIntervals={1}
                      placeholderText="00.00"
                      minTime={dataHrlog.rl_start_filed ? new Date(dataHrlog.rl_start_filed) : undefined}
                      maxTime={dataHrlog.rl_start_filed ? new Date(moment(dataHrlog.rl_start_filed).endOf("D")) : undefined}
                      customInput={<CustomInput />}
                    ></DatePicker>
                    <label>เวลาสิ้นสุด
                    <span style={{ color: "red" }}>{!disable ? " *" : ""}</span>
                    </label>
                  </div>

                </div>
              </div>

              <div className="row">
                <div
                  className="col-12 col-md-6 col-xl-3"
                >
                  <div className="form-group" style={{ zIndex: "94" }}>
                    <Select
                      isDisabled={disable}
                      options={getLogType.data.map((el) => ({
                        value: el.rml_id,
                        label: el.rml_name,
                      }))}
                      value={dataHrlog.rl_rml
                        ? {
                          value: dataHrlog.rl_rml,
                          label: dataHrlog.rl_rml_name
                        }
                        : null}
                      onChange={(selectedOptions) => {

                        setDataHrlog({
                          ...dataHrlog,
                          rl_rml: selectedOptions.value || "",
                          rl_rml_name: selectedOptions.label || "",
                        });
                        dataHrlog.rl_rml = selectedOptions.value || "";
                        dataHrlog.rl_rml_name = selectedOptions.label || "";
                      }}
                    />
                    <label htmlFor="" style={{ marginTop: "-4px" }}>
                      เลือกประเภท
                      <span style={{ color: "red" }}>{!disable ? " *" : ""}</span>
                    </label>
                  </div>


                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-12 col-xl-6" >
                  <div className="form-group">
                    <textarea
                      type="text"
                      className="form-control"
                      required
                      id="award_point_name"
                      value={dataHrlog.rl_remark}
                      // placeholder="ตัวอย่าง สมศรี เรืองศักดา,Somsri Ruangsakda"
                      disabled={disable}
                      onChange={(e) => {
                        // check_rf_name(e);
                        setDataHrlog({
                          ...dataHrlog,
                          rl_remark: e.target.value,
                        });
                        dataHrlog.rl_remark = e.target.value;
                      }}
                    />
                    <label>
                      รายละเอียด
                      <span style={{ color: "red" }}>{!disable ? " *" : ""}</span>
                    </label>{" "}
                    {nameNotNull ? (
                      <span style={{ color: "red" }}>
                        *กรุณากรอกรายละเอียด
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="row ">
                <div className="col-12 col-md-6 col-xl-4" style={{ marginBottom: "30px" }}>
                  <label htmlFor="">แนบหลักฐาน<span style={{ color: "red" }}>{!disable ? " (PNG, JPEG) *" : " (PNG, JPEG)"}</span></label>
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="exampleInputFile"
                        disabled={disable}
                        accept="image/*"
                        onChange={upload_file}
                      />
                      <label
                        style={{ zIndex: "unset" }}
                        className="custom-file-label"
                        htmlFor="exampleInputFile"
                      >
                        {product_category.rl_name_file !== "" &&
                          product_category.rl_name_file !== null &&
                          product_category.rl_name_file !== undefined

                          ? product_category.rl_name_file.length > 25
                            ? product_category.rl_name_file.substring(0, 25) +
                            "..."
                            : product_category.rl_name_file
                          : " "}
                      </label>
                    </div>
                  </div>
                  {rl_path_file_null ? (
                    <span style={{ color: "red" }}>
                      *กรุณาอัปโหลดรูปภาพ
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-7 col-xl-3">
                  <label htmlFor="exampleInputFile"> </label>
                  <img
                    id="img"
                    alt="..."
                    className="img-fluid rounded shadow border-0"
                    src={
                      product_category.rl_path_file !== "" &&
                        product_category.rl_path_file !== null &&
                        product_category.rl_path_file !== undefined
                        ? product_category.rl_path_file?.indexOf("blob") >= 0
                          ? product_category.rl_path_file
                          : Configs.API_URL_IMG +
                          product_category.rl_path_file
                        : userdefault_img.imgs
                    }
                    style={{
                      width: "400px",
                      height: "300px",
                      objectFit: "cover",
                      position: "relative",
                      marginTop: "10px",
                    }}
                  />
                  <br />{" "}
                  <span style={{ color: "red", fontSize: "15px" }}>
                    Recommend Size:400x300px{" "}
                  </span>
                  <span style={{ color: "red", fontSize: "12px" }}></span>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-12 col-md-6 col-xl-3"
                  style={{ "z-index": "99" }}
                >
                  <div className="form-group">
                    <Select
                      isDisabled={disable}
                      options={allEmployee.data.map((el) => ({
                        value: el.emp_id,
                        label: el.emp_name_th,
                        isDisabled: el.emp_id === dataHrlog.rl_usr_id
                      }))}

                      value={dataHrlog.rl_usr_id_filed
                        ? {
                          value: dataHrlog.rl_usr_id_filed,
                          label: dataHrlog.rl_usr_name_filed
                        }
                        : null}
                      onChange={(selectedOptions) => {

                        setDataHrlog({
                          ...dataHrlog,
                          rl_usr_id_filed: selectedOptions.value || "",
                          rl_usr_name_filed: selectedOptions.label || "",
                        });
                        dataHrlog.rl_usr_id_filed = selectedOptions.value || "";
                        dataHrlog.rl_usr_name_filed = selectedOptions.label || "";
                      }}
                    />
                    <label htmlFor="" style={{ marginTop: "-4px" }}>
                      ชื่อผู้ให้ข้อมูล
                      <span style={{ color: "red" }}>{!disable ? " *" : ""}</span>
                    </label>
                  </div>


                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-12 col-xl-12"><label htmlFor="status" style={{ marginTop: "-4px" }}>สถานะ<span style={{ color: "red" }}>{!disable ? " *" : ""}</span></label></div>
                <div className="col-12 col-md-3 col-xl-2">
                  <div className="form-group">
                    <div id="status" className="status-options d-flex">
                      <label htmlFor="status-pending" className="status-text text-danger" style={{ marginTop: "-1.5mm", fontSize: "15pt", paddingLeft: "10pt", whiteSpace: 'nowrap' }}>รอพิจารณา</label>
                      <div className="">
                        <input
                          type="radio"
                          id="status-pending"
                          value="rl_is_conside"
                          disabled={disable || dataHrlog.rl_usr_name === "ไม่ทราบชื่อ"}
                          checked={dataHrlog.rl_is_conside === "pending"}
                          onChange={(e) => {
                            setDataHrlog({
                              ...dataHrlog,
                              rl_is_conside: e.target.checked ? "pending" : "",
                              rl_is_giving: "",
                              rl_is_close: "",
                            });
                          }}
                        />

                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-3 col-xl-2">
                  <div className="form-group">
                    <div id="status" className="status-options d-flex">
                      <label htmlFor="stutus-point" className="status-text text-warning" style={{ marginTop: "-1.5mm", fontSize: "15pt", paddingLeft: "10pt", whiteSpace: 'nowrap' }}>รอมอบ Point</label>
                      <div className="">
                        <input
                          type="radio"
                          id="stutus-point"
                          value="rl_is_giving"
                          disabled={disable || dataHrlog.rl_usr_name === "ไม่ทราบชื่อ"}
                          checked={dataHrlog.rl_is_giving === "point"}
                          onChange={(e) => {
                            setDataHrlog({
                              ...dataHrlog,
                              rl_is_giving: e.target.checked ? "point" : "",
                              rl_is_conside: "",
                              rl_is_close: "",
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-3 col-xl-2">
                  <div className="form-group">
                    <div id="status" className="status-options d-flex">
                      <label htmlFor="status-closed" className="status-text text-success" style={{ marginTop: "-1.5mm", fontSize: "15pt", paddingLeft: "10pt", whiteSpace: 'nowrap' }}>ปิด Case</label>
                      <div className="">
                        <input
                          type="radio"
                          id="status-closed"
                          value="rl_is_close"
                          disabled={disable}
                          checked={dataHrlog.rl_is_close === "closed"}
                          onChange={(e) => {
                            setDataHrlog({
                              ...dataHrlog,
                              rl_is_close: e.target.checked ? "closed" : "",
                              rl_is_conside: "",
                              rl_is_giving: "",
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>


            </div>
            {/* card */}
          </div>

        </section>
      </div>
    </div>
  );
}

export default HrLogAdd;
