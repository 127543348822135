import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../../../component/Footter";
import Header from "../../../../component/Header";
import Sidebar from "../../../../component/Sidebar";
import axios from "axios";
import Configs from "../../../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../Utils/Common";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import { useParams } from "react-router";
import Select from "react-select";
import jsPDF from "jspdf";
import {
  Get_font_pdf_th,
  Get_font_pdf_th2,
} from "../../../../assets/font/pdf_font";
import { userdefault_img, logo_smz } from "../../../../routes/imgRoute/imgUrl";

function Assign_Work_Add(mode) {
  const [filterDataTable, setFilterDataTable] = useState({ data: null });
  const { task_id, week, year, user_id } = useParams();
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);

  const [inputData, setInputData] = useState({
    id: null,
    task: null,
    task_detail: null,
    assign_to_id: mode.mode === "add" || mode.mode === "addt" ? [] : null,
    due_date: null,
  });

  const [employeeData, setEmployeeData] = useState({
    data: [],
  });

  const [employeeOption, setEmployeeOption] = useState({
    data: [],
  });
  const [getEmployeeWorkTypeData, setGetEmployeeWorkTypeData] = useState({
    data: [],
  });

  const [selectEmpDropdown, setSelectEmpDropdown] = useState({
    data: [],
  });

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  function saveOption() {
    const error_list = [];

    if (inputData.task === null || inputData.task.trim() === "") {
      let temp_err = {
        message: "กรุณากรอก Task",
      };
      error_list.push(temp_err);
    }
    if (
      (mode.mode === "add" || mode.mode === "addt") &&
      inputData.assign_to_id.length === 0
    ) {
      let temp_err = {
        message: "กรุณาเลือกคนที่ต้องการ Assign Task",
      };
      error_list.push(temp_err);
    }
    if (
      (mode.mode !== "add" || mode.mode !== "addt") &&
      inputData.assign_to_id === null
    ) {
      let temp_err = {
        message: "กรุณาเลือกคนที่ต้องการ Assign Task",
      };
      error_list.push(temp_err);
    }
    if (inputData.due_date === null) {
      let temp_err = {
        message: "กรุณาเลือกวันที่",
      };
      error_list.push(temp_err);
    }
    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      let axiosData = {};

      // const currentDate = new Date(inputData.due_date);
      // const startDate = new Date(currentDate.getFullYear(), 0, 1);
      // let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
      // let weekNumber = Math.ceil(days / 7);

      if (mode.mode === "add" || mode.mode === "addt") {
        const temp = {
          task: inputData.task.trim(),
          task_detail: inputData.task_detail || null,
          assign_to_id: inputData.assign_to_id,
          due_date: inputData.due_date,
          assign_by_id: getUser().emp_id,
          // week: weekNumber,
          // year: currentDate.getFullYear(),
          week: moment(new Date(inputData.due_date)).isoWeekday(1).week(),
          year: moment(new Date(inputData.due_date)).isoWeekYear(),
          company_id: getUser().com,
          oem_id: getOem(),
        };

        axiosData = {
          method: "post",
          url:
            Configs.API_URL_hrMagenatement + "/api/hrManagement/saveToDoTask",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        };
      }
      if (mode.mode === "edit" || mode.mode === "editt") {
        const temp = {
          task_id: inputData.id,
          task: inputData.task.trim(),
          task_detail: inputData.task_detail || null,
          assign_to_id: inputData.assign_to_id,
          due_date: inputData.due_date,
          // week: weekNumber,
          // year: currentDate.getFullYear(),
          week: moment(new Date(inputData.due_date)).isoWeekday(1).week(),
          year: moment(new Date(inputData.due_date)).isoWeekYear(),
        };

        axiosData = {
          method: "post",
          url:
            Configs.API_URL_hrMagenatement + "/api/hrManagement/updateToDoTask",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        };
      }
      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then(async (result) => {
        await axios(axiosData)
          .then(function (response) {
            if (filterDataTable.data) {
              sessionStorage.setItem(
                "state_filter_assign_work",
                filterDataTable.data
              );
            }
            // if (result.isConfirmed) {
            if (
              mode.mode === "add" ||
              mode.mode === "edit" ||
              mode.mode === "read"
            ) {
              window.location.href = mode.url;
            } else {
              window.location.href = `${mode.url}/${week}/${year}`;
            }
            // }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire({
              icon: "warning",
              // title: "กรุณากรอกข้อมูลใหม่",
              text: error.response.data.error.message,
            });
          });
      });
    }
  }

  function cancelOption(params) {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        if (filterDataTable.data) {
          sessionStorage.setItem(
            "state_filter_assign_work",
            filterDataTable.data
          );
        }
        if (
          mode.mode === "add" ||
          mode.mode === "edit" ||
          mode.mode === "read"
        ) {
          window.location.href = mode.url;
        } else if (mode.mode === "readt") {
          window.location.href = mode.url;
        } else {
          window.location.href = `${mode.url}/${week}/${year}`;
        }
      }
    });
  }

  const GetEmployeeWorkType = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      getAll: true,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmpWorkType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(async function (response) {
        const arrList = [];

        for (let item of response.data.data) {
          let temp = {
            id: item.id,
            contract_type_name: item.contract_type_name,
            sort: item.sort,
          };

          arrList.push(temp);
        }

        // arrList.sort((a, b) => {
        //   if (a.sort === null) {
        //     return 1;
        //   }

        //   if (b.sort === null) {
        //     return -1;
        //   }

        //   if (a.sort === b.sort) {
        //     return 0;
        //   }

        //   return a.sort < b.sort ? -1 : 1;
        // });

        // console.log(arrList);

        setGetEmployeeWorkTypeData({ data: arrList });
        getEmployeeWorkTypeData.data = arrList;

        // console.log('getEmployeeWorkTypeData', getEmployeeWorkTypeData.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const FilterEmployee = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      resign_status: true,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterEmployee",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        let empAll = [];

        for (let item of getEmployeeWorkTypeData.data) {
          const empEqualType = response.data.data.filter((empItem) => {
            return empItem.emp_contract_type_id === item.id;
          });

          const empEqualType_1 = empEqualType.filter((item) => {
            return (
              item.emp_status_type_id === "6c4a34ff-f9d4-4f7e-91ae-5a90697b10cb"
            );
          });

          const empEqualType_2 = empEqualType.filter((item) => {
            return (
              item.emp_status_type_id !== "6c4a34ff-f9d4-4f7e-91ae-5a90697b10cb"
            );
          });

          empEqualType_1.sort((a, b) => {
            if (a.emp_no === null) {
              return 1;
            }

            if (b.emp_no === null) {
              return -1;
            }

            if (a.emp_no.toLowerCase() === b.emp_no.toLowerCase()) {
              return 0;
            }

            return a.emp_no.toLowerCase() < b.emp_no.toLowerCase() ? -1 : 1;
          });

          empEqualType_2.sort((a, b) => {
            if (a.emp_no === null) {
              return 1;
            }

            if (b.emp_no === null) {
              return -1;
            }

            if (a.emp_no.toLowerCase() === b.emp_no.toLowerCase()) {
              return 0;
            }

            return a.emp_no.toLowerCase() < b.emp_no.toLowerCase() ? -1 : 1;
          });

          empAll.push(...empEqualType_1);
          empAll.push(...empEqualType_2);
        }

        // response.data.data.sort((a, b) => {
        //   if (a.emp_name_th === null) {
        //     return 1;
        //   }

        //   if (b.emp_name_th === null) {
        //     return -1;
        //   }

        //   if (a.emp_name_th === b.emp_name_th) {
        //     return 0;
        //   }

        //   return a.emp_name_th < b.emp_name_th ? -1 : 1;
        // });

        setEmployeeData({ data: empAll });
        employeeData.data = empAll;

        // const options = [{ value: "", label: "Select All" }];
        const options = [];

        empAll.forEach((el) => {
          var element = {};
          element["value"] = el.emp_id;
          element["label"] = `${el.emp_name_th || ""} ${
            el.emp_nickname ? "( " + el.emp_nickname + " )" : ""
          } `;
          options.push(element);
        });

        setEmployeeOption({ data: options });
        employeeOption.data = options;

        if (user_id !== undefined) {
          setSelectEmpDropdown({
            data: options.filter((item) => {
              return item.value === user_id;
            }),
          });
          selectEmpDropdown.data = options.filter((item) => {
            return item.value === user_id;
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const FilterTeam = async () => {
    let get_filler_assign = {
      oem_id: getOem(),
      company_id: getUser().com,
      user: getUser().emp_id,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterTeam",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_assign,
    })
      .then(async function (response) {
        if (response.data.data.length > 0) {
          const tempMemberId = response.data.data.map(
            (item) => item.employee_id
          );
          const tempEmployeeOption = JSON.parse(
            JSON.stringify(employeeOption.data)
          );

          const matchingList = tempEmployeeOption.filter((item) => {
            return tempMemberId.includes(item.value);
          });
          const nonMatchingList = tempEmployeeOption.filter(
            (item) => !tempMemberId.includes(item.value)
          );

          setEmployeeOption({ data: [...matchingList, ...nonMatchingList] });
          employeeOption.data = [...matchingList, ...nonMatchingList];
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const GetAssign = async () => {
    let get_com_oem = {
      task_id: task_id,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getAssign",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        setInputData({
          ...inputData,
          id: response.data.data.task_id,
          task: response.data.data.task,
          task_detail: response.data.data.task_detail,
          assign_to_id: response.data.data.assign_to_id,
          due_date: new Date(response.data.data.due_date),
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (mode.mode === "read" || mode.mode == "readt") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add" || mode.mode === "addt") {
      setdisable(false);
      setPageMode("Add");

      const currentDate = new Date();
      const dueDate = new Date(
        currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 5)
      );

      setInputData({
        ...inputData,
        due_date: dueDate,
      });
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  const todoId = sessionStorage.getItem("todoId");
  useEffect(async () => {
    await GetEmployeeWorkType();
    await FilterEmployee();
    await FilterTeam();

    if (
      mode.mode == "read" ||
      mode.mode == "readt" ||
      mode.mode == "edit" ||
      mode.mode == "editt"
    ) {
      await GetAssign();
    }

    if (todoId != null) {
      const matchedOptions = employeeOption.data.filter((option) => {
        return option.value === todoId;
      });

      const dataId = matchedOptions.map((item) => item.value);
      const currentDate = new Date();
      const dueDate = new Date(
        currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 5)
      );
      setSelectEmpDropdown({
        data: matchedOptions,
      });
      setInputData({
        ...inputData,
        assign_to_id: dataId,
        due_date: dueDate,
      });
      sessionStorage.removeItem("todoId");
    }

    const data2 = sessionStorage.getItem("state_filter_assign_work");
    if (data2) {
      setFilterDataTable({ data: data2 });
      filterDataTable.data = data2;
      sessionStorage.removeItem("state_filter_assign_work");
    }
  }, []);

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Task {pageMode}
                  {"  "}
                  {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Assign</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode.mode === "read" || mode.mode === "readt" ? (
              <div className="row mb-2">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">To Do List</h3>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <textarea
                      type="text"
                      className="form-control"
                      required
                      id="text_name_th"
                      value={inputData.task}
                      // placeholder=""
                      disabled={disable}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          task: e.target.value,
                        });
                      }}
                      style={{ padding: "0px 10px",  fontSize: "25px", }}
                    />
                    
                    <label>
                      Task
                      {inputData.task === "" || inputData.task === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-12 col-xl-6">
                  <div class="form-group">
                    <textarea
                      type="text"
                      className="form-control"
                      required
                      id="text_name_th"
                      style={{
                        height: "150px",
                        fontSize: "25px",
                        padding: "5px 10px",
                      }}
                      value={inputData.task_detail}
                      // placeholder=""
                      disabled={disable}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          task_detail: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Task Detail 
                      {/* {employee.emp_name_th === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 col-xl-3">
                  <div class="form-group">
                    {/* <select
                      className="form-control custom-select select2"
                      type="text"
                      required
                      id="text_nation_id"
                      value={
                        employeeData.data.find((value) => {
                          return value.emp_id === inputData.assign_to_id;
                        })?.emp_id
                      }
                      disabled={disable}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          assign_to_id: e.target.value,
                        });
                      }}
                    >
                      <option value="" disabled selected>
                        ----เลือก----
                      </option>
                      {employeeData.data.map((item) => {
                        return (
                          <option value={item.emp_id}>
                            {`${item.emp_name_th || ""} ${
                              item.emp_nickname
                                ? "( " + item.emp_nickname + " )"
                                : ""
                            } `}
                          </option>
                        );
                      })}
                    </select> */}
                    {mode.mode === "add" || mode.mode === "addt" ? (
                      <>
                        <Select
                          isMulti
                          isDisabled={disable}
                          options={employeeOption.data}
                          value={selectEmpDropdown.data}
                          onChange={(e) => {
                            setSelectEmpDropdown({
                              data: e,
                            });
                            selectEmpDropdown.data = e;

                            if (e !== null) {
                              const dataId = e.map((item) => item.value);

                              setInputData({
                                ...inputData,
                                assign_to_id: dataId,
                              });
                            } else {
                              setInputData({
                                ...inputData,
                                assign_to_id: [],
                              });
                            }
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Select
                          isDisabled={disable}
                          options={employeeOption.data}
                          value={employeeOption.data.filter((e) => {
                            return e.value === inputData.assign_to_id;
                          })}
                          onChange={(e) => {
                            if (e !== null) {
                              setInputData({
                                ...inputData,
                                assign_to_id: e.value,
                              });
                            }
                          }}
                        />
                      </>
                    )}
                    <label>
                      Assign To
                      {inputData.assign_to_id === "" ||
                      inputData.assign_to_id === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-3">
                  <div class="form-group">
                    <DatePicker
                      selected={inputData.due_date}
                      disabled={disable}
                      wrapperClassName="w-100"
                      locale="th"
                      id="text_start_work"
                      dateFormat={"dd-MM-yyyy"}
                      onChange={async (date) => {
                        setInputData({
                          ...inputData,
                          due_date: date,
                        });
                        // SetDate_start(date);
                      }}
                      selectsStart
                      showYearDropdown
                      showMonthDropdown
                      // startDate={employee.emp_start_work}
                      // endDate={employee.emp_end_work}
                      // maxDate={employee.emp_end_work}
                      minDate={new Date()}
                      customInput={<CustomInput />}
                    />
                    <label>
                      Due Date
                      {inputData.due_date === "" ||
                      inputData.due_date === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Assign_Work_Add;
