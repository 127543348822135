import React, { Component, useEffect, useState, useRef } from "react";

import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser } from "../../Utils/Common";
import Select from "react-select";
import moment from "moment";
import { Tree, TreeNode } from "react-organizational-chart";

import CompanyCard from "./Manage_Component/Company_Card";

function Manage_Company() {
  const validThai = new RegExp(`[\u0E00-\u0E7F]`);
  const validEng = new RegExp(`[a-zA-Z]`);
  const validNumber = new RegExp(`^(?:[0-9]*)$`);

  const [company, setcompany] = useState();
  const [dataCompany, setdataCompany] = useState({
    word_2: "",
    word_1: "",
    abbreviation: "",
    top_color: "",
    bg_color: "",
    icon: false,
    company: "",
    dis_del: true,
    dis_edit: true,
    dis_add: true,
    hid_del: false,
    hid_edit: false,
    hid_add: false,
  });

  let [inputData, setInputData] = useState({
    id: null,
    dep_name: null,
    company_id: null,
    oem_id: null,
    user: null,
    parent_id: null,
    dep_detail: null,
    dep_level_id: null,
    sort: null,
    dep_name_eng: null,
    dep_no: null,
    district: null,
    province: null,
    minor_district: null,
    poseCode: null,
    poseCode_id: null,
    tel: null,
    fax: null,
    email: null,
    ThaiLang: {
      id: null,
      building: null,
      room_no: null,
      floor: null,
      vilage: null,
      number: null,
      swin: null,
      alley: null,
      separate: null,
      road: null,
      post_id: null,
      tel: null,
      fax: null,
      email: null,
    },
    EngLang: {
      id: null,
      building: null,
      room_no: null,
      floor: null,
      vilage: null,
      number: null,
      swin: null,
      alley: null,
      separate: null,
      road: null,
      post_id: null,
      tel: null,
      fax: null,
      email: null,
    },
  });
  function setDefault() {
    // console.log("setDefault");
    setInputData({
      id: null,
      dep_name: "",
      company_id: "",
      oem_id: "",
      user: "",
      parent_id: "",
      dep_detail: "",
      dep_level_id: "",
      sort: "",
      dep_name_eng: "",
      dep_no: "",
      district: null,
      province: null,
      minor_district: null,
      poseCode: "",
      poseCode_id: "",
      tel: "",
      fax: "",
      mail: "",
      ThaiLang: {
        id: null,
        building: "",
        room_no: "",
        floor: "",
        vilage: "",
        number: "",
        swin: "",
        alley: "",
        separate: "",
        road: "",
        post_id: "",
        tel: "",
        fax: "",
        email: "",
      },
      EngLang: {
        id: null,
        building: "",
        room_no: "",
        floor: "",
        vilage: "",
        number: "",
        swin: "",
        alley: "",
        separate: "",
        road: "",
        post_id: "",
        tel: "",
        fax: "",
        email: "",
      },
    });

    inputData.id = "";
    inputData.dep_name = "";
    inputData.company_id = "";
    inputData.oem_id = "";
    inputData.user = "";
    inputData.parent_id = "";
    inputData.dep_detail = "";
    inputData.dep_level_id = "";
    inputData.sort = "";
    inputData.dep_name_eng = "";
    inputData.dep_no = "";
    inputData.district = null;
    inputData.province = null;
    inputData.minor_district = null;
    inputData.poseCode = "";
    inputData.poseCode_id = "";
    inputData.tel = "";
    inputData.fax = "";
    inputData.email = "";

    inputData.ThaiLang.id = "";
    inputData.ThaiLang.building = "";
    inputData.ThaiLang.room_no = "";
    inputData.ThaiLang.floor = "";
    inputData.ThaiLang.vilage = "";
    inputData.ThaiLang.number = "";
    inputData.ThaiLang.swin = "";
    inputData.ThaiLang.alley = "";
    inputData.ThaiLang.separate = "";
    inputData.ThaiLang.road = "";
    inputData.ThaiLang.post_id = "";
    inputData.ThaiLang.tel = "";
    inputData.ThaiLang.fax = "";
    inputData.ThaiLang.email = "";

    inputData.EngLang.id = "";
    inputData.EngLang.building = "";
    inputData.EngLang.room_no = "";
    inputData.EngLang.floor = "";
    inputData.EngLang.vilage = "";
    inputData.EngLang.number = "";
    inputData.EngLang.swin = "";
    inputData.EngLang.alley = "";
    inputData.EngLang.separate = "";
    inputData.EngLang.road = "";
    inputData.EngLang.post_id = "";
    inputData.EngLang.tel = "";
    inputData.EngLang.fax = "";
    inputData.EngLang.email = "";

    // console.log("after", inputData);
  }

  const modalRef = useRef(null);
  const [modal, setModal] = useState({
    level: 0,
    openClose: false,
    mode: "",
    id: "",
    func: null,
    parent_id: null,
    dep_level_id: null,
    sortup: null,
  });
  const [department, setdepartment] = useState([]);
  const [province, setprovince] = useState([]);
  const [district, setdistrict] = useState([]);
  const [minor_district, setminor_district] = useState([]);
  const [poseCode, setposeCode] = useState([]);
  async function GetProvice() {
    axios({
      method: "GET",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getProvince",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const options = [{ value: null, label: "เลือก" }];
        await response.data.data.forEach((el) => {
          var element = {};
          element["value"] = el.province_id;
          element["label"] = el.province_name;

          options.push(element);
        });
        setprovince(options);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function GetDistrict(id) {
    axios({
      method: "GET",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getDistrict?id=" +
        id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const options = [{ value: null, label: "เลือก" }];
        await response.data.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.district_name;

          options.push(element);
        });
        /* // console.log("options", options); */
        setdistrict(options);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function GetMinorDistrict(id) {
    // console.log("GetMinorDistrictid", id);
    axios({
      method: "GET",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getMinorDistrict?id=" +
        id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const options = [{ value: null, label: "เลือก" }];
        await response.data.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.tambon_name;

          options.push(element);
        });
        setminor_district(options);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function GetPoseCode(id) {
    axios({
      method: "GET",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getPoseCode?id=" +
        id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        /*   const options = [{"value":null,"label":"เลือก"}];
      await   response.data.data.forEach((el) => {
           var element = {};
           element["value"] = el.id;
           element["label"] = el.post_no;
 
           options.push(element);
         }); */
        setInputData({
          ...inputData,
          poseCode: response.data.data.post_no,
          poseCode_id: response.data.data.id,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  const saveBranch = async () => {
    let axiosData = {};

    const error_list = [];
    if (!inputData.ThaiLang.number || inputData.ThaiLang.number === null) {
      error_list.push({
        message: "กรุณาระบุ เลขที่ (Th)",
      });
    }

    if (!inputData.ThaiLang.road || inputData.ThaiLang.road === null) {
      error_list.push({
        message: "กรุณาระบุ ถนน (Th)",
      });
    }

    if (!inputData.dep_name || inputData.dep_name === null) {
      error_list.push({
        message: "กรุณาระบุ ชื่อ สาขา หรือ แผนก",
      });
    }
    if (!inputData.dep_no || inputData.dep_no === null) {
      error_list.push({
        message: "กรุณาระบุ รหัส สาขา หรือ แผนก",
      });
    }

    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      if (modal.mode === "add") {
        const temp = {
          dep_name: inputData.dep_name,
          company_id: getUser().com,
          oem_id: getOem(),
          user: getUser().fup,
          parent_id: modal.parent_id,
          dep_detail: inputData.dep_detail,
          dep_level_id: modal.dep_level_id,
          sort: modal.sortup,
          dep_name_eng: inputData.dep_name_eng,
          dep_no: inputData.dep_no,
          ThaiLang: {
            building: inputData.ThaiLang.building,
            room_no: inputData.ThaiLang.room_no,
            floor: inputData.ThaiLang.floor,
            vilage: inputData.ThaiLang.vilage,
            number: inputData.ThaiLang.number,
            swin: inputData.ThaiLang.swin,
            alley: inputData.ThaiLang.alley,
            separate: inputData.ThaiLang.separate,
            road: inputData.ThaiLang.road,
            post_id: inputData.poseCode_id,
            tel: inputData.tel,
            fax: inputData.fax,
            email: inputData.email,
          },
          EngLang: {
            building: inputData.EngLang.building,
            room_no: inputData.EngLang.room_no,
            floor: inputData.EngLang.floor,
            vilage: inputData.EngLang.vilage,
            number: inputData.EngLang.number,
            swin: inputData.EngLang.swin,
            alley: inputData.EngLang.alley,
            separate: inputData.EngLang.separate,
            road: inputData.EngLang.road,
            post_id: inputData.poseCode_id,
            tel: inputData.tel,
            fax: inputData.fax,
            email: inputData.email,
          },
        };
        // console.log("temp", temp);
        axiosData = {
          method: "post",
          url: Configs.API_URL_hrMagenatement + "/api/hrManagement/saveBranch",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        };
      }
      if (modal.mode === "edit") {
        const temp = {
          id: modal.id,
          dep_name: inputData.dep_name,
          company_id: getUser().com,
          oem_id: getOem(),
          user: getUser().fup,
          parent_id: modal.parent_id,
          dep_detail: inputData.dep_detail,
          dep_level_id: modal.dep_level_id,
          sort: modal.sortup,
          dep_name_eng: inputData.dep_name_eng,
          dep_no: inputData.dep_no,
          ThaiLang: {
            id: inputData.ThaiLang.id,
            building: inputData.ThaiLang.building,
            room_no: inputData.ThaiLang.room_no,
            floor: inputData.ThaiLang.floor,
            vilage: inputData.ThaiLang.vilage,
            number: inputData.ThaiLang.number,
            swin: inputData.ThaiLang.swin,
            alley: inputData.ThaiLang.alley,
            separate: inputData.ThaiLang.separate,
            road: inputData.ThaiLang.road,
            post_id: inputData.poseCode_id,
            tel: inputData.tel,
            fax: inputData.fax,
            email: inputData.email,
          },
          EngLang: {
            id: inputData.EngLang.id,
            building: inputData.EngLang.building,
            room_no: inputData.EngLang.room_no,
            floor: inputData.EngLang.floor,
            vilage: inputData.EngLang.vilage,
            number: inputData.EngLang.number,
            swin: inputData.EngLang.swin,
            alley: inputData.EngLang.alley,
            separate: inputData.EngLang.separate,
            road: inputData.EngLang.road,
            post_id: inputData.poseCode_id,
            tel: inputData.tel,
            fax: inputData.fax,
            email: inputData.email,
          },
        };

        // console.log("inputData", inputData);
        // console.log("temp", temp);
        axiosData = {
          method: "post",
          url: Configs.API_URL_hrMagenatement + "/api/hrManagement/editBranch",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        };
      }

      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        axios(axiosData)
          .then(function (response) {
            if (response.data) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href =
                  "/Human_Resource/organization/manage_company";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire({
              icon: "warning",
              // title: "กรุณากรอกข้อมูลใหม่",
              text: error.response.data.error.message,
            });
          });
      });
    }
  };

  const DeleteBranch = async (data) => {
    const temp = {
      id: data.id,
      ThaiLang: {
        id: data.ThaiLang.id,
      },
      EngLang: {
        id: data.EngLang.id,
      },
    };
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "กำลังลบข้อมูล",
          text: "Loading",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement + "/api/hrManagement/deleteBranch",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(async function (response) {
            Swal.fire({
              icon: "success",
              text: "เสร็จสิ้น",
              showConfirmButton: false,
              timer: 1000,
            }).then(async () => {
              window.location.href =
                "/Human_Resource/organization/manage_company";
            });
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
          });
      }
    });
  };

  async function saveDepartment() {
    let axiosData = {};
    const error_list = [];
    if (!inputData.dep_name || inputData.dep_name === null) {
      error_list.push({
        message: "กรุณาระบุ ชื่อ สาขา หรือ แผนก",
      });
    }
    if (!inputData.dep_no || inputData.dep_no === null) {
      error_list.push({
        message: "กรุณาระบุ รหัส สาขา หรือ แผนก",
      });
    }
    console.log(error_list);
    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      if (modal.mode === "add") {
        const temp = {
          dep_name: inputData.dep_name,
          company_id: getUser().com,
          oem_id: getOem(),
          user: getUser().fup,
          parent_id: modal.parent_id,
          dep_detail: inputData.dep_detail,
          dep_level_id: modal.dep_level_id,
          sort: modal.sortup,
          dep_name_eng: inputData.dep_name_eng,
          dep_no: inputData.dep_no,
        };
        /* // console.log("temp", temp); */
        axiosData = {
          method: "post",
          url:
            Configs.API_URL_hrMagenatement + "/api/hrManagement/saveDepartment",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        };
      }

      if (modal.mode === "edit") {
        const temp = {
          id: modal.id,
          dep_name: inputData.dep_name,
          company_id: getUser().com,
          oem_id: getOem(),
          user: getUser().fup,
          parent_id: modal.parent_id,
          dep_detail: inputData.dep_detail,
          dep_level_id: modal.dep_level_id,
          sort: modal.sortup,
          dep_name_eng: inputData.dep_name_eng,
          dep_no: inputData.dep_no,
        };
        // console.log("temp", temp);
        axiosData = {
          method: "post",
          url:
            Configs.API_URL_hrMagenatement + "/api/hrManagement/editDepartment",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        };
      }

      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        axios(axiosData)
          .then(function (response) {
            if (response.data) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href =
                  "/Human_Resource/organization/manage_company";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire({
              icon: "warning",
              // title: "กรุณากรอกข้อมูลใหม่",
              text: error.response.data.error.message,
            });
          });
      });
    }
  }

  const DeleteDepartment = async (data) => {
    const temp = {
      id: data.id,
    };
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "กำลังลบข้อมูล",
          text: "Loading",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement +
            "/api/hrManagement/deleteDepartment",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(async function (response) {
            Swal.fire({
              icon: "success",
              text: "เสร็จสิ้น",
              showConfirmButton: false,
              timer: 1000,
            }).then(async () => {
              window.location.href =
                "/Human_Resource/organization/manage_company";
            });
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
          });
      }
    });
  };
  async function GetCompany(params) {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/findById?id=" + getUser().com,
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
    })
      .then(function (response) {
        /* // console.log("com", response.data); */
        setcompany(response.data);
        setdataCompany({
          word_2: "บริษัท ทีทีที บราเธอร์ส จำกัด",
          word_1: response.data.company_name,
          abbreviation: "",
          top_color: "#002BB2",
          bg_color: "#ffffff",
          icon: false,
          company: response.data,
          dis_del: true,
          dis_edit: true,
          dis_add: false,
          hid_del: false,
          hid_edit: false,
          hid_add: false,
          id: response.data.id,
        });
        dataCompany.word_2 = "บริษัท ทีทีที บราเธอร์ส จำกัด";
        dataCompany.word_1 = response.data.company_name;
        dataCompany.abbreviation = response.data.abbr;
        dataCompany.top_color = "#002BB2";
        dataCompany.bg_color = "#ffffff";
        dataCompany.icon = false;
        dataCompany.company = response.data;
        dataCompany.dis_del = false;
        dataCompany.dis_edit = false;
        dataCompany.dis_add = false;
        dataCompany.hid_del = false;
        dataCompany.hid_edit = false;
        dataCompany.hid_add = false;
        dataCompany.id = response.data.id;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    GetCompany();

    getAllDepartment();
    GetProvice();
  }, []);

  async function getAllDepartment(params) {
    const temp = {
      company_id: getUser().com,
      oem_id: getOem(),
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/filterAllDepByCompany",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        setdepartment(response.data.data);
        /*   if (response.data) {
        Swal.fire({
          icon: "success",
          title: "Save",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {});
      } */
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "warning",
          // title: "กรุณากรอกข้อมูลใหม่",
          text: error.response.data.error.message,
        });
      });
  }
  async function searchJSON(obj, key, val) {
    let results = [];
    for (let k in obj) {
      if (obj.hasOwnProperty(k)) {
        if (k === key && obj[k] === val) {
          results.push(obj);
        } else if (typeof obj[k] === "object") {
          results = results.concat(await searchJSON(obj[k], key, val));
        }
      }
    }
    return results;
  }

  async function setFormodal(params) {
    if (params.mode !== "del") {
      modalRef.current.click();
    }

    let temp_level_id = "";

    switch (params.level) {
      case 1:
        temp_level_id = "646cef1e-8206-4dc2-9ae0-a47dc3c2efdb";
        break;
      case 2:
        temp_level_id = "b249bdc1-3daa-4592-ae2e-a1f289f2247b";
        break;
      case 3:
        temp_level_id = "69186a7b-3fac-4d19-847e-3c0f1351e4e1";
        break;
      case 4:
        temp_level_id = "1a3442a9-03c9-4955-b9a6-ddb897f4efc5";
        break;
      case 5:
        temp_level_id = "e6ad03a4-0a5b-4d55-b6d7-a0bf5cad681e";
        break;
      default:
        temp_level_id = "e6ad03a4-0a5b-4d55-b6d7-a0bf5cad681e";
        break;
    }

    setModal({
      ...modal,
      level: params.level,
      openClose: params.openClose,
      mode: params.mode,
      id: params.id,
      func: params.func,
      parent_id: params.parent_id,
      dep_level_id: temp_level_id,
      sortup: params.sortup,
    });

    if (params.mode === "edit") {
      const fineEdit = await searchJSON(department, "id", params.id);

      if (fineEdit.length !== 0) {
        let tempData = {
          ...inputData,
          id: fineEdit[0].id,
          dep_name: fineEdit[0].dep_name || "",
          dep_name_eng: fineEdit[0].dep_name_eng || "",
          dep_no: fineEdit[0].dep_no || "",
          dep_detail: fineEdit[0].dep_detail || "",
        };

        inputData.id = fineEdit[0].id || "";
        inputData.dep_name = fineEdit[0].dep_name || "";
        inputData.dep_name_eng = fineEdit[0].dep_name_eng || "";
        inputData.dep_no = fineEdit[0].dep_no || "";
        inputData.dep_detail = fineEdit[0].dep_detail || "";

        if (params.level === 2) {
          tempData = {
            ...tempData,
            ThaiLang: {
              ...fineEdit[0].ThaiLang,
              building: fineEdit[0].ThaiLang.building || "",
              room_no: fineEdit[0].ThaiLang.room_no || "",
              floor: fineEdit[0].ThaiLang.floor || "",
              vilage: fineEdit[0].ThaiLang.vilage || "",
              number: fineEdit[0].ThaiLang.number || "",
              swin: fineEdit[0].ThaiLang.swin || "",
              alley: fineEdit[0].ThaiLang.alley || "",
              separate: fineEdit[0].ThaiLang.separate || "",
              road: fineEdit[0].ThaiLang.road || "",
              post_id: fineEdit[0].ThaiLang.post_id || "",
              tel: fineEdit[0].ThaiLang.tel || "",
              fax: fineEdit[0].ThaiLang.fax || "",
              email: fineEdit[0].ThaiLang.email || "",
            },
            EngLang: {
              ...fineEdit[0].EngLang,
              building: fineEdit[0].EngLang.building || "",
              room_no: fineEdit[0].EngLang.room_no || "",
              floor: fineEdit[0].EngLang.floor || "",
              vilage: fineEdit[0].EngLang.vilage || "",
              number: fineEdit[0].EngLang.number || "",
              swin: fineEdit[0].EngLang.swin || "",
              alley: fineEdit[0].EngLang.alley || "",
              separate: fineEdit[0].EngLang.separate || "",
              road: fineEdit[0].EngLang.road || "",
              post_id: fineEdit[0].EngLang.post_id || "",
              tel: fineEdit[0].EngLang.tel || "",
              fax: fineEdit[0].EngLang.fax || "",
              email: fineEdit[0].EngLang.email || "",
            },
            tel: fineEdit[0].tel || "",
            fax: fineEdit[0].fax || "",
            email: fineEdit[0].email || "",
            district: fineEdit[0].ThaiLang.district_id
              ? fineEdit[0].ThaiLang.district_id
              : null,
            province: fineEdit[0].ThaiLang.province_id
              ? fineEdit[0].ThaiLang.province_id
              : null,
            minor_district: fineEdit[0].ThaiLang.tambon_id
              ? fineEdit[0].ThaiLang.tambon_id
              : null,
            poseCode: fineEdit[0].ThaiLang.post_no
              ? fineEdit[0].ThaiLang.post_no
              : "",
          };

          inputData.ThaiLang = {
            ...fineEdit[0].ThaiLang,
            building: fineEdit[0].ThaiLang.building || "",
            room_no: fineEdit[0].ThaiLang.room_no || "",
            floor: fineEdit[0].ThaiLang.floor || "",
            vilage: fineEdit[0].ThaiLang.vilage || "",
            number: fineEdit[0].ThaiLang.number || "",
            swin: fineEdit[0].ThaiLang.swin || "",
            alley: fineEdit[0].ThaiLang.alley || "",
            separate: fineEdit[0].ThaiLang.separate || "",
            road: fineEdit[0].ThaiLang.road || "",
            post_id: fineEdit[0].ThaiLang.post_id || "",
            tel: fineEdit[0].ThaiLang.tel || "",
            fax: fineEdit[0].ThaiLang.fax || "",
            email: fineEdit[0].ThaiLang.email || "",
          };

          inputData.EngLang = {
            ...fineEdit[0].EngLang,
            building: fineEdit[0].EngLang.building || "",
            room_no: fineEdit[0].EngLang.room_no || "",
            floor: fineEdit[0].EngLang.floor || "",
            vilage: fineEdit[0].EngLang.vilage || "",
            number: fineEdit[0].EngLang.number || "",
            swin: fineEdit[0].EngLang.swin || "",
            alley: fineEdit[0].EngLang.alley || "",
            separate: fineEdit[0].EngLang.separate || "",
            road: fineEdit[0].EngLang.road || "",
            post_id: fineEdit[0].EngLang.post_id || "",
            tel: fineEdit[0].EngLang.tel || "",
            fax: fineEdit[0].EngLang.fax || "",
            email: fineEdit[0].EngLang.email || "",
          };

          inputData.tel = fineEdit[0].tel || "";
          inputData.fax = fineEdit[0].fax || "";
          inputData.email = fineEdit[0].email || "";
          inputData.district = fineEdit[0].ThaiLang.district_id
            ? fineEdit[0].ThaiLang.district_id
            : null;
          inputData.province = fineEdit[0].ThaiLang.province_id
            ? fineEdit[0].ThaiLang.province_id
            : null;
          inputData.minor_district = fineEdit[0].ThaiLang.tambon_id
            ? fineEdit[0].ThaiLang.tambon_id
            : null;
          inputData.poseCode = fineEdit[0].ThaiLang.post_no
            ? fineEdit[0].ThaiLang.post_no
            : "";

          if (fineEdit[0].ThaiLang.province_id) {
            await GetDistrict(fineEdit[0].ThaiLang.province_id);
          }
          if (fineEdit[0].ThaiLang.district_id) {
            await GetMinorDistrict(fineEdit[0].ThaiLang.district_id);
          }
          if (fineEdit[0].ThaiLang.tambon_id) {
            await GetPoseCode(fineEdit[0].ThaiLang.tambon_id);
          }
        }

        setInputData(tempData);
      }
    } else if (params.mode === "del") {
      const fineEdit = await searchJSON(department, "id", params.id);

      if (fineEdit.length !== 0) {
        if (params.level === 2) {
          const temp_del = {
            id: fineEdit[0].id,
            ThaiLang: {
              id: fineEdit[0].ThaiLang.id,
            },
            EngLang: {
              id: fineEdit[0].EngLang.id,
            },
          };
          DeleteBranch(temp_del);
        } else {
          const temp_del = {
            id: fineEdit[0].id,
          };
          DeleteDepartment(temp_del);
        }
      }
    }
    // console.log("modal", modal);
    // console.log("inputData", inputData);
  }

  function Buildnode(data, currentChild) {
    /*   // console.log("currentChild", currentChild); */
    if (!currentChild.has_child) {
      return (
        <TreeNode
          key={currentChild.id}
          label={
            <CompanyCard
              key={currentChild.id + "card"}
              model={{
                word_2: currentChild.dep_name_eng,
                word_1: currentChild.dep_name,
                abbreviation: "",
                top_color: currentChild.dep_color,
                bg_color: "#ffffff",
                icon: currentChild.dep_level > 2 ? true : false,
                company:
                  currentChild.dep_icon === ""
                    ? ""
                    : { logo_path: currentChild.dep_icon },
                dis_del: false,
                dis_edit: false,
                dis_add: false,
                hid_del: false,
                hid_edit: false,
                hid_add: false,
              }}
              toggleAdd={() => {
                setDefault();
                setFormodal({
                  level: Number(currentChild.dep_level) + 1,
                  openClose: true,
                  mode: "add",
                  id: currentChild.id,
                  func: saveDepartment.bind(this),
                  parent_id: currentChild.id,
                  dep_level_id: currentChild.dep_level_id,
                  sortup: currentChild.sort,
                });
              }}
              toggleEdit={() => {
                setDefault();
                setFormodal({
                  level: Number(currentChild.dep_level),
                  openClose: true,
                  mode: "edit",
                  id: currentChild.id,
                  func: saveDepartment.bind(this),
                  parent_id: currentChild.parent_id,
                  dep_level_id: currentChild.dep_level_id,
                  sortup: currentChild.sort,
                });
              }}
              toggleDelete={() => {
                setFormodal({
                  level: Number(currentChild.dep_level),
                  openClose: false,
                  mode: "del",
                  id: currentChild.id,
                  func: saveDepartment.bind(this),
                  parent_id: currentChild.parent_id,
                  dep_level_id: currentChild.dep_level_id,
                  sortup: currentChild.sort,
                });
              }}
            />
          }
        />
      );
    } else if (currentChild.has_child) {
      return (
        <TreeNode
          key={currentChild.id}
          label={
            <CompanyCard
              key={currentChild.id + "card"}
              model={{
                word_2: currentChild.dep_name_eng,
                word_1: currentChild.dep_name,
                abbreviation: "",
                top_color: currentChild.dep_color,
                bg_color: "#ffffff",
                icon: currentChild.dep_level > 2 ? true : false,
                company:
                  currentChild.dep_icon === ""
                    ? ""
                    : { logo_path: currentChild.dep_icon },
                dis_del: true,
                dis_edit: false,
                dis_add: false,
                hid_del: false,
                hid_edit: false,
                hid_add: false,
              }}
              toggleAdd={() => {
                setDefault();
                setFormodal({
                  level: Number(currentChild.dep_level) + 1,
                  openClose: true,
                  mode: "add",
                  id: currentChild.id,
                  func: saveDepartment.bind(this),
                  parent_id: currentChild.id,
                  dep_level_id: currentChild.dep_level_id,
                  sortup: currentChild.sort,
                });
              }}
              toggleEdit={() => {
                setDefault();
                setFormodal({
                  level: Number(currentChild.dep_level),
                  openClose: true,
                  mode: "edit",
                  id: currentChild.id,
                  func: saveDepartment.bind(this),
                  parent_id: currentChild.parent_id,
                  dep_level_id: currentChild.dep_level_id,
                  sortup: currentChild.sort,
                });
              }}
            />
          }
        >
          {data.map((parentNode) => {
            return Buildnode(parentNode.child, parentNode);
          })}
        </TreeNode>
      );
    }
  }

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  โครงสร้างองค์กร{"  "}
                  {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Manage Company</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid"></div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              {/* <h3 className="card-title">Project</h3> */}
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive"
              style={{ textAlign: "center" }}
            >
              {department.length > 0 ? (
                <Tree
                  lineWidth={"4px"}
                  lineColor={"#01408F"}
                  lineBorderRadius={"10px"}
                  label={
                    <CompanyCard
                      model={{
                        word_2: "",
                        word_1: "บริษัท ทีทีที บราเธอร์ส จำกัด",
                        abbreviation: "",
                        top_color: "#002BB2",
                        bg_color: "#ffffff",
                        icon: false,
                        company: company,
                        dis_del: true,
                        dis_edit: true,
                        dis_add: false,
                        hid_del: false,
                        hid_edit: false,
                        hid_add: false,
                      }}
                      toggleAdd={() => {
                        setDefault();
                        setFormodal({
                          level: 2,
                          openClose: true,
                          mode: "add",
                          id: company.id,
                          parent_id: null,
                          dep_level_id: "b249bdc1-3daa-4592-ae2e-a1f289f2247b",
                          sortup: 0,
                          func: saveBranch,
                        });
                      }}
                      /* toggleEdit={() => {
                      setFormodal({
                        level: 0,
                        openClose: true,
                        mode: "edit",
                        id: company.id,
                        func: saveBranch,
                      });
                    }} 
                    toggleDelete={() => DeleteBranch()}*/
                    />
                  }
                >
                  {department.map((element) => {
                    return Buildnode(element.child, element);
                  })}
                </Tree>
              ) : (
                <Tree
                  lineWidth={"4px"}
                  lineColor={"#01408F"}
                  lineBorderRadius={"10px"}
                  label={
                    <CompanyCard
                      model={{
                        word_2: "",
                        word_1: "บริษัท ทีทีที บราเธอร์ส จำกัด",
                        abbreviation: "",
                        top_color: "#002BB2",
                        bg_color: "#ffffff",
                        icon: false,
                        company: company,
                        dis_del: true,
                        dis_edit: true,
                        dis_add: false,
                        hid_del: false,
                        hid_edit: false,
                        hid_add: false,
                      }}
                      toggleAdd={() => {
                        setDefault();
                        setFormodal({
                          level: 2,
                          openClose: true,
                          mode: "add",
                          id: company.id,
                          parent_id: null,
                          dep_level_id: "b249bdc1-3daa-4592-ae2e-a1f289f2247b",
                          sortup: 0,
                          func: saveBranch,
                        });
                      }}
                      /* toggleEdit={() => {
                      setFormodal({
                        level: 0,
                        openClose: true,
                        mode: "edit",
                        id: company.id,
                        func: saveBranch,
                      });
                    }} 
                    toggleDelete={() => DeleteBranch()}*/
                    />
                  }
                />
              )}
              <div style={{ marginBottom: "10px" }}>
                <button
                  style={{ display: "none" }}
                  data-toggle="modal"
                  data-target="#modal-input"
                  ref={modalRef}
                ></button>
              </div>
            </div>

            <div className="card-footer" />
          </div>
        </section>

        <div className="modal fade" id="modal-input">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  {" "}
                  จัดการ {modal.mode === "add" ? "ADD" : "EDIT"}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" onClick={() => setDefault()}>
                    ×
                  </span>
                </button>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className="card card-primary">
                      <div className="card-header">
                        <h3 className="card-title">ข้อมูลหลัก</h3>
                        <div className="card-tools">
                          <button
                            type="button"
                            className="btn btn-tool"
                            data-card-widget="collapse"
                          >
                            <i className="fas fa-minus" />
                          </button>
                        </div>
                      </div>

                      <div className="card-body">
                        <div className="row">
                          <div className="col-12 col-md-12 col-xl-12">
                            <div className="form-group ">
                              <input
                                type="text"
                                className="form-control"
                                value={inputData.dep_no}
                                onChange={(e) => {
                                  if (
                                    validNumber.test(
                                      e.target.value.toString().trim()
                                    )
                                  ) {
                                    setInputData({
                                      ...inputData,
                                      dep_no: e.target.value,
                                    });
                                    inputData.dep_no = e.target.value;
                                  }
                                }}
                              />
                              <label htmlFor="">
                                {modal.level === 2 ? "รหัสสำนักงานสาขา" : ""}
                                {modal.level === 3 ? "รหัสแผนก" : ""}
                                {modal.level === 4 ? "รหัสฝ่าย" : ""}
                                {modal.level >= 5 ? "รหัสหน่วยงาน" : ""}

                                {inputData.dep_no === null ||
                                inputData.dep_no === "" ? (
                                  <span style={{ color: "red" }}>*</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 col-md-6 col-xl-12">
                            <div className="form-group ">
                              <input
                                type="text"
                                className="form-control"
                                value={inputData.dep_name}
                                onChange={(e) => {
                                  if (
                                    !validEng.test(
                                      e.target.value.toString().trim()
                                    )
                                  ) {
                                    setInputData({
                                      ...inputData,
                                      dep_name: e.target.value,
                                    });
                                    inputData.dep_name = e.target.value;
                                  }
                                }}
                              />
                              <label htmlFor="">
                                {modal.level === 2
                                  ? "ชื่อสำนักงานสาขา (ไทย)"
                                  : ""}
                                {modal.level === 3 ? "ชื่อแผนก (ไทย)" : ""}
                                {modal.level === 4 ? "ชื่อฝ่าย (ไทย)" : ""}
                                {modal.level >= 5 ? "ชื่อหน่วยงาน (ไทย)" : ""}

                                {inputData.dep_name === null ||
                                inputData.dep_name === "" ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>{" "}
                            </div>
                          </div>
                          <div className="col-6 col-md-6 col-xl-12">
                            <div className="form-group ">
                              <input
                                type="text"
                                className="form-control"
                                value={inputData.dep_name_eng}
                                onChange={(e) => {
                                  if (
                                    !validThai.test(
                                      e.target.value.toString().trim()
                                    )
                                  ) {
                                    setInputData({
                                      ...inputData,
                                      dep_name_eng: e.target.value,
                                    });
                                    inputData.dep_name_eng = e.target.value;
                                  }
                                }}
                              />
                              <label htmlFor="">
                                {modal.level === 2
                                  ? "ชื่อสำนักงานสาขา (อังกฤษ)"
                                  : ""}
                                {modal.level === 3 ? "ชื่อแผนก (อังกฤษ)" : ""}
                                {modal.level === 4 ? "ชื่อฝ่าย (อังกฤษ)" : ""}
                                {modal.level >= 5
                                  ? "ชื่อหน่วยงาน (อังกฤษ)"
                                  : ""}
                                {inputData.dep_name_eng === null ||
                                inputData.dep_name_eng === "" ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}{" "}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-12 col-xl-12">
                            <div className="form-group ">
                              <textarea
                                type="text"
                                style={{ height: "100px" }}
                                className="form-control"
                                value={inputData.dep_detail}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    dep_detail: e.target.value,
                                  });
                                  inputData.dep_detail = e.target.value;
                                }}
                              />
                              <label htmlFor="">
                                {modal.level === 2
                                  ? "รายละเอียดสำนักงานสาขา"
                                  : ""}
                                {modal.level === 3 ? "รายละเอียดแผนก" : ""}
                                {modal.level === 4 ? "รายละเอียดฝ่าย" : ""}
                                {modal.level >= 5 ? "รายละเอียดหน่วยงาน" : ""}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {modal.level < 3 ? (
                      <div className="card card-primary collapsed-card">
                        <div className="card-header">
                          <h3 className="card-title">ข้อมูลที่อยู่</h3>
                          <div className="card-tools">
                            <button
                              type="button"
                              className="btn btn-tool"
                              data-card-widget="collapse"
                            >
                              <i className="fas fa-plus" />
                            </button>
                          </div>
                        </div>

                        <div className="card-body">
                          <h4>ที่อยู่ {"(ไทย)"}</h4>
                          <hr />
                          <div className="row">
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.ThaiLang.building}
                                  onChange={(e) => {
                                    if (
                                      !validEng.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        ThaiLang: {
                                          ...inputData.ThaiLang,
                                          building: e.target.value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">อาคาร</label>{" "}
                              </div>
                            </div>
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.ThaiLang.room_no}
                                  onChange={(e) => {
                                    if (
                                      !validEng.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        ThaiLang: {
                                          ...inputData.ThaiLang,
                                          room_no: e.target.value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">ห้องเลขที่</label>{" "}
                              </div>
                            </div>
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.ThaiLang.floor}
                                  onChange={(e) => {
                                    if (
                                      !validEng.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        ThaiLang: {
                                          ...inputData.ThaiLang,
                                          floor: e.target.value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">ชั้นที่</label>{" "}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.ThaiLang.vilage}
                                  onChange={(e) => {
                                    if (
                                      !validEng.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        ThaiLang: {
                                          ...inputData.ThaiLang,
                                          vilage: e.target.value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">หมู่บ้าน</label>{" "}
                              </div>
                            </div>
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.ThaiLang.number}
                                  onChange={(e) => {
                                    if (
                                      !validEng.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        ThaiLang: {
                                          ...inputData.ThaiLang,
                                          number: e.target.value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">
                                  เลขที่
                                  {inputData.ThaiLang.number === null ||
                                  inputData.ThaiLang.number === "" ? (
                                    <span style={{ color: "red" }}> *</span>
                                  ) : (
                                    ""
                                  )}
                                </label>{" "}
                              </div>
                            </div>
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.ThaiLang.swin}
                                  onChange={(e) => {
                                    if (
                                      !validEng.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        ThaiLang: {
                                          ...inputData.ThaiLang,
                                          swin: e.target.value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">หมู่</label>{" "}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.ThaiLang.alley}
                                  onChange={(e) => {
                                    if (
                                      !validEng.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        ThaiLang: {
                                          ...inputData.ThaiLang,
                                          alley: e.target.value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">ตรอก/ซอย</label>{" "}
                              </div>
                            </div>
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.ThaiLang.separate}
                                  onChange={(e) => {
                                    if (
                                      !validEng.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        ThaiLang: {
                                          ...inputData.ThaiLang,
                                          separate: e.target.value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">เเยก</label>{" "}
                              </div>
                            </div>
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.ThaiLang.road}
                                  onChange={(e) => {
                                    if (
                                      !validEng.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        ThaiLang: {
                                          ...inputData.ThaiLang,
                                          road: e.target.value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">
                                  ถนน
                                  {inputData.ThaiLang.road === null ||
                                  inputData.ThaiLang.road === "" ? (
                                    <span style={{ color: "red" }}> *</span>
                                  ) : (
                                    ""
                                  )}
                                </label>{" "}
                              </div>
                            </div>
                          </div>
                          <h4>ที่อยู่ {"(อังกฤษ)"}</h4>
                          <hr />

                          <div className="row">
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.EngLang.building}
                                  onChange={(e) => {
                                    if (
                                      !validThai.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        EngLang: {
                                          ...inputData.EngLang,
                                          building: e.target.value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">อาคาร</label>{" "}
                              </div>
                            </div>
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.EngLang.room_no}
                                  onChange={(e) => {
                                    if (
                                      !validThai.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        EngLang: {
                                          ...inputData.EngLang,
                                          room_no: e.target.value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">ห้องเลขที่</label>{" "}
                              </div>
                            </div>
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.EngLang.floor}
                                  onChange={(e) => {
                                    if (
                                      !validThai.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        EngLang: {
                                          ...inputData.EngLang,
                                          floor: e.target.value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">ชั้นที่</label>{" "}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.EngLang.vilage}
                                  onChange={(e) => {
                                    if (
                                      !validThai.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        EngLang: {
                                          ...inputData.EngLang,
                                          vilage: e.target.value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">หมู่บ้าน</label>{" "}
                              </div>
                            </div>
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.EngLang.number}
                                  onChange={(e) => {
                                    if (
                                      !validThai.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        EngLang: {
                                          ...inputData.EngLang,
                                          number: e.target.value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">
                                  เลขที่{" "}
                                  {/* {inputData.EngLang.number === null ||
                                  inputData.EngLang.number === "" ? (
                                    <span style={{ color: "red" }}> *</span>
                                  ) : (
                                    ""
                                  )} */}
                                </label>{" "}
                              </div>
                            </div>
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.EngLang.swin}
                                  onChange={(e) => {
                                    if (
                                      !validThai.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        EngLang: {
                                          ...inputData.EngLang,
                                          swin: e.target.value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">หมู่</label>{" "}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.EngLang.alley}
                                  onChange={(e) => {
                                    if (
                                      !validThai.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        EngLang: {
                                          ...inputData.EngLang,
                                          alley: e.target.value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">ตรอก/ซอย</label>{" "}
                              </div>
                            </div>
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.EngLang.separate}
                                  onChange={(e) => {
                                    if (
                                      !validThai.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        EngLang: {
                                          ...inputData.EngLang,
                                          separate: e.target.value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">เเยก</label>{" "}
                              </div>
                            </div>
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.EngLang.road}
                                  onChange={(e) => {
                                    if (
                                      !validThai.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        EngLang: {
                                          ...inputData.EngLang,
                                          road: e.target.value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">
                                  ถนน
                                  {/* {inputData.EngLang.road === null ||
                                  inputData.EngLang.road === "" ? (
                                    <span style={{ color: "red" }}> *</span>
                                  ) : (
                                    ""
                                  )} */}
                                </label>{" "}
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-3 col-md-3 col-xl-3">
                              <div className="form-group ">
                                <Select
                                  /*  isDisabled={disable} */
                                  options={province}
                                  value={province.filter((e) => {
                                    return e.value === inputData.province;
                                  })}
                                  defaultValue={
                                    modal.mode === "add"
                                      ? null
                                      : inputData.province
                                  }
                                  onChange={(e) => {
                                    //// console.log(e);
                                    if (e !== null) {
                                      setInputData({
                                        ...inputData,
                                        province: e.value || "",
                                      });
                                    }
                                    inputData.province = e.value || "";
                                    GetDistrict(e.value);
                                  }}
                                />
                                <label>จังหวัด</label>
                              </div>
                            </div>
                            <div className="col-3 col-md-3 col-xl-3">
                              <div className="form-group ">
                                <Select
                                  isDisabled={
                                    inputData.province === null ? true : false
                                  }
                                  options={district}
                                  value={district.filter((e) => {
                                    return e.value === inputData.district;
                                  })}
                                  defaultValue={
                                    modal.mode === "add"
                                      ? null
                                      : inputData.district
                                  }
                                  onChange={(e) => {
                                    //// console.log(e);
                                    if (e !== null) {
                                      setInputData({
                                        ...inputData,
                                        district: e.value || "",
                                      });
                                    }
                                    inputData.district = e.value || "";
                                    GetMinorDistrict(e.value);
                                  }}
                                />
                                <label>อำเภอ/เขต</label>
                              </div>
                            </div>
                            <div className="col-3 col-md-3 col-xl-3">
                              <div className="form-group ">
                                <Select
                                  isDisabled={
                                    inputData.district === null ? true : false
                                  }
                                  options={minor_district}
                                  value={minor_district.filter((e) => {
                                    return e.value === inputData.minor_district;
                                  })}
                                  defaultValue={
                                    modal.mode === "add"
                                      ? null
                                      : inputData.minor_district
                                  }
                                  onChange={(e) => {
                                    //// console.log(e);
                                    if (e !== null) {
                                      setInputData({
                                        ...inputData,
                                        minor_district: e.value || "",
                                      });
                                    }
                                    inputData.minor_district = e.value || "";
                                    GetPoseCode(e.value);
                                  }}
                                />
                                <label>ตำบล/แขวง</label>
                              </div>
                            </div>
                            <div className="col-3 col-md-3 col-xl-3">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={inputData.poseCode}
                                />
                                <label htmlFor="">รหัสไปรษณี</label>{" "}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.tel}
                                  onChange={(e) => {
                                    if (
                                      !validThai.test(
                                        e.target.value.toString().trim()
                                      ) &&
                                      !validEng.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        tel: e.target.value,
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">เบอร์โทรศัพท์</label>{" "}
                              </div>
                            </div>
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.fax}
                                  onChange={(e) => {
                                    if (
                                      !validThai.test(
                                        e.target.value.toString().trim()
                                      ) &&
                                      !validEng.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        fax: e.target.value,
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">แฟ็กซ์</label>{" "}
                              </div>
                            </div>
                            <div className="col-4 col-md-4 col-xl-4">
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={inputData.email}
                                  onChange={(e) => {
                                    if (
                                      !validThai.test(
                                        e.target.value.toString().trim()
                                      )
                                    ) {
                                      setInputData({
                                        ...inputData,
                                        email: e.target.value,
                                      });
                                    }
                                  }}
                                />
                                <label htmlFor="">อีเมล</label>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <br />
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={() => setDefault()}
                >
                  Cancel
                </button>

                {/* {modal.level === 1 ? (
                  <button
                    type="button"
                    className="btn btn-success"
                    data-dismiss="modal"
                    onClick={saveBranch}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-success"
                    data-dismiss="modal"
                    onClick={
                      modal.level === 2
                        ? modal.mode === "edit"
                          ? saveBranch
                          : saveDepartment
                        : saveDepartment
                    }
                  >
                    Save
                  </button>
                )} */}

                {modal.level === 2 ? (
                  <button
                    type="button"
                    className="btn btn-success"
                    /*  data-dismiss="modal" */
                    onClick={saveBranch}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-success"
                    /*  data-dismiss="modal" */
                    onClick={saveDepartment}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Manage_Company;
