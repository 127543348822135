import React, { useEffect, useState, useRef } from "react";

import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../../config";
import {
    getOem,
    getToken,
    getUser,
} from "../../../../Utils/Common";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function Generate_PO_EV(mode) {

    const { id } = useParams();
    const closeModal = useRef();
    function closeMo() {
        closeModal.current.click();
    }
    const [zIndex, setzIndex] = useState({
        inquiryBox: false,
        startDate: false,
        endDate: false,
    })
    const [pageMode, setpageMode] = useState("");
    const [disable, setdisable] = useState(true);
    const [Inquiry_option, setInquiry_option] = useState([]);
    const [Inquiry, setInquiry] = useState({
        inquiry_id: "",
        inquiry_no: "",
    });
    const [Filter, setFilter] = useState({
        order_id: "",
        user_id: "",
        start_date: "",
        end_date: "",
    })
    const [ProductInquiry, setProductInquiry] = useState([]);
    const [Remark, setRemark] = useState({
        remark: "",
        order_id: "",
    })

    const [confirmData, setconfirmData] = useState([]);
    const [cancelData, setcancelData] = useState([]);

    useEffect(() => {
        if (mode.mode === "edit") {
            console.log(mode.mode);
            setdisable(false);
            setpageMode("Edit");
        }
        else {
            console.log(mode.mode);
            setdisable(true);
            setpageMode("View");
        }
        getInquiryForEV();

    }, []);

    function getInquiryForEV() {
        let data = {
            po_id: id,
            oem_id: getOem(),
            company_id: getUser().com,
        };
        axios({
            method: "post",
            url: Configs.API_URL_incentive + "/api/orderInquiry/getInquiryForEV",
            headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: data,
        })
            .then((response) => {
                console.log(response.data);
                if (response.data.length > 0) {
                    let supplier_option = [];
                    response.data.map((item) => {
                        supplier_option.push({
                            value: item.oi_id,
                            label: item.oi_no,
                        });
                    });
                    setInquiry_option(supplier_option);
                    setInquiry({
                        ...Inquiry,
                        inquiry_id: response.data[0].oi_id,
                        inquiry_no: response.data[0].oi_no,
                    })
                    Inquiry.inquiry_id = response.data[0].oi_id;
                    Inquiry.inquiry_no = response.data[0].oi_no;
                    FilterProductInquiry();
                }
            })
            .catch((error) => {
                Swal.fire({
                    title: "Error!",
                    text: error.response.data.error[0].errorDis,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            });

    }

    function FilterProductInquiry() {
        setconfirmData([]);
        setcancelData([]);
        let data = {
            po_id: id,
            oem_id: getOem(),
            company_id: getUser().com,
            inquiry_id: Inquiry.inquiry_id,
            inquiry_no: Inquiry.inquiry_no,
            order_id: Filter.order_id !== "" ? Filter.order_id.trim() : null,
            user_id: Filter.user_id !== "" ? Filter.user_id.trim() : null,
            start_date: Filter.start_date !== "" ? Filter.start_date : null,
            end_date: Filter.end_date !== "" ? Filter.end_date : null,
        };
        axios({
            method: "post",
            url: Configs.API_URL_incentive + "/api/orderInquiry/FilterProductInquiryForEV",
            headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: data,
        })
            .then((response) => {
                console.log(response.data);
                if (response.data.length > 0) {
                    let ProductInquiry = [];
                    /* response.data.map((item) => {
                        console.log(item.productList);
                        item.productList.map((item2) => {
                            ProductInquiry.push({
                                ol_id: item2.ol_id,
                                Order_ID: item2.Order_ID,
                                User_ID: item2.User_ID,
                                Product_No: item2.Product_No,
                                Product_Name: item2.Product_Name,
                                Product_Model: item2.Product_Model,
                                Order_Date: item2.Order_Date === null ? "" : moment(item2.Order_Date).format("DD/MM/YYYY, HH:mm น."),
                                Order_Status: item2.Order_Status,
                                Confirm_Order_Date: item2.Confirm_Order_Date === null ? "" : moment(item2.Confirm_Order_Date).format("DD/MM/YYYY, HH:mm น."),
                                Remark: item2.Remark,
                            });
                        });
                    }); */
                    response.data.map((item) => {
                        ProductInquiry.push({
                            ol_id: item.ol_id,
                            Order_ID: item.Order_ID,
                            User_ID: item.User_ID,
                            Product_No: item.Product_No,
                            Product_Name: item.Product_Name,
                            Product_Model: item.Product_Model,
                            Order_Date: item.Order_Date === null ? "" : moment(item.Order_Date).format("DD/MM/YYYY, HH:mm น."),
                            Order_Status: item.Order_Status,
                            Confirm_Order_Date: item.Confirm_Order_Date === null ? "" : moment(item.Confirm_Order_Date).format("DD/MM/YYYY, HH:mm น."),
                            Remark: item.Remark,
                            supplier_id: item.supplier_id,
                        });
                    });
                    setProductInquiry(ProductInquiry);
                }
                else {
                    setProductInquiry([]);
                }
            })
            .catch((error) => {
                Swal.fire({
                    title: "Error!",
                    text: error.response.data.error[0].errorDis,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            });
    }

    function clear() {
        setFilter({
            order_id: "",
            user_id: "",
            start_date: "",
            end_date: "",
        });
        Filter.order_id = "";
        Filter.user_id = "";
        Filter.start_date = "";
        Filter.end_date = "";
        FilterProductInquiry();
    }

    function saveOption() {
        if (Inquiry.inquiry_id === "") {
            Swal.fire({
                title: "Warning!",
                text: "กรุณาเลือก Inquiry No.",
                icon: "warning",
                confirmButtonText: "OK",
            });
            return;
        }
        let data = {
            // confirm: confirmData,
            // cancel: cancelData,
            all: ProductInquiry,
            oem_id: getOem(),
            company_id: getUser().com,
            fup: getUser().fup,
            inquiry_id: Inquiry.inquiry_id,
        }
        axios({
            method: "post",
            url: Configs.API_URL_incentive + "/api/orderInquiry/saveEditOption",
            headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: data,
        })
            .then((response) => {
                if (response.data === "OK") {
                    Swal.fire({
                        title: "Success!",
                        text: "บันทึกสำเร็จ",
                        icon: "success",
                        confirmButtonText: "OK",
                    })
                        .then((result) => {
                            window.location.href = "/Human_Resource/intensive_TTT/perchase_order";
                        });

                }
            })
            .catch((error) => {
                Swal.fire({
                    title: "Error!",
                    text: error.response.data.error[0].errorDis,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            });
    }

    function updateConfirmStatus(id) {
        let data = {
            order_id: id,
            company_id: getUser().com,
            oem_id: getOem(),
        }
        axios({
            method: "post",
            url: Configs.API_URL_incentive + "/api/orderInquiry/CheckSupBeforeConfirm",
            headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: data,
        })
            .then((response) => {
                if (response.data === "No Data") {
                    Swal.fire({
                        title: "ไม่สามารถ Confirm ได้!",
                        text: "ไม่พบข้อมูล Supplier กรุณาตรวจสอบ",
                        icon: "warning",
                        confirmButtonText: "OK",
                    });
                    return;
                }
                else {
                    let CONFIRM = ProductInquiry;
                    setProductInquiry([]);
                    let confirm = CONFIRM.map((item) => {
                        if (item.ol_id === id) {
                            item.Order_Status = "2b7d431a-6697-4e33-bc41-a678bf9f28f5";
                            return item;
                        }
                        else {
                            return item;
                        }
                    });
                    setProductInquiry(confirm);
                    closeMo();
                }
                /* else {
                    let ConfirmData = structuredClone(confirmData);
                    ProductInquiry.map((item) => {
                        if (item.ol_id === id) {
                            let haveconfirm = ConfirmData.filter((item2) => item2.order_id === id).length === 0
                            if (haveconfirm) {
                                ConfirmData.push({
                                    order_id: id,
                                    supplier_id: item.supplier_id,
                                    ol_status_id: "2b7d431a-6697-4e33-bc41-a678bf9f28f5"
                                })
                            }
                        }
                    });
                    setconfirmData(ConfirmData);
                } */
            })
            .catch((error) => {
                Swal.fire({
                    title: "Error!",
                    text: error.response.data.error[0].errorDis,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            });
    }

    function updateCancelStatus() {
        if (Remark.remark === "") {
            Swal.fire({
                title: "Error!",
                text: "กรุณากรอกหมายเหตุ",
                icon: "error",
                confirmButtonText: "OK",
            });
            return;
        }
        let CANCEL = ProductInquiry;
        setProductInquiry([]);
        let cancel = CANCEL.map((item) => {
            if (item.ol_id === Remark.order_id) {
                item.Order_Status = "11c0ae24-51fe-4c53-839a-c4a78a05ffe2";
                return item;
            }
            else {
                return item;
            }
        });
        setProductInquiry(cancel);
    }

    /* let data = {
        order_id: Remark.order_id,
        remark: Remark.remark !== "" ? Remark.remark.trim() : null,
    }
    axios({
        method: "post",
        url: Configs.API_URL_incentive + "/api/orderInquiry/updateCancelStatus",
        headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
        },
        data: data,
    })
        .then((response) => {
            if (response.data === "OK") {
                closeMo();
                FilterProductInquiry();
            }
        })
        .catch((error) => {
            Swal.fire({
                title: "Error!",
                text: error.response.data.error[0].errorDis,
                icon: "error",
                confirmButtonText: "OK",
            });
        }); */

    const CustomInput = ({ value, onClick }) => (
        <div className="input-group">
            <input
                style={{ fontSize: "inherit" }}
                type="text"
                className="form-control float-left"
                onClick={onClick}
                value={value}
                data-provide="datepicker"
                data-date-language="th-th"
            />{" "}
            <div className="input-group-prepend">
                <span className="input-group-text">
                    <i className="far fa-calendar-alt" />
                </span>
            </div>
        </div>
    );
    const OrderInquiryTableData = [];
    for (let index = 0; index < ProductInquiry.length; index++) {
        const rowItem = {};
        rowItem["No"] = index + 1;
        rowItem["Order_ID"] = ProductInquiry[index].Order_ID;
        rowItem["User_ID"] = ProductInquiry[index].User_ID;
        rowItem["Product_No"] = ProductInquiry[index].Product_No;
        rowItem["Product_Name"] = ProductInquiry[index].Product_Name;
        rowItem["Product_Model"] = ProductInquiry[index].Product_Model;
        rowItem["Order_Date"] = ProductInquiry[index].Order_Date;
        rowItem["Order_Status"] = (
            ProductInquiry[index].Order_Status === '76b3aa3d-96ae-4525-a6f7-3f82b1bea353'
                && confirmData.filter((item) => item.order_id === ProductInquiry[index].ol_id).length === 0
                && cancelData.filter((item) => item.order_id === ProductInquiry[index].ol_id).length === 0
                ?
                <>
                    <div className="row">
                        <div className="col-12" style={{ display: "flex" }}>
                            <div className="col-6">
                                <button
                                    type="button"
                                    className="btn btn-block btn-success"
                                    disabled={disable}
                                    onClick={
                                        updateConfirmStatus.bind(this, ProductInquiry[index].ol_id)
                                    }
                                >
                                    Confirm
                                </button>
                            </div>
                            <div className="col-6">
                                <button
                                    type="button"
                                    className="btn btn-block btn-danger"
                                    data-toggle="modal"
                                    data-target="#cancel-modal"
                                    disabled={disable}
                                    onClick={() => {
                                        setRemark({
                                            ...Remark,
                                            remark: "",
                                            order_id: ProductInquiry[index].ol_id,
                                        })
                                        Remark.remark = "";
                                        Remark.order_id = ProductInquiry[index].ol_id;
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </>
                : ProductInquiry[index].Order_Status === '76b3aa3d-96ae-4525-a6f7-3f82b1bea353'
                    && confirmData.filter((item) => item.order_id === ProductInquiry[index].ol_id).length !== 0
                    && cancelData.filter((item) => item.order_id === ProductInquiry[index].ol_id).length === 0
                    ?
                    <>
                        <div className="row">
                            <div className="col-12" style={{ display: "flex" }}>
                                <div className="col-6">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-success"
                                        style={{ backgroundColor: "#28a745", borderColor: "#28a745", opacity: "1" }}
                                        disabled={true}
                                    >
                                        Confirm
                                    </button>
                                </div>
                                <div className="col-6">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-danger"
                                        style={{ backgroundColor: "#808080", borderColor: "#808080", opacity: "1" }}
                                        disabled={true}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                    : ProductInquiry[index].Order_Status === '76b3aa3d-96ae-4525-a6f7-3f82b1bea353'
                        && confirmData.filter((item) => item.order_id === ProductInquiry[index].ol_id).length === 0
                        && cancelData.filter((item) => item.order_id === ProductInquiry[index].ol_id).length !== 0
                        ?
                        <>
                            <div className="row">
                                <div className="col-12" style={{ display: "flex" }}>
                                    <div className="col-6">
                                        <button
                                            type="button"
                                            className="btn btn-block btn-success"
                                            style={{ backgroundColor: "#808080", borderColor: "#808080", opacity: "1" }}
                                            disabled={true}
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                    <div className="col-6">
                                        <button
                                            type="button"
                                            className="btn btn-block btn-danger"
                                            style={{ backgroundColor: "#dc3545", borderColor: "#dc3545", opacity: "1" }}
                                            disabled={true}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                        : (ProductInquiry[index].Order_Status === '2b7d431a-6697-4e33-bc41-a678bf9f28f5'
                            || ProductInquiry[index].Order_Status === 'fc464f6f-3db0-4442-9e22-67748a6624a7'
                            || ProductInquiry[index].Order_Status === '612884f0-493a-43c4-a2f9-1cd9314b8b31')
                            ?
                            <>
                                <div className="row">
                                    <div className="col-12" style={{ display: "flex" }}>
                                        <div className="col-6">
                                            <button
                                                type="button"
                                                className="btn btn-block btn-success"
                                                style={{ backgroundColor: "#28a745", borderColor: "#28a745", opacity: "1" }}
                                                disabled={true}
                                            >
                                                Confirm
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button
                                                type="button"
                                                className="btn btn-block btn-danger"
                                                style={{ backgroundColor: "#808080", borderColor: "#808080", opacity: "1" }}
                                                disabled={true}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : (ProductInquiry[index].Order_Status === '11c0ae24-51fe-4c53-839a-c4a78a05ffe2')
                                ?
                                <>
                                    <div className="row">
                                        <div className="col-12" style={{ display: "flex" }}>
                                            <div className="col-6">
                                                <button
                                                    type="button"
                                                    className="btn btn-block btn-success"
                                                    style={{ backgroundColor: "#808080", borderColor: "#808080", opacity: "1" }}
                                                    disabled={true}
                                                >
                                                    Confirm
                                                </button>
                                            </div>
                                            <div className="col-6">
                                                <button
                                                    type="button"
                                                    className="btn btn-block btn-danger"
                                                    style={{ backgroundColor: "#dc3545", borderColor: "#dc3545", opacity: "1" }}
                                                    disabled={true}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <></>
        );
        rowItem["Confirm_Order_Date"] = ProductInquiry[index].Confirm_Order_Date;
        rowItem["Remark"] = ProductInquiry[index].Remark
            ? ProductInquiry[index].Remark
            : cancelData.filter((item) => item.order_id === ProductInquiry[index].ol_id).length !== 0
                ? cancelData.filter((item) => item.order_id === ProductInquiry[index].ol_id)[0].remark
                : '';

        OrderInquiryTableData.push(rowItem);
    }
    const OrderInquiryTable = {
        columns: [
            {
                label: "No",
                field: "No",
                sort: "asc",
                width: 150,
            },
            {
                label: "Order ID",
                field: "Order_ID",
                sort: "asc",
                width: 150,
            },
            {
                label: "User ID",
                field: "User_ID",
                sort: "asc",
                width: 150,
            },
            {
                label: "Product No",
                field: "Product_No",
                sort: "asc",
                width: 150,
            },
            {
                label: "Product Name",
                field: "Product_Name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Product Model",
                field: "Product_Model",
                sort: "asc",
                width: 150,
            },
            {
                label: "Order Date",
                field: "Order_Date",
                sort: "asc",
                width: 150,
            },
            {
                label: "Order Status",
                field: "Order_Status",
                sort: "asc",
                width: 150,
            },
            {
                label: "Confirm Order Date",
                field: "Confirm_Order_Date",
                sort: "asc",
                width: 150,
            },
            {
                label: "Remark",
                field: "Remark",
                sort: "asc",
                width: 150,
            },
        ],
        rows: OrderInquiryTableData,
    };
    const OrderInquiryTableDataForExcel = [];
    for (let index = 0; index < ProductInquiry.length; index++) {
        const rowItem = {};
        rowItem["No"] = index + 1;
        rowItem["Order_ID"] = ProductInquiry[index].Order_ID;
        rowItem["User_ID"] = ProductInquiry[index].User_ID;
        rowItem["Product_No"] = ProductInquiry[index].Product_No;
        rowItem["Product_Name"] = ProductInquiry[index].Product_Name;
        rowItem["Product_Model"] = ProductInquiry[index].Product_Model;
        rowItem["Order_Date"] = ProductInquiry[index].Order_Date;
        rowItem["Order_Status"] = ProductInquiry[index].Order_Status;
        rowItem["Confirm_Order_Date"] = ProductInquiry[index].Confirm_Order_Date;
        rowItem["Remark"] = ProductInquiry[index].Remark;

        OrderInquiryTableDataForExcel.push(rowItem);
    }
    const OrderInquiryTableForExcel = {
        columns: [
            {
                label: "No",
                field: "No",
                sort: "asc",
                width: 150,
            },
            {
                label: "Order ID",
                field: "Order_ID",
                sort: "asc",
                width: 150,
            },
            {
                label: "User ID",
                field: "User_ID",
                sort: "asc",
                width: 150,
            },
            {
                label: "Product No",
                field: "Product_No",
                sort: "asc",
                width: 150,
            },
            {
                label: "Product Name",
                field: "Product_Name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Product Model",
                field: "Product_Model",
                sort: "asc",
                width: 150,
            },
            {
                label: "Order Date",
                field: "Order_Date",
                sort: "asc",
                width: 150,
            },
            {
                label: "Order Status",
                field: "Order_Status",
                sort: "asc",
                width: 150,
            },
            {
                label: "Confirm Order Date",
                field: "Confirm_Order_Date",
                sort: "asc",
                width: 150,
            },
            {
                label: "Remark",
                field: "Remark",
                sort: "asc",
                width: 150,
            },
        ],
        rows: OrderInquiryTableDataForExcel,
    };
    return (
        <div className="wrapper">
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h1>{pageMode} Purchase Order</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        incentive
                                    </li>
                                    <li className="breadcrumb-item active">
                                        Purchase Order
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-md-4 col-xl-2" hidden={mode.mode == "view"}>
                                <div className="form-group">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-success"
                                        onClick={() => {
                                            saveOption();
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-xl-2">
                                <div className="form-group">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-danger"
                                        onClick={() => {
                                            Swal.fire({
                                                title: "warning",
                                                text: "คุณต้องการยกเลิกหรือไม่?",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText: "ใช่",
                                                cancelButtonText: "ไม่",
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    window.location.href = "/Human_Resource/intensive_TTT/perchase_order";
                                                }
                                            });
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className="col-12 col-md-6 col-xl-2"
                                style={{ zIndex: zIndex.inquiryBox ? "99" : "0" }}
                                onClick={() => {
                                    setzIndex({
                                        ...zIndex,
                                        inquiryBox: true,
                                        startDate: false,
                                        endDate: false,
                                    })
                                    zIndex.inquiryBox = true;
                                    zIndex.startDate = false;
                                    zIndex.endDate = false;
                                }}
                            >
                                <div className="form-group">
                                    <Select
                                        value={Inquiry_option.find(obj => obj.value === Inquiry.inquiry_id)}
                                        options={Inquiry_option}
                                    /* onChange={(e) => {
                                        setInquiry({
                                            ...Inquiry,
                                            inquiry_id: e.value,
                                            inquiry_no: e.label,
                                        })
                                        Inquiry.inquiry_id = e.value;
                                        Inquiry.inquiry_no = e.label;
                                        setdisable(false);
                                        FilterProductInquiry();
                                    }} */
                                    />
                                    <label htmlFor="">Inquiry No.</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6 col-xl-2">

                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        required={true}
                                        disabled={disable}
                                        value={Filter.order_id}
                                        onChange={(e) => {
                                            setFilter({
                                                ...Filter,
                                                order_id: e.target.value,
                                            })
                                            Filter.order_id = e.target.value;
                                        }}
                                    />
                                    <label htmlFor="">Order ID</label>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-2">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        required={true}
                                        disabled={disable}
                                        value={Filter.user_id}
                                        onChange={(e) => {
                                            setFilter({
                                                ...Filter,
                                                user_id: e.target.value,
                                            })
                                            Filter.user_id = e.target.value;
                                        }}
                                    />
                                    <label htmlFor="">User ID</label>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-2">
                                <div className="row">
                                    <div className="col-4 col-md-4 col-xl-4" style={{ display: "flex", justifyContent: "center", alignItems: "center", whiteSpace: "nowrap" }}>
                                        <h5><b>Start Date</b></h5>
                                    </div>
                                    <div className="col-8 col-md-8 col-xl-8">
                                        <div className="form-group" style={{ zIndex: zIndex.startDate ? "99" : "0" }}
                                            onClick={() => {
                                                setzIndex({
                                                    ...zIndex,
                                                    inquiryBox: false,
                                                    startDate: true,
                                                    endDate: false,
                                                })
                                                zIndex.inquiryBox = false;
                                                zIndex.startDate = true;
                                                zIndex.endDate = false;
                                            }}>
                                            <DatePicker
                                                className="form-control"
                                                wrapperClassName="w-100"
                                                dateFormat="dd/MM/yyyy"
                                                selected={Filter.start_date}
                                                readOnly={disable}
                                                maxDate={Filter.end_date ? Filter.end_date : ""}
                                                onChange={(date) => {
                                                    // console.log(date);
                                                    setFilter({
                                                        ...Filter,
                                                        start_date: date,
                                                    })
                                                    Filter.start_date = date;
                                                }}
                                                customInput={<CustomInput />}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-2">
                                <div className="row">
                                    <div className="col-4 col-md-4 col-xl-4" style={{ display: "flex", justifyContent: "center", alignItems: "center", whiteSpace: "nowrap" }}>
                                        <h5><b>End Date</b></h5>
                                    </div>
                                    <div className="col-8 col-md-8 col-xl-8">
                                        <div className="form-group" style={{ zIndex: zIndex.endDate ? "99" : "0" }}
                                            onClick={() => {
                                                setzIndex({
                                                    ...zIndex,
                                                    inquiryBox: false,
                                                    startDate: false,
                                                    endDate: true,
                                                })
                                                zIndex.inquiryBox = false;
                                                zIndex.startDate = false;
                                                zIndex.endDate = true;
                                            }}>
                                            <DatePicker
                                                className="form-control"
                                                wrapperClassName="w-100"
                                                dateFormat="dd/MM/yyyy"
                                                selected={Filter.end_date}
                                                readOnly={disable}
                                                minDate={Filter.start_date ? Filter.start_date : ""}
                                                onChange={(date) => {
                                                    // console.log(date);
                                                    setFilter({
                                                        ...Filter,
                                                        end_date: date,
                                                    })
                                                    Filter.end_date = date;
                                                }}
                                                customInput={<CustomInput />}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-6 col-xl-2">
                                <div className="form-group">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-info"
                                        disabled={disable}
                                        onClick={() => {
                                            console.log(Filter);
                                            console.log(Inquiry);
                                            FilterProductInquiry();
                                        }}
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>
                            <div className="col-6 col-md-6 col-xl-2">
                                <div className="form-group">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-info"
                                        disabled={disable}
                                        onClick={() => {
                                            clear();
                                        }}
                                    >
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </div>
                        {ProductInquiry.length === 0 ? (
                            <>
                                <div className="row">
                                    <div className="col-12 col-md-6 col-xl-2">
                                        <div className="form-group">
                                            <button
                                                type="button"
                                                className="btn btn-block btn-primary"
                                                disabled={disable}
                                            >
                                                Download Excel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="row">
                                    <div className="col-12 col-md-6 col-xl-2">
                                        <div className="form-group">
                                            <ReactHTMLTableToExcel
                                                isDisabled={disable}
                                                id="test-table-xls-button"
                                                className="btn btn-block btn-primary"
                                                table="OrderInquiryTableForExcel"
                                                filename={"PO" + moment(new Date()).format("DD-MM-yy")
                                                }
                                                sheet={"PO" + moment(new Date()).format("DD-MM-yy")}
                                                buttonText="Download Excel"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </section>
                <section className="content-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive " style={{ whiteSpace: 'nowrap' }}>
                                            <MDBDataTable
                                                striped
                                                bordered
                                                hover
                                                data={OrderInquiryTable}
                                                // paging={false}
                                                searching={false}
                                                sortable={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="modal fade" id="cancel-modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Order Remark</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <section className="content-body">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-12 col-md-12 col-xl-12">
                                                            <div className="form-group">
                                                                <label htmlFor="">Remark</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={Remark.remark}
                                                                    onChange={(e) => {
                                                                        setRemark({
                                                                            ...Remark,
                                                                            remark: e.target.value,
                                                                        });
                                                                        Remark.remark = e.target.value;
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-primary" onClick={() => { updateCancelStatus() }}>Save</button>
                                    <button type="button" className="btn btn-default" data-dismiss="modal" ref={closeModal}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{ display: "none" }}>
                    <MDBDataTable
                        id="OrderInquiryTableForExcel"
                        data={OrderInquiryTableForExcel}
                    />
                </section>
            </div >
        </div >
    )
}

export default Generate_PO_EV