import React, { useState, useEffect } from "react";

function Wave_Type_2({
  gradient_id = "",
  gradient_offset_1 = "0%",
  gradient_offset_2 = "100%",
  gradient_color_1 = "#FFFFFF",
  gradient_color_2 = "#FFFFFF",
  gradient_opacity_1 = "1",
  gradient_opacity_2 = "1",
  use_y = "0",
  use_class = "",
}) {
  return (
    <>
      <div className="waves-position">
        <svg
          className="waves-box"
          viewBox="0 0 140 80"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <linearGradient id={gradient_id} x1="0" x2="0" y1="0" y2="1">
              <stop
                offset={gradient_offset_1}
                stopColor={gradient_color_1}
                stopOpacity={gradient_opacity_1}
              />
              <stop
                offset={gradient_offset_2}
                stopColor={gradient_color_2}
                stopOpacity={gradient_opacity_2}
              />
            </linearGradient>
          </defs>

          <svg className="waves-parallax" viewBox={`-50 -${use_y} 140 80`}>
            <path
              id="path-wave"
              d="M -160 44 c 30 0 58 -12 88 -12 s 58 12 88 12 58 -12 88 -12 58 12 88 12 v 44 h -352 z"
              fill={`url(#${gradient_id})`}
              className={use_class}
            />
          </svg>
        </svg>
      </div>
    </>
  );
}
export default Wave_Type_2;
