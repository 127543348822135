import React, { Component, useEffect, useState, useRef } from "react";
import axios from "axios";
import Configs from "../../../../config";
import { useLocation, useParams } from "react-router";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import NumberFormat from "react-number-format";

import {
    getOem,
    getToken,
    getUser,
    getAcademy,
} from "../../../../Utils/Common";

import "../../../../assets/css/ProductService.css";


import Select from "react-select";
import PointCartLine from "../Incentive_User_component/Point_Cart_Line";
import { browserHistory, useHistory } from 'react-router-dom';

import customerImage from "../../../../assets/img/product_incentive/customer.png"
import "../../../../assets/css/product_incentive.css";

function Confirm_Product() {
    const location = useLocation();
    const history = useHistory();
    const dataReceived = location.state;
    useEffect(() => {
        console.log('dataReceived', dataReceived);
    }, []);

    const [address_emp, setAddress_emp] = useState({
        data: [],
    });

    const [product_emp, setProduct_emp] = useState({
        product: [],
    });

    const [addAddress_emp, setAddAddress_emp] = useState({
        emp_name: '',
        emp_lastname: '',
        emp_mail: '',
        emp_phone: '',
        emp_address: '',
        default_status: false,
        emp_checkbox: false,

    });

    const [totalPoint, setTotalPoint] = useState({
        totalPoint: 0,
        totalDiscount: 0,
    })

    const [modeAndIndexModal, setModeAndIndexModal] = useState({
        mode: null,
        index: null,
    });

    const handleRedirect = () => {
        for (let item = 0; item <= 20; item++) {
            history.push('/Human_Resource/incentive_user/product_service');
        }
    }

    useEffect(async () => {
        await getAddressOfEmp();

        /* const temp = {
            p_no: '1234',
            p_name: 'Mouse 77',
            p_model: 'black',
            p_amount: 2,
            p_point: 999,
            p_discount: null,
            p_discount_flash_sale: null,
        }
        const arr = [];
        arr.push(temp);
        setProduct_emp({ product: arr });
        product_emp.product = arr; */
    }, []);

    const closeModal = useRef();

    const onClickAddAddress = () => {
        closeModal.current.click();
    }


    const checkSwalErrorAdd = () => {
        var error_list = [];
        if (addAddress_emp.emp_name == "") {
            let temp_err = {
                message: "กรุณากรอก ช่องชื่อ",
            };
            error_list.push(temp_err);
        }

        if (addAddress_emp.emp_lastname == "") {
            let temp_err = {
                message: "กรุณากรอก ช่องนามสกุล",
            };
            error_list.push(temp_err);
        }

        if (addAddress_emp.emp_address == "") {
            let temp_err = {
                message: "กรุณากรอก ช่องที่อยู่",
            };
            error_list.push(temp_err);
        }

        if (addAddress_emp.emp_phone == "") {
            let temp_err = {
                message: "กรุณากรอก ช่องเบอร์โทรศัพท์",
            };
            error_list.push(temp_err);
        }

        return error_list;

    }

    const Add_AddressEmp = () => {
        const errorList = checkSwalErrorAdd();
        if (errorList.length > 0) {
            var err_message = "";
            for (var e = 0; e < errorList.length; e++) {
                err_message += "<br/>" + errorList[e].message;
            }
            Swal.fire("Error", err_message, "error");
            return;
        }

        const temp = {
            emp_name: addAddress_emp.emp_name,
            emp_lastname: addAddress_emp.emp_lastname,
            emp_mail: addAddress_emp.emp_mail || '',
            emp_phone: addAddress_emp.emp_phone,
            emp_address: addAddress_emp.emp_address,
            default_status: addAddress_emp.default_status,
            emp_checkbox: addAddress_emp.emp_checkbox
        }

        const arr = address_emp.data;
        setAddress_emp({ data: [] });
        if (modeAndIndexModal.mode && modeAndIndexModal.index) {
            const indexEdit = modeAndIndexModal.index;
            arr[indexEdit] = temp;
        } else {
            arr.push(temp);
        }
        setAddress_emp({ data: arr });
        address_emp.data = arr;
        setModeAndIndexModal({
            mode: null,
            index: null,
        })
        modeAndIndexModal.mode = null;
        modeAndIndexModal.index = null;
        onClickAddAddress();
    }

    const getAddressOfEmp = async () => {
        var get_category = {
            oem_id: getOem(),
            company_id: getUser().com,
            fup: getUser().emp_id,
        }
        await axios({
            method: "post",
            url: Configs.API_URL_incentive + "/api/detailsAddressEmployee/getAddressOfEmp",
            headers: {
                Authorization: 'Bearer ' + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: get_category,
        })
            .then(function (response) {
                console.log(response.data.employee_address_list, "ds");
                // setselect_category(response.data.category_list);
                const arr = [];
                let arr2 = [];
                if (/* response.data.employee_address_list.length */dataReceived.emp_address.length > 0) {
                    for (let item of dataReceived.emp_address) {
                        arr.push(item);
                    }
                }
                let sumDiscount = 0;
                let sumTotal = 0;
                if (/* response.data.employee_product_list.length */ dataReceived.product.length > 0) {
                    for (let item of dataReceived.product) {
                        if (item.ps_checkbox === true) {
                            let amount = item.p_amount ? parseInt(item.p_amount) : 0;
                            let point = item.p_point ? parseInt(item.p_point) : 0;
                            if (item.p_point_flash_sale) {
                                let flash_sale = parseInt(item.p_point_flash_sale) * amount;
                                sumTotal += flash_sale;
                                let discount = (point * amount) - flash_sale;
                                sumDiscount += discount;
                            } else {
                                sumTotal += point * amount;
                            }
                            arr2.push(item);
                        }
                    }
                }
                setTotalPoint({
                    totalPoint: sumTotal,
                    totalDiscount: sumDiscount
                });
                totalPoint.totalPoint = sumTotal;
                totalPoint.totalDiscount = sumDiscount;
                setProduct_emp({ product: arr2 });
                product_emp.product = arr;
                setAddress_emp({ data: arr });
                address_emp.data = arr;
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire({
                    icon: "warning",
                    title: "เกิดข้อผิดพลาด",
                    text: error.response.data.error.errorDis,
                });
            });
    }

    function checkSwalErrorSave() {
        var error_list = [];
        if (addAddress_emp.length == 0) {
            let temp_err = {
                message: "กรุณาเพิ่มที่อยู่การจัดส่ง",
            };
            error_list.push(temp_err);
        }

        return error_list;
    }

    const saveEmpProductList = () => {
        const errorList = [];
        if (errorList.length > 0) {
            var err_message = "";
            for (var e = 0; e < errorList.length; e++) {
                err_message += "<br/>" + errorList[e].message;
            }
            Swal.fire("Error", err_message, "error");
            return;
        } else {
            Swal.fire({
                title: "ยืนยันการสั่งซื้อสินค้า ใช่หรือไม่",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: `ใช่`,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: `ไม่`,
            }).then((result) => {
                if (result.isConfirmed) {
                    var employees = {
                        oem_id: getOem(),
                        company_id: getUser().com,
                        fup: getUser().emp_id,
                        product: product_emp.product,
                        employee: dataReceived.emp_address[0],
                        totalPoint: totalPoint.totalPoint ? parseInt(totalPoint.totalPoint) : 0
                    }
                    axios({
                        method: "post",
                        url: Configs.API_URL_incentive + "/api/detailsAddressEmployee/saveOrderProductEmployee",
                        headers: {
                            Authorization: 'Bearer ' + getToken(),
                            "X-TTT": Configs.API_TTT,
                            "Content-Type": "application/json",
                        },
                        data: employees,
                    })
                        .then(function (response) {
                            console.log(response.data.employee_address_list, "ds");
                            Swal.fire({
                                title: 'ทำการสั่งสินค้าเรียบร้อยแล้ว',
                                // text: `กรุณาตรวจสอบสถานะ ที่เมนูสถานะสินค้า`,
                                html: `<h4 style="color: #0d9bff;">กรุณาตรวจสอบสถานะ ที่เมนูสถานะสินค้า</h4>`,
                                iconHtml: `<img style="width: 120px; height: 120px; border: 0;" src="${customerImage}" >`,
                                customClass: {
                                    icon: 'no-border',
                                    text: 'text-color'
                                }
                            }).then((res) => {
                                if (res.isConfirmed) {
                                    handleRedirect();

                                    // window.location.replace('/Human_Resource/incentive_user/product_service');
                                    // window.location.href = "/Human_Resource/incentive_user/product_service"
                                }
                            })
                            // setselect_category(response.data.category_list);
                            // getDetailsAddressOfEmp();
                        })
                        .catch(function (error) {
                            console.log(error);
                            Swal.fire({
                                icon: "warning",
                                title: "เกิดข้อผิดพลาด",
                                text: error.response.data.error.errorDis,
                            });
                        });
                }
            })
        }
    }

    function numberWithCommas(x) {
        return (
            <NumberFormat
                thousandSeparator={true}
                value={x || 0}
                displayType="text"
            />
        );
        /*  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
    }

    return (
        <div className="wrapper">
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h1>สั่งซื้อสินค้า</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="/Welcome">Home</a>
                                    </li>

                                    <li className="breadcrumb-item active">Human Resource</li>
                                    <li className="breadcrumb-item active">แลกสินค้า</li>
                                    <li className="breadcrumb-item active">สั่งซื้อสินค้า</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="row">
                        <PointCartLine />
                    </div>

                    <div className='row'>
                        <div className='' style={{ backgroundColor: '#e4f4ff', height: 'auto', padding: '1em', margin: '1em', width: '100%', borderRadius: '20px' }}>
                            <h4 style={{ margin: '0', fontWeight: '800' }}>รายการสินค้าที่สั่ง</h4>
                        </div>
                    </div>
                    <div className="scroll-response" >
                        <div style={{ width: '100%', minWidth: '1024px' }} >
                            <div className='row'>
                                <div className='col-12 margin-response' style={{ height: 'auto', padding: '1em', margin: '0', width: 'auto', borderRadius: '20px' }}>
                                    <table style={{ width: '100%' }}>
                                        <tr>
                                            <th style={{ backgroundColor: 'inherit', textAlign: 'start' }}>รหัสสินค้า</th>
                                            <th style={{ backgroundColor: 'inherit', textAlign: 'start' }}>สินค้า</th>
                                            <th style={{ backgroundColor: 'inherit', textAlign: 'start' }}>ตัวเลือกสินค้า</th>
                                            <th style={{ backgroundColor: 'inherit' }}>จำนวน</th>
                                            <th style={{ backgroundColor: 'inherit' }}>คะแนนรวม</th>
                                        </tr>
                                        {product_emp.product.map((el, index) => {
                                            return (
                                                <>
                                                    <tr style={{ textAlign: 'center' }}>
                                                        <td style={{ textAlign: 'start' }}>{el.p_no ? el.p_no : '-'}</td>
                                                        <td style={{ textAlign: 'start' }}>{el.p_name ? el.p_name : '-'}</td>
                                                        <td style={{ textAlign: 'start' }}>{el.p_model ? el.p_model : '-'}</td>
                                                        <td style={{ textAlign: 'start' }}>{el.p_amount ? el.p_amount : '-'}</td>
                                                        <td style={{ textAlign: 'start' }}>{numberWithCommas(el.p_point * el.p_amount)} คะแนน</td>
                                                    </tr>
                                                </>
                                            )
                                        })}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='' style={{ backgroundColor: '#e4f4ff', height: 'auto', padding: '1em', margin: '1em', width: '100%', borderRadius: '20px' }}>
                            <h4 style={{ margin: '0', fontWeight: '800' }}>รายละเอียดการจัดส่ง</h4>
                        </div>
                    </div>

                    {
                        address_emp.data.map((el, index) => {
                            return <>
                                <div className='row' key={'address' + index}>
                                    <div className='' key={'address-col' + index} style={{ backgroundColor: '#e4f4ff', height: 'auto', padding: '1em', margin: '0 1em', width: '100%', borderRadius: '20px' }}>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col-12 col-sm-4 col-md-4 col-xl-4'>
                                                        <div style={{ display: 'flex' }}>
                                                            <div className="col-gab-response">
                                                                <h5><b>ชื่อ</b></h5>
                                                            </div>
                                                            <h5>: {el.emp_name}</h5>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-sm-4 col-md-4 col-xl-4'>
                                                        <div style={{ display: 'flex' }}>
                                                            <div className="col-gab-response">
                                                                <h5><b>นามสกุล</b></h5>
                                                            </div>
                                                            <h5>: {el.emp_lastname}</h5>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-sm-4 col-md-4 col-xl-4'>
                                                        <div style={{ display: 'flex' }}>
                                                            <div className="col-gab-response">
                                                                <h5><b>เบอร์โทรศัพท์</b></h5>
                                                            </div>
                                                            <h5>: {el.emp_phone}</h5>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-sm-10 col-md-10 col-xl-10'>
                                                        <div style={{ display: 'flex' }}>
                                                            <div className="col-gab-response">
                                                                <h5><b>ที่อยู่</b></h5>
                                                            </div>
                                                            <h5>: {el.emp_address}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <h4 style={{ margin: '0', fontWeight: '800' }}>รายละเอียดการจัดส่ง</h4> */}
                                    </div>
                                </div>
                            </>
                        })
                    }

                    <div className='row'>
                        <div className='' style={{ backgroundColor: '#e4f4ff', height: 'auto', padding: '1em', margin: '1em', width: '100%', borderRadius: '20px' }}>
                            <h4 style={{ margin: '0', fontWeight: '800' }}>ข้อมูลการชำระคะแนน</h4>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12 margin-response' style={{ height: 'auto', padding: '0 1em 1em 2em', margin: '0', width: 'auto', borderRadius: '20px' }}>
                            {
                                product_emp.product.map((el, index) => {
                                    return (
                                        <>
                                            <div className="row">
                                                <div className="col-7 col-sm-4 col-md-4 col-xl-4"><h5>รายการสั่งซื้อ : {`${el.p_name} X ${el.p_amount}`}</h5></div>
                                                <div className="col-5 col-sm-3 col-md-3 col-xl-3" style={{ textAlign: 'end' }}><h5>{numberWithCommas(el.p_point * el.p_amount)} คะแนน</h5></div>
                                            </div>
                                        </>
                                    )
                                })
                            }

                            <div className="row">
                                <div className="col-7 col-sm-4 col-md-4 col-xl-4"><h5>การจัดส่ง</h5></div>
                                <div className="col-5 col-sm-3 col-md-3 col-xl-3" style={{ textAlign: 'end' }}><h5> 0 คะแนน</h5></div>
                            </div>
                            <div className="row">
                                <div className="col-7 col-sm-4 col-md-4 col-xl-4"><h5>ส่วนลด</h5></div>
                                <div className="col-5 col-sm-3 col-md-3 col-xl-3" style={{ textAlign: 'end' }}><h5>{numberWithCommas(totalPoint.totalDiscount)} คะแนน</h5></div>
                            </div>
                            <div className="row" style={{ color: '#0d9bff' }}>
                                <div className="col-7 col-sm-4 col-md-4 col-xl-4"><h4>ยอดชำระคะแนนทั้งหมด</h4></div>
                                <div className="col-5 col-sm-3 col-md-3 col-xl-3" style={{ textAlign: 'end' }}><h4>{numberWithCommas(totalPoint.totalPoint)} คะแนน</h4></div>
                            </div>
                        </div>
                    </div>


                    <div className='row' style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='col-5 col-xl-2 col-md-3' style={{ height: 'auto', padding: '1em', margin: '0', width: 'auto', borderRadius: '20px' }}>
                            <button
                                type="button"
                                // onClick={saveOption}
                                style={{ color: '#001f50', border: '1px solid #001f50', borderRadius: '15px', padding: '.5em 1.5em' }}
                                className="btn btn-block btn-outline-primary btn-hov"
                                id="btn-add"
                                onClick={() => {
                                    history.push({
                                        pathname: '/Human_Resource/incentive_user/product_service/pay_details',
                                        state: dataReceived
                                    });
                                    // window.location.href = "/Human_Resource/incentive_user/product_service/pay_details"
                                }}
                            // disabled={disable}
                            >
                                ย้อนกลับ
                            </button>
                        </div>
                        <div className='col-5 col-xl-2 col-md-3' style={{ height: 'auto', padding: '1em', margin: '0', width: 'auto', borderRadius: '20px', whiteSpace: 'nowrap' }}>
                            <button
                                type="button"
                                // onClick={saveOption}
                                style={{ backgroundColor: '#001f50', borderRadius: '15px', padding: '.5em 1.5em', border: 'none' }}
                                className="btn btn-block btn-primary"
                                id="btn-add"
                                /*  data-toggle="modal"
                                 data-target="#modal-add-supplier"
                                 data-backdrop="static"
                                 data-keyboard="false" */
                                // disabled={disable}
                                onClick={() => {
                                    console.log('log');
                                    saveEmpProductList();

                                }}
                            >
                                ยืนยันสั่งสินค้า
                            </button>
                        </div>
                    </div>


                </section>

            </div>
        </div>
    );
}

export default Confirm_Product;