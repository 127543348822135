import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../Utils/Common";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import Select from "react-select";

function Leave_information_approve(params) {
  const [filterLeave, setFilterLeave] = useState({
    emp_name_th: "",
    emp_no: "",
    leave_type: "",
    head_id: getUser().emp_id,
    start_date: null,
    end_date: null,
    is_not_approve_only: false,
  });

  const [data_leave, setdata_leave] = useState({
    id: "",
    name: "",
    leave_status: "",
    detail: "",
    day_status: "",
    start_date: "",
    finish_date: "",
    start_time: "",
    finish_time: "",
    file_name: "",
    file_path: "",
  });

  const [dateZindex, setdateZindex] = useState({
    date1: "98",
    date2: "98",
    time1: "98",
    time2: "98",
  });

  const [teamEntitileLeave, setTeamEntitileLeave] = useState({
    head_id: "",
    emp_name_th: "",
    is_hr_approve: false,
    is_team_head_approve: false,
    team_member: [],
  });
  const [LeaveTypeOption, setLeaveTypeOption] = useState([]);
  const [status_check, setstatus_check] = useState(false);

  const [employeeHistory, setemployeeHistory] = useState({});

  const [tableDRAS, setTableDRAS] = useState(true);

  async function getAllLeaveInfomation(params) {
    const data = { ...filterLeave };
    data.head_id = getUser().emp_id;
    data.oem_id = getOem();
    data.company_id = getUser().com;
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/filterLeaveInformation",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then(async function (response) {
        // console.log('response.data.data', response.data.data.team_member[0].start_date)
        let AllList = [];
        let isDepartmentNull = [];
        let isDepartmentTrue = [];
        let isDepartmentFalse = [];
        let isHrNull = [];
        let isHrTrue = [];
        let isHrFalse = [];

        if (!filterLeave.is_not_approve_only) {
          isDepartmentNull = response.data.data.team_member.filter((item) => {
            return item.is_department === null;
          });

          isHrNull = response.data.data.team_member.filter((item) => {
            return item.is_department === true && item.is_hr === null;
          });

          isHrTrue = response.data.data.team_member.filter((item) => {
            return item.is_department === true && item.is_hr === true;
          });

          isHrFalse = response.data.data.team_member.filter((item) => {
            return item.is_department === true && item.is_hr === false;
          });

          isDepartmentFalse = response.data.data.team_member.filter((item) => {
            return item.is_department === false;
          });
        } else {
          if (response.data.data.is_team_head_approve) {
            isDepartmentNull = response.data.data.team_member.filter((item) => {
              return (
                item.is_department === null &&
                response.data.data.head_id === item.head_id
              );
            });
          }

          if (response.data.data.is_hr_approve) {
            isHrNull = response.data.data.team_member.filter((item) => {
              return item.is_department === true && item.is_hr === null;
            });
          }
        }

        AllList.push(...isDepartmentNull);
        AllList.push(...isHrNull);
        AllList.push(...isHrTrue);
        AllList.push(...isHrFalse);
        AllList.push(...isDepartmentFalse);

        AllList.sort(
          (a, b) => moment(b.ent_date).unix() - moment(a.ent_date).unix()
        );

        response.data.data.team_member = AllList;

        setTeamEntitileLeave(response.data.data);
      })
      .catch(function (error) {
        console.log(error.response.data.error);
        Swal.fire(
          "Error",
          "เกิดข้อผิดพลาด: " + error.response.data.error.message,
          "error"
        );
      });
  }

  const getLeaveType = async () => {
    //console.log(filler_employee);
    axios({
      method: "get",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getLeaveType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        const options = [];
        var tmp = {
          value: "",
          label: "ทั้งหมด",
        };
        options.push(tmp);
        response.data.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.status_name;
          options.push(element);
        });
        setLeaveTypeOption(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  const rowsData = [];

  for (var index = 0; index < teamEntitileLeave.team_member.length; index++) {
    const rowItem = {};

    rowItem["no"] = index + 1;
    rowItem["emp_no"] = teamEntitileLeave.team_member[index].emp_no;
    rowItem["emp_name"] = teamEntitileLeave.team_member[index].emp_name_th;
    rowItem["leave_type"] =
      teamEntitileLeave.team_member[index].leave_type_name || "";

    if (teamEntitileLeave.team_member[index].leave_type === 1) {
      rowItem["leave_date"] = (
        <>
          {teamEntitileLeave.team_member[index].start_date !== null
            ? moment(teamEntitileLeave.team_member[index].start_date).format(
                "DD/MM/YYYY"
              )
            : "เวลาไม่ถูกต้อง"}
          -
          {teamEntitileLeave.team_member[index].ent_date !== null
            ? moment(teamEntitileLeave.team_member[index].ent_date).format(
                "DD/MM/YYYY"
              )
            : "เวลาไม่ถูกต้อง"}
        </>
      );
    } else {
      rowItem["leave_date"] = (
        <>
          {teamEntitileLeave.team_member[index].start_date !== null
            ? `${moment(teamEntitileLeave.team_member[index].start_date).format(
                "DD/MM/YYYY"
              )}`
            : "เวลาไม่ถูกต้อง"}
          -
          {teamEntitileLeave.team_member[index].ent_date !== null
            ? moment(teamEntitileLeave.team_member[index].ent_date).format(
                "DD/MM/YYYY"
              )
            : "เวลาไม่ถูกต้อง"}
          {/* {teamEntitileLeave.team_member[index].ent_date !== null
            ? `${moment(teamEntitileLeave.team_member[index].ent_date).format(
                "DD/MM/YYYY"
              )}   ${
                teamEntitileLeave.team_member[index].start_time !== null
                  ? "," + teamEntitileLeave.team_member[index].start_time
                  : ""
              }
               ${
                 teamEntitileLeave.team_member[index].end_time !== null
                   ? "-" + teamEntitileLeave.team_member[index].end_time
                   : ""
               }`
            : "เวลาไม่ถูกต้อง"} */}
        </>
      );
    }

    rowItem["department"] = teamEntitileLeave.team_member[index].dep_name || "";

    if (teamEntitileLeave.team_member[index].import_file === null) {
      rowItem["documents"] = (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "100%" }}>
              <a
                href={
                  Configs.API_URL_IMG +
                  teamEntitileLeave.team_member[index].import_file
                }
                download
              >
                <button
                  type="button"
                  disabled
                  id="btn_search"
                  className="btn btn-block btn-info"
                  style={{ minWidth: "60px" }}
                >
                  ไม่พบไฟล์
                </button>
              </a>
            </div>
          </div>
        </>
      );
    } else {
      rowItem["documents"] = (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "100%" }}>
              <a
                href={
                  Configs.API_URL_IMG +
                  teamEntitileLeave.team_member[index].import_file
                }
                download
              >
                <button
                  type="button"
                  id="btn_search"
                  className="btn btn-block btn-info"
                  style={{ minWidth: "60px" }}
                >
                  Download
                </button>
              </a>
            </div>
          </div>
        </>
      );
    }

    if (teamEntitileLeave.team_member[index].is_department === null) {
      if (
        teamEntitileLeave.head_id ===
        teamEntitileLeave.team_member[index].head_id
      ) {
        rowItem["department_status"] = (
          <>
            <div
              key={
                teamEntitileLeave.team_member[index].employee_id + "ApproveDep"
              }
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <div style={{ width: "100%" }} className="mr-1">
                <button
                  type="button"
                  id={JSON.stringify(teamEntitileLeave.team_member[index].id)}
                  className="btn btn-block btn-success"
                  style={{ minWidth: "60px" }}
                  onClick={(e) => {
                    approve_by_department(true, e.target.id);
                  }}
                >
                  อนุมัติ
                </button>
              </div>
              <div style={{ width: "100%" }} className="ml-1">
                <button
                  type="button"
                  id={JSON.stringify(teamEntitileLeave.team_member[index].id)}
                  className="btn btn-block btn-danger"
                  style={{ minWidth: "60px" }}
                  onClick={(e) => {
                    approve_by_department(false, e.target.id);
                  }}
                >
                  ไม่อนุมัติ
                </button>
              </div>
            </div>
          </>
        );
      } else {
        <></>;
      }
    } else {
      if (teamEntitileLeave.team_member[index].is_department === true) {
        rowItem["department_status"] = (
          <>
            <div
              key={
                teamEntitileLeave.team_member[index].employee_id + "ApproveDep"
              }
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <div style={{ width: "100%" }} className="mr-1">
                <button
                  type="button"
                  disabled
                  id="btn_search"
                  className="btn btn-block btn-success"
                  style={{ minWidth: "60px" }}
                >
                  อนุมัติ
                </button>
              </div>
            </div>
          </>
        );
      } else {
        rowItem["department_status"] = (
          <>
            <div
              key={
                teamEntitileLeave.team_member[index].employee_id +
                "noneApproveDep"
              }
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <div style={{ width: "100%" }} className="ml-1">
                <button
                  type="button"
                  id="btn_search"
                  disabled
                  className="btn btn-block btn-danger"
                  style={{ minWidth: "60px" }}
                >
                  ไม่อนุมัติ
                </button>
              </div>
            </div>
          </>
        );
      }
    }

    if (teamEntitileLeave.team_member[index].is_hr === null) {
      if (
        teamEntitileLeave.team_member[index].is_department === false ||
        teamEntitileLeave.team_member[index].is_department === null
      ) {
        <></>;
      } else {
        rowItem["hr_status"] = (
          <>
            <div
              key={
                teamEntitileLeave.team_member[index].employee_id + "ApproveHR"
              }
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <div style={{ width: "100%" }} className="mr-1">
                <button
                  type="button"
                  id={JSON.stringify(teamEntitileLeave.team_member[index].id)}
                  className="btn btn-block btn-success"
                  disabled={
                    teamEntitileLeave.team_member[index].is_department === null
                      ? true
                      : false
                  }
                  style={{ minWidth: "60px" }}
                  onClick={(e) => {
                    approve_by_hr(true, e.target.id);
                  }}
                >
                  อนุมัติ
                </button>
              </div>
              <div style={{ width: "100%" }} className="ml-1">
                <button
                  type="button"
                  id={JSON.stringify(teamEntitileLeave.team_member[index].id)}
                  disabled={
                    teamEntitileLeave.team_member[index].is_department === null
                      ? true
                      : false
                  }
                  className="btn btn-block btn-danger"
                  style={{ minWidth: "60px" }}
                  onClick={(e) => {
                    approve_by_hr(false, e.target.id);
                  }}
                >
                  ไม่อนุมัติ
                </button>
              </div>
            </div>
          </>
        );
      }
    } else {
      if (teamEntitileLeave.team_member[index].is_hr === true) {
        rowItem["hr_status"] = (
          <>
            <div
              key={
                teamEntitileLeave.team_member[index].employee_id + "ApproveHR"
              }
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <div style={{ width: "100%" }} className="mr-1">
                <button
                  type="button"
                  id="btn_search"
                  disabled
                  className="btn btn-block btn-success"
                  style={{ minWidth: "60px" }}
                >
                  อนุมัติ
                </button>
              </div>
            </div>
          </>
        );
      } else {
        rowItem["hr_status"] = (
          <>
            <div
              key={
                teamEntitileLeave.team_member[index].employee_id +
                "noneApproveHR"
              }
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <div style={{ width: "100%" }} className="ml-1">
                <button
                  type="button"
                  id="btn_search"
                  className="btn btn-block btn-danger"
                  style={{ minWidth: "60px" }}
                  disabled
                >
                  ไม่อนุมัติ
                </button>
              </div>
            </div>
          </>
        );
      }
    }

    rowItem["remark"] = (
      <>
        {teamEntitileLeave.team_member[index].not_approve_remark}

        {/*   */}
      </>
    );
    if (teamEntitileLeave.team_member[index].leave_half_day === 1) {
      rowItem["leave_status"] = "ลาครึ่งเช้า";
    } else if (teamEntitileLeave.team_member[index].leave_half_day === 2) {
      rowItem["leave_status"] = "ลาครึ่งบ่าย";
    } else {
      if (teamEntitileLeave.team_member[index].leave_type === 1) {
        rowItem["leave_status"] = "ลาทั้งวัน";
      } else {
        // rowItem["leave_status"] = "-";
        rowItem["leave_status"] = `${
          teamEntitileLeave.team_member[index].start_time !== null
            ? teamEntitileLeave.team_member[index].start_time
            : ""
        } - ${
          teamEntitileLeave.team_member[index].end_time !== null
            ? teamEntitileLeave.team_member[index].end_time
            : ""
        }`;
      }
    }
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            key={teamEntitileLeave.team_member[index].id}
            /* id={JSON.stringify(teamEntitileLeave.team_member[index].id)} */
            className="btn btn-xs "
            data-toggle="modal"
            data-target="#modal-viewLeaveHistory"
            onClick={setModalDataLeave.bind(
              this,
              teamEntitileLeave.team_member[index].id
            )}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
      </div>
    );

    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "รหัสพนักงาน",
        field: "emp_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อ - นามสกุล",
        field: "emp_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "ประเภทการลา",
        field: "leave_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "ช่วงเวลา",
        field: "leave_status",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่ลา",
        field: "leave_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Department",
        field: "department",
        sort: "asc",
        width: 50,
      },
      {
        label: "เอกสารเพิ่มเติม",
        field: "documents",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานะ Department",
        field: "department_status",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานะ Hr",
        field: "hr_status",
        sort: "asc",
        width: 50,
      },
      {
        label: "สาเหตุที่ไม่อนุมัติ",
        field: "remark",
        sort: "asc",
        width: 50,
      },
      {
        label: "การจัดการ",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
  if (!teamEntitileLeave.is_team_head_approve) {
    const index = data.columns.findIndex((e) => {
      return e.field === "department_status";
    });

    data.columns.splice(index, 1);
  }
  if (!teamEntitileLeave.is_hr_approve) {
    const index = data.columns.findIndex((e) => {
      return e.field === "hr_status";
    });
    data.columns.splice(index, 1);
  }
  async function setModalDataLeave(id) {
    console.log("id", id);
    const findData = teamEntitileLeave.team_member.filter((e) => {
      return e.id === id;
    });

    console.log("findData", findData);
    /* 
    const start_time = moment(findData[0].start_date)
      .startOf("day")
      .add(findData[0].start_time.split(":")[0], "hours")
      .add(findData[0].start_time.split(":")[1], "minutes");
    const finish_time = moment(findData[0].start_date)
      .startOf("day")
      .add(findData[0].end_time.split(":")[0], "hours")
      .add(findData[0].end_time.split(":")[1], "minutes"); */

    setdata_leave({
      ...data_leave,
      name: findData[0].emp_name_th,
      leave_status: findData[0].leave_status || "ไม่พบ",
      detail: findData[0].remark || "ไม่พบ",
      day_status: findData[0].leave_type_name,

      file_path: findData[0].import_file,
    });

    data_leave.detail = findData[0].remark || "ไม่พบ";
  }

  async function clearFilter() {
    setFilterLeave({
      emp_name_th: "",
      emp_no: "",
      leave_type: "",
      head_id: getUser().emp_id,
      start_date: null,
      end_date: null,
      // is_not_approve_only: false,
    });

    filterLeave.emp_name_th = "";
    filterLeave.emp_no = "";
    filterLeave.leave_type = "";
    filterLeave.start_date = null;
    filterLeave.end_date = null;
    // filterLeave.is_not_approve_only = false;

    setTableDRAS(false);

    await getAllLeaveInfomation();

    setTableDRAS(true);
  }

  async function approve_by_hr(approved, emp) {
    console.log(approved, emp);

    if (approved) {
      Swal.fire({
        title: "อนุมัติการลา",
        text: "คุณต้องการอนุมัติการลาใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่",
        cancelButtonText: "ไม่",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const save_temp = {
            id: JSON.parse(emp),
            approved: approved,
            remark: "",
          };

          axios({
            method: "post",
            url:
              Configs.API_URL_hrMagenatement + "/api/hrManagement/approvedByHR",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: save_temp,
          })
            .then(async function (response) {
              console.log(response.data.data);
              Swal.fire({
                icon: "success",
                title: "สำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              });
              getAllLeaveInfomation();
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            });
        }
      });
    } else {
      Swal.fire({
        title: "อนุมัติการลา",
        text: "คุณไม่อนุมัติการลาใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่",
        cancelButtonText: "ไม่",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const save_temp = {
            id: JSON.parse(emp),
            approved: approved,
            remark: "",
          };

          const { value: formValues } = await Swal.fire({
            title: "สาเหตุที่ไม่อนุมัติ",
            html: '<input id="swal-input1" class="swal2-input">',
            focusConfirm: false,
            preConfirm: () => {
              return document.getElementById("swal-input1").value;
            },
          });

          const error_list = [];
          if (formValues === null || formValues.trim() === "") {
            let temp_err = {
              message: "กรุณากรอกสาเหตุที่ไม่อนุมัติ",
            };
            error_list.push(temp_err);
          }

          if (error_list.length > 0) {
            var err_message = "";
            for (var e = 0; e < error_list.length; e++) {
              err_message += "<br/>" + error_list[e].message;
            }

            Swal.fire("Warning", err_message, "warning");
          } else {
            if (formValues) {
              save_temp.remark = formValues;
              axios({
                method: "post",
                url:
                  Configs.API_URL_hrMagenatement +
                  "/api/hrManagement/approvedByHR",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: save_temp,
              })
                .then(async function (response) {
                  console.log(response.data.data);

                  Swal.fire({
                    icon: "success",
                    title: "สำเร็จ",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  getAllLeaveInfomation();
                })
                .catch(function (error) {
                  console.log(error);
                  Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
                });
            }
          }
        }
      });
    }
  }
  async function approve_by_department(approved, emp) {
    console.log(approved, emp);

    if (approved) {
      Swal.fire({
        title: "อนุมัติการลา",
        text: "คุณต้องการอนุมัติการลาใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่",
        cancelButtonText: "ไม่",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const save_temp = {
            id: JSON.parse(emp),
            approved: approved,
            remark: "",
            path_url: Configs.API_URL_IMG,
          };

          console.log(save_temp);
          axios({
            method: "post",
            url:
              Configs.API_URL_hrMagenatement +
              "/api/hrManagement/approvedByDepartment",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: save_temp,
          })
            .then(async function (response) {
              console.log(response.data.data);
              Swal.fire({
                icon: "success",
                title: "สำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              });
              getAllLeaveInfomation();
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            });
        }
      });
    } else {
      Swal.fire({
        title: "อนุมัติการลา",
        text: "คุณไม่อนุมัติการลาใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่",
        cancelButtonText: "ไม่",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const save_temp = {
            id: JSON.parse(emp),
            approved: approved,
            remark: "",
          };

          const { value: formValues } = await Swal.fire({
            title: "สาเหตุที่ไม่อนุมัติ",
            html: '<input id="swal-input1" class="swal2-input">',
            focusConfirm: false,
            preConfirm: () => {
              return document.getElementById("swal-input1").value;
            },
          });

          const error_list = [];
          if (formValues === null || formValues.trim() === "") {
            let temp_err = {
              message: "กรุณากรอกสาเหตุที่ไม่อนุมัติ",
            };
            error_list.push(temp_err);
          }

          if (error_list.length > 0) {
            var err_message = "";
            for (var e = 0; e < error_list.length; e++) {
              err_message += "<br/>" + error_list[e].message;
            }

            Swal.fire("Warning", err_message, "warning");
          } else {
            if (formValues) {
              console.log("formValues", formValues);
              save_temp.remark = formValues;

              axios({
                method: "post",
                url:
                  Configs.API_URL_hrMagenatement +
                  "/api/hrManagement/approvedByDepartment",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: save_temp,
              })
                .then(async function (response) {
                  console.log(response.data.data);
                  Swal.fire({
                    icon: "success",
                    title: "สำเร็จ",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  getAllLeaveInfomation();
                })
                .catch(function (error) {
                  console.log(error);
                  Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
                });
            }
          }
        }
      });
    }
  }
  useEffect(() => {
    getAllLeaveInfomation();
    getLeaveType();
  }, []);

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>อนุมัติการลา</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">ข้อมูลวันลาพนักงาน</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    id="text_empname"
                    value={filterLeave.emp_name_th}
                    onChange={(e) => {
                      setFilterLeave({
                        ...filterLeave,
                        emp_name_th: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">ชื่อ-นามสกุล</label>{" "}
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    id="text_empname"
                    value={filterLeave.emp_no}
                    onChange={(e) => {
                      setFilterLeave({
                        ...filterLeave,
                        emp_no: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">รหัสพนักงาน</label>{" "}
                </div>
              </div>

              <div
                className="col-12 col-md-4 col-xl-2"
                style={{ "z-index": dateZindex.dropdown1 }}
              >
                <div
                  className="form-group"
                  onClick={() => {
                    setdateZindex({
                      ...dateZindex,
                      dropdown1: "99",
                      datehis1: "98",
                      datehis2: "98",
                    });
                    dateZindex.dropdown1 = "99";
                    dateZindex.datehis1 = "98";
                    dateZindex.datehis2 = "98";
                  }}
                >
                  <Select
                    options={LeaveTypeOption}
                    /*    ref = {select_matype} */
                    value={LeaveTypeOption.filter((e) => {
                      return e.value === filterLeave.leave_type;
                    })}
                    onChange={(e) => {
                      //console.log(e);
                      if (e !== null) {
                        setFilterLeave({
                          ...filterLeave,
                          leave_type: e.value,
                        });
                      }
                    }}
                  />

                  <label htmlFor="">ประเภทการลา</label>
                </div>
              </div>

              <div
                className="col-6 col-md-3 col-xl-3"
                style={{ "z-index": "98" }}
              >
                <div className="form-group input-group">
                  <DatePicker
                    id="input-emp-time_start"
                    // customInput={<CustomInput />}
                    selected={filterLeave.start_date}
                    className="form-control"
                    wrapperClassName="form-control"
                    dateFormat="dd-MM-yyyy"
                    placeholderText="DD-MM-YYYY"
                    onChange={async (date) => {
                      setFilterLeave({
                        ...filterLeave,
                        start_date: date,
                      });
                    }}
                    selectsStart
                    startDate={filterLeave.start_date}
                    endDate={filterLeave.end_date}
                    maxDate={filterLeave.end_date}
                    isClearable
                  />
                  <label> วันที่เริ่มต้น </label>
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="far fa-calendar-alt" />
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="col-6 col-md-3 col-xl-3"
                style={{ "z-index": "98" }}
              >
                <div className="form-group input-group">
                  <DatePicker
                    id="input-emp-time_start"
                    // customInput={<CustomInput />}
                    selected={filterLeave.end_date}
                    className="form-control"
                    wrapperClassName="form-control"
                    dateFormat="dd-MM-yyyy"
                    placeholderText="DD-MM-YYYY"
                    onChange={async (date) => {
                      setFilterLeave({
                        ...filterLeave,
                        end_date: date,
                      });
                    }}
                    selectsStart
                    startDate={filterLeave.start_date}
                    endDate={filterLeave.end_date}
                    minDate={filterLeave.start_date}
                    isClearable
                  />
                  <label> วันที่สิ้นสุด </label>
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="far fa-calendar-alt" />
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-info  "
                    onClick={async () => {
                      setTableDRAS(false);

                      await getAllLeaveInfomation();

                      setTableDRAS(true);
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_clear"
                    className="btn btn-block btn-info"
                    onClick={async () => await clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>

              <div
                className="col-12 col-md-12 col-xl-4"
                style={{ marginLeft: "15px", alignSelf: "center" }}
              >
                <div className="form-check" style={{ height: "38px" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="is_not_approve_only"
                    // name="work_choup"
                    // value="work_choup"
                    checked={filterLeave.is_not_approve_only}
                    onChange={async (event) => {
                      console.log(event.currentTarget.checked);

                      setFilterLeave({
                        ...filterLeave,
                        is_not_approve_only: event.currentTarget.checked,
                      });
                      filterLeave.is_not_approve_only =
                        event.currentTarget.checked;

                      await getAllLeaveInfomation();
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="is_not_approve_only"
                  >
                    แสดงเฉพาะที่ยังไม่อนุมัติ
                  </label>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h4>อนุมัติการลา</h4>
            </div>
            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
              <MDBDataTable
                sortable={false}
                id="table-to-xls"
                className="table table-head-fixed"
                striped
                bordered
                disableRetreatAfterSorting={tableDRAS}
                hover
                fixedHeader
                data={data}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>

            <div className="modal fade" id="modal-viewLeaveHistory">
              <div className="modal-dialog modal-md">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">ข้อมูลการลา</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      /* onClick={() => {
                                }} */
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {/* <div className="row">
                      <div
                        className="col-1 col-md-1 col-xl-1"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          type="radio"
                          className="form-check-input"
                          id="pak_ron_rdo"
                          checked={
                            data_leave.leave_status === "ลาพักร้อน"
                              ? true
                              : false
                          }
                          name="type_staus"
                          disabled
                        />
                      </div>
                      <div className="col-5 col-md-1 col-xl-1">
                        <label htmlFor="pak_ron_rdo">ลาพักร้อน</label>
                      </div>

                      <div
                        className="col-1    col-md-0.5 col-xl-0.5"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          type="radio"
                          className="form-check-input"
                          id="la_kit_rdo"
                          name="type_staus"
                          checked={
                            data_leave.leave_status === "ลากิจ" ? true : false
                          }
                          disabled
                        />
                      </div>
                      <div className="col-5 col-md-1 col-xl-1">
                        <label htmlFor="la_kit_rdo">ลากิจ</label>
                      </div>

                      <div
                        className="col-1 col-md-0.5 col-xl-0.5"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          type="radio"
                          className="form-check-input"
                          id="sick_rdo"
                          name="type_staus"
                          checked={
                            data_leave.leave_status === "ลาป่วย" ? true : false
                          }
                          disabled
                        />
                      </div>
                      <div className="col-5  col-md-1 col-xl-1">
                        <label htmlFor="sick_rdo"></label>
                      </div>

                      <div
                        className="col-1 col-md-0.5 col-xl-0.5"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          type="radio"
                          className="form-check-input"
                          id="out_site_rdo"
                          checked={
                            data_leave.leave_status === "ปฏิบัติงานนอกสถานที่"
                              ? true
                              : false
                          }
                          name="type_staus"
                          disabled
                        />
                      </div>
                      <div className="col-1 col-md-1 col-xl-1">
                        <label htmlFor="out_site_rdo">
                          ปฏิบัติงานนอกสถานที่
                        </label>
                      </div>
                    </div> */}

                    <br />
                    <div className="row">
                      <div className="col-1"></div>
                      <div className="col-12 col-md-612 col-xl-12">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            value={data_leave.detail}
                            disabled
                          />
                          <label htmlFor="">รายละเอียด</label>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div
                        className="col-1 col-md-1 col-xl-1"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          type="radio"
                          className="form-check-input"
                          id="full_day_rdo"
                          name="type_staus_day"
                          disabled
                        />
                      </div>
                      <div className="col-5 col-md-1 col-xl-1">
                        <label htmlFor="full_day_rdo">ลาทั้งวัน</label>
                      </div>

                      <div
                        className="col-1 col-md-1 col-xl-1"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          type="radio"
                          className="form-check-input"
                          id="half_day_rdo"
                          name="type_staus_day"
                          disabled
                        />
                      </div>
                      <div className="col-5 col-md-1 col-xl-1">
                        <label htmlFor="half_day_rdo">ลาช่วงเวลา</label>
                      </div>
                    </div> */}

                    {/* <div className="row">
                      <div className="col-1"></div>

                      <div
                        className="col-11 col-md-3 col-xl-2"
                        style={{ "z-index": dateZindex.date1 }}
                      >
                        <div className="form-group ">
                          <DatePicker
                            selected={data_leave.start_date}
                            className="form-control"
                            dateFormat={"dd-MM-yyyy"}
                            customInput={<CustomInput />}
                            disabled
                            maxDate={data_leave.finish_date}
                          />
                          <label>
                            วันที่เริ่มลา
                            {}
                          </label>{" "}
                        </div>
                      </div>

                      <div className="col-1 col-md-0.5 col-xl-0.5"></div>

                      <div
                        className="col-11 col-md-3 col-xl-2"
                        style={{ "z-index": dateZindex.date2 }}
                      >
                        <div className="form-group ">
                          <DatePicker
                            selected={data_leave.finish_date}
                            className="form-control"
                            dateFormat={"dd-MM-yyyy"}
                            customInput={<CustomInput />}
                            //onChange={async (date) => SetDate_end_fg(date)}
                            disabled
                            minDate={data_leave.start_date}
                          />
                          <label>
                            วันที่สิ้นสุดการลา
                            {}
                          </label>{" "}
                        </div>
                      </div>
                    </div> */}

                    {/* {data_leave.day_status == "ครึ่งวัน" ? (
                      <>
                        <div className="row">
                          <div className="col-1"></div>

                          <div
                            className="col-11 col-md-3 col-xl-2"
                            style={{ "z-index": dateZindex.time1 }}
                          >
                            <div className="form-group ">
                              <DatePicker
                                disabled
                                selected={data_leave.start_time}
                                className="form-control"
                                
                                showTimeSelect
                                showTimeSelectOnly
                                customInput={<CustomInput />}
                                timeCaption="เวลา"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                              
                              />
                              <label>เวลาเริ่มต้น</label>{" "}
                            </div>
                          </div>
                          <div className="col-1 col-md-0.5 col-xl-0.5"></div>
                          <div
                            className="col-11 col-md-3 col-xl-2"
                            style={{ "z-index": dateZindex.time2 }}
                          >
                            <div className="form-group ">
                              <DatePicker
                                selected={data_leave.finish_time}
                                className="form-control"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                showTimeSelect
                                showTimeSelectOnly
                                customInput={<CustomInput />}
                                timeCaption="เวลา"
                                disabled
                              />
                              <label>เวลาสิ้นสุด</label>{" "}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )} */}

                    {/* <div className="row">
                      <div className="col-1"></div>
                      <div className="col-10 col-md-6 col-xl-6">
                        <a
                          href={
                            Configs.API_URL_IMG 
                          }
                          download
                        >
                          <label htmlFor="">ไฟล์ที่เกี่ยวข้อง</label>
                        </a>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Leave_information_approve;
