import React, { Component, useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import Configs from "../../../../config";
import { useParams, browserHistory } from "react-router";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import NumberFormat from "react-number-format";
import success_img from "../Incentive_User_icon_img/success_1.png";
import { Tooltip } from "reactstrap";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
} from "../../../../Utils/Common";

import "../../../../assets/css/ProductService.css";

import {
  LightningBolt,
  Cart,
  CartSuccess,
} from "../Incentive_User_icon_img/All_Icon";

import PointCartLine from "../Incentive_User_component/Point_Cart_Line";
import Product_Display_Card from "../Incentive_User_component/Product_Display_Card";
import ProductServiceCard from "../Incentive_User_component/Product_Service_Card";
import ProductServiceCardImageSlider from "../Incentive_User_component/Product_Service_Card_Image_Slider";
import ProductServiceCardMultiSliderProfile from "../Incentive_User_component/Product_Service_Card_Multi_Slider_Pofile";
import ProductServiceCardMultiSlider from "../Incentive_User_component/Product_Service_Card_Multi_Slider";
import { useHistory } from "react-router-dom";
import CustomerService from "../Incentive_User_component/CustomerService";
import moment from "moment";

function Product_View_Details() {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  let [cartFetch, setCartFetch] = useState(false);
  const history = useHistory();
  const toggle = () => setCartFetch(!cartFetch);
  


  const { id } = useParams();
  function testdata(params) {
    console.log("testdata");
  }
  let [product_data, setproduct_data] = useState({
    product_no: "",
    product_name: "",
    product_detail: "",
    product_deaciplies: "",
    product_category_id: "",
    product_brand: "",
    product_image: "",
    product_image_name: "",
    product_image_path: "",
    product_point: "",
  });
  const [productNewData, setProductNewData] = useState([]);
  const [product_image_list, setproduct_image_list] = useState([]);
  const [test, settest] = useState({
    test: "",
  });
  let [product_model_list, setproduct_model_list] = useState([]);
  const [product_images_profiles, setproduct_images_profiles] = useState({
    product_image: "",
    product_image_path: "",
    product_name: "",
  });
  let [cart, setCart] = useState({
    product_id: id,
    model: "",
    amount: 1,
  });

  async function GetProductNew() {
    let tempInput = {
      oem_id: getOem(),
      company_id: getUser().com,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/productService/GetRecommend",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempInput,
    })
      .then(async (response) => {
        let allData = [];

        for (let item of response.data) {
          let data = {
            productId: item.product_id,
            productName: item.product_name,
            productDetail: item.product_detail,
            productValue: item.product_point,
            productSold: item.product_count_sales,
            productImageName: item.product_image,
            productImagePath:
              item.product_image_path !== "" &&
              item.product_image_path !== null &&
              item.product_image_path !== undefined
                ? Configs.API_URL_IMG_incentive + item.product_image_path
                : userdefault_img.imgs,
          };

          if (item.fs_id) {
            data["productValue"] = item.fs_product_point;
            data["discount"] = item.fs_discount;
            data["discountValue"] = item.fs_point;
          }

          allData.push(data);
        }

        setProductNewData(allData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const GetProduct = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/manageProduct/getproduct/" + id,
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        // console.log(response.data.image_list);
        function IsSameDate(date_val) {
          return moment(date_val).startOf('d').isSame(moment().startOf('d'));
        }

        function CheckDateBetween(el) {
          return ((moment(el.fs_end_date).isAfter(moment()) || IsSameDate(el.fs_end_date))
            && (moment(el.fs_start_date).isBefore(moment()) || IsSameDate(el.fs_start_date)));
        }
        setproduct_data({
          product_id: response.data.product_list[0].product_id,
          product_no: response.data.product_list[0].product_no,
          product_brand: response.data.product_list[0].product_brand,
          product_deaciplies: response.data.product_list[0].product_description,
          product_name: response.data.product_list[0].product_name,
          product_detail: response.data.product_list[0].product_detail,
          product_image_name: response.data.product_list[0].product_image,
          product_image_path: response.data.product_list[0].product_image_path,
          product_point: response.data.product_flashsale_list.length > 0
            ? response.data.product_flashsale_list.find(
                el => CheckDateBetween(el)
              )?.fs_point || response.data.product_list[0].product_point/* response.data.product_flashsale_list[0].fs_point */
            : response.data.product_list[0].product_point,
          product_category_id:
            response.data.product_list[0].product_category_id,
          product_count_sales:
            response.data.product_list[0].product_count_sales,
          category_name: response.data.product_list[0].category_name,
        });
        setproduct_images_profiles({
          product_image: response.data.product_list[0].product_image,
          product_image_path: response.data.product_list[0].product_image_path,
          product_name: response.data.product_list[0].product_name,
        });
        setproduct_image_list(response.data.image_list);

        // const arrImage = [];
        // for (let item of response.data.image_list) {
        //     // console.log('item', item);
        //     const tempDataImg = {
        //         pi_id: item.pi_id,
        //         sbd_document_name: item.pi_img_name,
        //         sbd_path_document: item.pi_img_path,
        //         file_isOld: false,
        //         is_checked: item.pi_is_profile
        //     };
        //     arrImage.push(tempDataImg);
        // }
        // setdocumentFile(arrImage);

        const arrModel = [];
        for (let item of response.data.product_model_list) {
          // console.log('item', item);
          const tempDataImg = {
            pm_id: item.pm_id,
            pm_model_name: item.pm_model_name,
            pm_is_active: false,
          };
          arrModel.push(tempDataImg);
        }
        // setInputFields(arrModel);
        setproduct_model_list(arrModel);
        // const arrFlashSale = [];
        // for (let item of response.data.product_flashsale_list) {
        //     // console.log('item', item);
        //     const tempDataImg = {
        //         fs_id: item.fs_id,
        //         product_start_date: item.fs_start_date,
        //         product_end_date: item.fs_end_date,
        //         product_point: item.fs_product_point || null,
        //         flash_sale: item.fs_point,//item.flash_sale,
        //         product_discount: item.fs_discount,
        //     };
        //     arrFlashSale.push(tempDataImg);
        // }
        // setFlashSaleTable({ data: arrFlashSale });

        // const arrSupplier = [];
        // for (let item of response.data.product_supplier_list) {
        //     // console.log('item', item);
        //     const tempDataImg = {
        //         srp_id: item.srp_id,
        //         supplier_id: item.srp_supplier,
        //         buy_price: item.srp_buy_price || 0,
        //         minimum_order: item.srp_min_order || 0,
        //         lead_time: item.srp_lead_time || 0,
        //         mat_cycle_time: null,
        //         remark: item.srp_remark || null,
        //         sort: arrSupplier.length + 1,
        //         is_active: item.srp_is_active,
        //         status: 'edit',
        //         create_date: moment(item.srp_created_by).format() || null
        //     };
        //     arrSupplier.push(tempDataImg);
        // }
        // arrSupplier.sort((a, b) => b.sort - a.sort);
        // arrSupplier.sort((a, b) => b.is_active - a.is_active);
        // setSupplier_select_data(arrSupplier);

        // setTest({ ...test, test: '' });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(async () => {
    await GetProduct();
    await GetProductNew();
  }, []);

  const [tooltipOpen, setTooltipOpen] = useState({});
  const [hoveredText, setHoveredText] = useState('');

  const toggleTooltip = (index) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  function setColorActive(index) {
    let tempDatalist = [];
    product_model_list.forEach((element) => {
      const tempDataImg = {
        pm_id: element.pm_id,
        pm_model_name: element.pm_model_name,
        pm_is_active:
          product_model_list[index].pm_id === element.pm_id ? true : false,
      };
      tempDatalist.push(tempDataImg);
    });
    setproduct_model_list(tempDatalist);
    settest({ ...test, test: "" });
  }

  async function addCart(params) {
    console.log(product_model_list, cart.model);
    if (cart.model === "") {
      Swal.fire({
        icon: "warning",
        title: "กรุณาเลือก คุณลักษณะ",
        text: "",
      });
    } else {
      let tempData = {
        sc_product_id: cart.product_id,
        sc_emp_id: getUser().emp_id,
        sc_company_id: getUser().com,
        sc_oem_id: getOem(),
        sc_amount: cart.amount,
        sc_pm_id: cart.model,
        sc_buy_certainly: null,
      };

      await axios({
        method: "post",
        url: Configs.API_URL_incentive + "/api/cartManagement/AddItemToCart",
        headers: {
          Authorization: "Bearer " + getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempData,
      })
        .then(async (response) => {
          console.log("addcart", response.data);
          if (response.data != null) {
            Swal.fire({
              title:
                '<div style=" display: grid; grid-template-columns: 65px 1fr; grid-template-rows: 45px 1fr;">' +
                '<svg width="100px" height="100px" viewBox="0 0 512 458" fill="none"  style="grid-area: 1 / 1 / 3 / 2;" >' +
                '<path d="M6.95 3.23502C-2.234 9.37102 -2.027 21.408 7.383 28.438C10.016 30.405 11.629 30.514 42.462 30.801C71.377 31.07 74.942 31.281 76.301 32.801C77.39 34.02 139.229 260.36  ' +
                " 145.546 286.25C146.166 288.791 145.987 289 143.198 289C138.532 289 128.529 292.452 123.1 295.936C92.124 315.813 94.712 360.861 127.772 377.252L136.339 381.5L152.769 381.838C171.473 " +
                " 382.222 169.749 380.955 166.594 392C163.438 403.052 165.008 416.942 170.758 428.826C174.402 436.36 185.786 447.843 193.131 451.395C210.106 459.604 228.783 458.905 244.5 449.472C255.383 " +
                "  442.94 262.954 433.508 267.14 421.266C270.923 410.202 270.201 394.814 265.357 383.25C264.942 382.26 274.661 382 312.033 382H359.233L358.525 384.25C355.663 393.344 354.936 397.546 354.937 " +
                "  405C354.939 419.536 359.927 431.607 370.16 441.84C380.3 451.98 392.499 457 407 457C430.735 457 450.841 441.757 457.49 418.723C460.304 408.973 459.493 394.159 455.644 385.004C454.225 381.629 " +
                "  454.254 381.524 457.107 379.789C466.728 373.938 466.1 359.623 455.987 354.275L451.685 352H297.803C146.704 352 143.851 351.964 140.061 350.031C128.745 344.258 128.9 326.072 140.312 320.656C143.512  " +
                " 319.138 156.109 319 291.765 319C387.667 319 441.622 318.643 445.114 317.985C456.646 315.812 467.024 307.863 471.916 297.456C473.106 294.924 482.662 255.648 493.151 210.176C514.147 119.152 513.698 122.035 " +
                "  508.52 111.452C505.676 105.638 501.248 101.243 494.722 97.756C490.532 95.517 490.082 95.495 435.5 94.873C405.25 94.528 329.425 94.134 267 93.997C204.575 93.861 146.979 93.468 139.009 93.124L124.518 92.5L115.448 " +
                " 58.832C110.459 40.314 105.515 23.252 104.462 20.915C101.738 14.875 94.009 7.36202 87.27 4.20402L81.5 1.50002L45.95 1.21502L10.401 0.929016L6.95 3.23502ZM177.447 124H200.584L204.818 156.25C207.147 173.988 209.041 " +
                " 189.287 209.026 190.25C209.002 191.876 206.951 192 180.179 192H151.359L142.633 159.683C137.835 141.908 133.632 126.31 133.295 125.021L132.682 122.677L143.497 123.338C149.445 123.702 164.722 124 177.447 124ZM297 " +
                "  158V192H268.545H240.091L239.525 188.75C238.956 185.484 231 125.56 231 124.543C231 124.244 245.85 124 264 124H297V158ZM392.96 125.75C392.938 126.713 391.009 141.9 388.673 159.5L384.426 191.5L355.713 191.765L327  " +
                " 192.031V158.015V124H360C390.777 124 392.997 124.118 392.96 125.75ZM481.503 127.25C481.209 128.488 477.818 143.167 473.967 159.872C470.117 176.577 466.723 190.639 466.425 191.122C466.126 191.605 454.434 192 440.441 " +
                "  192C420.763 192 414.996 191.717 414.984 190.75C414.976 190.063 416.867 174.988 419.186 157.25L423.404 125H452.721H482.038L481.503 127.25ZM217.824 253.75C220.141 271.212 222.028 286.288 222.018 287.25C222.001 288.856  " +
                "  220.401 289 202.565 289C191.876 289 181.973 288.56 180.56 288.023C179.146 287.485 177.325 285.573 176.513 283.773C175.059 280.551 160 225.209 160 223.089C160 222.331 168.149 222 186.806 222H213.613L217.824 253.75ZM297  " +
                " 255.5V289H275.019H253.038L252.555 286.75C252.096 284.609 244 223.924 244 222.622C244 222.28 255.925 222 270.5 222H297V255.5ZM380 223.532C380 225.826 371.987 286.303 371.491 287.75C371.166 288.699 365.761 289 349.031  " +
                " 289H327V255.5V222H353.5C376.633 222 380 222.195 380 223.532ZM459 223.307C459 225.354 446.052 281.188 444.897 284.12C444.329 285.561 442.749 287.249 441.385 287.87C438.625 289.128 402 289.498 402 288.268C402 286.665 410.183  " +
                "  224.417 410.547 223.25C411.13 221.384 459 221.44 459 223.307ZM228 385.318C241.249 391.99 243.689 410.58 232.65 420.755C227.27 425.714 222.472 427.347 214.889 426.798C207.006 426.228 201.499 422.458 197.75 415.064C187.602 395.052  " +
                " 207.83 375.16 228 385.318ZM412.499 383.104C421.748 385.433 429.143 394.922 429.143 404.461C429.143 417.462 419.928 426.925 407.218 426.978C394.145 427.032 385.072 417.931 385.022 404.71C384.995 397.586 387.37 392.551 393.054  " +
                ' 387.686C399.021 382.578 404.905 381.192 412.499 383.104Z" fill="#003583" />' +
                "</svg>" +
                `<img  style="width:50px; height:50px; position: relative; top:-4px; left:7px; "  src=${success_img} /> </div>`,
              html: "<h2>คุณได้ทำการเพิ่มสินค้าลงรถเข็นแล้ว</h2>",
              showConfirmButton: false,
              showCloseButton: true,
              timer: 1500,
            }).then((result) => {
              toggle();
              settest({ ...test, test: "" });
              test.test = "";
              console.log("toggle", cartFetch);
              //  window.location.href = "/Human_Resource/intensive_TTT/manage_product_system";
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  

  async function goToBuy(params) {
    if (cart.model === "") {
      Swal.fire({
        icon: "warning",
        title: "กรุณาเลือก คุณลักษณะ",
        text: "",
      });
    } else {
      let tempData = {
        sc_product_id: cart.product_id,
        sc_emp_id: getUser().emp_id,
        sc_company_id: getUser().com,
        sc_oem_id: getOem(),
        sc_amount: cart.amount,
        sc_pm_id: cart.model,
        sc_buy_certainly: true,
      };
      await axios({
        method: "post",
        url: Configs.API_URL_incentive + "/api/cartManagement/AddItemToCart",
        headers: {
          Authorization: "Bearer " + getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempData,
      })
        .then(async (response) => {
          console.log("addcart", response.data);
          const dataToSend = {
            product: [response.data],
          };

          history.push({
            pathname:
              "/Human_Resource/incentive_user/product_service/pay_details",
            state: dataToSend,
          });
          /* 
          if (response.data != null) {
            window.location.href = "/Human_Resource/incentive_user/product_service/cart_product/"+response.data;
          } */
        })
        .catch(function (error) {
          console.log(error);
        });
        
    }
    
  }

  
  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h1>รายละเอียดสินค้า</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">สินค้าและบริการ</li>
                  <li className="breadcrumb-item active">รายละเอียดสินค้า</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="row">
            <PointCartLine fetch={cartFetch} />
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-12 col-sm-6">
                <h3 className="d-inline-block d-sm-none">
                  {product_data.product_name}
                </h3>
                <div
                  className="col-12"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <img
                    style={{
                      boxSizing: "border-box",
                      borderBottom: "10px 10px 10px 10px",
                      borderRadius: "10px 10px 10px 10px",
                      maxWidth: "500px",
                      width: "100%",
                      height: "500px",
                      border: "0.2px solid #00358354",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                      borderColor: "#00358354",
                    }}
                    src={
                      product_images_profiles.product_image_path !== "" &&
                      product_images_profiles.product_image_path !== null &&
                      product_images_profiles.product_image_path !== undefined
                        ? Configs.API_URL_IMG_incentive +
                          product_images_profiles.product_image_path
                        : userdefault_img.imgs
                    }
                    className="product-image image-res"
                    alt="Product Image"
                  />
                </div>
                <div className="col-12 product-image-thumbs">
                  <ProductServiceCardMultiSlider
                    id="CategoryCard"
                    data={product_image_list.map((item) => {
                      return (
                        <>
                          <div
                            style={{
                              margin: "0px 6px",
                              border: item.pi_img_path === product_images_profiles.product_image_path ? '2px solid #007bff' : 'none',
                              padding: '4px',
                              borderRadius: '8px',
                            }}
                            onClick={() => {
                              setproduct_images_profiles({
                                ...product_images_profiles,
                                product_image_path: item.pi_img_path,
                                product_image_name: item.pi_img_name,
                              });
                              product_data.product_image_path = item.pi_img_path;
                              product_data.product_image_name = item.pi_img_name;
                            }}
                          >
                            <Product_Display_Card data={item} />
                          </div>
                        </>
                      );
                    })}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <p
                  className="my-3"
                  style={{
                    fontSize: "48px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: " 54px",
                  }}
                >
                  {product_data.product_name}
                </p>
                <p
                  style={{
                    fontSize: "32px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: " 36px",
                    marginRight: "1rem",
                  }}
                >
                  {product_data.product_detail}
                </p>
                <p
                  style={{
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: " 23px",
                    marginRight: "1rem",
                    color: "#8A8A8A",
                  }}
                >
                  รหัสสินค้า : {product_data.product_no}
                </p>
                <p
                  style={{
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: " 15px",
                    marginRight: "1rem",
                    color: "#B4B1B1",
                  }}
                >
                  {product_data.product_count_sales || 0} ขายเเล้ว
                </p>
                {/* 
                <hr /> */}
                <div
                  style={{
                    backgroundColor: "#E4F4FF",
                    borderRadius: "15px",
                    height: "130px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="py-2 px-3 mt-4"
                >
                  <p
                    className="mb-0 mt-0"
                    style={{
                      fontSize: "64px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: " 72px",

                      color: "#0395FF",
                    }}
                  >
                    {product_data.product_point} คะแนน{" "}
                    <small
                      style={{
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: " 23px",
                        color: "#003583",
                      }}
                    >
                      คะแนนรวมบริการจัดส่ง
                    </small>
                  </p>
                  {/*  <h4 className="mt-0">
                    <small>Ex Tax: $80.00 </small>
                  </h4> */}
                </div>
                <br />
                {/* <div
                  className="btn-group btn-group-toggle"
                  data-toggle="buttons"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div style={{ fontWeight: "700", marginRight: "1rem" }}>
                    คุณลักษณะ{" "}
                  </div>
                  {product_model_list.map((el, index) => {
                    return (
                      <div
                        key={index}
                        id={`tooltip-${index}`}
                        className="btn"
                        style={{
                          backgroundColor: el.pm_is_active ? "#003583" : "#E4F4FF",
                          border: "0.2px solid #003583",
                          borderRadius: "50px",
                          marginRight: "1rem",
                          color: el.pm_is_active ? "#FFFFFF" : "#000000",
                          whiteSpace: "nowrap", 
                          overflow: "hidden", 
                          textOverflow: "ellipsis", 
                          minWidth: "108px", 
                          maxWidth: "108px", 
                          display: "inline-block", 
                          padding: "5px 10px", 
                          cursor: "pointer",
                          textAlign: "center", 
                        }}
                      
                        onClick={() => {
                          setColorActive(index);
                          setCart({ ...cart, model: el.pm_id });
                          cart.model = el.pm_id;
                        }}
                        onMouseEnter={() => setHoveredText(el.pm_model_name)}
                      >
                        {el.pm_model_name}
                        <Tooltip
                        placement="top"
                        isOpen={tooltipOpen[index]}
                        target={`tooltip-${index}`} 
                        toggle={() => toggleTooltip(index)}
                      >
                        {el.pm_model_name}
                      </Tooltip>
                      </div>
                    );
                  })}
                </div> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "20px",
                    flexWrap: "wrap",
                    width: "100%",
                    marginBottom: "20px", 
                  }}
                >
                  <div
                    style={{
                      fontWeight: "700",
                      minWidth: "100px", 
                      textAlign: "left",
                      alignSelf: "flex-start", 
                      marginTop: "5px", 
                    }}
                  >
                    คุณลักษณะ
                  </div>

                  
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px", 
                      width: "calc(100% - 150px)", 
                    }}
                  >
                    {product_model_list.map((el, index) => (
                      <div
                        key={index}
                        id={`tooltip-${index}`}
                        className="btn"
                        style={{
                          backgroundColor: el.pm_is_active ? "#003583" : "#E4F4FF",
                          border: "0.2px solid #003583",
                          borderRadius: "50px",
                          color: el.pm_is_active ? "#FFFFFF" : "#000000",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "108px", 
                          minWidth: "108px",
                          display: "inline-block", 
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "5px 10px", 
                          cursor: "pointer",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          setColorActive(index);
                          setCart({ ...cart, model: el.pm_id });
                          cart.model = el.pm_id;
                        }}
                        onMouseEnter={() => setHoveredText(el.pm_model_name)}
                      >
                        {el.pm_model_name}
                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[index]}
                          target={`tooltip-${index}`}
                          toggle={() => toggleTooltip(index)}
                        >
                          {el.pm_model_name}
                        </Tooltip>
                      </div>
                    ))}
                  </div>
                </div>
                <br />
                <div
                  className="btn-group btn-group-toggle"
                  data-toggle="buttons"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div style={{ fontWeight: "700", marginRight: "1rem" }}>
                    จำนวน{" "}
                  </div>
                  <div
                    className="btn"
                    style={{
                      backgroundColor: "#E4F4FF",
                      border: "0.2px solid #003583",
                      borderRadius: " 50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "1rem",
                      // color: el.pm_is_active ? "#FFFFFF":"#000000",
                      whiteSpace: "nowrap",
                      maxWidth: "25px",
                      maxHeight: "25px",
                    }}
                    onClick={() => {
                      if (cart.amount > 1) {
                        setCart({ ...cart, amount: cart.amount - 1 });
                        cart.amount = cart.amount - 1;
                      }
                    }}
                  >
                    -
                  </div>
                  <div
                    className="btn"
                    style={{
                      backgroundColor: "#E4F4FF",
                      border: "0.2px solid #003583",
                      borderRadius: " 50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      marginRight: "1rem",
                      // color: el.pm_is_active ? "#FFFFFF":"#000000",
                      whiteSpace: "nowrap",
                      maxWidth: "63px",
                      maxHeight: "35px",
                    }}
                  >
                    {cart.amount}
                  </div>

                  <div
                    className="btn"
                    style={{
                      backgroundColor: "#E4F4FF",
                      border: "0.2px solid #003583",
                      borderRadius: " 50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "1rem",
                      //color: el.pm_is_active ? "#FFFFFF":"#000000",
                      whiteSpace: "nowrap",
                      maxWidth: "25px",
                      maxHeight: "25px",
                    }}
                    onClick={() => {
                      setCart({ ...cart, amount: cart.amount + 1 });
                      cart.amount = cart.amount + 1;
                    }}
                  >
                    +
                  </div>
                  {/* {product_model_list.map((el,index) => {
                    return (
                      <div
                        className="btn"
                        style={{
                          backgroundColor:  el.pm_is_active ? "#003583":"#E4F4FF",
                          border: "0.2px solid #003583",
                          borderRadius: " 50px",
                          marginRight:"1rem",
                          color: el.pm_is_active ? "#FFFFFF":"#000000",
                          whiteSpace:"nowrap"
                        }}
                        onClick={()=>{ setColorActive(index);}}
                      >
                        {el.pm_model_name}
                      </div>
                    );
                  })} */}
                </div>

                <div
                  className="mt-4"
                  style={{ display: "flex", paddingLeft: "120px" }}
                >
                  <div
                    className="btn "
                    style={{
                      backgroundColor: "#E4F4FF",
                      border: "0.2px solid #003583",
                      borderRadius: "10px",
                      marginRight: "1rem",
                      color: "#003583",
                      whiteSpace: "nowrap",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "160px",
                      height: "50px",
                    }}
                    onClick={() => {
                      addCart();
                    }}
                  >
                    <Cart width="25px" height="25px" fill="#003583" /> &nbsp;
                    เพิ่มไปยังรถเข็น
                  </div>
                  <div
                    className="btn "
                    style={{
                      backgroundColor: "#003583",
                      border: "0.2px solid #003583",
                      borderRadius: "10px",
                      marginRight: "1rem",
                      color: "#FFFFFF",
                      whiteSpace: "nowrap",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "160px",
                      height: "50px",
                    }}
                    onClick={() => {
                      goToBuy();
                    }}
                  >
                    ซื้อสินค้า
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row mt-4">
              <div className="col-md-12">
                <div
                  className="card-header"
                  style={{ backgroundColor: "#E4F4FF", borderRadius: "15px" }}
                >
                  <h3 className="card-title">ข้อมูลจำเพาะสินค้า</h3>
                  <div className="card-tools"></div>
                  {/* /.card-tools */}
                </div>
                <br />
                <div style={{ borderRadius: "15px" }}>
                  {/* /.card-header */}
                  <div className="card-body">
                    <div className="row">
                      <div
                        className="col-md-1"
                        style={{
                          fontWeight: "400",
                          marginRight: "1rem",
                          color: "#8A8A8A",
                        }}
                      >
                        หมวดหมู่
                      </div>
                      <div className="col-md-6 mr-1">
                        {product_data.category_name}
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-md-1"
                        style={{
                          fontWeight: "400",
                          marginRight: "1rem",
                          color: "#8A8A8A",
                        }}
                      >
                        ยี่ห้อสินค้า
                      </div>
                      <div className="col-md-6 mr-1">
                        {product_data.product_brand}
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-md-1"
                        style={{
                          fontWeight: "400",
                          marginRight: "1rem",
                          color: "#8A8A8A",
                        }}
                      >
                        รุ่นสินค้า
                      </div>
                      <div className="col-md-6 mr-1">
                        {product_data.product_detail}
                      </div>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12">
                <div
                  className="card-header"
                  style={{ backgroundColor: "#E4F4FF", borderRadius: "15px" }}
                >
                  <h3 className="card-title">รายละเอียดสินค้า</h3>
                  <div className="card-tools"></div>
                  {/* /.card-tools */}
                </div>{" "}
                <br />
                <div style={{ borderRadius: "15px" }}>
                  {/* /.card-header */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: product_data.product_deaciplies,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
            </div>
            <br />
            <div
              className="card-header"
              style={{ backgroundColor: "#E4F4FF", borderRadius: "15px" }}
            >
              <h3 className="card-title">สินค้าแนะนำ</h3>
              <div className="card-tools"></div>
              {/* /.card-tools */}
            </div>
            <br />
            <div
              style={{
                /*    marginTop:"25px", */
                padding: "10px 0px",
                backgroundColor: "#E4F4FF",
                borderRadius: "10px",
              }}
            >
              <div className="row" style={{ margin: "10px" }}>
                <ProductServiceCardMultiSlider
                  id="ProductServiceCard_New"
                  data={productNewData.map((item) => {
                    return (
                      <>
                        <div style={{ margin: "0px 10px" }}>
                          <a
                            href={`/Human_Resource/incentive_user/product_service/product_view_details/${item.productId}`}
                          >
                            <ProductServiceCard data={item} />
                          </a>
                        </div>
                      </>
                    );
                  })}
                />
              </div>

              {/*  <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "20px 30px 20px 0px",
                }}
              >
                <label
                  style={{
                    fontSize: "17px",
                    fontWeight: "400",
                    color: "#003583",
                    lineHeight: "normal",
                    margin: "0px",
                    textDecoration: "underline",
                  }}
                >
                  ดูเพิ่มเติม
                </label>
              </div> */}
            </div>
          </div>

          <CustomerService />
        </section>
      </div>
    </div>
  );
}

export default Product_View_Details;
