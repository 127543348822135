import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
  getFeature,
} from "../../Utils/Common";
import NumberFormat from "react-number-format";
import { useParams } from "react-router";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import th from "date-fns/locale/th";

function NewEmployee() {
  useEffect(() => {
    getfilterNewEmployee();
    getEmpWorkTypefilter();
  }, []);
  const [test, settest] = useState({ test: "" });
  const [ListNewEmployee, setListNewEmployee] = useState([]);
  const [filterNewEmployee, setfilterNewEmployee] = useState({
    name_th: "",
    surname_th: "",
    position_want: "",
  });
  const [newEmployee, setnewEmployee] = useState([]);
  const [Contract, setContract] = useState([]);

  function getEmpWorkTypefilter() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmpWorkType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        // console.log(response.data.data);
        setContract(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function addNewEmp() {
    const arrList = newEmployee;
    setnewEmployee([]);
    const temp = {
      emp_contract: "",
      emp_national_id: "",
      link: "",
    };
    arrList.push(temp);
    setnewEmployee(arrList);
    settest({ ...test, test: "" });
  }
  function deleteNewEmp(index) {
    const arrList = newEmployee;
    arrList.splice(index, 1);
    setnewEmployee(arrList);
    settest({ ...test, test: "" });
  }
  function getfilterNewEmployee() {
    let splite = filterNewEmployee.name_th?.trim().split(/(?<=^\S+)\s/);
    // console.log("splite", splite);
    let data = {
      name_th: splite[0] || null,
      surname_th: splite[1] || null,
      position: filterNewEmployee.position_want?.trim() || null,
      oem_id: getOem(),
      company_id: getUser().com
    };
    console.log("data", data);
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement_public +
        "/api/hrManagement/getfilterNewEmployee",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((res) => {
        console.log("res", res.data.data);
        setListNewEmployee(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.error.message,
        });
      });
  }

  function deldata(id) {
    console.log("id", id);
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url:
            Configs.API_URL_hrMagenatement_public +
            "/api/hrManagement/delNewEmployeeById?id=" +
            id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            Swal.fire({
              icon: "success",
              text: "เสร็จสิ้น",
            }).then(() => {
              getfilterNewEmployee();
              //   window.location.href = "/Human_Resource/ข้อมูลพนักงาน/Employee";
            });
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
          });
      }
    });
  }

  const [btnSyncEmp, setBtnSyncEmp] = useState({
    data: false,
  });

  useEffect(() => {
    const check = ListNewEmployee.find(
      (el) => el.is_draft === false && el.is_sync === false
    );
    if (check) {
      console.log("false");

      setBtnSyncEmp({ data: false });
      btnSyncEmp.data = false;
    } else {
      console.log("true");
      setBtnSyncEmp({ data: true });
      btnSyncEmp.data = true;
    }
  }, [ListNewEmployee]);

  function genLink(params) {
    // console.log('ListNewEmployee', ListNewEmployee);
    // console.log('newEmployee', newEmployee);
    Swal.fire({
      title: "WARNING",
      text: "ต้องการสร้าง Link ใหม่ใช่หรือไม่?",
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = newEmployee;
        let listTableEmps = ListNewEmployee;
        let arr = [];
        let error_list = [];
        // for (let item of data) {
        for (let item = 0; item < data.length; item++) {
          let inx = 1;
          let error_meg = `ข้อมูลแถวที่ ${item + 1} : กรุณา`;
          let citizenIdLength = data[item].emp_national_id
            .replace(/-/g, "")
            .trim().length;
          if (data[item].emp_contract === "" || data[item].emp_contract === null) {
            error_meg = error_meg + "เลือกประเภทพนักงาน, ";
          }

          if (data[item].emp_national_id === "" || data[item].emp_national_id === null) {
            error_meg = error_meg + "กรอกรหัสบัตรประชาชน, ";
          } else if (citizenIdLength < 13) {
            error_meg = error_meg + "กรอกรหัสบัตรประชาชนให้ครบ 13 หลัก, ";
          } else {
            let check = arr.filter((item2) => {
              return item2.emp_national_id == data[item].emp_national_id;
            });
            if (check.length === 0) {
              arr.push(data[item]);
            } else {
              error_meg = error_meg + "รหัสบัตรประชาชนซ้ำกันในรายการ, ";
            }
          }
          if (`ข้อมูลแถวที่ ${item + 1} : กรุณา` !== error_meg) {
            let temp_err = {
              message: error_meg.slice(0, -2),
            };
            error_list.push(temp_err);
          }
          inx += 1;
        }
        console.log('listTableEmps', listTableEmps)
        console.log('data', data);
        let tempLis = [];
        listTableEmps.map((el, index) => {
          const empDuplicate = data.find((item, idx) => 
            el.citizen_id === item.emp_national_id && (el.employee_contract_type_id !== (item.emp_contract ? item.emp_contract : item.contract_type_name))
          )
          if (empDuplicate) {
            tempLis.push(el);
          }
        });
        if (tempLis.length > 0) {
          let temp_err = {
            message: "อัปเดตข้อมูลไม่สำเร็จ"
          }
          error_list.push(temp_err);
          for (let item of tempLis) {
            let temp_err1 = {
              message: `หมายเลขบัตรประชาชน ${item.citizen_id} ( ${ Contract.find(el => el.id ===  item.employee_contract_type_id).contract_type_name || ""} )`
            };
            error_list.push(temp_err1);
          }
          let temp_err2 = {
            message: "มีประเภทพนักงานไม่เหมือนเดิม กรุณาแก้ไข"
          }
          error_list.push(temp_err2);
          
        }
        if (error_list.length > 0) {
          var err_message = "";
          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }
          Swal.fire("Error", err_message, "error",);
        } else {
          // console.log(data);
          axios({
            method: "post",
            url:
              Configs.API_URL_hrMagenatement +
              "/api/hrManagement/checkNewEmployeeSameCitizen",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: { data: data, oem_id: getOem(), company_id: getUser().com },
          })
            .then((res) => {
              // console.log("res", res.data.data);
              if (res.data.data.length > 0) {
                let text2 = "";
                for (let item of res.data.data) {
                  text2 += item.emp_national_id + ",<br/>";
                }
                Swal.fire({
                  icon: "error",
                  title: "รหัสบัตรประชาชนซ้ำกันในระบบ",
                  html: text2
                });
              } else {
                axios({
                  method: "post",
                  url:
                    Configs.API_URL_hrMagenatement_public +
                    "/api/hrManagement/genLink",
                  headers: {
                    Authorization: getToken(),
                    "X-TTT": Configs.API_TTT,
                    "Content-Type": "application/json",
                  },
                  data: { data, oem_id: getOem(), company_id: getUser().com },
                })
                  .then((res) => {
                    // console.log("res", res.data.data);
                    setnewEmployee([]);
                    let data = res.data.data;
                    let arrList = data.map((item, index) => {
                      let data = {
                        contract_type_name: item.emp_contract,
                        emp_contract: item.emp_contract,
                        link: item.employee_id,
                        emp_national_id: item.emp_national_id,
                      };
                      return data;
                    });
                    // console.log(arrList);
                    setnewEmployee(arrList);
                    getfilterNewEmployee();
                  })
                  .catch((err) => {
                    console.log(err);
                    Swal.fire({
                      icon: "error",
                      title: "Error",
                      text: err.response.data.error.message,
                    });
                  });
              }
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: "Error",
                text: err.response.data.error.message,
              });
            });
        }
        // axios({
        //   method: "post",
        //   url:
        //     Configs.API_URL_hrMagenatement +
        //     "/api/hrManagement/checkNewEmployeeSameCitizen",
        //   headers: {
        //     Authorization: getToken(),
        //     "X-TTT": Configs.API_TTT,
        //     "Content-Type": "application/json",
        //   },
        //   data: data,
        // })
        //   .then((res) => {
        //     // console.log("res", res.data.data);
        //     if (res.data.data.length > 0) {
        //       let text2 = "";
        //       for (let item of res.data.data) {
        //         text2 += item.emp_national_id + ",";
        //       }
        //       Swal.fire({
        //         icon: "error",
        //         title: "รหัสบัตรประชาชนซ้ำกันในระบบ",
        //         text: text2,
        //       });
        //     } else {
        //       axios({
        //         method: "post",
        //         url:
        //           Configs.API_URL_hrMagenatement_public +
        //           "/api/hrManagement/genLink",
        //         headers: {
        //           Authorization: getToken(),
        //           "X-TTT": Configs.API_TTT,
        //           "Content-Type": "application/json",
        //         },
        //         data: data,
        //       })
        //         .then((res) => {
        //           console.log("res", res.data.data);
        //           setnewEmployee([]);
        //           let data = res.data.data;
        //           let arrList = data.map((item, index) => {
        //             let data = {
        //               contract_type_name: item.emp_contract,
        //               link: item.employee_id,
        //               emp_national_id: item.emp_national_id,
        //             };
        //             return data;
        //           });
        //           // console.log(arrList);
        //           setnewEmployee(arrList);
        //           getfilterNewEmployee();
        //         })
        //         .catch((err) => {
        //           console.log(err);
        //           Swal.fire({
        //             icon: "error",
        //             title: "Error",
        //             text: err.response.data.error.message,
        //           });
        //         });
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     Swal.fire({
        //       icon: "error",
        //       title: "Error",
        //       text: err.response.data.error.message,
        //     });
        //   });
        // console.log("data", data);
      }
    });
  }

  function syncEmp(employee) {
    let data = {
      oem_id: getOem(),
      company_id: getUser().com,
      fup: getUser().fup,
      newEmployee: employee,
    };
    // console.log("data.newEmployee", data.newEmployee);
    let empSync = [];
    if (data.newEmployee.length === 0) {
      return;
    } else {
      empSync = data.newEmployee.filter((el) => {
        return el.is_draft === false && el.is_sync === false;
      });
      data.newEmployee = empSync;
    }
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/syncHistory",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((res) => {
        console.log(res.data.data);
        Swal.fire({
          icon: "success",
          text: "เสร็จสิ้น",
        }).then(() => {
          window.location.href = "/Human_Resource/ข้อมูลพนักงาน/Employee";
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.error.message,
        });
      });
  }

  function syncEmp2(employee) {
    let data = {
      oem_id: getOem(),
      company_id: getUser().com,
      fup: getUser().fup,
      newEmployee: employee,
    };
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/syncHistory",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((res) => {
        console.log(res.data.data);
        Swal.fire({
          icon: "success",
          text: "เสร็จสิ้น",
        }).then(() => {
          // window.location.href = "/Human_Resource/ข้อมูลพนักงาน/Employee";
          window.location.reload();
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.error.message,
        });
      });
  }

  const rowsData = [];
  for (let index = 0; index < ListNewEmployee.length; index++) {
    const rowItem = {};

    rowItem["no"] = index + 1;
    rowItem["name"] =
      (ListNewEmployee[index].name_th ? ListNewEmployee[index].name_th : "") +
      " " +
      (ListNewEmployee[index].surname_th
        ? ListNewEmployee[index].surname_th
        : "");
    rowItem["citizen_id"] = ListNewEmployee[index].citizen_id
      ? ListNewEmployee[index].citizen_id
      : "";
    rowItem["position"] = ListNewEmployee[index].position_want
      ? ListNewEmployee[index].position_want
      : "";
    rowItem["phone"] = ListNewEmployee[index].mobile
      ? ListNewEmployee[index].mobile
      : "";
    rowItem["line"] = ListNewEmployee[index].line_id
      ? ListNewEmployee[index].line_id
      : "";
    rowItem["image"] = (
      <img
        src={
          ListNewEmployee[index].image_path
            ? Configs.API_URL_IMG + ListNewEmployee[index].image_path
            : userdefault_img.imgs
        }
        alt="img_new_employee"
        style={{ width: "100px", height: "100px" /* , objectFit: "initial" */ }}
      />
    );
    rowItem["create_date"] = ListNewEmployee[index].created_date
      ? moment(ListNewEmployee[index].created_date).format("DD/MM/YYYY")
      : "";
    rowItem["link"] = ListNewEmployee[index].link
      ? ListNewEmployee[index].link
      : "";
    rowItem["link_exp"] = ListNewEmployee[index].link_exp
      ? moment(ListNewEmployee[index].link_exp).format(
        "DD/MM/YYYY ::: HH:mm:ss"
      )
      : "";
    rowItem["sync"] =
      ListNewEmployee[index].is_draft === false ? (
        <div style={{ textAlign: "center" }}>
          <button
            type="button"
            className="btn btn-block btn-primary"
            id={index}
            onClick={syncEmp2.bind(this, [ListNewEmployee[index]])}
          >
            Sync
          </button>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <button
            type="button"
            className="btn btn-block btn-primary"
            id={index}
            disabled="true"
          >
            Sync
          </button>
        </div>
      );
    rowItem["mgt"] = (
      <>
        <div className="row" style={{ flexWrap: "nowrap" }}>
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              className=" btn btn-xs "
              // onClick={deldata.bind(this, ListNewEmployee[index].id)}
              href={
                "/Human_Resource/ข้อมูลพนักงาน/Employee/NewEmployee/view/" +
                ListNewEmployee[index].id
              }
            // onClick={getUserFup.bind(this, ListNewEmployee[index].id, "read")}
            >
              <i className="fas fa-eye"></i>
              {"   "}
            </a>
          </div>
          <div className="col-xl-1 col-md-1 col-xs-1 "></div>
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              className=" btn btn-xs "
              onClick={deldata.bind(this, ListNewEmployee[index].id)}
            >
              <i className="fas fa-trash-alt"></i>
            </a>
          </div>
        </div>
        {/* <a
                    className=" btn btn-xs "
                    onClick={() => { }}
                >
                    <i className="fas fa-eye"></i>
                </a> */}
      </>
    );
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อ - นามสกุล ",
        field: "name",
        sort: "asc",
        width: 50,
      },
      {
        label: "เลขบัตรประชาชน",
        field: "citizen_id",
        sort: "asc",
        width: 50,
      },
      {
        label: "ตำแหน่ง",
        field: "position",
        sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์โทรศัพท์",
        field: "phone",
        sort: "asc",
        width: 50,
      },
      {
        label: "Line ID",
        field: "line",
        sort: "asc",
        width: 50,
      },
      {
        label: "รูปภาพ",
        field: "image",
        sort: "asc",
        width: 50,
      },
      {
        label: "Sync",
        field: "sync",
        sort: "asc",
        width: 100,
      },
      {
        label: "วันที่กรอกข้อมูล",
        field: "create_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "ลิงค์",
        field: "link",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันหมดอายุ",
        field: "link_exp",
        sort: "asc",
        width: 50,
      },
      {
        label: "จัดการ",
        field: "mgt",
        sort: "asc",
        width: 80,
      },
    ],
    rows: rowsData,
  };

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-2 col-md-2 col-xl-1">
                <button
                  type="button"
                  className="btn btn-block btn-primary"
                  onClick={() => {
                    window.location.href =
                      "/Human_Resource/ข้อมูลพนักงาน/Employee";
                  }}
                >
                  Back
                </button>
              </div>
              <div className="col-5 col-md-5 col-xl-6">
                <h1>พนักงานใหม่</h1>
              </div>
              <div className="col-sm-5">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Human_Resource</li>
                  <li className="breadcrumb-item active">ข้อมูลพนักงาน</li>
                  <li className="breadcrumb-item active">พนักงานใหม่</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-12 col-md-4 col-xl-4">
              <div className="form-group ">
                <input
                  type="text"
                  className="form-control"
                  id="text_emp_name"
                  required="false"
                  value={filterNewEmployee.name_th}
                  onChange={(e) => {
                    setfilterNewEmployee({
                      ...filterNewEmployee,
                      name_th: e.target.value,
                    });
                  }}
                />
                <label htmlFor="">ชื่อ - นามสกุล</label>{" "}
              </div>
            </div>
            {/* <div className="col-12 col-md-4 col-xl-2">
                            <div className="form-group ">
                                <input
                                    type="text"
                                    className="form-control"
                                    required="false"
                                    id="text_emp_surname"
                                    value={filterNewEmployee.surname_th}
                                    onChange={(e) => {
                                        setfilterNewEmployee({
                                            ...filterNewEmployee,
                                            surname_th: e.target.value,
                                        });
                                    }}
                                />
                                <label htmlFor="">นามสกุล</label>{" "}
                            </div>
                        </div> */}
            <div className="col-12 col-md-4 col-xl-2">
              <div className="form-group ">
                <input
                  type="text"
                  className="form-control"
                  required="false"
                  id="text_emp_position"
                  value={filterNewEmployee.position_want}
                  onChange={(e) => {
                    setfilterNewEmployee({
                      ...filterNewEmployee,
                      position_want: e.target.value,
                    });
                  }}
                />
                <label htmlFor="">ตำแหน่ง</label>{" "}
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-2">
              <div className="form-group ">
                <button
                  type="button"
                  id="btn_search"
                  className="btn btn-block btn-info"
                  onClick={() => {
                    getfilterNewEmployee();
                  }}
                >
                  Search
                </button>
              </div>
            </div>

            <div className="col-6 col-md-4 col-xl-2">
              <div className="form-group ">
                <button
                  type="button"
                  id="btn_clear"
                  className="btn btn-block btn-info"
                  onClick={() => {
                    setfilterNewEmployee({
                      name_th: "",
                      surname_th: "",
                      position_want: "",
                    });
                    filterNewEmployee.name_th = "";
                    filterNewEmployee.surname_th = "";
                    filterNewEmployee.position_want = "";
                    getfilterNewEmployee();
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-6 col-md-4 col-xl-2">
              <div className="form-group ">
                <button
                  type="button"
                  id="btn_genLink"
                  className="btn btn-block btn-primary"
                  data-toggle="modal"
                  data-target="#modal-genLink"
                  onClick={() => {
                    setnewEmployee([]);
                  }}
                >
                  Gen Link
                </button>
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-2">
              <div className="form-group ">
                <button
                  type="button"
                  id="btn_syncEmp"
                  disabled={btnSyncEmp.data}
                  className="btn btn-block btn-info"
                  onClick={() => {
                    syncEmp(ListNewEmployee);
                  }}
                >
                  Sync All New Employee
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">ข้อมูลพนักงานใหม่</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="table-responsive "
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <MDBDataTable
                          sortable={false}
                          className="table table-head-fixed"
                          striped
                          bordered
                          hover
                          fixedHeader
                          data={data}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="modal-genLink">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Generate Link for New Employee</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => { }}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-6 col-md-4 col-xl-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            id="btn_genLink"
                            className="btn btn-block btn-primary"
                            disabled={newEmployee.length === 0}
                            onClick={() => {
                              genLink();
                            }}
                          >
                            Gen Link
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-6 col-md-4 col-xl-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            id="btn_addEmp"
                            className="btn btn-block btn-success"
                            onClick={() => {
                              addNewEmp();
                            }}
                          >
                            เพิ่มคน
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {newEmployee.map((el, index) => {
                  return (
                    <div className="row" key={index}>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-2 col-md-2 col-xl-2">
                            <div className="form-group ">
                              <select
                                className="form-control"
                                id="select_emp_title"
                                value={el.emp_contract}
                                onChange={(e) => {
                                  // console.log('el.contract_type_name ? el.contract_type_name : el.emp_contract', el.contract_type_name ? el.contract_type_name : el.emp_contract);
                                  const update = [...newEmployee];
                                  update[index].emp_contract = e.target.value;
                                  // update[index].contract_type_name =  e.target.value;
                                  console.log('update[index]', update[index])
                                  setnewEmployee(update);
                                }}
                              >
                                <option value="">--เลือก--</option>
                                {Contract.map((el) => {
                                  return (
                                    <option value={el.id} key={el.id}>
                                      {el.contract_type_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-4 col-md-4 col-xl-4">
                            <div className="form-group ">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                id="text_emp_citizenID"
                                format={"#-####-#####-##-#"}
                                required
                                value={el.emp_national_id}
                                onChange={(e) => {
                                  const update = [...newEmployee];
                                  update[index].emp_national_id =
                                    e.target.value;
                                  setnewEmployee(update);
                                }}
                              />
                              <label htmlFor="">เลขบัตรประชาชน</label>{" "}
                            </div>
                          </div>
                          <div className="col-4 col-md-4 col-xl-4">
                            <div className="form-group ">
                              <input
                                type="text"
                                className="form-control"
                                id="text_emp_link"
                                required
                                disabled="true"
                                value={el.link}
                              />
                              <label htmlFor="">Link</label>{" "}
                            </div>
                          </div>
                          {el.link !== "" ? (
                            ""
                          ) : (
                            <div className="col-2 col-md-2 col-xl-2">
                              <div className="form-group ">
                                <button
                                  type="button"
                                  id="btn_del"
                                  className="btn btn-block btn-danger"
                                  onClick={() => {
                                    deleteNewEmp(index);
                                  }}
                                >
                                  ลบ
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewEmployee;
