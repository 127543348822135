import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../Utils/Common";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Card } from "reactstrap";

function Leave_information(params) {
  function addpage(params) {
    window.location.href = "/Human_Resource/ข้อมูลการปฏิบัติงาน/leave_information/add";
  }
  const [data_entLeave, setdata_entLeave] = useState({
    pak_ron: "",
    la_kit: "",
    sick: "",
    out_pak_ron: "",
    out_la_kit: "",
    out_sick: "",
  });
  const [lev, setlev] = useState({
    lev_id: "",
    lev_hot: "" /* ลาพักร้อน */,
    lev_gut: "" /* ลากิจ */,
    lev_sick: "" /* ลาป่วย */,
    emp_id: "" /* ID พนักงานที่ผูกกับ USER_ID */,
    emp_user_id: "" /* ID ผู้ใช้ login */,
  }); /* วันคงเหลือ */

  const [leave_list, set_leave_list] = useState([]); /* วันลาประจำปี */

  const [all_leave, set_all_leave] = useState([]);
  const [redio, set_redio] = useState([]);

  const [filter_leave, set_filter_leave] = useState({
    user_id: "",
    year: new Date(),
    all_list: "",
  });
  const [display_year, set_display_year] = useState({
    displayYYY: "",
  });

  const [gt_user_id, set_gt_user_id] = useState({
    id: "",
  });
  const [historyLeave, sethistoryLeave] = useState([]);
  const [viewSelect, setviewSelect] = useState({
    name: "",
  });
  const [LeaveTypeOption, setLeaveTypeOption] = useState([]);
  const [dateZindex, setdateZindex] = useState({
    dropdown1: "99",
    datehis1: "98",
    datehis2: "97",
  });
  const start_date_his = moment(new Date()).startOf("years");
  const end_date_his = moment(new Date()).endOf("years");
  const [filter_history, setfilter_history] = useState({
    leave_type: "",
    start_date: new Date(start_date_his),
    finish_date: new Date(end_date_his),
  });
  const [emp_id, setemp_id] = useState();
  const getLeaveType = async () => {
    //console.log(filler_employee);
    axios({
      method: "get",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getLeaveType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        /*   console.log(response.data, "ds"); */

        const options = [];
        var tmp = {
          value: "",
          label: "ทั้งหมด",
        };
        options.push(tmp);
        response.data.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.status_name;
          options.push(element);
        });
        setLeaveTypeOption(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  async function SetDate(date) {
    // let datefrom = "";
    let momenform = moment(date).format("yyyy");
    // datefrom = parseInt(momenform) + 543,
    // console.log("แปลงเเลว้",parseInt(momenform) + 543);
    // console.log("datefrom",datefrom);
    set_display_year({
      ...display_year,
      displayYYY: parseInt(momenform) + 543,
    });
    set_filter_leave({ ...filter_leave, all_list: date });
  }

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  async function getEntLeaveByEmpId() {
    let temp = {
      iden_id: getUser().emp_id,
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getEntLeaveByEmpIdenId",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        setemp_id(response.data.data.emp_id);
        setdata_entLeave({
          ...data_entLeave,
          pak_ron: response.data.data.ent_vacation_leave,
          la_kit: response.data.data.ent_personal_leave,
          sick: response.data.data.ent_sick_leave,
          out_pak_ron: response.data.data.out_vacation_leave,
          out_la_kit: response.data.data.out_personal_leave,
          out_sick: response.data.data.out_sick_leave,
        });
        getLeaveHistoryByEmpId(response.data.data.emp_id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const GetBalanceDay = async () => {
    /*  console.log("GET วันคงเหลือ"); */
    const user_id = JSON.parse(sessionStorage.getItem("user")) || [];
    axios({
      method: "get",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getEntitlementleaveByID?id=" +
        user_id.emp_id,
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      //data:filler_pad_connection.pad_name,
    })
      .then(function (response) {
        //response.data.data.sort((a,b)=> a.pad_name.localeCompare(b.pad_name));
        console.log("วันคงเหลือ", response.data.data[0]);
        setlev({
          /* ...lev, */ lev_id: response.data.data[0].lev_id || "",
          emp_user_id: response.data.data[0].user_id || "",
          emp_id: response.data.data[0].emp_id || "",
          lev_hot: response.data.data[0].lev_hot || 0,
          lev_gut: response.data.data[0].lev_gut || 0,
          lev_sick: response.data.data[0].lev_sick || 0,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetDataLogLeave = async () => {
    /*  console.log("GET ข้อมูลวันลา"); */
    console.log("เรียกใช้", filter_leave);
    const user_id = JSON.parse(sessionStorage.getItem("user")) || [];
    var temp = {
      user_id: user_id.emp_id,
      year: filter_leave.year,
      all_list: filter_leave.all_list,
      is_ttt: getUser().com === "5146dd92-19f6-4dab-ad49-735a114fcfdb"
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/filterEntitilementleave",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        //response.data.data.sort((a,b)=> a.pad_name.localeCompare(b.pad_name));
        console.log("ข้อมูลทั้งหมด", response.data.data);
        set_leave_list(response.data.data.filterYears);
        set_all_leave(response.data.data.filterAll);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function getUserID() {
    const user_id = JSON.parse(sessionStorage.getItem("user")) || [];
    set_filter_leave({ ...filter_leave, user_id: user_id.fup });
    set_gt_user_id({ ...gt_user_id, id: user_id.fup });
  }

  const clearFilter = async () => {
    const user_id = JSON.parse(sessionStorage.getItem("user")) || [];
    await set_filter_leave({
      user_id: "",
      year: new Date(),
      all_list: "",
    });

    filter_leave.user_id = user_id.fup;
    filter_leave.year = new Date();
    filter_leave.all_list = "";
    GetDataLogLeave();
  };

  console.log("ผู้ใช้งาน", filter_leave);
  useEffect(() => {
    getEntLeaveByEmpId();
    getUserID();
    GetBalanceDay();
    GetDataLogLeave();
    getLeaveType();

    // GetBalanceDay() /* ฟังชั่นวันคงเหลือ */
    //GetDataLogLeave() /* ฟังชั่นดาต้าทั้งหมด */
  }, []);

  function getLeaveHistoryByEmpId(emp_id_res) {
    var emp_id_ = emp_id;
    if (emp_id_res) {
      emp_id_ = emp_id_res;
    }

    /*  console.log("e",emp_id) */
    /* console.log("f",f,g) */
    var temp = {
      emp_id: emp_id_,
      start_date: filter_history.start_date,
      finish_date: filter_history.finish_date,
      leave_type: filter_history.leave_type,
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getLeaveHistoryByEmpId",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        sethistoryLeave(response.data.data);
        /*   console.log(response.data, "ds"); */
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // console.log("sesid",filter_leave);

  //   function deldata(id) {
  //     Swal.fire({
  //       title: "คุณแน่ใจไหม?",
  //       text: "คุณจะไม่สามารถย้อนกลับได้!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "ใช่ ลบทิ้ง!",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         axios({
  //           method: "DELETE",
  //           url: Configs.API_URL_hrMagenatement + "/config/" + id,
  //           headers: {
  //             Authorization: "Bearer "+getToken(),
  //             "Content-Type": "application/json",
  //             "X-TTT": Configs.API_TTT,
  //           },
  //         })
  //           .then(function (response) {
  //             if (response.data.status === "success") {
  //               Swal.fire(
  //                 "ลบแล้ว!",
  //                 "ไฟล์ของคุณถูกลบแล้ว",
  //                 "เสร็จสิ้น"
  //               ).then(() => {
  //                 GetLogPadConnection();
  //                 // window.location.href =
  //                 //   "/Warehouse_Management/Human_Resource/Employee";
  //               });
  //             }
  //           })
  //           .catch(function (error) {
  //             console.log(error);
  //             Swal.fire("Error", "บางอย่างผิดพลาด: " + error, "error");
  //           });
  //       }
  //     });
  //   }

  const rowsData = [];
  for (var index = 0; index < leave_list.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["type_leave"] = leave_list[index].type_leave;
    rowItem["date_leave"] =
      moment(leave_list[index].date_leave).format("DD/MM/yyyy") +
      " - " +
      moment(leave_list[index].ent_date).format("DD/MM/yyyy");
    rowItem["date_record"] = leave_list[index].date_record
      ? moment(leave_list[index].date_record).format("DD/MM/yyyy")
      : "";

    var dev_sts = "";
    var hr_sts = "";
    if (leave_list[index].status_dev !== null) {
      if (leave_list[index].status_dev !== false) {
        rowItem["status_dep"] = (
          <div className="row">
            <div className="col-12 col-md-12 col-xl-12">
              <div
                key={leave_list[index].employee_id + "อนุมัติDept." + index}
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <div style={{ width: "100%" }} className="mr-1">
                  <button
                    type="button"
                    id="btn_search"
                    disabled
                    className="btn btn-block btn-success"
                    style={{ minWidth: "60px" }}
                  >
                    อนุมัติ Dept.
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        rowItem["status_dep"] = (
          <div className="row">
            <div className="col-12 col-md-12 col-xl-12">
              <div
                key={leave_list[index].employee_id + "ไม่อนุมัติDept.." + index}
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <div style={{ width: "100%" }} className="mr-1">
                  <button
                    type="button"
                    id="btn_search"
                    disabled
                    className="btn btn-block btn-danger"
                    style={{ minWidth: "60px" }}
                  >
                    ไม่อนุมัติ Dept.
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      rowItem["status_dep"] = (
        <div className="row">
          <div className="col-12 col-md-12 col-xl-12">
            <div
              key={leave_list[index].employee_id + "รอการอนุมัติ" + index}
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <div style={{ width: "100%" }} className="mr-1">
                <button
                  type="button"
                  id="btn_search"
                  disabled
                  className="btn btn-block btn-secondary"
                  style={{ minWidth: "60px" }}
                >
                  รอการอนุมัติ Dept.
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (leave_list[index].status_hr !== null) {
      if (leave_list[index].status_hr !== false) {
        rowItem["status_hr"] = (
          <div className="row">
            <div className="col-12 col-md-12 col-xl-12">
              <div
                key={leave_list[index].employee_id + "อนุมัติHR" + index}
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <div style={{ width: "100%" }} className="mr-1">
                  <button
                    type="button"
                    id="btn_search"
                    disabled
                    className="btn btn-block btn-success"
                    style={{ minWidth: "60px" }}
                  >
                    อนุมัติ HR.
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        rowItem["status_hr"] = (
          <div className="row">
            <div className="col-12 col-md-12 col-xl-12">
              <div
                key={leave_list[index].employee_id + "ไม่อนุมัติHR." + index}
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <div style={{ width: "100%" }} className="mr-1">
                  <button
                    type="button"
                    id="btn_search"
                    disabled
                    className="btn btn-block btn-danger"
                    style={{ minWidth: "60px" }}
                  >
                    ไม่อนุมัติ HR.
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      if (leave_list[index].status_dev === false) {
        <></>;
      } else {
        rowItem["status_hr"] = (
          <div className="row">
            <div className="col-12 col-md-12 col-xl-12">
              <div
                key={leave_list[index].employee_id + "รอการอนุมัติ" + index}
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <div style={{ width: "100%" }} className="mr-1">
                  <button
                    type="button"
                    id="btn_search"
                    disabled
                    className="btn btn-block btn-secondary"
                    style={{ minWidth: "60px" }}
                  >
                    รอการอนุมัติ Hr.
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    if (leave_list[index].status_dev !== null) {
      if (leave_list[index].status_dev === false) {
        rowItem["status"] = (
          <div className="row">
            <div className="col-12 col-md-12 col-xl-12">
              <div
                key={leave_list[index].employee_id + "ไม่อนุมัติ" + index}
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <div style={{ width: "100%" }} className="mr-1">
                  <button
                    type="button"
                    id="btn_search"
                    disabled
                    className="btn btn-block btn-danger"
                    style={{ minWidth: "60px" }}
                  >
                    ไม่อนุมัติ
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        if (leave_list[index].status_hr !== null) {
          if (leave_list[index].status_hr === false) {
            rowItem["status"] = (
              <div className="row">
                <div className="col-12 col-md-12 col-xl-12">
                  <div
                    key={leave_list[index].employee_id + "ไม่อนุมัติ" + index}
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div style={{ width: "100%" }} className="mr-1">
                      <button
                        type="button"
                        id="btn_search"
                        disabled
                        className="btn btn-block btn-danger"
                        style={{ minWidth: "60px" }}
                      >
                        ไม่อนุมัติ
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            rowItem["status"] = (
              <div className="row">
                <div className="col-12 col-md-12 col-xl-12">
                  <div
                    key={leave_list[index].employee_id + "อนุมัติ" + index}
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div style={{ width: "100%" }} className="mr-1">
                      <button
                        type="button"
                        id="btn_search"
                        disabled
                        className="btn btn-block btn-success"
                        style={{ minWidth: "60px" }}
                      >
                        อนุมัติ
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        } else {
          <div className="row">
            <div className="col-12 col-md-12 col-xl-12">
              <div
                key={leave_list[index].employee_id + "รอการอนุมัติ" + index}
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <div style={{ width: "100%" }} className="mr-1">
                  <button
                    type="button"
                    id="btn_search"
                    disabled
                    className="btn btn-block btn-secondary"
                    style={{ minWidth: "60px" }}
                  >
                    รอการอนุมัติ
                  </button>
                </div>
              </div>
            </div>
          </div>;
        }
      }
    } else {
      if (leave_list[index].status_hr !== null) {
        if (leave_list[index].status_hr === false) {
          rowItem["status"] = (
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div
                  key={leave_list[index].employee_id + "ไม่อนุมัติ" + index}
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div style={{ width: "100%" }} className="mr-1">
                    <button
                      type="button"
                      id="btn_search"
                      disabled
                      className="btn btn-block btn-danger"
                      style={{ minWidth: "60px" }}
                    >
                      ไม่อนุมัติ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          rowItem["status"] = (
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div
                  key={leave_list[index].employee_id + "อนุมัติ" + index}
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div style={{ width: "100%" }} className="mr-1">
                    <button
                      type="button"
                      id="btn_search"
                      disabled
                      className="btn btn-block btn-success"
                      style={{ minWidth: "60px" }}
                    >
                      อนุมัติ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      } else {
        <div className="row">
          <div className="col-12 col-md-12 col-xl-12">
            <div
              key={leave_list[index].employee_id + "รอการอนุมัติ" + index}
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <div style={{ width: "100%" }} className="mr-1">
                <button
                  type="button"
                  id="btn_search"
                  disabled
                  className="btn btn-block btn-secondary"
                  style={{ minWidth: "60px" }}
                >
                  รอการอนุมัติ
                </button>
              </div>
            </div>
          </div>
        </div>;
      }
    }

    /*  if (
      (leave_list[index].status_dev === false &&
        leave_list[index].status_hr !== null) ||
      (leave_list[index].status_hr === false &&
        leave_list[index].status_dev !== null)
    ) {
      rowItem["status"] = (
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-block btn-danger"
              disabled={true}
            >
              ไม่อนุมัติ
            </button>
          </div>
        </div>
      );
    } else if (
      leave_list[index].status_dev === true &&
      leave_list[index].status_hr === true
    ) {
      rowItem["status"] = (
        <div className="row">
          <div
            className="col-12 col-md-6 col-xl-6" 
          >
            <button
              type="button"
              className="btn btn-block btn-success"
              disabled={true}
            >
              อนุมัติ Dept.
            </button>
          </div>

          <div className="col-12 col-md-6 col-xl-6">
            <button
              type="button"
              className="btn btn-block btn-success"
              disabled={true}
            >
              อนุมัติ HR.
            </button>
          </div>
        </div>
      );
      // }
      if (leave_list[index].status_dev === null) {
        dev_sts = <h5 style={{ color: "green" }}> ไม่อนุมัติ Dept.</h5>;
        //<button type="button" className="btn btn-block btn-danger" disabled = {false}> รออนุมัติ Dept.</button>
      }
      if (leave_list[index].status_dev === false) {
        dev_sts = <h5 style={{ color: "green" }}> ไม่อนุมัติ Dept.</h5>;
        // <button type="button" className="btn btn-block btn-danger" disabled = {false}> ไม่อนุมัติ Dept.</button>
      }
      if (leave_list[index].status_hr === true) {
        hr_sts = <h5 style={{ color: "green" }}> อนุมัติ HR</h5>;
        //<button type="button" className="btn btn-block btn-success" disabled = {false}> อนุมัติ Dept.</button>
      }
      if (leave_list[index].status_hr === null) {
        hr_sts = <h5 style={{ color: "red" }}> รออนุมัติ HR</h5>;
        //<button type="button" className="btn btn-block btn-danger" disabled = {false}> รออนุมัติ Dept.</button>
      }
      if (leave_list[index].status_hr === false) {
        hr_sts = <h5 style={{ color: "red" }}> ไม่อนุมัติ HR</h5>;
        //<button type="button" className="btn btn-block btn-danger" disabled = {false}> ไม่อนุมัติ Dept.</button>
      }
      console.log("SSD", dev_sts, hr_sts.props.children);
      //rowItem["status"] =<h5 style={{color:hr_sts.props.style.color}}> {hr_sts.props.children }</h5> + <h5 style={{color:hr_sts.props.style.color}}> {hr_sts.props.children }</h5>;
    } else if (
      leave_list[index].status_dev === null &&
      leave_list[index].status_hr === null
    ) {
      rowItem["status"] = (
        <div className="row">
          <div
            className="col-12 col-md-6 col-xl-6" 
          >
            <button
              type="button"
              className="btn btn-block btn-danger"
              disabled={true}
            >
              รออนุมัติ Dept.
            </button>
          </div>

          <div className="col-12 col-md-6 col-xl-6">
            <button
              type="button"
              className="btn btn-block btn-danger"
              disabled={true}
            >
              รออนุมัติ HR.
            </button>
          </div>
        </div>
      );
    } else if (
      leave_list[index].status_dev === true &&
      leave_list[index].status_hr === null
    ) {
      rowItem["status"] = (
        <div className="row">
          <div
            className="col-12 col-md-6 col-xl-6" 
          >
            <button
              type="button"
              className="btn btn-block btn-success"
              disabled={true}
            >
              อนุมัติ Dept.
            </button>
          </div>

          <div className="col-12 col-md-6 col-xl-6">
            <button
              type="button"
              className="btn btn-block btn-danger"
              disabled={true}
            >
              รออนุมัติ HR.
            </button>
          </div>
        </div>
      );
    } else if (
      leave_list[index].status_dev === null &&
      leave_list[index].status_hr === true
    ) {
      rowItem["status"] = (
        <div className="row">
          <div
            className="col-12 col-md-6 col-xl-6" 
          >
            <button
              type="button"
              className="btn btn-block btn-danger"
              disabled={true}
            >
              รออนุมัติ Dept.
            </button>
          </div>

          <div className="col-12 col-md-6 col-xl-6">
            <button
              type="button"
              className="btn btn-block btn-success"
              disabled={true}
            >
              อนุมัติ HR.
            </button>
          </div>
        </div>
      );
    } */
    rowItem["remark"] = leave_list[index].not_approve_remark;
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={
              "/Human_Resource/ข้อมูลการปฏิบัติงาน/leave_information/read/" + leave_list[index].id
            }
            key={leave_list[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
        {leave_list[index].status_dev !== null ||
        leave_list[index].status_hr !== null ? (
          <></>
        ) : (
          <>
            <div className="col-4">
              <a
                href={
                  "/Human_Resource/ข้อมูลการปฏิบัติงาน/leave_information/edit/" +
                  leave_list[index].id
                }
                key={leave_list[index].id}
                className=" btn btn-xs "
              >
                {" "}
                <i class="fas fa-pencil-alt"></i>{" "}
              </a>
            </div>
          </>
        )}
      </div>
    );
    rowsData.push(rowItem);
  }
  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ประเภทการลา",
        field: "type_leave",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่ลา",
        field: "date_leave",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่บันทึก",
        field: "date_record",
        sort: "asc",
        width: 50,
      },

      {
        label: "สถานะอนุมัติการลา แผนก",
        field: "status_dep",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานะอนุมัติการลา ฝ่ายบุคคล",
        field: "status_hr",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานะการลา",
        field: "status",
        sort: "asc",
        width: 50,
      },
      {
        label: "หมายเหตุ",
        field: "remark",
        sort: "asc",
        width: 50,
      },
      {
        label: "การจัดการ",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  }; /* ประจำเเค่ละปี */

  const allrowsData = [];
  for (var index = 0; index < all_leave.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["type_leave"] = all_leave[index].type_leave;
    rowItem["date_leave"] =
      moment(all_leave[index].date_leave).format("DD/MM/yyyy") +
      " - " +
      moment(all_leave[index].ent_date).format("DD/MM/yyyy");
    rowItem["date_record"] = all_leave[index].date_record
      ? moment(all_leave[index].date_record).format("DD/MM/yyyy")
      : "";

    var dev_sts = "";
    var hr_sts = "";
    if (
      (all_leave[index].status_dev === false &&
        all_leave[index].status_hr !== null) ||
      (all_leave[index].status_hr === false &&
        all_leave[index].status_dev !== null)
    ) {
      rowItem["status"] = (
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-block btn-danger"
              disabled={true}
            >
              ไม่อนุมัติ
            </button>
          </div>
        </div>
      );
    } else if (
      all_leave[index].status_dev === true &&
      all_leave[index].status_hr === true
    ) {
      rowItem["status"] = (
        <div className="row">
          <div className="col-12 col-md-6 col-xl-6">
            <button
              type="button"
              className="btn btn-block btn-success"
              disabled={true}
            >
              อนุมัติ Dept.
            </button>
          </div>

          <div className="col-12 col-md-6 col-xl-6">
            <button
              type="button"
              className="btn btn-block btn-success"
              disabled={true}
            >
              อนุมัติ HR.
            </button>
          </div>
        </div>
      );
      //rowItem["status"] =<h5 style={{color:hr_sts.props.style.color}}> {hr_sts.props.children }</h5> + <h5 style={{color:hr_sts.props.style.color}}> {hr_sts.props.children }</h5>;
    } else if (
      all_leave[index].status_dev === null &&
      all_leave[index].status_hr === null
    ) {
      rowItem["status"] = (
        <div className="row">
          <div className="col-12 col-md-6 col-xl-6">
            <button
              type="button"
              className="btn btn-block btn-danger"
              disabled={true}
            >
              รออนุมัติ Dept.
            </button>
          </div>

          <div className="col-12 col-md-6 col-xl-6">
            <button
              type="button"
              className="btn btn-block btn-danger"
              disabled={true}
            >
              รออนุมัติ HR.
            </button>
          </div>
        </div>
      );
    } else if (
      all_leave[index].status_dev === true &&
      all_leave[index].status_hr === null
    ) {
      rowItem["status"] = (
        <div className="row">
          <div className="col-12 col-md-6 col-xl-6">
            <button
              type="button"
              className="btn btn-block btn-success"
              disabled={true}
            >
              อนุมัติ Dept.
            </button>
          </div>

          <div className="col-12 col-md-6 col-xl-6">
            <button
              type="button"
              className="btn btn-block btn-danger"
              disabled={true}
            >
              รออนุมัติ HR.
            </button>
          </div>
        </div>
      );
    } else if (
      all_leave[index].status_dev === null &&
      all_leave[index].status_hr === true
    ) {
      rowItem["status"] = (
        <div className="row">
          <div className="col-12 col-md-6 col-xl-6">
            <button
              type="button"
              className="btn btn-block btn-danger"
              disabled={true}
            >
              รออนุมัติ Dept.
            </button>
          </div>

          <div className="col-12 col-md-6 col-xl-6">
            <button
              type="button"
              className="btn btn-block btn-success"
              disabled={true}
            >
              อนุมัติ HR.
            </button>
          </div>
        </div>
      );
    }

    //  rowItem["mgt"] = (
    //    <div className="row">
    //      <div className="col-4">
    //        <a
    //             href={/* "/Human_Resource/ตั้งค่า/Pad_Connection/edit/" */+ leave_list[index].id}
    //             key={leave_list[index].id}
    //          className=" btn btn-xs "
    //        >
    //          {" "}
    //          <i class="fas fa-pencil-alt"></i>{" "}
    //        </a>
    //      </div>
    //    </div>
    //  );
    allrowsData.push(rowItem);
  }
  const alldata = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ประเภทการลา",
        field: "type_leave",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่ลา",
        field: "date_leave",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่บันทึก",
        field: "date_record",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานะ",
        field: "status",
        sort: "asc",
        width: 50,
      },
      //  {
      //    label: "เพิ่มข้อมูล",
      //    field: "mgt",
      //    sort: "asc",
      //    width: 50,
      //  },
    ],
    rows: allrowsData,
  };

  const rowsDataHistory = [];
  for (var index = 0; index < historyLeave.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["date"] =
      moment(historyLeave[index].start_date).format("DD/MM/YYYY") +
      " - " +
      moment(historyLeave[index].finish_date).format("DD/MM/YYYY");
    rowItem["time"] =
      historyLeave[index].start_time + "-" + historyLeave[index].finish_time;
    rowItem["leave_type"] = historyLeave[index].leave_type;
    rowItem["day_type"] = historyLeave[index].leave_half_day;
    /*   if (historyLeave[index].leave_half_day === 1) {
      rowItem["day_type"] = "ลาครึ่งเช้า";
    } else if (historyLeave[index].leave_half_day === 2) {
      rowItem["day_type"] = "ลาครึ่งบ่าย";
    } else {
      if (historyLeave[index].leave_type === 1) {
        rowItem["day_type"] = "ลาทั้งวัน";
      } else {
        rowItem["day_type"] = "-";
      }
    } */
    rowItem["detail"] = (
      <>
        <div
          style={{
            maxWidth: "30vw",
            width: "100%",
            // overflow: 'hidden',
            // whiteSpace: 'nowrap',
            // textOverflow: 'ellipsis',
            // border: '1px solid red',
          }}
        >
          <p
            style={{
              margin: 0,
              overflow: "hidden",
              whiteSpace: "pre-wrap",
              textOverflow: "ellipsis",
            }}
          >
            {historyLeave[index].detail}
          </p>
        </div>
      </>
    );

    rowItem["file"] =
      historyLeave[index].file_path !== "" ? (
        <>
          <a   target="_blank"
    download="test" href={Configs.API_URL_IMG + historyLeave[index].file_path}>
            <button type="button" className="btn btn-block btn-info  ">
              Download tttt
            </button>
          </a>
        </>
      ) : (
        ""
      );

    if (
      historyLeave[index].is_department === false ||
      historyLeave[index].is_hr === false
    ) {
      rowItem["status"] = "ไม่อนุมัติ";
    } else if (
      historyLeave[index].is_hr === true &&
      historyLeave[index].is_department === true
    ) {
      rowItem["status"] = "อนุมัติ";
    } else {
      rowItem["status"] = "";
    }

    /* rowItem["mgt"] = (
       <>
        <div className="row">
        <div className="col-4">
             <a
              
               key={Employee[index].id}
               id = {Employee[index].id}
               className=" btn btn-xs " 
               data-toggle="modal"
               data-target="#modal-viewLeaveHistory"
               onClick={getLeaveHistoryByEmpId.bind(this,Employee[index].id,Employee[index].name)}
                 
               
             
             >
               <i class="fas fa-eye"></i>
             </a>
           </div>
         <div className="col-4">
           <a
                href={"/Human_Resource/leave_information_admin/mgt/"+ Employee[index].id}
                key={Employee[index].id}
             className=" btn btn-xs "
           >
             {" "}
             <i class="fas fa-pencil-alt"></i>{" "}
           </a>
         </div>
       </div>
       </>
     ); */

    rowsDataHistory.push(rowItem);
  }

  const dataHistory = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ช่วงวันที่ลา",
        field: "date",
        sort: "asc",
        width: 50,
      },
      {
        label: "ช่วงเวลา",
        field: "time",
        sort: "asc",
        width: 50,
      },
      {
        label: "ประเภทการลา",
        field: "leave_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "รูปแบบการลา",
        field: "day_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "รายละเอียด",
        field: "detail",
        sort: "asc",
        width: 50,
      },
      {
        label: "เอกสาร",
        field: "file",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานะ",
        field: "status",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsDataHistory,
  }; /* ประจำเเค่ละปี */

  function clearFilter_history() {
    setfilter_history({
      ...filter_history,
      leave_type: "",
      start_date: new Date(start_date_his),
      finish_date: new Date(end_date_his),
    });
    filter_history.leave_type = "";
    filter_history.start_date = new Date(start_date_his);
    filter_history.finish_date = new Date(end_date_his);
    getLeaveHistoryByEmpId();
  }

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  ข้อมูลวันลาพนักงาน{"  "}
                  {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">ข้อมูลวันลาพนักงาน</li>
                </ol>
              </div>
            </div>
          </div>

          {/*   <div className="container-fluid">
            <div className="row">
          ปุ่มเซิฟ
              <div className="col-6 col-md-6 col-xl-2">
                <div className="form-group ">
        
                </div>
              </div>
              <div className="col-6 col-md-6 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      id = "btn_add"
                      className="btn btn-block btn-primary"
                      onClick={addpage}
                    >
                      Add
                    </button>
                  </div>
                </div>

            </div>
          </div> */}
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>วันลาคงเหลือ</h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    <font color="green">
                      ลาพักร้อน {data_entLeave.pak_ron} วัน
                    </font>
                  </h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    <font color="blue">ลากิจ {data_entLeave.la_kit} วัน</font>
                  </h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    <font color="red">ลาป่วย {data_entLeave.sick} วัน</font>
                  </h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    ประจำปี {"  "}
                    {parseInt(moment(new Date()).format("YYYY")) + 543}
                  </h4>
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>วันลาที่เกิน</h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    <font color="green">
                      ลาพักร้อน {data_entLeave.out_pak_ron} วัน
                    </font>
                  </h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    <font color="blue">
                      ลากิจ {data_entLeave.out_la_kit} วัน
                    </font>
                  </h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    <font color="red">ลาป่วย {data_entLeave.out_sick} วัน</font>
                  </h4>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <h4>
                    ประจำปี {"  "}
                    {parseInt(moment(new Date()).format("YYYY")) + 543}
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      id="btn_leave"
                      className="btn btn-block btn-primary"
                      data-toggle="modal"
                      data-target="#modal-viewLeaveHistory"
                      onClick={() => {
                        setfilter_history({
                          leave_type: "",
                          start_date: new Date(start_date_his),
                          finish_date: new Date(end_date_his),
                        });
                        filter_history.leave_type = "";
                        filter_history.start_date = new Date(start_date_his);
                        filter_history.finish_date = new Date(end_date_his);
                        getLeaveHistoryByEmpId();
                      }}
                    >
                      ประวัติลาย้อนหลังทั้งหมด
                    </button>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-xl-2" align="right">
                  <div className="form-group ">
                    <button
                      type="button"
                      id="btn_leave"
                      className="btn btn-block btn-primary"
                      onClick={addpage}
                    >
                      แจ้งลา
                    </button>
                  </div>
                </div>
              </div>

              {/* <div className="row">
                    <div className="col-7 col-md-7 col-xl-7"> 
                    <div className="row"> 
                    <div className="col-6 col-md-3 col-xl-2 mt-4"> 
                         <h5 >วันลาคงเหลือ: </h5>  
                        </div>
                       <div className="col-6 col-md-3 col-xl-3 mt-4"> 
                         <h5 style={{color:"green"}}>ลาพักร้อน  {lev.lev_hot}  วัน</h5>  
                        </div>
                        <div className="col-12 col-md-3 col-xl-3 mt-4" > 
                         <h5 style={{color:"blue"}}> ลากิจ :  {lev.lev_gut}   วัน</h5>  
                        </div>
                        <div className="col-12 col-md-3 col-xl-3 mt-4"> 
                         <h5 style={{color:"red"}}> ลาป่วย :  {lev.lev_sick}  วัน</h5>  
                        </div>
                    </div>
                    
                    </div>
                    <div className="col-5 col-md-5 col-xl-5">
                    <div className="row">
                        <div className="col-6 col-md-6 col-xl-8 mt-4">

                      
                        <a
                         data-toggle="modal"
                         data-target="#modal-all_leave_detail" 
                        href = ""
                        //key={Log_pad_connection[index].id}
                        onClick={() => {GetDataLogLeave();}}
                        //className=" btn btn-xs "
                        >
                    
                        <h5> ประวัติลาย้อนหลังทั้งหมด </h5>
                      
                        </a>
                      

                        </div>
                    <div className="col-6 col-md-6 col-xl-4" align="right"> 
                    <div className="form-group ">
                    <button
                      type="button"
                      id = "btn_leave"
                      className="btn btn-block btn-primary"
                      onClick={addpage}
                    >
                      แจ้งลา
                    </button>
                    </div>
                    </div>
                    </div>
                 
                    </div>
             
            
           
                </div> */}

              {/* <h3 className="card-title">Project</h3> */}
              {/* <div className="card-tools"></div> */}
            </div>

            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
              <MDBDataTable
                sortable={false}
                id="table-to-xls"
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            <div className="modal fade" id="modal-all_leave_detail">
              <div className="modal-dialog modal-xl">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="col-7">
                      <div className="row">
                        <div className="col-12 col-md-12 col-xl-12">
                          <div className="row">
                            <div className="col-12 col-md-5 col-xl-3">
                              <h4 className="modal-label ">
                                ประวัติลาย้อนหลัง ปี
                              </h4>
                            </div>
                            <div className="col-12 col-md-2 col-xl-2">
                              {/*    { <h4 style={{ color: "red" }}> {display_year.displayYYY ? display_year.displayYYY : "ทั้งหมด" } </h4>} */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-1.0" style={{ "z-index": "99" }}>
                          <div className="form-group ">
                            <DatePicker
                              selected={filter_leave.all_list}
                              dateFormat={"yyyy"}
                              showYearPicker
                              onChange={async (date) => SetDate(date)}
                              //selectsStart
                              //startDate={filler_express_record.start_date}
                              //endDate={filler_express_record.end_date}
                              customInput={<CustomInput />}
                            />
                            <label>
                              ปี
                              {/* {data_leave.lev_start_date === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                            </label>{" "}
                          </div>
                        </div>
                        <div className="col-md-3 col-6 col-xl-2">
                          <div className="form-group ">
                            <button
                              type="button"
                              className="btn btn-block btn-info  "
                              onClick={() => {
                                GetDataLogLeave();
                              }}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                        <div className="col-md-3 col-6 col-xl-2">
                          <div className="form-group ">
                            <button
                              type="button"
                              className="btn btn-block btn-info"
                              onClick={() => clearFilter()}
                            >
                              Clear
                            </button>
                          </div>
                        </div>
                      </div>
                      {/*      <div className="row">
                <div className="col-6 col-md-3 col-xl-2 mt-4"> 
                         <h5 >วันลาคงเหลือ: </h5>  
                        </div>
                       <div className="col-6 col-md-3 col-xl-3 mt-4"> 
                         <h5 style={{color:"green"}}>ลาพักร้อน  {lev.lev_hot}  วัน</h5>  
                        </div>
                        <div className="col-12 col-md-3 col-xl-3 mt-4" > 
                         <h5 style={{color:"blue"}}> ลากิจ :  {lev.lev_gut}   วัน</h5>  
                        </div>
                        <div className="col-12 col-md-3 col-xl-3 mt-4"> 
                         <h5 style={{color:"red"}}> ลาป่วย :  {lev.lev_sick}  วัน</h5>  
                        </div>
                </div> */}
                    </div>
                    {/* <div className="col-5">
              <h4 className="modal-label">ประวัติลาย้อนหลัง </h4>
              </div> */}
                    <div className="col-1 mb-4">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                  </div>
                  {/* <div className="modal-header row">
                       <div className="col-6 col-md-3 col-xl-2 mt-4"> 
                         <h5 >วันลาคงเหลือ: </h5>  
                        </div>
                       <div className="col-6 col-md-3 col-xl-3 mt-4"> 
                         <h5 style={{color:"green"}}>ลาพักร้อน  {lev.lev_hot}  วัน</h5>  
                        </div>
                        <div className="col-12 col-md-3 col-xl-3 mt-4" > 
                         <h5 style={{color:"blue"}}> ลากิจ :  {lev.lev_gut}   วัน</h5>  
                        </div>
                        <div className="col-12 col-md-3 col-xl-3 mt-4"> 
                         <h5 style={{color:"red"}}> ลาป่วย :  {lev.lev_sick}  วัน</h5>  
                        </div>
            </div> */}
                  <div className="modal-body">
                    {/* <div className="row">
                <div className="col-12">
             
                  <h4>เลขที่ใบส่งของ{" : "}</h4>
                  <h4>วันที่ออกบิล{" : "}
                  </h4>
                  <h4>ชื่อลูกค้า{" : "}</h4>
                  <h4>หน้างาน{" : "}</h4>
                  <h4>มูลค่า{" : "}</h4>
                   <h4>สถานะ{" : "}</h4>
                  <h4>latitude{" : "}</h4>
                  <h4>longitude{" : "}</h4>
                  <h4>remark{" : "}</h4>
                 
                </div>
              </div> */}
                    <div
                      className="card-body table-responsive "
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <MDBDataTable
                        sortable={false}
                        id="table-to-xls1"
                        className="table table-head-fixed"
                        striped
                        bordered
                        hover
                        fixedHeader
                        data={alldata}
                      />
                    </div>
                  </div>
                  <div className="modal-footer " align="right">
                    <div className="row">
                      {/*      <div className="col-12" align="right"> */}
                      <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                        onClick={() => clearFilter()}
                      >
                        Close
                      </button>
                      {/*    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="modal-viewLeaveHistory">
              <div className="modal-dialog modal-xl">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-label">ประวัติการลา</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div
                        className="col-11 col-md-3 col-xl-2"
                        style={{ "z-index": dateZindex.dropdown1 }}
                      >
                        <div
                          className="form-group"
                          onClick={() => {
                            setdateZindex({
                              ...dateZindex,
                              dropdown1: "99",
                              datehis1: "98",
                              datehis2: "97",
                            });
                            dateZindex.dropdown1 = "99";
                            dateZindex.datehis1 = "98";
                            dateZindex.datehis2 = "97";
                          }}
                        >
                          <Select
                            options={LeaveTypeOption}
                            /*    ref = {select_matype} */
                            value={LeaveTypeOption.filter((e) => {
                              return e.value === filter_history.leave_type;
                            })}
                            onChange={(e) => {
                              //console.log(e);
                              if (e !== null) {
                                setfilter_history({
                                  ...filter_history,
                                  leave_type: e.value,
                                });
                              }
                            }}
                          />

                          <label htmlFor="">ประเภทการลา</label>
                        </div>
                      </div>

                      <div
                        className="col-11 col-md-3 col-xl-2"
                        style={{ "z-index": dateZindex.datehis1 }}
                      >
                        <div
                          className="form-group "
                          onClick={() => {
                            setdateZindex({
                              ...dateZindex,
                              dropdown1: "99",
                              datehis1: "98",
                              datehis2: "97",
                            });
                            dateZindex.dropdown1 = "99";
                            dateZindex.datehis1 = "98";
                            dateZindex.datehis2 = "97";
                          }}
                        >
                          <DatePicker
                            selected={filter_history.start_date}
                            className="form-control"
                            dateFormat={"dd-MM-yyyy"}
                            customInput={<CustomInput />}
                            onChange={async (date) => {
                              setfilter_history({
                                ...filter_history,
                                start_date: date,
                              });
                            }}
                            maxDate={filter_history.finish_date}
                          />
                          <label>เริ่มต้น</label>{" "}
                        </div>
                      </div>

                      {/*     <div className="col-1 col-md-0.5 col-xl-0.5"></div> */}

                      <div
                        className="col-11 col-md-3 col-xl-2"
                        style={{ "z-index": dateZindex.datehis2 }}
                      >
                        <div
                          className="form-group "
                          onClick={() => {
                            setdateZindex({
                              ...dateZindex,
                              dropdown1: "99",
                              datehis1: "98",
                              datehis2: "97",
                            });
                            dateZindex.dropdown1 = "99";
                            dateZindex.datehis1 = "98";
                            dateZindex.datehis2 = "97";
                          }}
                        >
                          <DatePicker
                            selected={filter_history.finish_date}
                            className="form-control"
                            dateFormat={"dd-MM-yyyy"}
                            customInput={<CustomInput />}
                            onChange={async (date) => {
                              setfilter_history({
                                ...filter_history,
                                finish_date: date,
                              });
                            }}
                            minDate={filter_history.start_date}
                          />
                          <label>สิ้นสุด</label>{" "}
                        </div>
                      </div>

                      <div className="col-4 col-md-3 col-xl-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info  "
                            onClick={() => {
                              getLeaveHistoryByEmpId();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>

                      <div className="col-4 col-md-3 col-xl-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            onClick={() => clearFilter_history()}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className=" table-responsive "
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <MDBDataTable
                        sortable={false}
                        id="table-to-xls"
                        className="table table-head-fixed"
                        striped
                        bordered
                        hover
                        fixedHeader
                        data={dataHistory}
                      />
                    </div>
                  </div>

                  <div className="modal-footer justify-content-between">
                    <div className="row">
                      <div className="col-4"></div>
                      <div className="col-1"> </div>
                      <div className="col-4"></div>
                    </div>

                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Leave_information;
