import React, { useEffect, useState, useRef } from "react";

import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../../config";
import {
  getOem,
  getToken,
  getUser,
} from "../../../../Utils/Common";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function Tracking_Status_TTT() {

  const closeModal = useRef();
  function closeMo() {
    closeModal.current.click();
  }

  const [test, settest] = useState({
    test: "",
  })
  const [OrderTrackingList, setOrderTrackingList] = useState([])
  const [OrderTrackingListSent, setOrderTrackingListSent] = useState([])

  const [FilterOrderTrackingList, setFilterOrderTrackingList] = useState({
    Order_Id: "",
    PO_No: "",
    User_Id: "",
    Supplier: "",
  })
  const [dateRange, setDateRange] = useState([null, null]);
  let [startDate, endDate] = dateRange;

  const [shipping, setshipping] = useState([]);

  const [dataShippingTrackingNo, setdataShippingTrackingNo] = useState({
    order_id: "",
    shippingName: "",
    trackingNo: "",
  })

  const [isSent, setisSent] = useState(false)
  const [sorting, setsorting] = useState(true)

  useEffect(() => {
    searchFilter();
  }, []);

  function searchFilter() {
    let data = {
      order_no: FilterOrderTrackingList.Order_Id.trim(),
      po_no: FilterOrderTrackingList.PO_No.trim(),
      user_name: FilterOrderTrackingList.User_Id.trim(),
      supplier: FilterOrderTrackingList.Supplier.trim(),
      start_date: startDate,
      end_date: endDate,
      oem_id: getOem(),
      company_id: getUser().com,
      fup: getUser().emp_id,
    }
    axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/orderInquiry/searchFilter",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        console.log(response.data);
        let data = [];
        let dataSent = [];
        for (let item of response.data) {
          data.push({
            ID: item.ol_id,
            Order_Id: item.ol_no,
            PO_No: item.po_no,
            PO_Date: item.po_created_date ? moment(item.po_created_date).format("DD/MM/YYYY, HH:mm น.") : "",
            User_Id: item.ol_emp_name,
            Address: item.ol_ea_adress,
            Product_No: item.product_no,
            Product_Name: item.ol_product_name,
            Tracking_Status: item.ol_status_id,
            Tracking_No: item.ol_tracking_no,
            Shipping_Name: item.ol_tracking_name,
            Supplier: item.sup_name,
            Update_Date: item.ol_tracking_update_date ? moment(item.ol_tracking_update_date).format("DD/MM/YYYY, HH:mm น.") : "",
          })
          if (item.ol_status_id === '2b7d431a-6697-4e33-bc41-a678bf9f28f5') {
            dataSent.push({
              ID: item.ol_id,
              Order_Id: item.ol_no,
              PO_No: item.po_no,
              PO_Date: item.po_created_date ? moment(item.po_created_date).format("DD/MM/YYYY, HH:mm น.") : "",
              User_Id: item.ol_emp_name,
              Address: item.ol_ea_adress,
              Product_No: item.product_no,
              Product_Name: item.ol_product_name,
              Tracking_Status: item.ol_status_id,
              Tracking_No: item.ol_tracking_no,
              Shipping_Name: item.ol_tracking_name,
              Supplier: item.sup_name,
              Update_Date: item.ol_tracking_update_date ? moment(item.ol_tracking_update_date).format("DD/MM/YYYY, HH:mm น.") : "",
            })
          }
        }
        setOrderTrackingList(data);
        setOrderTrackingListSent(dataSent);
        setsorting(true)
        settest({ ...test, test: "" })
        test.test = ""
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.response.data.error[0].errorDis,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }

  /* function clearFilter() {
    console.log("clear");
    settest({ ...test, test: "" })
    test.test = ""
    // setselectAll(false);
    setFilterOrderInquiry({
      product_no: "",
      product_name: "",
      user_redeem_name: "",
    });
    FilterOrderInquiry.product_no = "";
    FilterOrderInquiry.product_name = "";
    FilterOrderInquiry.user_redeem_name = "";
    setDateRange([null, null]);
    startDate = null;
    endDate = null;
    settest({ ...test, test: "" })
    test.test = ""
    FilterOrderList(2);
  } */
  function ShippingTracking() {
    const error_list = [];
    if (dataShippingTrackingNo.shippingName === "") {
      error_list.push("<br>" + "กรุณาเลือกชื่อบริษัทขนส่ง");
    }
    if (dataShippingTrackingNo.trackingNo === "") {
      error_list.push("<br>" + "กรุณากรอกเลขพัสดุ");
    }
    if (error_list.length > 0) {
      Swal.fire({
        title: "Error!",
        html: error_list,
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    let data = {
      order_id: dataShippingTrackingNo.order_id,
      shipping_name: dataShippingTrackingNo.shippingName === "" ? null : dataShippingTrackingNo.shippingName.trim(),
      tracking_no: dataShippingTrackingNo.trackingNo === "" ? null : dataShippingTrackingNo.trackingNo.trim(),
      company_id: getUser().com,
      oem_id: getOem(),
    }
    console.log(data);
    // let arrList = [];
    // arrList.push(data);
    /* let check = shipping.filter((item) => item.order_id === data.order_id);
    if (check.length > 0) {
      let index = shipping.findIndex((item) => item.order_id === data.order_id);
      shipping[index] = data;
      setshipping(shipping);
      settest({ ...test, test: "" })
      test.test = ""
    } else {
      setshipping([...shipping, data]);
      settest({ ...test, test: "" })
      test.test = ""
    } */
    axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/orderInquiry/updateTracking",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data === "OK") {
          searchFilter();
          /* Swal.fire({
            title: "Success!",
            text: "บันทึกข้อมูลสำเร็จ",
            icon: "success",
            confirmButtonText: "OK",
          })
            .then((result) => {
              if (result.isConfirmed) {
                searchFilter();
              }
            }); */
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.response.data.error[0].errorDis,
          icon: "error",
          confirmButtonText: "OK",
        });
      });


    closeMo();
  }

  /* function saveTrackingOption() {
    let data = {
      tracking: shipping,
    }
    console.log(data);
  } */
  const OrderTrackingData = [];
  if (isSent === true) {
    for (let index = 0; index < OrderTrackingList.length; index++) {
      const rowItem = {};
      rowItem["No"] = index + 1;
      rowItem["Order_Id"] = OrderTrackingList[index].Order_Id;
      rowItem["PO_No"] = OrderTrackingList[index].PO_No;
      rowItem["PO_Date"] = OrderTrackingList[index].PO_Date;
      rowItem["User_Id"] = OrderTrackingList[index].User_Id;
      rowItem["Address"] = OrderTrackingList[index].Address;
      rowItem["Product_No"] = OrderTrackingList[index].Product_No;
      rowItem["Product_Name"] = OrderTrackingList[index].Product_Name;
      rowItem["Tracking_Status"] = OrderTrackingList[index].Tracking_Status === '2b7d431a-6697-4e33-bc41-a678bf9f28f5'
        ?
        <>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-block btn-success"
                  style={{ fontSize: "30px", fontWeight: "bold" }}
                  data-toggle="modal"
                  data-target="#shipping-modal"
                  onClick={() => {
                    setdataShippingTrackingNo({
                      ...dataShippingTrackingNo,
                      order_id: OrderTrackingList[index].ID,
                      shippingName:
                        shipping.filter((item) => item.order_id === OrderTrackingList[index].ID).length > 0
                          ? shipping.filter((item) => item.order_id === OrderTrackingList[index].ID)[0].shippingName
                          : "",
                      trackingNo:
                        shipping.filter((item) => item.order_id === OrderTrackingList[index].ID).length > 0
                          ? shipping.filter((item) => item.order_id === OrderTrackingList[index].ID)[0].trackingNo
                          : "",
                    })
                    dataShippingTrackingNo.order_id = OrderTrackingList[index].ID;
                    dataShippingTrackingNo.shippingName =
                      shipping.filter((item) => item.order_id === OrderTrackingList[index].ID).length > 0
                        ? shipping.filter((item) => item.order_id === OrderTrackingList[index].ID)[0].shippingName
                        : "";
                    dataShippingTrackingNo.trackingNo =
                      shipping.filter((item) => item.order_id === OrderTrackingList[index].ID).length > 0
                        ? shipping.filter((item) => item.order_id === OrderTrackingList[index].ID)[0].trackingNo
                        : "";
                  }}
                >
                  Shipping
                </button>
              </div>
            </div>
          </div>
        </>
        : OrderTrackingList[index].Tracking_Status === '612884f0-493a-43c4-a2f9-1cd9314b8b31'
          ?
          <>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-block btn-success"
                    style={{ backgroundColor: "#808080", borderColor: "#808080", opacity: "1", fontSize: "30px" }}
                    disabled
                  >
                    Shipping
                  </button>
                </div>
              </div>
            </div>
          </>
          :
          <></>;
      rowItem["Tracking_No"] = OrderTrackingList[index].Tracking_No;
      rowItem["Shipping_Name"] = OrderTrackingList[index].Shipping_Name;
      rowItem["Supplier"] = OrderTrackingList[index].Supplier;
      rowItem["Update_Date"] = OrderTrackingList[index].Update_Date;

      OrderTrackingData.push(rowItem);
    }
  }
  else {
    for (let index = 0; index < OrderTrackingListSent.length; index++) {
      const rowItem = {};
      rowItem["No"] = index + 1;
      rowItem["Order_Id"] = OrderTrackingListSent[index].Order_Id;
      rowItem["PO_No"] = OrderTrackingListSent[index].PO_No;
      rowItem["PO_Date"] = OrderTrackingListSent[index].PO_Date;
      rowItem["User_Id"] = OrderTrackingListSent[index].User_Id;
      rowItem["Address"] = OrderTrackingListSent[index].Address;
      rowItem["Product_No"] = OrderTrackingListSent[index].Product_No;
      rowItem["Product_Name"] = OrderTrackingListSent[index].Product_Name;
      rowItem["Tracking_Status"] = OrderTrackingListSent[index].Tracking_Status === '2b7d431a-6697-4e33-bc41-a678bf9f28f5'
        ?
        <>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-block btn-success"
                  style={{ fontSize: "30px", fontWeight: "bold" }}
                  data-toggle="modal"
                  data-target="#shipping-modal"
                  onClick={() => {
                    setdataShippingTrackingNo({
                      ...dataShippingTrackingNo,
                      order_id: OrderTrackingListSent[index].ID,
                      shippingName:
                        shipping.filter((item) => item.order_id === OrderTrackingListSent[index].ID).length > 0
                          ? shipping.filter((item) => item.order_id === OrderTrackingListSent[index].ID)[0].shippingName
                          : "",
                      trackingNo:
                        shipping.filter((item) => item.order_id === OrderTrackingListSent[index].ID).length > 0
                          ? shipping.filter((item) => item.order_id === OrderTrackingListSent[index].ID)[0].trackingNo
                          : "",
                    })
                    dataShippingTrackingNo.order_id = OrderTrackingListSent[index].ID;
                    dataShippingTrackingNo.shippingName =
                      shipping.filter((item) => item.order_id === OrderTrackingListSent[index].ID).length > 0
                        ? shipping.filter((item) => item.order_id === OrderTrackingListSent[index].ID)[0].shippingName
                        : "";
                    dataShippingTrackingNo.trackingNo =
                      shipping.filter((item) => item.order_id === OrderTrackingListSent[index].ID).length > 0
                        ? shipping.filter((item) => item.order_id === OrderTrackingListSent[index].ID)[0].trackingNo
                        : "";
                  }}
                >
                  Shipping
                </button>
              </div>
            </div>
          </div>
        </>
        : OrderTrackingListSent[index].Tracking_Status === '612884f0-493a-43c4-a2f9-1cd9314b8b31'
          ?
          <>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-block btn-success"
                    style={{ backgroundColor: "#808080", borderColor: "#808080", opacity: "1", fontSize: "30px" }}
                    disabled
                  >
                    Shipping
                  </button>
                </div>
              </div>
            </div>
          </>
          :
          <></>;
      rowItem["Tracking_No"] = OrderTrackingListSent[index].Tracking_No;
      rowItem["Shipping_Name"] = OrderTrackingListSent[index].Shipping_Name;
      rowItem["Supplier"] = OrderTrackingListSent[index].Supplier;
      rowItem["Update_Date"] = OrderTrackingListSent[index].Update_Date;

      OrderTrackingData.push(rowItem);
    }
  }
  const OrderTrackingTable = {
    columns: [
      {
        label: "No",
        field: "No",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Id",
        field: "Order_Id",
        sort: "asc",
        width: 150,
      },
      {
        label: "PO No",
        field: "PO_No",
        sort: "asc",
        width: 150,
      },
      {
        label: "PO Date",
        field: "PO_Date",
        sort: "asc",
        width: 150,
      },
      {
        label: "User Id",
        field: "User_Id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Address",
        field: "Address",
        sort: "asc",
        width: 150,
      },
      {
        label: "Product No",
        field: "Product_No",
        sort: "asc",
        width: 150,
      },
      {
        label: "Product Name",
        field: "Product_Name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Tracking Status",
        field: "Tracking_Status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Tracking No",
        field: "Tracking_No",
        sort: "asc",
        width: 150,
      },
      {
        label: "Shipping Name",
        field: "Shipping_Name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Supplier",
        field: "Supplier",
        sort: "asc",
        width: 150,
      },
      {
        label: "Update Date",
        field: "Update_Date",
        sort: "asc",
        width: 150,
      }
    ],
    rows: OrderTrackingData,
  };

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        style={{ fontSize: "inherit" }}
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
        data-provide="datepicker"
        data-date-language="th-th"
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h1>Tracking Status</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    incentive
                  </li>
                  <li className="breadcrumb-item active">
                    Purchase Order
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-4 col-xl-2" style={{ marginTop: "19px" }}>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  id='Inquiry_No'
                  name='Inquiry_No'
                  required="false"
                  value={FilterOrderTrackingList.Order_Id}
                  onChange={(e) => {
                    setFilterOrderTrackingList({
                      ...FilterOrderTrackingList,
                      Order_Id: e.target.value,
                    });
                    FilterOrderTrackingList.Order_Id = e.target.value;
                  }}
                />
                <label htmlFor=''>Order Id</label>
              </div>
            </div>
            <div className="col-12 col-md-4 col-xl-2" style={{ marginTop: "19px" }}>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  id='Inquiry_No'
                  name='Inquiry_No'
                  required="false"
                  value={FilterOrderTrackingList.PO_No}
                  onChange={(e) => {
                    setFilterOrderTrackingList({
                      ...FilterOrderTrackingList,
                      PO_No: e.target.value,
                    });
                    FilterOrderTrackingList.PO_No = e.target.value;
                  }}
                />
                <label htmlFor=''>PO No</label>
              </div>
            </div>
            <div className="col-12 col-md-4 col-xl-3">
              <label style={{ margin: "0px 0px" }}>PO Date</label>
              <div className="form-group" style={{ fontSize: "initial", margin: "0px 0px", padding: "0px 0px", zIndex: "99" }}>

                <DatePicker
                  className="form-control"
                  wrapperClassName="w-100"
                  locale="th"
                  id="redeem_date"
                  dateFormat={"dd-MM-yyyy"}
                  value={
                    startDate && endDate ? `${moment(startDate).format("DD/MM/YYYY")} - ${moment(endDate).format("DD/MM/YYYY")}` : ""
                  }
                  selected={startDate}
                  onChange={async (update) => {
                    console.log(update);
                    setDateRange(update);
                  }}
                  shouldCloseOnSelect={
                    !(!startDate || Boolean(startDate && endDate))
                  }
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange={true}
                  // isClearable={true}
                  showMonthDropdown
                  showYearDropdown
                  disabledKeyboardNavigation
                  yearDropdownItemNumber={15}
                  scrollableYearDropdown
                  // todayButton="Today"
                  customInput={<CustomInput />}
                />
              </div>
            </div>
            <div className="col-12 col-md-4 col-xl-2" style={{ marginTop: "19px" }}>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  id='Inquiry_No'
                  name='Inquiry_No'
                  required="false"
                  value={FilterOrderTrackingList.User_Id}
                  onChange={(e) => {
                    setFilterOrderTrackingList({
                      ...FilterOrderTrackingList,
                      User_Id: e.target.value,
                    });
                    FilterOrderTrackingList.User_Id = e.target.value;
                  }}
                />
                <label htmlFor=''>User Id</label>
              </div>
            </div>
            <div className="col-12 col-md-4 col-xl-2" style={{ marginTop: "19px" }}>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  id='Inquiry_No'
                  name='Inquiry_No'
                  required="false"
                  value={FilterOrderTrackingList.Supplier}
                  onChange={(e) => {
                    setFilterOrderTrackingList({
                      ...FilterOrderTrackingList,
                      Supplier: e.target.value,
                    });
                    FilterOrderTrackingList.Supplier = e.target.value;
                  }}
                />
                <label htmlFor=''>Supplier</label>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-4 col-xl-2">
              <div className='form-group'>
                <button
                  type='button'
                  className='btn btn-block btn-info'
                  onClick={() => {
                    searchFilter();
                  }}
                >
                  Search
                </button>
              </div>
            </div>
            <div className="col-12 col-md-4 col-xl-2">
              <div className='form-group'>
                <button
                  type='button'
                  className='btn btn-block btn-info'
                  onClick={() => {
                    setFilterOrderTrackingList({
                      Order_Id: "",
                      PO_No: "",
                      User_Id: "",
                      Supplier: "",
                    });
                    FilterOrderTrackingList.Order_Id = "";
                    FilterOrderTrackingList.PO_No = "";
                    FilterOrderTrackingList.User_Id = "";
                    FilterOrderTrackingList.Supplier = "";
                    setDateRange([]);
                    startDate = null;
                    endDate = null;
                    searchFilter();
                    // clearFilter();
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
            {OrderTrackingList.length === 0 ? (
              <>
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-block btn-primary"
                    // disabled={disable}
                    >
                      Download Excel
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group">
                    <ReactHTMLTableToExcel
                      // isDisabled={disable}
                      id="test-table-xls-button"
                      className="btn btn-block btn-primary"
                      table="OrderInquiryTableForExcel"
                      filename={"PO" + moment(new Date()).format("DD-MM-yy")
                      }
                      sheet={"PO" + moment(new Date()).format("DD-MM-yy")}
                      buttonText="Download Excel"
                    />
                  </div>
                </div>
              </>
            )}
            <div className="col-6 col-md-4 col-xl-2">
              <div className="form-group">
                <input
                  type="checkbox"
                  id="chkbox"
                  name="chkbox"
                  className="form-control"
                  style={{height:"20px", marginTop:"5px", marginLeft:"0px"}}
                  checked={isSent}
                  onChange={(e) => {
                    setisSent(e.target.checked);
                    setsorting(false);
                    searchFilter();
                  }}
                />
                <label style={{marginTop:"25px"}} htmlFor="chkbox">Show All</label>
              </div>
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive " style={{ whiteSpace: 'nowrap' }}>
                      <MDBDataTable
                        striped
                        bordered
                        hover
                        data={OrderTrackingTable}
                        // paging={false}
                        id="OrderInquiryTableForExcel"
                        searching={false}
                        sortable={false}
                        disableRetreatAfterSorting={sorting}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="modal fade" id="shipping-modal">
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Order Tracking Detail</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12 col-md-12 col-xl-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="TrackingNo"
                          name="TrackingNo"
                          required
                          value={dataShippingTrackingNo.trackingNo}
                          onChange={(e) => {
                            setdataShippingTrackingNo({
                              ...dataShippingTrackingNo,
                              trackingNo: e.target.value,
                            });
                            dataShippingTrackingNo.trackingNo = e.target.value;
                          }}
                        // readOnly
                        />
                        <label htmlFor="">Tracking No</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12 col-xl-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="ShippingName"
                          name="ShippingName"
                          required
                          value={dataShippingTrackingNo.shippingName}
                          onChange={(e) => {
                            setdataShippingTrackingNo({
                              ...dataShippingTrackingNo,
                              shippingName: e.target.value,
                            });
                            dataShippingTrackingNo.shippingName = e.target.value;
                          }}
                        />
                        <label htmlFor="">Shipping Name</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer justify-content-between">
                  <button type="button" className="btn btn-primary" onClick={() => { ShippingTracking() }} >Save</button>
                  <button type="button" className="btn btn-default" data-dismiss="modal" ref={closeModal}>Close</button>
                </div>
              </div>
            </div>
          </div>




        </section>
      </div>
    </div>

  )
}

export default Tracking_Status_TTT